import { capitalCase } from 'change-case';
import { endOfMonth, startOfDay } from 'date-fns';
import { ArrayElement, months } from 'src/domain';
import { MaybeNumber } from 'src/domain/types/maybe-types';

export const getNext12MonthKeys = () => {
  const today = new Date();

  const next12MonthKeys = Array.from(Array(12).keys()).map((i) => {
    const nextMonth = new Date();

    nextMonth.setDate(1);
    nextMonth.setMonth(today.getMonth() + i);

    const monthKey = formatDateToMonthKey(nextMonth);

    return monthKey;
  });

  return next12MonthKeys;
};

export const formatDateToMonthKey = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() is zero-based
  const paddedMonth = month.toString().padStart(2, '0'); // 8 -> 08, 12 -> 12

  const yearMonthStr = year.toString() + paddedMonth;

  const yearMonth = parseInt(yearMonthStr);

  return yearMonth;
};

export const formatMonthKeyToDate = (monthkey: MaybeNumber) => {
  const date = monthkey?.toString().match(/(\d\d\d\d)(\d\d)/);
  const month = date?.[2];
  const year = date?.[1];

  if (!month || !year) return undefined;

  return new Date(parseInt(year), parseInt(month) - 1, 1);
};

export const monthKeyToString = (monthKey: number) => {
  // get month: 202203 => 3
  const monthNumber = monthKey % 100;

  // Month 3 (March) is index 2
  const monthIndex = monthNumber - 1;

  const monthString = months[monthIndex].toLowerCase() as Uncapitalize<ArrayElement<typeof months>>;

  return monthString;
};

export const monthKeyToTitle = (monthKey: number) => {
  let fieldName = monthKeyToField(monthKey);
  if (!fieldName.match(/\d{4}$/)) {
    fieldName = fieldName + new Date().getFullYear();
  }

  return capitalCase(fieldName, {
    splitRegexp: /([a-z])([A-Z0-9])/g,
  });
};

export const monthKeyToField = (monthKey: number) => {
  const monthString = monthKeyToString(monthKey);
  const monthKeyHasYear = monthKey.toString().length > 4;
  const year = monthKeyHasYear ? Number(monthKey.toString().slice(0, 4)) : new Date().getFullYear();
  return `${monthString}${year}`;
};

export const monthFieldToDate = (monthField: string) => {
  const month = monthField.slice(0, -4);
  const year = monthField.slice(-4);
  const monthIndex = months.findIndex((m) => m.toLowerCase() === month);
  return new Date(parseInt(year), monthIndex, 1);
};

export const monthFieldToPrettyName = (monthField: string) => {
  const month = monthField.slice(0, -4);
  const year = monthField.slice(-4);
  return `${capitalCase(month)} ${year}`;
};

export const monthKeyToDateRange = (monthKey: number) => {
  const month = getMonthFromMonthKey(monthKey);
  const yearStr = getYearFromMonthKey(monthKey);

  if (!month || !yearStr) {
    throw new Error('Invalid monthKey supplied');
  }

  const monthIndex = month - 1;
  const year = Number(yearStr);

  const monthStartDate = new Date(year, monthIndex, 1);
  // endOfMonth set the timestamp to end of day - we normalize that to start of day
  const monthEndDate = startOfDay(endOfMonth(monthStartDate));

  return { monthStartDate, monthEndDate };
};

// get month: 202203 => 3 or  202212 => 12
export const getMonthFromMonthKey = (monthKey: MaybeNumber) => monthKey && monthKey % 100;

export const getYearFromMonthKey = (monthkey: MaybeNumber) => monthkey && monthkey.toString().slice(0, 4);
