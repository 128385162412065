import { ColDef } from 'ag-grid-community';
import { round } from 'lodash';

export interface GetHeatMapColorOptions {
  minHue?: number;
  maxHue?: number;
  minSaturation?: number;
  maxSaturation?: number;
  minLightness?: number;
  maxLightness?: number;
  minAlpha?: number;
  maxAlpha?: number;
}

export function getHeatMapColor(
  value: number,
  {
    minHue = 101,
    maxHue = 101,
    minSaturation = 36,
    maxSaturation = 36,
    minLightness = 57,
    maxLightness = 57,
    minAlpha = 0.2,
    maxAlpha = 1,
  }: GetHeatMapColorOptions = {}
) {
  // Lower values are closer to minHue and higher values are closer to maxHue
  const h = round(minHue + value * (maxHue - minHue));

  // Higher values have higher saturation
  const s = round(minSaturation + value * (maxSaturation - minSaturation));

  // Lower values have higher lightness value
  const l = round(maxLightness - value * (maxLightness - minLightness));

  // Larger values have higher alpha value
  const a = minAlpha + value * (maxAlpha - minAlpha);

  return `hsla(${h}, ${s}%, ${l}%, ${a})`;
}

export const defaultColDef: ColDef = {
  wrapHeaderText: true,
  headerClass: '',
  initialWidth: 100,
  suppressAutoSize: true,
  suppressHeaderMenuButton: true,
};

export const categoryColumn: ColDef = {
  field: 'category',
  width: 150,
  suppressSizeToFit: true,
  pinned: 'left',
  lockPinned: true,
  cellStyle({ value }) {
    if (['Left', 'Right'].includes(value)) {
      return {
        paddingLeft: '2rem',
      };
    }
  },
};
