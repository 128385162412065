import { sortBy } from 'lodash';
import { ArrayElement } from 'src/domain';
import { WeekCoverList } from 'src/domain/models/week-cover-list';
import { TableOverviewRow } from 'src/domain/table/table-overview-row';
import { StyleCategoryModel } from 'src/infrastructure/rest-api/api-types';
import { monthKeyToField } from 'src/utils/monthKeys';

export function groupByStyleCategory(
  data: WeekCoverList,
  styleCategories: StyleCategoryModel[]
): Map<number, ArrayElement<WeekCoverList>[]> {
  const sortedStyleCategories = sortBy(data, (value, _) => {
    const categorySort = styleCategories.find((x) => x?.id === value?.styleCategoryId)?.order;
    return categorySort;
  });

  return sortedStyleCategories?.reduce((acc, item) => {
    const styleCategoryId = item?.styleCategoryId;

    if (!styleCategoryId) {
      return acc;
    }

    const items = acc.get(styleCategoryId) ?? [];
    items.push(item);

    acc.set(styleCategoryId, items);

    return acc;
  }, new Map<number, ArrayElement<WeekCoverList>[]>());
}

export const mapWeekCoverToTableData = (
  list: WeekCoverList,
  styleCategories: StyleCategoryModel[] | undefined
): TableOverviewRow[] => {
  if (!list || !styleCategories) return [];

  const groupedByCategory = groupByStyleCategory(list, styleCategories);

  return Array.from(groupedByCategory.entries()).reduce((acc: TableOverviewRow[], [categoryId, weekCover]) => {
    const row: TableOverviewRow = {
      id: categoryId,
      category: styleCategories?.find((styleCategory) => styleCategory?.id === categoryId)?.name ?? '',
      columns: {},
    };

    weekCover.forEach((item) => {
      if (!item) return;
      const fieldName = monthKeyToField(item.monthKey);
      row.columns[fieldName] = parseFloat(item.weekCover.toFixed(2));
      row.columns[`${fieldName}Id`] = item.id;
    });

    acc.push(row as TableOverviewRow);

    return acc;
  }, []);
};
