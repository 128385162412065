import styled from '@emotion/styled';
import { ListItem, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { UnsavedChangesBadge } from 'src/components/molecules/Badge/UnsavedChangesBadge';
import { Color } from 'src/domain';

const defaultIndentIndex = 8;
const defaultLength = 216;
const defaultHight = 30;
interface SidebarListItemProps {
  text: string | null;
  indent?: 1 | 2;
  hasChanges?: boolean;
  isActive: boolean;
  to: {
    pathname: string;
    search: string;
  };
}

export const SidebarListItem: FC<SidebarListItemProps> = ({ text = '', hasChanges = false, to, isActive, indent }) => {
  return (
    <StyledListItem disablePadding active={isActive ? 1 : 0} indent={indent}>
      <StyledNavLink to={to} active={isActive ? 1 : 0} onClick={(e) => isActive && e.preventDefault()}>
        <StyledListItemButton>
          {hasChanges && <UnsavedChangesBadge hasChanges anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }} />}
          <Tooltip title={text ?? ''} placement="right-start" enterDelay={1500}>
            <StyledListItemText active={isActive ? 1 : 0} primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }}>
              {text}
            </StyledListItemText>
          </Tooltip>
        </StyledListItemButton>
      </StyledNavLink>
    </StyledListItem>
  );
};

const StyledListItemText = styled(ListItemText)<{ active: 1 | 0 }>`
  padding-left: 16px;
  color: ${({ active }) => (active ? '#333333' : Color.white)};
  width: 100%;
`;

const StyledNavLink = styled(NavLink)<{ active: 1 | 0; indent?: 1 | 2 }>`
  height: ${defaultHight}px;
  border-radius: 8px;
  padding: 0px;
  width: 100%;
  background-color: ${({ active }) => (active ? Color.white : '#C4C4C4')};
`;

const StyledListItem = styled(ListItem)<{ active: 1 | 0; indent?: 1 | 2 }>`
  height: ${defaultHight}px;
  border-radius: 8px;
  padding: 0px;
  margin: 1px 0px;
  margin-left: ${({ indent }) => (indent ? defaultIndentIndex * indent : 0)}px;
  width: ${({ indent }) => (indent ? defaultLength - defaultIndentIndex * indent : defaultLength)}px;
  background-color: ${({ active }) => (active ? Color.white : '#C4C4C4')};
`;

const StyledListItemButton = styled(ListItemButton)`
  width: '100%';
  border-radius: 8px;
  padding: 4px 8px;
  height: ${defaultHight}px;
`;
