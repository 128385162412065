import { deepClone } from '@datadog/browser-core';
import { isNumber } from 'lodash';
import { useCallback } from 'react';
import { Cell } from 'src/components/organisms/DataTable/types';
import { Module } from 'src/domain';
import { UnsavedChangesEvent } from 'src/domain/events/unsaved-changes.event';
import { OpenToBuyLimitOverviewRow } from 'src/domain/table/open-to-buy-limit.row';
import { useUnsavedChangesModule } from 'src/hooks';
import { useOpenToBuyLimitOverviewSimulationRows } from 'src/hooks/open-to-buy-limit/simulation/useOpenToBuyLimitOverviewSimulationRow';

export const useOpenToBuyLimitHandleCellValueChanged = () => {
  const [_, setUnsavedChanges] = useUnsavedChangesModule<OpenToBuyLimitOverviewRow[]>(Module.OpenToBuyLimit);

  const { data } = useOpenToBuyLimitOverviewSimulationRows() ?? {};

  const callback = useCallback(
    async (cellValues: Cell[]) => {
      if (!data) {
        throw new Error('Data is missing!');
      }

      const updatedData = deepClone(data);

      cellValues.forEach((cellValue) => {
        const rowIndexToUpdate = updatedData.findIndex((x) => x.id === cellValue.rowId);
        const fieldName: string | undefined = cellValue.column;

        if (fieldName && rowIndexToUpdate !== -1) {
          const currentRow = updatedData?.[rowIndexToUpdate];

          const expressRowIndex = updatedData.findIndex(
            (row) => row.category === currentRow?.category && row.orderMonth === null
          );

          const columns = currentRow.columns;
          const month = fieldName.split('.')[1];

          const categoryRows = updatedData.filter(
            (row) => row.category === currentRow.category && row.orderMonth !== null
          );

          const currentSum = categoryRows.reduce((acc, row) => {
            if (isNumber(row.columns[month])) {
              if (row.id === cellValue.rowId) {
                acc += isNaN(cellValue.value) ? 0 : cellValue.value;
              } else {
                acc += isNaN(row.columns[month]) ? 0 : row.columns[month];
              }
            }
            return acc;
          }, 0);

          const expressValue = currentSum < 100 && currentSum >= 0 ? 100 - currentSum : 0;

          const valueIsValid = !isNaN(cellValue.value) && isNumber(Number(cellValue.value));

          updatedData[rowIndexToUpdate] = {
            ...currentRow,
            columns: {
              ...columns,
              [month]: valueIsValid ? cellValue.value : 0,
            },
          };

          updatedData[expressRowIndex] = {
            ...updatedData?.[expressRowIndex],
            columns: {
              ...updatedData?.[expressRowIndex].columns,
              [month]: expressValue,
            },
          };
        }
      });

      setUnsavedChanges(updatedData);

      dispatchEvent(new Event(UnsavedChangesEvent));
    },
    [data, setUnsavedChanges]
  );

  return callback;
};
