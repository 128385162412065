import styled from '@emotion/styled';
import { Rating } from '@mui/material';
import { IDoesFilterPassParams } from 'ag-grid-community';
import { CustomFilterProps, useGridFilter } from 'ag-grid-react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';

export const RatingFilter = forwardRef(({ onModelChange, colDef }: CustomFilterProps, ref) => {
  const [filterRatingOneStar, setFilterRatingOneStar] = useState<boolean>(true);
  const [filterRatingTwoStars, setFilterRatingTwoStars] = useState<boolean>(true);
  const [filterRatingThreeStars, setFilterRatingThreeStars] = useState<boolean>(true);
  const [filterRatingFourStars, setFilterRatingFourStars] = useState<boolean>(true);
  const [filterRatingFiveStars, setFilterRatingFiveStars] = useState<boolean>(true);

  const className = 'ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper';
  const classNameChecked = 'ag-checked';
  const filterRatingAll = useMemo(() => {
    return (
      filterRatingOneStar &&
      filterRatingTwoStars &&
      filterRatingThreeStars &&
      filterRatingFourStars &&
      filterRatingFiveStars
    );
  }, [filterRatingFiveStars, filterRatingFourStars, filterRatingOneStar, filterRatingThreeStars, filterRatingTwoStars]);

  const doesFilterPass = useCallback(
    (params: IDoesFilterPassParams) => {
      if (filterRatingAll || !colDef.field) return true;
      const value = params.data[colDef.field];

      const oneStarFilterCheck = value === 1;
      const twoStarsFilterCheck = value === 2;
      const threeStarsFilterCheck = value === 3;
      const fourStarsFilterCheck = value === 4;
      const fiveStarsFilterCheck = value === 5;

      if (oneStarFilterCheck && filterRatingOneStar) return true;
      if (twoStarsFilterCheck && filterRatingTwoStars) return true;
      if (threeStarsFilterCheck && filterRatingThreeStars) return true;
      if (fourStarsFilterCheck && filterRatingFourStars) return true;
      if (fiveStarsFilterCheck && filterRatingFiveStars) return true;
      return false;
    },
    [
      colDef.field,
      filterRatingAll,
      filterRatingFiveStars,
      filterRatingFourStars,
      filterRatingOneStar,
      filterRatingThreeStars,
      filterRatingTwoStars,
    ]
  );

  useImperativeHandle(ref, () => ({
    getModel: () => ({
      filterRatingOneStar,
      filterRatingTwoStars,
      filterRatingThreeStars,
      filterRatingFourStars,
      filterRatingFiveStars,
    }),
    doesFilterPass: (params: IDoesFilterPassParams) => doesFilterPass(params),
    isFilterActive: () => !filterRatingAll,
  }));

  const handleSelectAll = () => {
    if (filterRatingAll) {
      setFilterRatingOneStar(false);
      setFilterRatingTwoStars(false);
      setFilterRatingThreeStars(false);
      setFilterRatingFourStars(false);
      setFilterRatingFiveStars(false);
    } else {
      setFilterRatingOneStar(true);
      setFilterRatingTwoStars(true);
      setFilterRatingThreeStars(true);
      setFilterRatingFourStars(true);
      setFilterRatingFiveStars(true);
    }
  };

  const onChange = (value: number) => {
    switch (value) {
      case 1:
        setFilterRatingOneStar((prev) => !prev);
        break;

      case 2:
        setFilterRatingTwoStars((prev) => !prev);
        break;

      case 3:
        setFilterRatingThreeStars((prev) => !prev);
        break;

      case 4:
        setFilterRatingFourStars((prev) => !prev);
        break;

      case 5:
        setFilterRatingFiveStars((prev) => !prev);
        break;
    }
  };

  useEffect(() => {
    onModelChange({
      filterRatingOneStar,
      filterRatingTwoStars,
      filterRatingThreeStars,
      filterRatingFourStars,
      filterRatingFiveStars,
    });
  }, [
    onModelChange,
    filterRatingAll,
    filterRatingOneStar,
    filterRatingTwoStars,
    filterRatingThreeStars,
    filterRatingFourStars,
    filterRatingFiveStars,
  ]);

  useGridFilter({
    doesFilterPass,
  });
  return (
    <Wrapper>
      <Row>
        <div
          className={`${className} ${filterRatingAll ? classNameChecked : ''}`}
          id="ag-markdown-filter-select-all-input"
          onClick={() => handleSelectAll()}
        ></div>
        <label className="ag-input-field-label ag-label ag-checkbox-label" id="ag-markdown-filter-select-all-input">
          (Select All)
        </label>
      </Row>
      <Row>
        <div
          className={`${className} ${filterRatingOneStar ? classNameChecked : ''}`}
          onClick={() => onChange(1)}
        ></div>
        <Rating name="read-only" value={1} readOnly />
      </Row>
      <Row>
        <div
          className={`${className} ${filterRatingTwoStars ? classNameChecked : ''}`}
          onClick={() => onChange(2)}
        ></div>
        <Rating name="read-only" value={2} readOnly />
      </Row>
      <Row>
        <div
          className={`${className} ${filterRatingThreeStars ? classNameChecked : ''}`}
          onClick={() => onChange(3)}
        ></div>
        <Rating name="read-only" value={3} readOnly />
      </Row>
      <Row>
        <div
          className={`${className} ${filterRatingFourStars ? classNameChecked : ''}`}
          onClick={() => onChange(4)}
        ></div>
        <Rating name="read-only" value={4} readOnly />
      </Row>
      <Row>
        <div
          className={`${className} ${filterRatingFiveStars ? classNameChecked : ''}`}
          onClick={() => onChange(5)}
        ></div>
        <Rating name="read-only" value={5} readOnly />
      </Row>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
