import React, { createContext, FC, PropsWithChildren, useState } from 'react';

export const MockContext = createContext<
  [Record<string, unknown>, React.Dispatch<React.SetStateAction<Record<string, unknown>>>]
>([
  {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

export const MockProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const contextValue = useState({});
  return <MockContext.Provider value={contextValue}>{children}</MockContext.Provider>;
};
