import { useChainId } from 'src/hooks/chain';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';

export const useProductLineGroupsApiQuery = () => {
  const chainId = useChainId();
  const { data, loading, error, refetch } = useApiQuery('/api/productlinegroups', 'get', {
    query: {
      chainId: chainId,
    },
    enabled: !!chainId,
  });
  return { data, loading, error, refetch };
};
