import {
  StyleTimelineStorePlacementDetailEnum,
  StyleTimelineStorePlacementEnum,
} from 'src/infrastructure/rest-api/api-types';

export const placements = [
  {
    storePlacement: 'FRONT',
    placementSort: 1,
  },
  {
    storePlacement: 'MID',
    placementSort: 2,
  },
  {
    storePlacement: 'BACK',
    placementSort: 3,
  },
  {
    storePlacement: 'STOCK_ROOM',
    placementSort: 4,
  },
];

export const formatPlacementEnum = (placement: StyleTimelineStorePlacementEnum | undefined) => {
  switch (placement) {
    case 'FRONT':
      return 'Front';
    case 'MID':
      return 'Mid';
    case 'BACK':
      return 'Back';
    case 'STOCK_ROOM':
      return 'Stock Room';
  }
};

export const formatPlacementDetailEnum = (placement: StyleTimelineStorePlacementDetailEnum | undefined | null) => {
  switch (placement) {
    case 'LEFT':
      return 'Left';
    case 'RIGHT':
      return 'Right';
    default:
      return undefined;
  }
};
