import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useStyleTimelineCollectionsQuery } from './useStyleTimelineCollectionsQuery';
import { useStyleTimelineLevelQuery } from './useStyleTimelineLevelQuery';

export const useStyleTimelineQuery = () => {
  const { data, error, loading } = useStyleTimelineLevelQuery() ?? {};
  const {
    data: styleCategories,
    loading: styleCategoriesLoading,
    error: styleCategoriesError,
  } = useStyleCategoriesQuery();
  const {
    data: collections,
    loading: collectionsLoading,
    error: collectionsError,
  } = useStyleTimelineCollectionsQuery();

  return {
    styleTimelines: data,
    collections: collections,
    styleCategories: styleCategories,
    loading: loading || collectionsLoading || styleCategoriesLoading,
    error: error ?? collectionsError ?? styleCategoriesError,
  };
};
