import _ from 'lodash';
import { useState } from 'react';
import { useSnackbar } from './snackbar/useSnackbar';

export const useSessionStorage = <T>(key: string, defaultValue: T): [T, (value: T | ((newValue: T) => T)) => void] => {
  const [storedValue, setStoredValue] = useState<T>(getContentInSessionStorage<T>(key, defaultValue));
  const showSnackbar = useSnackbar();

  const setValue = (value: T | ((newValue: T) => T)): void => {
    try {
      setStoredValue(value);
      window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error: unknown) {
      showSnackbar(
        'Operation failed. Too many active changes. Try to make changes in smaller batches by saving before retrying the operation again.',
        'error'
      );

      // Log the error to get statistics in datadog
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return [storedValue, setValue];
};

export const getContentInSessionStorage = <T>(key: string, defaultValue: T) => {
  try {
    const item = window.sessionStorage.getItem(key);
    if (item) {
      return JSON.parse(item) as T;
    } else {
      if (!_.isEmpty(defaultValue)) {
        window.sessionStorage.setItem(key, JSON.stringify(defaultValue));
      }
      return defaultValue;
    }
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.error(error);

    return defaultValue;
  }
};
