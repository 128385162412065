import { Stack } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { FC, useMemo } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { calculateAveragesWithPlannedSplits } from 'src/components/organisms/DataTable/aggregators/average-total.agg';
import { defaultCategoryColumn } from 'src/components/organisms/DataTable/columns/columns-overview-sum';
import { DefaultColumnsOptionsOverviewPercentage } from 'src/components/organisms/DataTable/columns/default-options';
import { parsePercentageValueToNumber } from 'src/components/organisms/DataTable/columns/utils';
import { averageCellRenderer } from 'src/components/organisms/DataTable/helpers/average-cell-renderer';
import GrossMargin from 'src/components/organisms/GrossMargin';
import InheritanceDropdown from 'src/components/organisms/InheritanceDropdown';
import { InheritanceTable } from 'src/components/organisms/InheritanceTable/InheritanceTable';
import Topbar from 'src/components/organisms/Topbar';
import { StyledHeaderWrapper } from 'src/components/styled/StyledHeaderWrapper';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { Headings, Module } from 'src/domain';
import { useHasUnsavedChangesGenderProductline } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useMarkdownReset } from 'src/hooks/markdown/reset/useMarkdownReset';
import { useMarkdownSaveChanges } from 'src/hooks/markdown/save-changes/useMarkdownSaveChanges';
import { useMarkdownOverviewSimulationRows } from 'src/hooks/markdown/simulation/useMarkdownOverviewSimulationRows';
import { useMarkdownUpdateInheritance } from 'src/hooks/markdown/split-level/useMarkdownUpdateInheritance';
import { useMarkdownHandleCellChanges } from 'src/hooks/markdown/useHandleCellValueChangedMarkDown';
import { useModulePlanningRange } from 'src/hooks/useModulePlanningRange';

export const MarkDown: FC = () => {
  const {
    data: overviewData,
    columns: monthColumns,
    loading: overviewLoading,
  } = useMarkdownOverviewSimulationRows() ?? {};
  const planningRange = useModulePlanningRange(Module.Markdown);

  const handleCellValueChanges = useMarkdownHandleCellChanges();
  const { updateInheritance, loading: updateInheritanceLoading } = useMarkdownUpdateInheritance();
  const { resetMarkdown, loading: resetLoading } = useMarkdownReset();

  const [saveChanges, { loading: savingChanges }] = useMarkdownSaveChanges();
  const onDiscardChanges = useDiscardChangesModule(Module.Markdown);

  const hasUnsavedChanges = useHasUnsavedChangesGenderProductline();
  const loading = overviewLoading || updateInheritanceLoading || resetLoading || savingChanges;

  const overviewRows = !loading ? overviewData : undefined;

  const columns = useMemo(() => {
    return [
      {
        ...defaultCategoryColumn,
        cellRenderer: (params: ICellRendererParams) => {
          return params.value ?? 'Average';
        },
      },
      ...monthColumns.map((column) => {
        return {
          ...column,
          cellRenderer: (params: ICellRendererParams) => averageCellRenderer(column, params),
          valueParser: parsePercentageValueToNumber,
        };
      }),
    ];
  }, [monthColumns]);

  return (
    <Stack spacing={2}>
      <Topbar
        onDiscard={onDiscardChanges}
        onSave={saveChanges}
        saveDisabled={!hasUnsavedChanges}
        onReset={resetMarkdown}
        onSetInheritance={updateInheritance}
        loading={loading}
        saveLoading={savingChanges}
      />

      <Container>
        <StyledHeaderWrapper>
          <Header heading={Headings.h2}>Markdown</Header>
          <InheritanceDropdown />
        </StyledHeaderWrapper>
        <TableWrapper className="ag-theme-alpine-custom-compact">
          <DataTable
            autoSizeToFit={planningRange === 'TWELVE_MONTHS'}
            headerHeight={60}
            columns={columns}
            rows={overviewRows}
            defaultColDef={DefaultColumnsOptionsOverviewPercentage}
            onCellValuesChanged={handleCellValueChanges}
            loading={loading}
            getGroupRowAgg={calculateAveragesWithPlannedSplits}
            grandTotalRow="bottom"
          />
        </TableWrapper>
      </Container>

      <GrossMargin includeMarkdown />
      <InheritanceTable moduleString="markdown" />
    </Stack>
  );
};
