import { HelpOutline } from '@mui/icons-material';
import { Alert, IconButton, Stack } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Select, { GroupedOption } from 'src/components/atoms/Select/Select';
import { Inheritance, InheritanceError, InheritanceOption } from 'src/domain';
import {
  useCurrentModule,
  useDefaultInheritance,
  useInheritance,
  useInheritanceOptions,
  useSetSelectedInheritance,
} from 'src/hooks';
import { getInheritanceWarning } from 'src/utils/inheritance-help';
import { getInheritanceValue } from 'src/utils/inheritance-options';
import { InheritanceHelpDialog } from './InheritanceHelpDialog';

interface Props {
  storeName?: string | null;
  countryName?: string | null;
  clusterName?: string | null;
  onSelect?: (selected: Inheritance) => unknown;
  active?: Inheritance | null;
}

const InheritanceDropdown: FC<Props> = (props) => {
  const options = useInheritanceOptions({ ...props });
  const nonGroupedOptions: GroupedOption[] = useMemo(
    () =>
      options
        .filter((x) => typeof x.value === 'string')
        .map((x) => ({
          isHeader: false,
          value: x.value as Inheritance,
          display: x.display,
        })),
    [options]
  );

  const groupedOptions = useMemo(
    () =>
      options
        .filter((x) => typeof x.value !== 'string')
        .flatMap((x) => (typeof x.value === 'string' ? x : [x, ...x.value]))
        .map<GroupedOption>((x) => ({
          isHeader: typeof x.value === 'object',
          display: x.display,
          value: x.value as Inheritance,
        })),
    [options]
  );
  const mappedOptions = useMemo(() => [...nonGroupedOptions, ...groupedOptions], [nonGroupedOptions, groupedOptions]);

  const flattedOptions = useMemo(
    () => options.flatMap((x) => (typeof x.value === 'string' ? x : [x, ...x.value])),
    [options]
  );

  const inheritance = useInheritance();

  const inheritanceOption = useMemo<InheritanceOption | undefined>(() => {
    const option = flattedOptions.find((x) => x.value === inheritance);

    if (!inheritance || !option) return;

    return { value: inheritance, display: option.display };
  }, [inheritance, flattedOptions]);

  const setInheritance = useSetSelectedInheritance();
  const setInheritanceOption = useCallback(
    (option: InheritanceOption) => {
      setInheritance(option.value as Inheritance); // safe type casting, groups can't be selected
    },
    [setInheritance]
  );

  // update active item
  useEffect(() => {
    // ignore undefined
    if (!props.active) return;

    const activeOption = options.find((x) => x.value === props.active);
    if (!activeOption) throw new InheritanceError(`Could not find option for inheritance ${props.active}`);

    // ignore same option
    if (inheritance === activeOption.value) return;

    setInheritanceOption(activeOption);
  }, [inheritance, options, props.active, setInheritanceOption]);

  const handleSelect = useCallback(
    (item: string) => {
      const selected = flattedOptions.find((x) => x.value === item);

      if (!selected) throw new InheritanceError('Could not find selected inheritance');

      setInheritanceOption(selected);
      props.onSelect?.(selected.value as Inheritance); // safe type casting, groups can't be selected
    },
    [flattedOptions, props, setInheritanceOption]
  );

  const [isInheritanceHelpOpen, setIsInheritanceHelpOpen] = useState(false);

  const showInheritanceHelp = useCallback(() => {
    setIsInheritanceHelpOpen(true);
  }, []);
  const hideInheritanceHelp = useCallback(() => {
    setIsInheritanceHelpOpen(false);
  }, []);

  const activeItem = useMemo(() => {
    return getInheritanceValue(inheritanceOption);
  }, [inheritanceOption]);

  const module = useCurrentModule();
  const defaultInheritance = useDefaultInheritance();
  const inheritanceWarning = useMemo(() => {
    return getInheritanceWarning(module, defaultInheritance, inheritance);
  }, [defaultInheritance, inheritance, module]);

  return (
    <>
      <Stack direction={'row'} alignItems={'center'}>
        {inheritanceWarning && <Alert severity="warning">{inheritanceWarning}</Alert>}
        <IconButton size="small" onClick={showInheritanceHelp}>
          <HelpOutline fontSize="small" />
        </IconButton>
        <Select
          label="Inheritance"
          options={mappedOptions}
          activeItem={activeItem}
          onSelect={handleSelect}
          minWidth="150px"
        />
      </Stack>
      <InheritanceHelpDialog open={isInheritanceHelpOpen} onClose={hideInheritanceHelp} />
    </>
  );
};

export default InheritanceDropdown;
