import { GridOptions } from 'ag-grid-community';
import { customRowGroupHeight } from './row-height-options';

export const defaultRowGroupOptions: GridOptions = {
  getRowHeight: customRowGroupHeight,
  suppressAggFuncInHeader: true,
  groupRemoveSingleChildren: true,
  groupDefaultExpanded: 1,
  showOpenedGroup: true,
  groupDisplayType: 'singleColumn',
  groupRowRendererParams: {
    suppressCount: true,
  },
};
