import { useCallback, useContext } from 'react';
import { StoreCacheContext } from 'src/contexts/StoreCacheContext';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { useClusterId } from '../cluster/useClusterId';
import { useCompositePartner } from '../partner/useCompositePartner';
import { useUnsavedChangesClusterManagement } from '../unsaved-changes/useUnsavedChangesClusterManagement';
import { useSelectedPartner } from '../useSelectedPartner';
import { useClustersByCompositeId } from './useClustersByCompositeId';

type DeleteClusterMethodParams = {
  clusterId: number;
};

export const useDeleteCluster = () => {
  const [_, { refetch }] = useContext(StoreCacheContext);
  const [partner] = useSelectedPartner();
  const compositePartner = useCompositePartner(partner);
  const clusterId = useClusterId(partner);
  const [unsavedChanges, setUnsavedChanges] = useUnsavedChangesClusterManagement();
  const { stores } = useClustersByCompositeId();

  const [deleteCluster] = useApiMutation('/api/clusters/{id}', 'delete', {
    update(_data, _variables, queryClient) {
      const partnerId = compositePartner?.id;
      const queryKey = [`/api/clusters/partners/${partnerId}`];
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({ queryKey: [`/api/turnover/partners/${partnerId}/stores`] });
      queryClient.invalidateQueries({ queryKey: [`/api/turnover/partners/${partnerId}`] });
      queryClient.invalidateQueries({ queryKey: [`/api/turnover/clusters/${clusterId}`] });
    },
  });

  const deleteRemoteCluster = useCallback(
    ({ clusterId }: DeleteClusterMethodParams) => {
      deleteCluster({
        path: { id: clusterId },
      }).then(() => {
        if (stores && stores.length > 0) {
          refetch();
        }
        const newUnsavedChanges = { ...unsavedChanges };
        delete newUnsavedChanges[clusterId];
        setUnsavedChanges(newUnsavedChanges);
      });
    },
    [deleteCluster, refetch, setUnsavedChanges, stores, unsavedChanges]
  );

  const deleteLocalCluster = useCallback(
    ({ clusterId }: DeleteClusterMethodParams) => {
      const newUnsavedChanges = { ...unsavedChanges };
      delete newUnsavedChanges[clusterId];
      setUnsavedChanges(newUnsavedChanges);
    },
    [setUnsavedChanges, unsavedChanges]
  );

  const deleteClusterMethod = useCallback(
    (params: DeleteClusterMethodParams) => {
      if (params.clusterId >= 0) {
        deleteRemoteCluster(params);
      } else {
        deleteLocalCluster(params);
      }
    },
    [deleteLocalCluster, deleteRemoteCluster]
  );

  return deleteClusterMethod;
};
