import styled from '@emotion/styled';
import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import Spacer from 'src/components/atoms/Spacer';
import { Color, Spacings } from 'src/domain';
import { PlanningLevelItemsType } from 'src/domain/models/split-modules';
import { useCreateNewBuyingEvent } from 'src/hooks/buying-event-managment/create/useCreateNewBuyingEvent';
import { useUpdateBuyingEvent } from 'src/hooks/buying-event-managment/update/useUpdateBuyingEvent';
import { formatDateToMonthKey } from 'src/utils/monthKeys';

interface Props {
  id?: number | null;
  eventName?: string | null;
  eventOrderMonth?: Date | null;
  initialDeliveryRangeStart?: Date | null;
  initialDeliveryRangeEnd?: Date | null;
  plannedOnIds?: number[] | null;
  planningLevelList: PlanningLevelItemsType | undefined;
  open: boolean;
  handleCloseDialog: () => void;
  handleRefetch: () => void;
}

export const BuyingEventDialog: FC<Props> = ({
  eventName,
  eventOrderMonth,
  initialDeliveryRangeStart,
  initialDeliveryRangeEnd,
  plannedOnIds,
  open,
  planningLevelList,
  handleCloseDialog,
  handleRefetch,
  id,
}) => {
  const today = useMemo(() => new Date(), []);
  const tomorrow = useMemo(() => new Date(today.getTime() + 24 * 60 * 60 * 1000), [today]);

  const [error, setError] = useState(false);
  const [orderMonth, setOrderMonth] = useState(eventOrderMonth ?? today);
  const [deliveryRangeStart, setDeliveryRangeStart] = useState(initialDeliveryRangeStart ?? today);
  const [deliveryRangeEnd, setDeliveryRangeEnd] = useState(initialDeliveryRangeEnd ?? tomorrow);
  const [name, setName] = useState(eventName ?? '');
  const [plannedOnIdsList, setPlannedOnIdsList] = useState<number[]>(plannedOnIds ?? []);
  const [dialogOpen, setDialogOpen] = useState(open ?? false);
  const isEditing = id !== undefined;

  const [createEvent, { loading: creatingEvent }] = useCreateNewBuyingEvent({
    initialDeliveryRangeStart: formatDateToMonthKey(deliveryRangeStart),
    initialDeliveryRangeEnd: formatDateToMonthKey(deliveryRangeEnd),
    name,
    orderMonth: formatDateToMonthKey(orderMonth),
    plannedOnIds: plannedOnIdsList,
  });

  const [updateEvent, { loading: updatingEvent }] = useUpdateBuyingEvent({
    id,
    name,
    initialDeliveryRangeStart: formatDateToMonthKey(deliveryRangeStart),
    initialDeliveryRangeEnd: formatDateToMonthKey(deliveryRangeEnd),
    orderMonth: formatDateToMonthKey(orderMonth),
    plannedOnIds: plannedOnIdsList,
  });

  const loading = updatingEvent || creatingEvent;

  // make sure deliveryRangeEnd is always later than deliveryRangeStart
  useEffect(() => {
    if (deliveryRangeStart > deliveryRangeEnd)
      setDeliveryRangeEnd(new Date(deliveryRangeStart.getTime() + 24 * 60 * 60 * 1000));
  }, [deliveryRangeEnd, deliveryRangeStart]);

  useEffect(() => {
    if (eventName) setName(eventName);
    if (eventOrderMonth) setOrderMonth(eventOrderMonth);
    if (initialDeliveryRangeStart) setDeliveryRangeStart(initialDeliveryRangeStart);
    if (initialDeliveryRangeEnd) setDeliveryRangeEnd(initialDeliveryRangeEnd);
    if (plannedOnIds) setPlannedOnIdsList(plannedOnIds);
    if (open) setDialogOpen(open);
  }, [initialDeliveryRangeEnd, initialDeliveryRangeStart, eventName, eventOrderMonth, plannedOnIds, open]);

  const handleSelectedItem = useCallback(
    (id: number) => {
      const index = plannedOnIdsList.findIndex((item) => item === id);
      const newArray = [...plannedOnIdsList];
      if (index !== -1) {
        newArray.splice(index, 1);
      } else {
        newArray.push(id);
      }
      setPlannedOnIdsList(newArray);
    },
    [plannedOnIdsList]
  );

  const closeDialogue = useCallback(() => {
    setDialogOpen(false);
    handleCloseDialog();
  }, [handleCloseDialog]);

  const resetEvent = useCallback(() => {
    setOrderMonth(today);
    setDeliveryRangeStart(today);
    setDeliveryRangeEnd(tomorrow);
    setPlannedOnIdsList([]);
    setName('');
    setError(false);
  }, [today, tomorrow]);

  const onCancel = useCallback(() => {
    closeDialogue();
    resetEvent();
  }, [closeDialogue, resetEvent]);

  const handleSaveEvent = useCallback(async () => {
    const response = id !== undefined ? await updateEvent() : await createEvent();

    if (response) {
      handleRefetch();
      closeDialogue();
      resetEvent();
    }
  }, [closeDialogue, createEvent, handleRefetch, id, resetEvent, updateEvent]);

  const canSave = useCallback(() => {
    if (!name || !orderMonth || !deliveryRangeStart || !deliveryRangeEnd || !plannedOnIdsList.length) return false;
    return true;
  }, [deliveryRangeEnd, deliveryRangeStart, name, orderMonth, plannedOnIdsList.length]);

  const showOverwriteWarning = useMemo(() => {
    if (id !== undefined && initialDeliveryRangeStart && initialDeliveryRangeEnd) {
      return initialDeliveryRangeStart < deliveryRangeStart || initialDeliveryRangeEnd > deliveryRangeEnd;
    }
    return false;
  }, [initialDeliveryRangeEnd, initialDeliveryRangeStart, deliveryRangeEnd, deliveryRangeStart, id]);

  return (
    <Dialog open={dialogOpen} onClose={closeDialogue} data-testid="buyingEventDialog">
      <DialogTitle>{isEditing ? 'Edit buying event' : 'Create buying event'}</DialogTitle>
      <DialogContent sx={{ width: '400px', maxWidth: '100%', boxSizing: 'border-box' }}>
        <Collapse in={showOverwriteWarning} timeout={1000} sx={{ marginBottom: showOverwriteWarning ? '10px' : '0px' }}>
          <Alert severity="warning">
            These changes made to the delivery range may overwrite Open To Buy Limits outside of the new range.
          </Alert>
        </Collapse>
        <Stack gap={'0.75rem'}>
          <FormFieldWrapper>
            <FormLabel>Event name</FormLabel>
            <TextField
              size="small"
              id="standard-basic"
              data-testid="buyingEventName"
              placeholder="New event name"
              error={error}
              onBlur={() => {
                if (!name) setError(true);
                else setError(false);
              }}
              onChange={(e) => {
                setName(e.target.value);
                if (e.target.value) setError(false);
              }}
              value={name}
              helperText={error ? 'Cannot be empty' : undefined}
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormLabel>Order month</FormLabel>
            <StyledDatePicker
              selected={orderMonth}
              onChange={(date: Date) => date && setOrderMonth(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          </FormFieldWrapper>
          <DeliveryRange>
            <Typography variant="h6">Delivery range</Typography>

            <FormFieldWrapper>
              <FormLabel>Start</FormLabel>
              <StyledDatePicker
                selected={deliveryRangeStart}
                onChange={(date: Date) => date && setDeliveryRangeStart(date)}
                selectsStart
                startDate={deliveryRangeStart}
                endDate={deliveryRangeEnd}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                customInput={<input data-testid="deliveryRangeStart" type="text" />}
              />
            </FormFieldWrapper>

            <FormFieldWrapper>
              <FormLabel>End</FormLabel>
              <StyledDatePicker
                selected={deliveryRangeEnd}
                onChange={(date: Date) => date && setDeliveryRangeEnd(date)}
                selectsEnd
                startDate={deliveryRangeStart}
                endDate={deliveryRangeEnd}
                minDate={deliveryRangeStart}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                customInput={<input data-testid="deliveryRangeEnd" type="text" />}
              />
            </FormFieldWrapper>
          </DeliveryRange>
          <Spacer spacing={Spacings.xSmall} />
          <Wrapper>
            <TagWrapper>
              {planningLevelList?.map((item) => (
                <Tag
                  key={item.id}
                  onClick={() => handleSelectedItem(item.id)}
                  selected={plannedOnIdsList.includes(item.id)}
                >
                  {item.name}
                </Tag>
              ))}
            </TagWrapper>
          </Wrapper>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<Save />}
          variant="contained"
          type="submit"
          disabled={!canSave()}
          onClick={handleSaveEvent}
        >
          {isEditing ? 'Update' : 'Save'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const StyledDatePicker = styled(ReactDatePicker)`
  border-radius: 4px;
  border: 0.8px solid rgba(0, 0, 0, 0.23);
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.9rem;
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  padding: 8.5px 14px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;

  &:hover {
    border: 0.8px solid rgba(0, 0, 0, 0.87);
  }
`;

const DeliveryRange = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #787c8d;
  gap: 0.75rem;
  margin-top: 8px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.span<{ selected: boolean }>`
  border: ${(props) => (props.selected ? `1px solid ${Color.secondary}` : '1px solid #787c8d')};
  color: ${(props) => (props.selected ? `${Color.secondary}` : '#787c8d')};
  background-color: ${(props) => (props.selected ? 'rgba(68, 114, 196, 0.2)' : '#ffffff')};
  ${(props) =>
    props.selected &&
    `
    font-weight: bold;
  `};
  border-radius: 3px;
  padding: 5px 10px;
  width: fit-content;
  margin-left: 2px;
  margin-top: 2px;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    background-color: rgba(68, 114, 196, 0.1);
  }
`;

const FormFieldWrapper = styled(Stack)`
  gap: 0.15rem;
  width: 100%;
`;
