import LockIcon from '@mui/icons-material/Lock';
import { FC, useMemo } from 'react';
import { Module } from 'src/domain/enums';
import { useCurrentModule } from 'src/hooks';
import { Tip } from './Tip';

export const LockIconTip: FC = () => {
  const currentModule = useCurrentModule();

  const showLockIcon = useMemo(() => {
    switch (currentModule) {
      case Module.WeekCover:
      case Module.DeliveryProfile:
      case Module.Turnover:
        return false;
    }
    return true;
  }, [currentModule]);

  if (!showLockIcon) {
    return <></>;
  }

  return (
    <Tip>
      A cell with user entries will have this icon: <LockIcon sx={{ fontSize: 15 }}></LockIcon>
    </Tip>
  );
};
