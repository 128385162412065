import { Module, SessionStorageGenderProductLineType } from 'src/domain';
import { StockManagementRow } from 'src/domain/table/stock-management.row';
import { useUnsavedChangesModule } from '../unsaved-changes';
import { useStockManagementTableKey } from './table/useStockManagementTableKey';

export const useStockManagementHasUnsavedChanges = () => {
  const tableKey = useStockManagementTableKey();
  const [unsavedChanges] = useUnsavedChangesModule<SessionStorageGenderProductLineType<StockManagementRow[]>>(
    Module.StockManagement
  );

  return !!unsavedChanges && !!unsavedChanges?.[tableKey];
};
