import { useCallback } from 'react';
import { Inheritance, Module, ScopeEnum } from 'src/domain';
import {
  useCurrentId,
  useCurrentModule,
  useCurrentSplitLevelQuery,
  useCurrentStore,
  useScope,
  useSelectedPartner,
} from 'src/hooks';
import { InheritanceLevelModel, UpdateInheritanceLevelInput } from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { moduleToSplitKey } from 'src/utils/modules';
import {
  inheritanceToSplitLevel,
  noosInheritanceToSplitLevel,
  salesBudgetInheritanceToSplitLevel,
} from 'src/utils/splitLevels';
import { useEntityType } from '../useEntityType';

export const useUpdateSplitLevel = () => {
  const scope = useScope();
  const store = useCurrentStore();
  const currentModule = useCurrentModule();
  const { data: currentSplitLevel } = useCurrentSplitLevelQuery();

  const currentId = useCurrentId();
  const entityType = useEntityType();
  const [partner] = useSelectedPartner();

  const [updateSplitLevel, { loading }] = useApiMutation('/api/inheritance', 'put', {
    update: (_data, _variables, queryClient) => {
      const query = {
        entityId: currentId,
        entityType,
      };

      if (scope === ScopeEnum.PARTNER) {
        query['entityId'] = partner?.compositeId;
      }

      queryClient.invalidateQueries({
        queryKey: ['/api/inheritance', query],
      });

      queryClient.invalidateQueries({
        queryKey: ['/api/inheritances', { partnerId: partner?.compositeId }],
      });

      if (currentModule === Module.Turnover && scope === ScopeEnum.STORE && store) {
        queryClient.invalidateQueries({
          queryKey: [`/api/turnover/partners/${store.operationalResponsibleComposite.id}`],
        });
        queryClient.invalidateQueries({ queryKey: [`/api/turnover/clusters/${store.cluster.id}`] });
      }
    },
  });

  const update = useCallback(
    (inheritance: Inheritance) => {
      if (!currentSplitLevel || !currentModule || !scope) {
        return Promise.reject(
          new Error(
            `Can't update split level. Missing required data. [currentSplitLevel=${currentSplitLevel}, currentModule=${currentModule}, scope=${scope}]`
          )
        );
      }

      const updatedSplitLevel = mapSplitLevelToUpdateSplitLevel(scope, currentSplitLevel, currentModule, inheritance);

      return updateSplitLevel({
        body: [updatedSplitLevel],
      });
    },
    [currentModule, currentSplitLevel, scope, updateSplitLevel]
  );

  return { updateSplitLevel: update, loading };
};

function mapSplitLevelToUpdateSplitLevel(
  scope: ScopeEnum,
  currentSplitLevel: InheritanceLevelModel,
  currentModule: Module,
  updatedInheritance: Inheritance
): UpdateInheritanceLevelInput {
  const splitLevelUpdate = {
    entityId: currentSplitLevel.entityId,
    entityType: currentSplitLevel.entityType,
    categorySplit: {
      splitLevel: currentSplitLevel.categorySplit.splitLevel,
    },
    deliveryProfile: {
      splitLevel: currentSplitLevel.deliveryProfile.splitLevel,
    },
    genderSplit: {
      splitLevel: currentSplitLevel.genderSplit.splitLevel,
    },
    markup: {
      splitLevel: currentSplitLevel.markup.splitLevel,
    },
    markdown: {
      splitLevel: currentSplitLevel.markdown.splitLevel,
    },
    noosShare: {
      splitLevel: currentSplitLevel.noosShare.splitLevel,
      noosSplit: currentSplitLevel.noosShare.noosSplit,
    },
    productLineSplit: {
      splitLevel: currentSplitLevel.productLineSplit.splitLevel,
    },
    salesBudget: {
      salesBudgetSplit: currentSplitLevel.salesBudget.salesBudgetSplit,
    },
    weekCover: {
      splitLevel: currentSplitLevel.weekCover.splitLevel,
    },
  };

  if (currentModule === Module.NoosShare) {
    const noosHistoricalSplitLevel = noosInheritanceToSplitLevel(updatedInheritance);
    splitLevelUpdate.noosShare.splitLevel = inheritanceToSplitLevel(updatedInheritance, scope);

    if (noosHistoricalSplitLevel) {
      splitLevelUpdate.noosShare.noosSplit = noosHistoricalSplitLevel;
    }
  } else if (currentModule === Module.Turnover) {
    splitLevelUpdate.salesBudget.salesBudgetSplit = salesBudgetInheritanceToSplitLevel(updatedInheritance);
  } else {
    const splitKey = moduleToSplitKey(currentModule);

    if (splitKey) {
      splitLevelUpdate[splitKey] = {
        splitLevel: inheritanceToSplitLevel(updatedInheritance, scope),
      };
    }
  }

  return splitLevelUpdate;
}
