import styled from '@emotion/styled';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import Spacer from 'src/components/atoms/Spacer';
import { Color, Spacings, months } from 'src/domain';
import { PlanningLevelItemsType } from 'src/domain/models/split-modules';
import { MaybeBoolean, MaybeNumber, MaybeNumberArray, MaybeString } from 'src/domain/types/maybe-types';
import { getMonthFromMonthKey, getYearFromMonthKey } from 'src/utils/monthKeys';
import { BuyingEventPlannedItems } from '../BuyingEventPlannedItems/BuyingEventPlannedItems';

interface Props {
  id: MaybeNumber;
  name: MaybeString;
  orderDate: MaybeNumber;
  deliveryRangeStart: MaybeNumber;
  deliveryRangeEnd: MaybeNumber;
  items: MaybeNumberArray;
  isDefault: MaybeBoolean;
  planningLevelList: PlanningLevelItemsType | undefined;
  onDeleteEvent: (id: MaybeNumber, name: MaybeString) => void;
  onEditEvent: (
    id: MaybeNumber,
    name: MaybeString,
    orderDate: MaybeNumber,
    deliveryRangeStart: MaybeNumber,
    deliveryRangeEnd: MaybeNumber,
    items: MaybeNumberArray,
    isDefault: MaybeBoolean
  ) => void;
}

export const BuyingEventListItem: FC<Props> = ({
  id,
  name,
  orderDate,
  deliveryRangeStart,
  deliveryRangeEnd,
  items,
  isDefault,
  planningLevelList,
  onDeleteEvent,
  onEditEvent,
}) => {
  const orderMonth = getMonthFromMonthKey(orderDate);
  const orderYear = getYearFromMonthKey(orderDate);

  const orderDateString = () => {
    if (!orderMonth || !orderYear) return undefined;
    return `${months?.[orderMonth - 1]?.slice(0, 3)}, ${orderYear}`;
  };

  const monthStart = getMonthFromMonthKey(deliveryRangeStart);
  const yearStart = getYearFromMonthKey(deliveryRangeStart);

  const buyingStart = () => {
    if (!monthStart || !yearStart) return undefined;
    return `${months?.[monthStart - 1]?.slice(0, 3)}, ${yearStart}`;
  };

  const monthEnd = getMonthFromMonthKey(deliveryRangeEnd);
  const yearEnd = getYearFromMonthKey(deliveryRangeEnd);

  const buyingEnd = () => {
    if (!monthEnd || !yearEnd) return undefined;
    return `${months?.[monthEnd - 1]?.slice(0, 3)}, ${yearEnd}`;
  };

  const buyingString = () => {
    if (!buyingStart() || !buyingEnd()) return undefined;
    return `${buyingStart()} - ${buyingEnd()}`;
  };

  return (
    <ListBox isdefault={isDefault}>
      <ItemContainer>
        <div />
        <StyledTypography>
          {name}
          {isDefault && <DefaultSpan>(default)</DefaultSpan>}
        </StyledTypography>
        <StyledTypography>{orderDateString()}</StyledTypography>
        <StyledTypography>{buyingString()}</StyledTypography>
        <BuyingEventPlannedItems items={items} planningLevelList={planningLevelList} />
        {!isDefault && (
          <ActionButtonsWrapper>
            <EditButton
              htmlColor={Color.editBlue}
              onClick={() => {
                onEditEvent(id, name, orderDate, deliveryRangeStart, deliveryRangeEnd, items, isDefault);
              }}
            />
            <Spacer spacing={Spacings.xSmall} />
            <DeleteButton
              color="error"
              onClick={() => {
                onDeleteEvent(id, name);
              }}
            />
          </ActionButtonsWrapper>
        )}
      </ItemContainer>
    </ListBox>
  );
};

const ItemContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.2fr 1.5fr 0.6fr 1.2fr 2fr 0.4fr 0.2fr;
  align-items: center;
`;

const DefaultSpan = styled.span`
  font-size: 12px;
  margin-left: 10px;
`;

const ListBox = styled(Box, {
  shouldForwardProp(propName) {
    return propName !== 'isdefault';
  },
})<{ isdefault?: boolean | null }>`
  background-color: ${(props) => (props.isdefault ? '#f7f7f7' : '#ffffff')};
  display: flex;
  align-items: center;
  border-radius: 3px;
  border: 2px solid #f5f5f5;
  height: 80px;
  margin-top: 6px;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
`;

const DeleteButton = styled(Delete)`
  cursor: pointer;
  &:hover {
    color: rgb(229, 91, 91);
  }
`;

const EditButton = styled(Edit)`
  cursor: pointer;
  &:hover {
    color: #719ce6;
  }
`;

const StyledTypography = styled(Typography)`
  color: #787c8d;
`;
