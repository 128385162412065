/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { Module } from 'src/domain';
import { IsHistoricalContext } from '../historical/IsHistoricalContext';

export const useInitializeIsHistorical = () => {
  const [isHistoricalMap, setIsHistoricalMap] = useContext(IsHistoricalContext);

  useEffect(() => {
    // delivery profile and week cover do not use historical, so always set value to false
    isHistoricalMap.set(Module.DeliveryProfile, false);
    isHistoricalMap.set(Module.WeekCover, false);
    isHistoricalMap.set(Module.OptimalOptionCount, false);
    setIsHistoricalMap(new Map(isHistoricalMap));
  }, []);
};
