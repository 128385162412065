import { useCallback } from 'react';
import { SessionStorageType } from 'src/domain';
import { ClusterManagementChange } from 'src/domain/models/cluster-management-change';
import { ClusterManagementStorageKey } from 'src/domain/session-storage/cluster-management-key';
import { useSelectedPartner } from '../useSelectedPartner';
import { useUnsavedChanges } from '../useUnsavedChanges';

export const useClusterManagementDiscardChanges = () => {
  const [partner] = useSelectedPartner();
  const moduleKey = ClusterManagementStorageKey;

  const [unsavedChangesForLevel, setUnsavedChangesForLevel] =
    useUnsavedChanges<SessionStorageType<ClusterManagementChange>>(moduleKey);

  const discardChanges = useCallback(() => {
    if (!partner?.id) return;
    if (!moduleKey) return;

    const currentChanges = { ...unsavedChangesForLevel };

    delete currentChanges[partner?.id];

    setUnsavedChangesForLevel(currentChanges);
  }, [partner?.id, moduleKey, unsavedChangesForLevel, setUnsavedChangesForLevel]);

  return discardChanges;
};
