import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { ComparableCell } from 'src/components/molecules/ComparableCell/ComparableCell';
import { EditablePercentageCell } from 'src/components/molecules/EditableCell/EditableCell';
import {
  DefaultColumn,
  DefaultColumnNumeric,
} from 'src/components/organisms/DataTable/columns/default-columns-details';
import { isEditableWithFooter } from 'src/components/organisms/DataTable/columns/is-editable';
import { avgAggFunc, sumAggFunc } from 'src/utils/ag-grid/agg-functions';
import { comparableCellAvg, comparableCellSum } from 'src/utils/comparableCellAgg';

export const GenderDetailsColumnDefinition: ColDef[] = [
  {
    ...DefaultColumn,
    field: 'category',
    width: 150,
    headerName: 'Gender',
    suppressSizeToFit: true,
    cellRenderer: (params: ICellRendererParams) => {
      return params.value ?? 'Sum';
    },
    aggFunc: sumAggFunc,
  },
  {
    ...DefaultColumnNumeric,
    field: 'splitLly',
    headerName: 'Split LLY',
    aggFunc: sumAggFunc,
  },
  {
    ...DefaultColumnNumeric,
    field: 'splitLy',
    headerName: 'Split LY',
    aggFunc: sumAggFunc,
  },
  {
    ...DefaultColumnNumeric,
    field: 'plannedSplit',
    headerName: 'Planned Split',
    aggFunc: sumAggFunc,
    cellRenderer: EditablePercentageCell,
    editable: isEditableWithFooter,
  },
  {
    ...DefaultColumnNumeric,
    field: 'plannedIndex',
    headerName: 'Planned Index',
    cellRenderer: ComparableCell,
    aggFunc: comparableCellAvg,
  },
  {
    ...DefaultColumnNumeric,
    field: 'plannedSalesIv',
    headerName: 'Planned Sales iV',
    cellRenderer: ComparableCell,
    aggFunc: comparableCellSum,
  },
  {
    ...DefaultColumnNumeric,
    field: 'salesIvLy',
    headerName: 'Sales iV LY',
    aggFunc: sumAggFunc,
  },
  {
    ...DefaultColumnNumeric,
    field: 'indexLy',
    headerName: 'Index LY',
    aggFunc: avgAggFunc,
  },
  {
    ...DefaultColumnNumeric,
    field: 'gmLy',
    headerName: 'GM % LY',
  },
];
