import { useMemo } from 'react';
import { Partner, ScopeEnum } from 'src/domain';
import { ClusterError } from 'src/domain/errors/Cluster.error';
import { useCurrentStore, useFilteredStores } from '../store';
import { useScope } from '../useScope';

export const useClusterId = (partner: Partner | null | undefined) => {
  const [stores] = useFilteredStores();
  const currentStore = useCurrentStore();
  const scope = useScope();

  const clusterId = useMemo(() => {
    switch (scope) {
      case ScopeEnum.STORE:
        return currentStore?.cluster?.id;

      case ScopeEnum.PARTNER:
        // Cluster id is not relevant on partner level - so we return undefined
        return;

      case ScopeEnum.CLUSTER: {
        if (!partner?.compositeId) return;
        if (!stores || stores.isEmpty()) return;

        const partnerStores = stores.filter(
          (store) => store.operationalResponsibleComposite?.id === partner?.compositeId
        );

        const clusterId = partnerStores.first()?.cluster?.id;

        if (!partnerStores.every((store) => store.cluster?.id === clusterId))
          throw new ClusterError(
            'Could not determine the clusterId for this partner. Please make sure that the cluster name is unique for this partner and chain.'
          );

        return clusterId;
      }
    }
  }, [currentStore?.cluster?.id, partner, scope, stores]);

  return clusterId ?? null;
};
