import styled from '@emotion/styled';
import { ComponentProps, FC } from 'react';
import Spacer from 'src/components/atoms/Spacer';
import { useFilterContext } from 'src/contexts/FilterContext';
import { Color, Spacings } from 'src/domain';

interface Props extends ComponentProps<'div'> {
  tabOneTitle: string;
  tabTwoTitle: string;
  tabThreeTitle: string;
  tabFourTitle: string;
  tabFiveTitle: string;
}

const Tabs: FC<Props> = ({ tabOneTitle, tabTwoTitle, tabThreeTitle, tabFourTitle, tabFiveTitle }) => {
  const [filterContext, updateFilterContext] = useFilterContext();

  return (
    <StyledTabs>
      <TabTitle
        active={filterContext.activeTab.ownStoresTab}
        onClick={() => {
          updateFilterContext({
            ...filterContext,
            activeTab: {
              ownStoresTab: true,
              partnersTab: false,
              semiOwnedTab: false,
              wholesaleTab: false,
              ownEComTab: false,
            },
          });
        }}
      >
        {tabTwoTitle}
      </TabTitle>
      <Spacer spacing={Spacings.small} />
      <TabTitle
        active={filterContext.activeTab.partnersTab}
        onClick={() => {
          updateFilterContext({
            ...filterContext,
            activeTab: {
              ownStoresTab: false,
              partnersTab: true,
              semiOwnedTab: false,
              wholesaleTab: false,
              ownEComTab: false,
            },
          });
        }}
      >
        {tabOneTitle}
      </TabTitle>
      <Spacer spacing={Spacings.small} />
      <TabTitle
        active={filterContext.activeTab.semiOwnedTab}
        onClick={() => {
          updateFilterContext({
            ...filterContext,
            activeTab: {
              ownStoresTab: false,
              partnersTab: false,
              semiOwnedTab: true,
              wholesaleTab: false,
              ownEComTab: false,
            },
          });
        }}
      >
        {tabThreeTitle}
      </TabTitle>
      <Spacer spacing={Spacings.small} />
      <TabTitle
        active={filterContext.activeTab.wholesaleTab}
        onClick={() => {
          updateFilterContext({
            ...filterContext,
            activeTab: {
              ownStoresTab: false,
              partnersTab: false,
              semiOwnedTab: false,
              wholesaleTab: true,
              ownEComTab: false,
            },
          });
        }}
      >
        {tabFourTitle}
      </TabTitle>
      <Spacer spacing={Spacings.small} />
      <TabTitle
        active={filterContext.activeTab.ownEComTab}
        onClick={() => {
          updateFilterContext({
            ...filterContext,
            activeTab: {
              ownStoresTab: false,
              partnersTab: false,
              semiOwnedTab: false,
              wholesaleTab: false,
              ownEComTab: true,
            },
          });
        }}
      >
        {tabFiveTitle}
      </TabTitle>
    </StyledTabs>
  );
};

const StyledTabs = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TabTitle = styled.h1<{ active: boolean }>`
  font-size: 18px;
  color: ${(props) => (props.active ? Color.midnightGrey : Color.steelGrey)};
  text-decoration: ${(props) => (props.active ? 'underline' : 'none')};
  margin: 0;
  cursor: pointer;
  padding: 7px;
  border-radius: 5px;
  &:hover {
    background-color: #f8f8f8;
  }
`;

export default Tabs;
