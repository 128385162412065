import { useMemo } from 'react';
import { EditableValueCell } from 'src/components/molecules/EditableCell/EditableCell';
import { createDynamicMonthColumns, filterEmpty } from 'src/components/organisms/DataTable/columns/utils';
import { Inheritance, TableOverviewRow } from 'src/domain';
import { mapWeekCoverToTableData } from 'src/mapping/week-cover.mapping';
import { groupByGenderAndProductLine } from 'src/utils/mergeRows';
import { useWeekCoverQuery } from './queries/useWeekCoverQuery';

export const useWeekCoverOverviewRows = (
  inheritance?: Inheritance,
  genderId?: number,
  productLineId?: number,
  aggregateOnGenderProductlines = false
) => {
  const { data, sanitizedData, styleCategories, error, loading } = useWeekCoverQuery(
    inheritance,
    genderId,
    productLineId
  );

  const aggregatedTableData = useMemo(() => {
    if (aggregateOnGenderProductlines) {
      if (!sanitizedData || !styleCategories) return;

      const rowsByGenderProductline: Record<string, Record<string, TableOverviewRow[]>> = {};
      const weekCoverByProductline = groupByGenderAndProductLine(sanitizedData);
      Object.keys(weekCoverByProductline).forEach((productlineKey) => {
        rowsByGenderProductline[productlineKey] = rowsByGenderProductline[productlineKey] ?? {};

        const weekCoverByGender = weekCoverByProductline[productlineKey];
        return Object.keys(weekCoverByGender).forEach((genderKey) => {
          const weekCoverExpected = weekCoverByGender[genderKey];
          rowsByGenderProductline[productlineKey][genderKey] =
            mapWeekCoverToTableData(weekCoverExpected, styleCategories) ?? [];
        });
      });
      return rowsByGenderProductline;
    }
  }, [aggregateOnGenderProductlines, sanitizedData, styleCategories]);

  const tableData = useMemo(() => {
    if (!data || !styleCategories) return [];
    return mapWeekCoverToTableData(data, styleCategories);
  }, [data, styleCategories]);

  const columns = useMemo(
    () =>
      data
        ? createDynamicMonthColumns(filterEmpty<{ monthKey: number }>(data), {
            cellRenderer: EditableValueCell,
          })
        : [],
    [data]
  );
  return { data: tableData, aggregatedData: aggregatedTableData, columns, error, loading };
};
