import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';

const Paragraph: FC<PropsWithChildren<unknown>> = ({ children, ...props }) => {
  return <StyledParagraph {...props}>{children}</StyledParagraph>;
};

const StyledParagraph = styled.p`
  margin: 0;
  font-size: 12px;
`;

export default Paragraph;
