import { useMemo } from 'react';
import { useType } from '../useType';
import { useChainMenuSettingsApiQuery } from './useChainMenuSettingsApiQuery';

export const useIsUsingOpenToBuyLimit = () => {
  const type = useType();
  const { data } = useChainMenuSettingsApiQuery();

  return useMemo((): boolean => {
    if (!type) return false;
    else {
      return !!data?.find((item) => item.menu.toString() === 'OpenToBuy')?.visible;
    }
  }, [data, type]);
};
