import { isNumber } from 'lodash';
import { useCallback } from 'react';
import { Cell } from 'src/components/organisms/DataTable/types';
import { updateRow } from 'src/components/views/Turnover/data/turnover';
import { Inheritance, Module, TurnoverRow } from 'src/domain';
import { UnsavedChangesEvent } from 'src/domain/events/unsaved-changes.event';
import { useSetSelectedInheritance, useStoreId, useUnsavedChangesModule } from 'src/hooks';
import { useTurnoverSimulationRows } from './simulation/useTurnoverSimulationRows';
import { useTurnoverRows } from './table/useTurnoverRows';

export const useTurnoverHandleCellValueChanged = () => {
  const { data: originalData } = useTurnoverRows() ?? {};
  const { data } = useTurnoverSimulationRows() ?? {};
  const storeId = useStoreId();
  const [_, setUnsavedChanges] = useUnsavedChangesModule<TurnoverRow[]>(Module.Turnover);

  const setSelectedInheritance = useSetSelectedInheritance();

  const callback = useCallback(
    async (cellValues: Cell[]) => {
      if (!data || !originalData) {
        throw new Error('Data is missing!');
      }

      if (!storeId) {
        throw new Error(`Store ID is missing`);
      }

      cellValues.forEach((cellValue) => {
        const originalRowData = originalData.find((x) => x.id === cellValue.rowId);
        const rowData = data.find((x) => x.id === cellValue.rowId);
        const rowIndexToUpdate = data.findIndex((x) => x.id === cellValue.rowId);
        const valueIsValid = !isNaN(cellValue.value) && isNumber(Number(cellValue.value));

        if (originalRowData && rowData && cellValue.column) {
          data[rowIndexToUpdate] = updateRow(
            cellValue.column,
            rowData,
            valueIsValid ? cellValue.value : 0,
            originalRowData.plannedSalesIv
          );
        }
      });

      const unsaved = [...data];
      setUnsavedChanges(unsaved);
      setSelectedInheritance(Inheritance.Typed);
      dispatchEvent(new Event(UnsavedChangesEvent));
    },
    [data, originalData, setUnsavedChanges, storeId, setSelectedInheritance]
  );

  return callback;
};
