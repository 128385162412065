import styled from '@emotion/styled';
import { NotificationImportant, Report } from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { FC, useState } from 'react';
import Container from 'src/components/atoms/Container';
import { StorefrontTypeSelect } from 'src/components/molecules/StorefrontTypeSelect/StorefrontTypeSelect';
import { tabItemStyles, tabsWrapperStyle } from 'src/components/molecules/Tabs/ChromeTabs/chromeTabs.styles';
import { ScopeEnum } from 'src/domain';
import { useScope } from 'src/hooks';
import { useStyleWarningsRows } from 'src/hooks/style-warnings/table/useStyleWarningsRows';
import { TabPanel } from './components/TabPanel';
import { predictedUnsuccessfulPhaseOutCols, unsuccessfulPhaseOutCols } from './data/columns';

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export const StyleWarnings: FC = () => {
  const { data: overviewRows, loading } = useStyleWarningsRows();
  const [tabIndex, setTabIndex] = useState(0);
  const scope = useScope();

  return (
    <Wrapper>
      <Container style={{ marginBottom: '1rem' }}>{scope !== ScopeEnum.STORE && <StorefrontTypeSelect />}</Container>
      <TabsWrapper value={tabIndex} onChange={(_, index) => setTabIndex(index)}>
        <StyledTab icon={<AlertIcon />} label="Predicted Unsuccessful Phase Out" {...a11yProps(0)} />
        <StyledTab icon={<WarningIcon />} label="Unsuccessful Phase Out" {...a11yProps(0)} />
      </TabsWrapper>
      {tabIndex === 0 && (
        <TabPanel
          columns={predictedUnsuccessfulPhaseOutCols}
          data={overviewRows.predictedUnsuccessfulPhaseOut}
          loading={!!loading}
          description={
            'These style options have a set exit week that they are predicted to miss. This could have to do with either a too high norm or a too low predicted sales quantity leading up to the exit week.'
          }
        />
      )}
      {tabIndex === 1 && (
        <TabPanel
          columns={unsuccessfulPhaseOutCols}
          data={overviewRows.unsuccessfulPhaseOut}
          loading={!!loading}
          description={
            'These style options still have a stock quantity after their exit week. Styles are expected to be at 0 in stock after their exit week.'
          }
        />
      )}
    </Wrapper>
  );
};

const TabsWrapper = muiStyled(Tabs)(({ theme }) => ({
  ...tabsWrapperStyle(theme),
}));

const StyledTab = muiStyled(Tab)(({ theme }) => ({
  ...tabItemStyles(theme),
}));

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 0;
  height: 100%;
`;

const WarningIcon = styled(Report)`
  color: #d9512c;
`;

const AlertIcon = styled(NotificationImportant)`
  color: #f7ce5b;
`;
