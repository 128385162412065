import { isArray } from 'lodash';
import { useCallback, useState } from 'react';
import { Module, ScopeEnum, months } from 'src/domain';
import { OptimalOptionCountRow } from 'src/domain/table/optimal-option-count.row';
import { useChangesSnackbar, useCurrentId, useScope } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useEntityType } from 'src/hooks/useEntityType';
import {
  UpdateOptimalOptionCountInput,
  UpdateOptimalOptionCountUpdatePlacementInput,
} from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import {
  OptimalOptionCountStylesPer100SQMLabel,
  OptimalOptionCountTotalLabel,
} from 'src/mapping/optimal-option-count.mapping';
import { useOptimalOptionCountPlacementSimulationRows } from '../simulation/useOptimalOptionCountPlacementSimulationRows';

function valueMapper(rows: OptimalOptionCountRow[]) {
  return months.reduce<UpdateOptimalOptionCountInput[]>((acc, month) => {
    const monthName = month.toLowerCase();
    const row = rows.reduce<UpdateOptimalOptionCountInput>(
      (rowAcc, currentRow) => {
        const id = currentRow.columns[`${monthName}Id`];
        const value = currentRow.columns[monthName];

        if (currentRow.category === OptimalOptionCountStylesPer100SQMLabel || currentRow.category === 'Total')
          return rowAcc;
        if (currentRow.category === OptimalOptionCountTotalLabel) {
          const updatedRow = { ...rowAcc };
          updatedRow.value = value;
          if (id) updatedRow.id = id;

          return updatedRow;
        }

        const placements: UpdateOptimalOptionCountUpdatePlacementInput[] = [];

        if (id) {
          placements.push({
            id,
            value,
          });
        }

        if (isArray(rowAcc.placements)) {
          const updatedRow: UpdateOptimalOptionCountInput = {
            ...rowAcc,
            placements: [...rowAcc.placements, ...placements],
          };
          return updatedRow;
        }
        return rowAcc;
      },
      {
        id: 0,
        placements: [],
        value: 0,
      }
    );
    acc.push(row);
    return acc;
  }, []);
}

export const useOptimalOptionCountSaveChanges = () => {
  const currentId = useCurrentId();
  const scope = useScope();
  const entityType = useEntityType();
  const partner = useCompositePartner();

  const [updateStoreOptimalOptionCount] = useApiMutation('/api/optimaloptioncount', 'put', {
    update(data, _variables, queryClient) {
      const storeQuery = { entityId: Number(currentId), entityType: entityType };
      queryClient.setQueryData(['/api/optimaloptioncount', storeQuery], data);
      const partnerQuery = { entityId: partner?.id, entityType: 'Partner' };
      queryClient.invalidateQueries({ queryKey: ['/api/optimaloptioncount', partnerQuery] });
    },
  });

  const showSnackbar = useChangesSnackbar();
  const discardChanges = useDiscardChangesModule(Module.OptimalOptionCount);

  const { data: valuesToUpdate } = useOptimalOptionCountPlacementSimulationRows();
  const [isUpdating, setIsUpdating] = useState(false);

  const sendUpdateQuery = useCallback(async () => {
    if (!valuesToUpdate) {
      return;
    }

    switch (scope) {
      case ScopeEnum.STORE:
        if (!currentId) return;
        return updateStoreOptimalOptionCount({
          body: valueMapper(valuesToUpdate),
        });

      case ScopeEnum.CLUSTER:
        throw new Error('Cluster optimal option count is not editable!');

      case ScopeEnum.PARTNER:
        throw new Error('Partner optimal option count is not editable!');
    }
  }, [scope, currentId, updateStoreOptimalOptionCount, valuesToUpdate]);

  const updateOptimalOptionCount = useCallback(async () => {
    setIsUpdating(true);

    try {
      const response = await sendUpdateQuery();
      discardChanges();
      showSnackbar();
      return response;
    } catch {
      showSnackbar(true);
    } finally {
      setIsUpdating(false);
    }
  }, [discardChanges, sendUpdateQuery, showSnackbar]);

  return [updateOptimalOptionCount, { loading: isUpdating }] as const;
};
