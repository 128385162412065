import { isNumber } from 'lodash';
import { useMemo } from 'react';
import { StockManagementChartRow } from 'src/domain';
import { monthFieldToPrettyName } from 'src/utils/monthKeys';
import { useStockManagementOverviewSimulationRows } from '../simulation/useStockManagementOverviewSimulationRows';

export const useStockManagementChartRows = () => {
  const { data: simulatedData, loading, error } = useStockManagementOverviewSimulationRows();

  const chartRows = useMemo(() => {
    if (!simulatedData) return;
    return Object.keys(simulatedData[0]?.columns ?? {})?.reduce<StockManagementChartRow[]>((acc, month) => {
      if (month.endsWith('Id')) return acc;
      const stockOptimal = simulatedData.find((row) => row.category === 'Stock Optimal')?.columns[month];
      const stockOptimalAdjusted = simulatedData.find((row) => row.category === 'Stock Optimal Adj.')?.columns[month];
      const stockMinimum = simulatedData.find((row) => row.category === 'Minimum')?.columns[month];
      const stockMaximum = simulatedData.find((row) => row.category === 'Maximum')?.columns[month];
      const monthData: StockManagementChartRow = {
        month: monthFieldToPrettyName(month),
        stockOptimal: Number(stockOptimal),
        stockOptimalAdjusted: Number(stockOptimalAdjusted),
        stockMinimum: isNumber(stockMinimum) ? stockMinimum : null,
        stockMaximum: isNumber(stockMaximum) ? stockMaximum : null,
      };
      acc.push(monthData);

      return acc;
    }, []);
  }, [simulatedData]);

  return useMemo(
    () => ({
      data: chartRows,
      error,
      loading,
    }),
    [error, loading, chartRows]
  );
};
