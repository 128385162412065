import { OptionsObject, VariantType, useSnackbar as notistackUseSnackbar } from 'notistack';

export const useSnackbar = () => {
  const { enqueueSnackbar } = notistackUseSnackbar();

  const defaultOptions: OptionsObject = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    autoHideDuration: 10000,
    variant: 'default',
  };

  const showSnackbar = (message: string, options: VariantType | OptionsObject) => {
    if (typeof options === 'string') {
      options = {
        variant: options,
      };
    }

    enqueueSnackbar(message, {
      ...defaultOptions,
      ...options,
    });
  };

  return showSnackbar;
};
