import { isNumber } from 'lodash';
import { useMemo } from 'react';
import { useGenderId } from 'src/hooks/gender';
import { useProductLineId } from 'src/hooks/productline';
import { useStores } from 'src/hooks/store-selection/queries/useStores';
import { mapInheritanceSettings } from 'src/mapping/inheritance-settings.mapping';
import { useInheritanceSettingsQuery } from '../queries/useInheritanceSettingsQuery';

export const useInheritanceSettingsRows = () => {
  const { data, error, loading } = useInheritanceSettingsQuery();
  const { data: storesData } = useStores();

  const genderId = useGenderId();
  const productLineGroupId = useProductLineId();

  const rows = useMemo(() => {
    if (!isNumber(genderId) || !isNumber(productLineGroupId)) {
      return [];
    }
    return mapInheritanceSettings(data, storesData.allStoresLookup, genderId, productLineGroupId);
  }, [data, genderId, productLineGroupId, storesData.allStoresLookup]);

  return { data: rows, error, loading };
};
