import { useContext, useEffect, useMemo } from 'react';
import { IsHistoricalMap, Module, ScopeEnum } from 'src/domain';
import { CategorySplit, CategorySplitLevelQuery } from '../category-split/queries/useCategorySplitLevelQuery';
import { useScope } from '../useScope';
import { IsHistoricalContext } from './IsHistoricalContext';

const getIsTyped = (data: CategorySplit) => data?.some((x) => !!x?.modifiedByUser);

export const isCategorySplitHistorical = (scope: ScopeEnum, data: CategorySplitLevelQuery['data'] | undefined) => {
  let isTyped = undefined;

  switch (scope) {
    case ScopeEnum.STORE:
      isTyped = getIsTyped(data?.storeCategorySplit);
      break;
    case ScopeEnum.CLUSTER:
      isTyped = getIsTyped(data?.clusterCategorySplit);
      break;
    case ScopeEnum.PARTNER:
      isTyped = getIsTyped(data?.partnerCategorySplit);
      break;
  }

  // invert isTyped but keep undefined
  if (isTyped === true) return false;
  if (isTyped === false) return true;
  return undefined;
};

export function updateIsCategorySplitHistorical(
  isHistorical: boolean | undefined,
  isHistoricalMap: IsHistoricalMap,
  setIsHistoricalMap: (value: IsHistoricalMap) => void
) {
  if (isHistorical === undefined) return;
  if (isHistoricalMap.get(Module.CategorySplit) === isHistorical) return;

  isHistoricalMap.set(Module.CategorySplit, isHistorical);
  setIsHistoricalMap(new Map(isHistoricalMap));
}

export const useInitializeIsCategorySplitHistorical = (data: CategorySplitLevelQuery['data'] | undefined) => {
  const scope = useScope();
  const [isHistoricalMap, setIsHistoricalMap] = useContext(IsHistoricalContext);

  const isHistorical = useMemo(() => {
    if (!scope || !data) return undefined;
    return isCategorySplitHistorical(scope, data);
  }, [data, scope]);

  useEffect(() => {
    updateIsCategorySplitHistorical(isHistorical, isHistoricalMap, setIsHistoricalMap);
  }, [isHistorical, isHistoricalMap, setIsHistoricalMap]);
};
