import { Cell } from 'src/components/organisms/DataTable/types';

interface ObjectWithId {
  id: unknown;
  rowId?: unknown;
}

export function findRow<T extends ObjectWithId>(cell: Cell, originalData: T[], simulationData: T[]) {
  const rowIndexToUpdate = originalData.findIndex(matchRow(cell));
  const simulationIndexToUpdate = simulationData.findIndex(matchRow(cell));

  const currentData = rowIndexToUpdate >= 0 ? originalData[rowIndexToUpdate] : simulationData[simulationIndexToUpdate];

  return {
    rowIndexToUpdate,
    simulationIndexToUpdate,
    currentData,
  };
}

export function matchRow<T extends ObjectWithId>(cell: Cell) {
  return (x: T) => (typeof x.rowId !== 'undefined' ? x.rowId === cell.rowId : x.id === cell.rowId);
}
