import styled from '@emotion/styled';
import { ICellRendererParams } from 'ag-grid-community';
import { FC, useMemo, useState } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { SwitchButton } from 'src/components/molecules/SwitchButton/SwitchButton';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { Headings, Inheritance, Module } from 'src/domain';
import {
  useGenderId,
  useInheritance,
  useProductLineId,
  useUnsavedChangesMarkdown,
  useUnsavedChangesMarkup,
} from 'src/hooks';
import { useCategorySplitOverviewSimulationRows } from 'src/hooks/category-split/simulation/useCategorySplitOverviewSimulationRows';
import { useCategorySplitOverviewRows } from 'src/hooks/category-split/table/useCategorySplitOverviewRows';
import { useMarkdownOverviewRows } from 'src/hooks/markdown/table/useMarkdownOverviewRows';
import { useMarkupOverviewRows } from 'src/hooks/markup/table/useMarkupOverviewRows';
import { useTurnoverOverviewSimulationRows } from 'src/hooks/turnover/table/useTurnoverOverviewSimulationRows';
import { useTurnoverVat } from 'src/hooks/turnover/table/useTurnoverVat';
import { mergeDataGrossMargin } from 'src/mapping/gross-margin.mapping';
import { mergeTableOverviewRowValuesToTuples } from 'src/mapping/table.mapping';
import { DataTable } from '../DataTable/DataTable';
import { weightedAverageGrossMargin } from '../DataTable/aggregators/average-gross-margin.agg';
import { defaultCategoryColumn } from '../DataTable/columns/columns-overview-sum';
import { DefaultColumnsOptionsOverviewPercentage, defaultColumnOptions } from '../DataTable/columns/default-options';
import { AutoGroupColumns } from './Columns';
import { renderMonthCell } from './grossMarginCellRenderer';

interface Props {
  includeMarkdown?: boolean;
  module?: Module;
}

const GrossMargin: FC<Props> = ({ includeMarkdown = false, module }) => {
  const genderId = useGenderId(module);
  const productLineId = useProductLineId(module);
  const [markdownState, setMarkdownState] = useState(includeMarkdown);

  const { data: turnoverData, loading: turnoverLoading } = useTurnoverOverviewSimulationRows();
  const { data: vat, loading: vatLoading } = useTurnoverVat();
  const { data: categorySplitData, loading: categorySplitLoading } =
    useCategorySplitOverviewRows(undefined, genderId, productLineId) ?? {};
  const { data: markupData, loading: markupLoading } = useMarkupOverviewRows(undefined, genderId, productLineId);
  const { data: markdownData, loading: markdownLoading } = useMarkdownOverviewRows();

  const { data: categorySplitSimulatedData, columns: monthColumns } =
    useCategorySplitOverviewSimulationRows(undefined, genderId, productLineId) ?? {};
  const markupUnsavedChanges = useUnsavedChangesMarkup();
  const markdownUnsavedChanges = useUnsavedChangesMarkdown();

  const inheritance = useInheritance();

  const isNotReady = !turnoverData || !vat || !categorySplitData || !markupData || !markdownData;

  const isLoading = turnoverLoading || vatLoading || categorySplitLoading || markupLoading || markdownLoading;

  const initialRows = useMemo(() => {
    if (isNotReady) return;

    return mergeDataGrossMargin({
      turnover: turnoverData,
      vat,
      categorySplitRows: categorySplitData,
      markupRows: markupData,
      markdownRows: markdownData,
      includeMarkdown: markdownState,
    });
  }, [categorySplitData, isNotReady, markdownData, markdownState, markupData, turnoverData, vat]);

  const unsavedChangesRows = useMemo(() => {
    if (isNotReady) return;
    return mergeDataGrossMargin({
      turnover: turnoverData,
      vat,
      categorySplitRows: categorySplitSimulatedData ?? categorySplitData,
      markupRows: markupUnsavedChanges ?? markupData,
      markdownRows: markdownUnsavedChanges ?? markdownData,
      includeMarkdown: markdownState,
    });
  }, [
    categorySplitData,
    categorySplitSimulatedData,
    isNotReady,
    markdownData,
    markdownState,
    markdownUnsavedChanges,
    markupData,
    markupUnsavedChanges,
    turnoverData,
    vat,
  ]);

  const rows = useMemo(() => {
    if (inheritance !== Inheritance.Typed) return initialRows ?? [];
    return mergeTableOverviewRowValuesToTuples(initialRows, unsavedChangesRows);
  }, [inheritance, initialRows, unsavedChangesRows]);

  const columns = useMemo(() => {
    return [
      {
        ...defaultCategoryColumn,
        cellRenderer: (params: ICellRendererParams) => {
          return params.value ?? 'Average';
        },
      },
      ...monthColumns.map((column) => {
        return {
          ...column,
          ...defaultColumnOptions,
          type: 'numericColumn',
          editable: false,
          cellRenderer: (params: ICellRendererParams) => renderMonthCell(params, column),
        };
      }),
    ];
  }, [monthColumns]);

  return (
    <Container>
      <HeaderWrapper>
        <Header heading={Headings.h2}>Gross margin</Header>
        {includeMarkdown && (
          <SwitchButton
            label={`Include ${Module.Markdown.toUpperCaseLetters(1)}`}
            handleSelection={setMarkdownState}
            active={includeMarkdown}
          />
        )}
      </HeaderWrapper>
      <TableWrapper className="ag-theme-alpine-custom-compact">
        <DataTable
          headerHeight={60}
          columns={columns}
          rows={rows}
          defaultColDef={DefaultColumnsOptionsOverviewPercentage}
          loading={isLoading}
          autoGroupColumnDef={AutoGroupColumns}
          noRowsOverlayComponent
          getGroupRowAgg={weightedAverageGrossMargin}
          grandTotalRow="bottom"
          columnMinWidth={120}
          autoSizeToFit
        />
      </TableWrapper>
    </Container>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default GrossMargin;
