import { useMemo } from 'react';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useStores } from 'src/hooks/store-selection/queries/useStores';

export const useStorefrontTypes = () => {
  const compositePartner = useCompositePartner();
  const { data } = useStores();

  return useMemo(() => {
    if (data?.allStores.length > 0 && compositePartner?.id) {
      // Ensure only unique and defined values - ignore empty string, null and undefined values
      const storefrontTypes = data.allStores.reduce((acc, store) => {
        if (store.storefrontType && store.operationalResponsibleComposite?.id === compositePartner.id) {
          acc.add(store.storefrontType);
        }
        return acc;
      }, new Set<string>());

      return Array.from<string>(storefrontTypes).sort((a, b) => {
        return a.localeCompare(b);
      });
    }
    return [];
  }, [compositePartner?.id, data.allStores]);
};
