import styled from '@emotion/styled';
import { CancelOutlined, Close, Edit, Save } from '@mui/icons-material';
import { ListItem } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyledDiscardButton } from 'src/components/styled/DiscardButton';
import { StyledLoadingButton } from 'src/components/styled/LoadingButton';
import { extractPlannedSales } from 'src/components/views/Turnover/data/turnover';
import { Color, months } from 'src/domain';
import { useDrawerState } from 'src/hooks';
import { useTurnoverQuery } from 'src/hooks/turnover/queries/useTurnoverQuery';
import { useTurnoverSimulationRows } from 'src/hooks/turnover/simulation/useTurnoverSimulationRows';
import { formatDateToMonthKey } from 'src/utils/monthKeys';

export type BulkEditRow = {
  monthKey: number;
  value: number | undefined;
};

interface Props {
  isSaving: boolean;
  onCancel: () => void;
  onSave: () => void;
  onShowDetails: () => void;
  onChanges: (updatedChanges: BulkEditRow[], originalTotalSales: number, updatedTotalSales: number) => void;
}

export const BulkEditDrawerSection: FC<Props> = ({ isSaving, onCancel, onSave, onShowDetails, onChanges }) => {
  const { bulkEditDrawerOpen } = useDrawerState();
  const { data: turnoverData } = useTurnoverSimulationRows();
  const { numberOfStores } = useTurnoverQuery() ?? {};
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const nextYear = new Date(new Date().setFullYear(currentYear + 1)).getFullYear();
  const currentMonth = currentDate.getMonth();

  const monthList = useMemo(() => {
    const list: Date[] = [];
    for (let i = 0; i < 18; i++) {
      const nextMonth = new Date(new Date().setMonth(currentMonth + i));

      if (nextMonth.getMonth() === 12) {
        list.push(new Date(nextYear, 0, 1));
      } else {
        list.push(new Date(currentYear, new Date().getMonth() + i, 1));
      }
    }
    return list;
  }, [currentMonth, currentYear, nextYear]);

  const yearList = useMemo(() => {
    return [currentYear, nextYear];
  }, [currentYear, nextYear]);

  const unEditedRows = useMemo(() => {
    return monthList.reduce<Record<string, BulkEditRow[]>>((acc, month) => {
      const monthKey = formatDateToMonthKey(month);
      if (acc[month.getFullYear()]) {
        return {
          ...acc,
          [month.getFullYear()]: [...acc[month.getFullYear()], { monthKey: monthKey, value: undefined }],
        };
      }
      return {
        ...acc,
        [month.getFullYear()]: [{ monthKey: monthKey, value: undefined }],
      };
    }, {});
  }, [monthList]);

  const [allValue, setAllValue] = useState<number | undefined>(0);
  const [rows, setRows] = useState<Record<string, BulkEditRow[]>>(unEditedRows);
  const inputRef = useRef<(HTMLInputElement | null)[]>(new Array(18));
  const allInputRef = useRef<HTMLInputElement>(null);

  const changes = useMemo(() => {
    return Object.keys(rows).reduce<BulkEditRow[]>((acc, year) => {
      return [...acc, ...rows[year].filter((row) => row.value !== 0 && row.value !== undefined)];
    }, []);
  }, [rows]);

  const originalTotalSales = useMemo(() => {
    const sales = extractPlannedSales(turnoverData);
    if (!sales) return 0;
    return sales?.reduce((acc, curr) => acc + curr, 0);
  }, [turnoverData]);

  const updatedTotalSales = useMemo(() => {
    return turnoverData.reduce((acc, row) => {
      const value = changes.find((change) => change.monthKey === row.monthKey)?.value;
      let percentage = 100;

      if (value) percentage = 100 + Number(value);
      percentage = percentage / 100;

      if (!value) return acc + row.plannedSalesIv;
      return acc + row.plannedSalesIv * percentage;
    }, 0);
  }, [changes, turnoverData]);

  const hasUnsavedChanges = useMemo(() => {
    return changes.length > 0;
  }, [changes]);

  const handleCancel = useCallback(() => {
    setRows(unEditedRows);
    setAllValue(0);
    onCancel();
  }, [onCancel, unEditedRows]);

  const handleEditAll = (newValue: number | undefined) => {
    setRows(
      Object.keys(rows).reduce<Record<string, BulkEditRow[]>>((acc, year) => {
        return {
          ...acc,
          [year]: rows[year].map((row) => ({ ...row, value: newValue })),
        };
      }, {})
    );
  };

  useEffect(() => {
    onChanges(changes, originalTotalSales, updatedTotalSales);
  }, [changes, onChanges, originalTotalSales, updatedTotalSales]);

  useEffect(() => {
    if (!bulkEditDrawerOpen) {
      handleCancel();
    }
  }, [bulkEditDrawerOpen, handleCancel]);

  return (
    <>
      <CloseButton onClick={handleCancel}>
        <Close />
        <span>Close</span>
      </CloseButton>
      <Wrapper>
        <Title>Bulk Edit</Title>
        <StyledListItem>
          All
          <InputWrapper
            onClick={() => {
              allInputRef.current?.select();
            }}
          >
            <EditIcon />
            <Input
              id="outlined-start-adornment"
              value={allValue}
              ref={allInputRef}
              placeholder="0"
              onFocus={(e) => {
                e.target.select();
              }}
              onChange={(e) => {
                if (e.target.value === '') return;
                const parsedValue = parseInt(e.target.value);
                if (!isNaN(parsedValue)) {
                  setAllValue(parsedValue);
                  handleEditAll(parsedValue);
                }
                if (e.target.value === '-' && allValue !== undefined) {
                  setAllValue(undefined);
                  handleEditAll(undefined);
                }
              }}
            />
            <Suffix>%</Suffix>
          </InputWrapper>
        </StyledListItem>
        {yearList.map((year) => (
          <React.Fragment key={year}>
            <YearWrapper>
              <Year>{year}</Year>
              <Divider />
            </YearWrapper>
            {monthList.map((month, index) => (
              <React.Fragment key={formatDateToMonthKey(month)}>
                {month.getFullYear() === year && (
                  <StyledListItem>
                    {months[month.getMonth()]}
                    <InputWrapper
                      onClick={() => {
                        const value = inputRef.current?.[index]?.value;
                        if (value !== undefined) inputRef.current?.[index]?.setSelectionRange(0, value.length);
                      }}
                    >
                      <EditIcon />
                      <Input
                        ref={(el) => (inputRef.current[index] = el)}
                        id="outlined-start-adornment"
                        value={rows[year]?.find((row) => row.monthKey === formatDateToMonthKey(month))?.value}
                        placeholder="0"
                        onFocus={(e) => {
                          e.target.setSelectionRange(0, e.target.value.length);
                        }}
                        onChange={(e) => {
                          if (e.target.value === '') return;
                          setRows({
                            ...rows,
                            [year]: rows[year].map((row) => {
                              if (row.monthKey === formatDateToMonthKey(month)) {
                                return {
                                  ...row,
                                  value: e.target.value,
                                };
                              }
                              return row;
                            }),
                          });
                        }}
                      />
                      <Suffix>%</Suffix>
                    </InputWrapper>
                  </StyledListItem>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
        <TextWrapper>
          <BoldText>{numberOfStores}</BoldText> stores will be effected by changes
        </TextWrapper>
        <Details onClick={onShowDetails}>See details</Details>

        <ButtonWrapper>
          <StyledDiscardButton
            disabled={false}
            color={'error'}
            size={'small'}
            variant={'contained'}
            startIcon={<CancelOutlined />}
            onClick={handleCancel}
          >
            Cancel
          </StyledDiscardButton>
          <StyledLoadingButton
            disabled={!hasUnsavedChanges}
            loading={isSaving}
            size={'small'}
            variant={'contained'}
            startIcon={<Save />}
            onClick={onSave}
          >
            Save changes
          </StyledLoadingButton>
        </ButtonWrapper>
      </Wrapper>
    </>
  );
};

const CloseButton = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  margin-left: 30px;
  margin-top: 10px;
  color: ${Color.midnightGrey};
  cursor: pointer;
`;

const Wrapper = styled(ListItem)`
  display: 'flex';
  flex-direction: column;
  transition: 'ease-in-out 0.5s';
  padding: 30px;
  color: ${Color.midnightGrey};
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: 500;
  margin: 0 0 30px 0;
`;

const YearWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Year = styled.div`
  font-size: 12px;
`;

const Divider = styled.hr`
  width: 30%;
  height: 1px;
  border: none;
  background-color: ${Color.midnightGrey};
`;

const StyledListItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Color.midnightGrey};
  margin-bottom: 8px;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Suffix = styled.div`
  position: absolute;
  right: 25px;
  color: ${Color.editBlue};
  font-size: 13px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 90px;
  height: 32px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  text-align: right;
  padding-right: 40px;
  color: ${Color.editBlue};
  font-weight: bold;
  &::placeholder {
    color: ${Color.editBlue};
  }
`;

const EditIcon = styled(Edit)`
  font-size: 16px;
  color: ${Color.editBlue};
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const TextWrapper = styled.div`
  font-size: 16px;
  align-self: flex-start;
  padding-top: 20px;
  width: 100%;
  white-space: pre-wrap;
`;

const Details = styled.div`
  font-size: 14px;
  color: ${Color.editBlue};
  align-self: flex-start;
  font-weight: bold;
  text-decoration: underline;
  margin-top: 5px;
  cursor: pointer;
`;
