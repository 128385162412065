import './wdyr';
import 'src/extensions/array.extensions';
import 'src/extensions/string.extensions';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { msal } from './auth/azure-authentication-config';
import './dayjs';
import './styles/index.scss';

const container = document.getElementById('root');

if (!container) throw new Error('Could not get root element');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const root = createRoot(container);
const msalInstance = new PublicClientApplication(msal.config);

root.render(
  <StrictMode>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </MsalProvider>
  </StrictMode>
);
