import { Box, Stack } from '@mui/material';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { FC, useMemo } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { StoresOverwrittenAlert } from 'src/components/molecules/StoresOverwrittenAlert/StoresOverwrittenAlert';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { defaultColDefNumberWithSpaces } from 'src/components/organisms/DataTable/columns/default-options';
import { processThousandOperators } from 'src/components/organisms/DataTable/options/clipboard-options';
import { defaultRowGroupOptions } from 'src/components/organisms/DataTable/options/row-group-options';
import { defaultLargeRowHeight } from 'src/components/organisms/DataTable/options/row-height-options';
import Topbar from 'src/components/organisms/Topbar';
import { HeaderWrapper } from 'src/components/styled/HeaderWrapper';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { WrapperWithAlert } from 'src/components/styled/WrapperWithAlert';
import { Headings, Module, ScopeEnum } from 'src/domain';
import { StockMgmtTable } from 'src/domain/enums/stock-management-table';
import { useScope } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useStockManagementChartRows } from 'src/hooks/stock-management/chart/useStockManagementChartRows';
import { useStockManagementSaveChanges } from 'src/hooks/stock-management/save-changes/useStockManagementSaveChanges';
import { useStockManagementOverviewSimulationRows } from 'src/hooks/stock-management/simulation/useStockManagementOverviewSimulationRows';
import { useStockManagementOverviewRows } from 'src/hooks/stock-management/table/useStockManagementOverviewRows';
import { useTable } from 'src/hooks/stock-management/table/useTable';
import { useHandleCellValueChanges } from 'src/hooks/stock-management/useHandleCellValueChanges';
import { useStockManagementHasChangedSplit } from 'src/hooks/stock-management/useStockManagementHasChangedSplit';
import { useStockManagementHasUnsavedChanges } from 'src/hooks/stock-management/useStockManagementHasUnsavedChanges';
import { mergeTableOverviewRowValuesToTuples } from 'src/mapping/table.mapping';
import { StockManagementLineChart } from './StockManagementLineChart/StockManagementLineChart';
import { StockManagementSwitchButton } from './StockManagementSwitchButton/StockManagementSwitchButton';
import { AutoGroupColumn } from './helpers/columns-definition-categories';

export const StockManagement: FC = () => {
  const {
    data: simulatedRows,
    columns: monthColumns,
    loading: overviewSimulationLoading,
  } = useStockManagementOverviewSimulationRows();
  const { data: chartRows, loading: chartRowsLoading } = useStockManagementChartRows();
  const { data: rows, loading: overviewLoading } = useStockManagementOverviewRows();
  const [saveChanges, { loading: isSaving }] = useStockManagementSaveChanges();
  const onDiscardChanges = useDiscardChangesModule(Module.StockManagement);
  const handleCellValueChanges = useHandleCellValueChanges();

  const selectedTable = useTable();
  const hasUnsavedChanges = useStockManagementHasUnsavedChanges();
  const hasChangedSplit = useStockManagementHasChangedSplit();
  const canSave = (hasUnsavedChanges || hasChangedSplit) && !isSaving;
  const scope = useScope();

  const isLoading = overviewLoading || overviewSimulationLoading || selectedTable === undefined || isSaving;

  const columns: ColDef[] = useMemo(() => {
    if (selectedTable === StockMgmtTable.Categories) {
      return [
        {
          field: 'category',
          hide: true,
          rowGroup: true,
          headerName: 'Category Limiter',
          suppressSizeToFit: true,
          suppressAutoSize: true,
          suppressHeaderMenuButton: true,
        },
        {
          field: 'subCategory',
          hide: true,
          headerName: 'Sub Category',
          suppressAutoSize: true,
          suppressHeaderMenuButton: true,
        },
        ...monthColumns,
      ];
    }

    return [
      {
        field: 'category',
        width: 150,
        headerName: 'Limiter',
        suppressSizeToFit: true,
        suppressAutoSize: true,
        suppressHeaderMenuButton: true,
        pinned: 'left',
        lockPinned: true,
      },
      ...monthColumns.map((column, index, arr) => ({
        ...column,
        valueGetter:
          index >= arr.length - 2
            ? (params: ValueGetterParams) => {
                if (params.data.category.includes('Stock Optimal')) {
                  return 'N/A';
                }
                return typeof column.valueGetter === 'function' ? column.valueGetter(params) : '';
              }
            : column.valueGetter,
      })),
    ];
  }, [monthColumns, selectedTable]);

  const defaultColDef = useMemo(() => {
    return {
      ...defaultColDefNumberWithSpaces,
      minWidth: 100,
      wrapHeaderText: true,
      headerClass: '',
    };
  }, []);

  const overviewRows = useMemo(() => {
    if (isLoading) {
      return;
    }

    return mergeTableOverviewRowValuesToTuples<number | null>(rows, simulatedRows);
  }, [simulatedRows, rows, isLoading]);

  return (
    <Stack spacing={2}>
      <Topbar
        onDiscard={onDiscardChanges}
        onSave={saveChanges}
        saveDisabled={!canSave}
        discardDisabled={!hasUnsavedChanges}
        loading={isLoading}
        saveLoading={isSaving}
        hideToggleButtons={true}
      />
      <Container>
        <HeaderWrapper>
          <WrapperWithAlert>
            <Header heading={Headings.h2}>Stock Management</Header>
            <StoresOverwrittenAlert />
          </WrapperWithAlert>
          <StockManagementSwitchButton />
        </HeaderWrapper>
        {selectedTable === StockMgmtTable.Categories && (
          <TableWrapper className="ag-theme-alpine-custom-compact ag-total-row-group">
            <DataTable
              {...defaultRowGroupOptions}
              autoSizeToFit
              rows={overviewRows}
              columns={columns}
              defaultColDef={defaultColDef}
              onCellValuesChanged={handleCellValueChanges}
              loading={isLoading}
              autoGroupColumnDef={AutoGroupColumn}
            />
          </TableWrapper>
        )}
        {selectedTable === StockMgmtTable.Total && (
          <>
            <TableWrapper className="ag-theme-alpine-custom-compact ag-minimal-height">
              <DataTable
                autoSizeToFit
                rows={overviewRows}
                columns={columns}
                defaultColDef={defaultColDef}
                onCellValuesChanged={handleCellValueChanges}
                loading={isLoading}
                getRowHeight={defaultLargeRowHeight}
                processCellFromClipboard={processThousandOperators}
              />
            </TableWrapper>
            {scope === ScopeEnum.STORE && chartRows && chartRows?.length > 0 && (
              <Box mt={4}>
                <StockManagementLineChart data={chartRows} loading={chartRowsLoading} />
              </Box>
            )}
          </>
        )}
      </Container>
    </Stack>
  );
};
