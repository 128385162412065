import { useAccount, useMsal } from '@azure/msal-react';
import { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';

export const AuthenticateGuard: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = !!account;

  if (!isAuthenticated) {
    navigate('/', { replace: true });
    return <></>;
  }

  return <>{children}</>;
};
