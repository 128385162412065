import { useMemo } from 'react';
import { EditablePercentageCell } from 'src/components/molecules/EditableCell/EditableCell';
import {
  createDynamicMonthColumns,
  parsePercentageValueToNumber,
} from 'src/components/organisms/DataTable/columns/utils';
import { Inheritance, Module } from 'src/domain';
import { useInheritance } from 'src/hooks/inheritance';
import { styleCategoriesToGroupedByData } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { mapInheritanceToExpectedNew } from 'src/mapping/inheritance.mapping';
import { mapSplitToOverviewTableDataNew } from 'src/mapping/split-modules.mapping';
import { avgAggFunc } from 'src/utils/ag-grid/agg-functions';
import { useNoosShareQuery } from '../queries/useNoosShareQuery';

export const useNoosShareOverviewRows = (inheritance?: Inheritance) => {
  const { expected, styleCategories, error, loading } = useNoosShareQuery(inheritance);

  const moduleInheritance = useInheritance(Module.NoosShare);
  const inheritanceToUse = inheritance ?? moduleInheritance;
  const isHistorical = inheritanceToUse
    ? [Inheritance.Historical, Inheritance.NoosDDR, Inheritance.NoosHistoricalOrders].includes(inheritanceToUse)
    : false;

  const tableData = useMemo(() => {
    if (!inheritanceToUse) {
      return;
    }
    return mapSplitToOverviewTableDataNew(
      mapInheritanceToExpectedNew(expected, inheritanceToUse),
      styleCategoriesToGroupedByData(styleCategories),
      isHistorical
    );
  }, [expected, styleCategories, inheritanceToUse, isHistorical]);

  const columns = useMemo(
    () =>
      expected
        ? createDynamicMonthColumns(expected, {
            cellRenderer: EditablePercentageCell,
            aggFunc: avgAggFunc,
            valueParser: parsePercentageValueToNumber,
          })
        : [],
    [expected]
  );

  return { data: tableData, columns, error, loading };
};
