import { useContext, useEffect, useMemo } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { MarkdownData } from '../markdown/queries/useMarkdownLevelQuery';
import { useScope } from '../useScope';
import { IsHistoricalContext } from './IsHistoricalContext';

export const useIsMarkdownHistorical = (data: MarkdownData | undefined) => {
  const scope = useScope();

  const isTyped = useMemo(() => {
    switch (scope) {
      case ScopeEnum.STORE:
        return data?.storeMarkdown?.some((x) => !!x?.modifiedByUser);

      case ScopeEnum.CLUSTER:
        return data?.clusterMarkdown?.some((x) => !!x?.modifiedByUser);

      case ScopeEnum.PARTNER:
        return data?.partnerMarkdown?.some((x) => !!x?.modifiedByUser);
    }
  }, [data?.clusterMarkdown, data?.partnerMarkdown, data?.storeMarkdown, scope]);

  // invert isTyped but keep undefined
  if (isTyped === true) return false;
  if (isTyped === false) return true;
  return undefined;
};

export const useInitializeIsMarkdownHistorical = (data: MarkdownData | undefined) => {
  const [isHistoricalMap, setIsHistoricalMap] = useContext(IsHistoricalContext);

  const isMarkdownHistorical = useIsMarkdownHistorical(data);

  useEffect(() => {
    if (isMarkdownHistorical === undefined) return;
    if (isHistoricalMap.get(Module.Markdown) === isMarkdownHistorical) return;

    isHistoricalMap.set(Module.Markdown, isMarkdownHistorical);
    setIsHistoricalMap(new Map(isHistoricalMap));
  }, [isMarkdownHistorical, isHistoricalMap, setIsHistoricalMap]);
};
