import { MenuItem } from '@mui/material';
import { NestedMenuItem } from 'mui-nested-menu';
import { FC, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface MenuItemProps {
  id: number | string;
  label: string;
  subItems?: MenuItemProps[];
  onClick?: (data?: Record<string, unknown>) => void;
}

export const CustomMenuItem: FC<MenuItemProps> = ({ id, label, onClick, subItems }) => {
  const hasSubItems = (subItems?.length ?? 0) > 0;
  const nonce = useMemo(() => uuidv4(), []);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return hasSubItems ? (
    <NestedMenuItem key={id} label={label} parentMenuOpen={true} nonce={nonce}>
      {subItems?.map((item) => <CustomMenuItem key={item.id} {...item} />)}
    </NestedMenuItem>
  ) : (
    <MenuItem key={id} onClick={handleClick}>
      {label}
    </MenuItem>
  );
};
