import { isNumber } from 'lodash';

export interface SelectOption<T = unknown> {
  label: string;
  value: T;
}

export enum ComparatorType {
  equals,
  notEquals,
  after,
  before,
  between,
  isEmpty,
  isNotEmpty,
  isInfinite,
}

export const commonComparators: SelectOption<ComparatorType>[] = [
  {
    label: 'Equals',
    value: ComparatorType.equals,
  },
  {
    label: 'Not Equals',
    value: ComparatorType.notEquals,
  },
  {
    label: 'After',
    value: ComparatorType.after,
  },
  {
    label: 'Before',
    value: ComparatorType.before,
  },
  {
    label: 'Between',
    value: ComparatorType.between,
  },
  {
    label: 'Is empty',
    value: ComparatorType.isEmpty,
  },
  {
    label: 'Is not empty',
    value: ComparatorType.isNotEmpty,
  },
];

export const doesDateFilterPass = (
  value: number | null | undefined,
  selectedComparator: ComparatorType,
  startDate: Date | null,
  endDate: Date | null,
  dateFormatter: (date: Date) => number,
  isInfinite: boolean
) => {
  switch (selectedComparator) {
    case ComparatorType.equals:
      if (!startDate) throw new Error('Missing date');
      return value === dateFormatter(startDate);
    case ComparatorType.notEquals:
      if (!startDate) throw new Error('Missing date');
      return value !== dateFormatter(startDate);
    case ComparatorType.after:
      if (!startDate) throw new Error('Missing date');
      if (!isNumber(value)) return false;
      return value > dateFormatter(startDate);
    case ComparatorType.before:
      if (!startDate) throw new Error('Missing date');
      if (!isNumber(value)) return false;
      return value < dateFormatter(startDate);
    case ComparatorType.between:
      if (!startDate) throw new Error('Missing start date');
      if (!endDate) throw new Error('Missing end date');
      if (!isNumber(value)) return false;
      return value >= dateFormatter(startDate) && value <= dateFormatter(endDate);
    case ComparatorType.isEmpty:
      return !value && !isInfinite;
    case ComparatorType.isNotEmpty:
      return !!value;
    case ComparatorType.isInfinite:
      return isInfinite;
    default:
      return false;
  }
};
