import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { FC } from 'react';
import { GenderToggleButtonGroup } from 'src/components/molecules/ToggleButtonGroup/ToggleButtonGroupExtensions/GenderToggleButtonGroup';
import { ProductlineToggleButtonGroup } from 'src/components/molecules/ToggleButtonGroup/ToggleButtonGroupExtensions/ProductlineToggleButtonGroup';
import { ToggleButtonWrapper } from 'src/components/styled/ToggleButtonWrapper';
import { useIsUsingGender, useIsUsingProductline } from 'src/hooks';
import { ActionButtons } from '../ActionButtons/ActionsButtons';
import { SmartModuleTitleCard } from '../SmartModuleTitleCard/SmartModuleTitleCard';
import { SmartTitleCard } from '../SmartTitleCard/SmartTitleCard';
interface Props {
  loading?: boolean;
  saveLoading?: boolean;
  saveDisabled?: boolean;
  saveTooltip?: string;
  discardTooltip?: string;
  setInheritanceDisabled?: boolean;
  resetDisabled?: boolean;
  discardDisabled?: boolean;
  hideToggleButtons?: boolean;
  hideButtons?: boolean;
  useModuleCard?: boolean;
  onDiscard?: () => unknown;
  onSave?: () => unknown;
  onReset?: () => unknown;
  onSetInheritance?: () => unknown;
}

const Topbar: FC<Props> = ({
  loading,
  saveLoading,
  saveDisabled,
  saveTooltip,
  discardTooltip,
  setInheritanceDisabled,
  resetDisabled,
  discardDisabled,
  hideToggleButtons = false,
  hideButtons = false,
  useModuleCard = false,
  onDiscard,
  onSave,
  onReset,
  onSetInheritance,
}) => {
  const showGender = useIsUsingGender();
  const showProductline = useIsUsingProductline();
  const showButtons = (showGender || showProductline) && !hideToggleButtons;

  return (
    <Container style={{ gap: '16px' }}>
      <Stack spacing={2}>
        {useModuleCard ? <SmartModuleTitleCard /> : <SmartTitleCard />}

        {showButtons && (
          <ToggleButtonWrapper>
            {showProductline && <ProductlineToggleButtonGroup />}
            {showGender && <GenderToggleButtonGroup />}
          </ToggleButtonWrapper>
        )}
      </Stack>

      {!hideButtons && (
        <ActionButtons
          discardDisabled={discardDisabled}
          saveDisabled={saveDisabled}
          setInheritanceDisabled={setInheritanceDisabled}
          resetDisabled={resetDisabled}
          onDiscard={onDiscard}
          onReset={onReset}
          onSave={onSave}
          onSetInheritance={onSetInheritance}
          loading={loading}
          saveLoading={saveLoading}
          saveTooltip={saveTooltip}
          discardTooltip={discardTooltip}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export default Topbar;
