import { useMemo } from 'react';
import { TableOverviewRow } from 'src/domain';

export const useOverviewRowsSum = (data: TableOverviewRow[] | undefined) => {
  const sum = useMemo(() => {
    const sum = (data || []).reduce(
      (acc, row) => {
        Object.keys(row.columns).forEach((key) => {
          if (key.endsWith('Id')) {
            return;
          }
          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] += Number(row.columns[key]);
        });
        return acc;
      },
      {} as Record<string, number>
    );

    // Ensure rounding to 2 decimal places
    return Object.keys(sum).reduce(
      (acc, key) => {
        acc[key] = Math.round(sum[key] * 100) / 100;
        return acc;
      },
      {} as Record<string, number>
    );
  }, [data]);

  return sum;
};
