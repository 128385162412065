import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';
import { Color } from 'src/domain';

const defaultFormatter = (value: unknown) => value?.toString();

export const ComparableCell: FC<IComparableCellRendererParams> = ({
  value,
  formatValue = defaultFormatter,
  hideTooltip = false,
}) => {
  /**
   * Value can be either a primitive value (string or number)
   * or it can be a tuple / an array in which case we assume current value is first
   * and predicted value is second
   * @see {@link mergeTableOverviewRowValuesToTuples}
   * @see {@link mergeTableDetailsRowValuesToTuples}
   */
  const current = Array.isArray(value) ? value[0] : value;
  const predicted = Array.isArray(value) ? value[1] : undefined;

  const currentFormatted = formatValue(current)?.toString();
  const predictedFormatted = predicted ? formatValue(predicted)?.toString() : predicted?.toString();

  const tooltipText = predictedFormatted ? `(${currentFormatted}) ${predictedFormatted}` : currentFormatted;

  return (
    <Tooltip title={hideTooltip ? false : tooltipText} placement="top" enterDelay={500} enterNextDelay={500}>
      <Wrapper>
        <CurrentValue $hasPredicted={typeof predictedFormatted !== 'undefined'}>{currentFormatted}</CurrentValue>
        {typeof predictedFormatted !== 'undefined' && <ComparedValue>{predictedFormatted}</ComparedValue>}
      </Wrapper>
    </Tooltip>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
`;

const CurrentValue = styled.div<{ $hasPredicted?: boolean }>`
  font-size: ${({ $hasPredicted }) => ($hasPredicted ? '12px' : '14px')};

  &:before {
    ${({ $hasPredicted }) => ($hasPredicted ? "content: '('" : '')}
  }

  &:after {
    ${({ $hasPredicted }) => ($hasPredicted ? "content: ')'" : '')}
  }
`;

const ComparedValue = styled.div`
  font-size: 14px;
  color: ${Color.editBlue}!important;
  margin-left: 5px;
`;

interface IComparableCellRendererParams extends ICellRendererParams {
  hideTooltip?: boolean;
}
