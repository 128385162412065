import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export interface TipProps {
  prefix?: string;
}

export const Tip: FC<PropsWithChildren<TipProps>> = ({ children, prefix = 'Tip:' }) => {
  return (
    <TipTypography noWrap={false}>
      <Typography component="span" fontWeight={500}>
        {prefix}
      </Typography>{' '}
      {children}
    </TipTypography>
  );
};

export const TipTypography = styled(Typography)`
  font-size: 14px;
  padding: 10px 5px;
  background-color: #f1f1f9;
  margin-left: 10px;
  border-radius: 4px;
`;
