import { useQueryClient } from '@tanstack/react-query';
import { createContext, FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef } from 'react';
import { Filter, GroupLevel } from 'src/domain';
import { useType } from 'src/hooks';
import { useDevTools } from 'src/hooks/devtools/useDevTools';
import { useStoreType } from 'src/hooks/useStoreType';
import { StoreModel, StoreType } from 'src/infrastructure/rest-api/api-types';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { GroupedStoresResult, groupStores } from 'src/mapping/store.mapping';

interface StoreCacheContextState {
  loading: boolean;
  error: Error | null;
  refetch: () => void;
}

type StoreCacheContextValue = readonly [
  (groupBy?: GroupLevel | null, storeType?: StoreType, filter?: Filter) => GroupedStoresResult,
  StoreCacheContextState,
];

export const StoreCacheContext = createContext<StoreCacheContextValue>([
  () => ({}) as GroupedStoresResult,
  { loading: true, error: null, refetch: () => {} },
]);

export const StoreCacheContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const queryClient = useQueryClient();
  const { data: stores, loading, error, refetch } = useApiQuery('/api/stores', 'get');
  const { impersonateEmail, isImpersonating } = useDevTools();

  const defaultGroupBy = useType();
  const defaultStoreType = useStoreType();

  const doGrouping = useCallback(
    (stores: StoreModel[] | undefined, groupBy: GroupLevel | null, storeType: StoreType, filter?: Filter) => {
      if (stores) {
        return groupStores(
          stores.map((store) => ({
            ...store,
            storeName: store.storeName?.trimChain() ?? null,
          })),
          groupBy,
          storeType,
          filter
        );
      }
      return {
        groupedStores: undefined,
        storeCount: 0,
        chains: [],
        clusters: [],
        countries: [],
        partners: [],
        allStores: stores ?? [],
        allStoresLookup: new Map(),
      };
    },
    []
  );

  const refetchStores = useCallback(() => {
    return refetch().then((res) => {
      doGrouping(res.data, defaultGroupBy, defaultStoreType);
    });
  }, [defaultGroupBy, defaultStoreType, doGrouping, refetch]);

  const getStores = useCallback(
    (groupBy?: GroupLevel | null, storeType?: StoreType, filter?: Filter) => {
      const groupByToUse = groupBy ?? defaultGroupBy;
      const storeTypeToUse = storeType ?? defaultStoreType;
      return doGrouping(stores, groupByToUse, storeTypeToUse, filter);
    },
    [defaultGroupBy, defaultStoreType, doGrouping, stores]
  );

  const isImpersonatingRef = useRef<boolean>(false);
  useEffect(() => {
    if (isImpersonating !== isImpersonatingRef.current && impersonateEmail) {
      queryClient.cancelQueries({ queryKey: ['/api/stores', undefined] }).then(() => {
        refetchStores();
      });
      isImpersonatingRef.current = isImpersonating;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonateEmail, isImpersonating, queryClient, refetchStores]);

  const contextValue = useMemo(() => {
    return [getStores, { loading, error, refetch: refetchStores }] as const;
  }, [error, getStores, loading, refetchStores]);

  return <StoreCacheContext.Provider value={contextValue}>{children}</StoreCacheContext.Provider>;
};
