import { ScopeEnum } from 'src/domain';
import { useScope } from 'src/hooks';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { selectedPartnerVar } from 'src/infrastructure/local_state';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useGlobalVar } from '../../useGlobalVar';

export const usePartnerCategorySplitQuery = () => {
  const [selectedPartner] = useGlobalVar(selectedPartnerVar);
  const partnerComposite = useCompositePartner(selectedPartner);
  const scope = useScope();

  const { data, error, loading } = useApiQuery('/api/categorysplit', 'get', {
    query: {
      entityId: Number(partnerComposite?.id),
      entityType: 'Partner',
    },
    enabled: !!partnerComposite?.id && (scope === ScopeEnum.PARTNER || scope === ScopeEnum.STORE),
  });

  return { data, error, loading } as const;
};
