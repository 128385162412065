import { isNumber, sortBy } from 'lodash';
import { InheritanceError, ScopeEnum } from 'src/domain';
import { InheritanceSettingsRow } from 'src/domain/table/inheritance-settings.row';
import { InheritanceLevelModel, StoreModel } from 'src/infrastructure/rest-api/api-types';
import {
  noosSplitLevelToInheritance,
  salesBudgetSplitLevelToInheritance,
  splitLevelToInheritance,
} from 'src/utils/splitLevels';

export type InheritanceSettingsData = InheritanceLevelModel[];

export const mapInheritanceSettings = (
  data: InheritanceSettingsData | undefined,
  storeLookup: Map<number, StoreModel>,
  genderId: number,
  productLineGroupId: number
): InheritanceSettingsRow[] => {
  if (!data || storeLookup.size <= 0) return [];

  // For inheritance settings, the scope is always store
  const scope = ScopeEnum.STORE;

  return sortBy(
    data.reduce<InheritanceSettingsRow[]>((acc, curr) => {
      if (!curr?.entityId) return acc;

      const storeId = curr.entityId;

      const store = storeLookup.get(storeId);

      if (!store) return acc;

      if (!isNumber(curr.id)) throw new InheritanceError(`Invalid inheritance id`);
      if (!isNumber(storeId)) throw new InheritanceError(`Store id missing!`);
      if (!store?.storeName) throw new InheritanceError(`Store name missing!`);
      if (!store?.operationalResponsible?.name) throw new InheritanceError(`Store operational responsible missing!`);
      if (!store?.cluster?.name) throw new InheritanceError(`Store cluster missing!`);
      if (!store?.country?.name) throw new InheritanceError(`Store country missing!`);

      if (
        !curr.categorySplit ||
        !curr.deliveryProfile ||
        !curr.genderSplit ||
        !curr.productLineSplit ||
        !curr.markup ||
        !curr.markdown ||
        !curr.noosShare ||
        !curr.deliveryProfile ||
        !curr.weekCover ||
        !curr.salesBudget
      ) {
        throw new InheritanceError(`Inheritance missing for ${store?.storeName}`);
      }

      const noosShareSplitLevel = splitLevelToInheritance(curr.noosShare, scope, genderId, productLineGroupId);
      const noosShareActualSplitLevel = noosSplitLevelToInheritance(curr.noosShare.noosSplit);

      acc.push({
        id: curr.id,
        storeId: storeId,
        storeName: store.storeName,
        clusterName: store.cluster?.name,
        partnerName: store.operationalResponsible?.name ?? '',
        countryName: store.country?.name ?? '',
        categorySplit: splitLevelToInheritance(curr.categorySplit, scope, genderId, productLineGroupId),
        deliveryProfile: splitLevelToInheritance(curr.deliveryProfile, scope, genderId, productLineGroupId),
        genderSplit: splitLevelToInheritance(curr.genderSplit, scope, genderId, productLineGroupId),
        productLineSplit: splitLevelToInheritance(curr.productLineSplit, scope, genderId, productLineGroupId),
        markup: splitLevelToInheritance(curr.markup, scope, genderId, productLineGroupId),
        markdown: splitLevelToInheritance(curr.markdown, scope, genderId, productLineGroupId),
        noosShare: {
          inheritance: noosShareSplitLevel,
          noosActualInheritance: noosShareActualSplitLevel,
        },
        weekCover: splitLevelToInheritance(curr.weekCover, scope, genderId, productLineGroupId),
        salesBudget: salesBudgetSplitLevelToInheritance(curr.salesBudget.salesBudgetSplit),
      });

      return acc;
    }, []),
    ['storeName']
  );
};
