import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { useClusterId } from 'src/hooks/cluster';
import { useStoreId } from 'src/hooks/store';
import { useScope } from 'src/hooks/useScope';
import { useSelectedPartner } from 'src/hooks/useSelectedPartner';
import { InheritanceLevelModel } from 'src/infrastructure/rest-api/api-types';
import { useSplitLevelApiQuery } from './useSplitLevelApiQuery';

type CurrentSplitLevelResponse = {
  data: InheritanceLevelModel | null | undefined;
  loading: boolean;
  error: Error | null;
};

export const useCurrentSplitLevelQuery = () => {
  const storeId = useStoreId();
  const [partner] = useSelectedPartner();
  const clusterId = useClusterId(partner);

  const scope = useScope();

  const storeData = useSplitLevelApiQuery(storeId, 'Store');
  const clusterData = useSplitLevelApiQuery(clusterId, 'Cluster');
  const partnerData = useSplitLevelApiQuery(partner?.compositeId, 'Partner');

  const result = useMemo<CurrentSplitLevelResponse>(() => {
    switch (scope) {
      case ScopeEnum.CLUSTER:
        return clusterData;
      case ScopeEnum.PARTNER:
        return partnerData;
      case ScopeEnum.STORE:
        return storeData;
    }
    return { data: null, loading: true, error: null };
  }, [clusterData, partnerData, scope, storeData]);

  return result;
};
