import { isNumber } from 'lodash';
import { useCallback } from 'react';
import { Cell } from 'src/components/organisms/DataTable/types';
import { Inheritance, Module, TableOverviewRow } from 'src/domain';
import { UnsavedChangesEvent } from 'src/domain/events/unsaved-changes.event';
import { DeliveryProfileRow } from 'src/domain/table/delivery-profile.row';
import { useSetSelectedInheritance, useStoreId, useUnsavedChangesModule } from 'src/hooks';
import { useDeliveryProfileOverviewSimulationRows } from 'src/hooks/delivery-profile/simulation/useDeliveryProfileOverviewSimulationRows';
import { useGenderProductLineKey } from 'src/hooks/useGenderProductLineKey';
import { mergeMap } from 'src/utils/mergeMap';

export const useDeliveryProfileHandleCellValueChanged = () => {
  const { data: typedData } = useDeliveryProfileOverviewSimulationRows(Inheritance.Typed) ?? {};
  const { data: originalData } = useDeliveryProfileOverviewSimulationRows() ?? {};
  const storeId = useStoreId();
  const genderProductLineKey = useGenderProductLineKey();
  const setSelectedInheritance = useSetSelectedInheritance();

  const [unsavedChanges, setUnsavedChanges] = useUnsavedChangesModule<Record<string, DeliveryProfileRow[]>>(
    Module.DeliveryProfile
  );

  const callback = useCallback(
    async (cellValues: Cell[]) => {
      if (!typedData || !originalData) {
        throw new Error('Data is missing!');
      }
      if (!storeId) {
        throw new Error(`Store ID is missing`);
      }

      const data = mergeMap([typedData, originalData], ([expectedRow, currentRow]) => {
        const currentValues = currentRow
          ? Object.keys(currentRow.columns).reduce(
              (acc, key) => {
                if (key.endsWith('Id')) {
                  return acc;
                }
                acc[key] = currentRow.columns[key];
                return acc;
              },
              {} as Record<string, number>
            )
          : {};

        const result = {
          ...expectedRow,
          ...currentRow,
          columns: {
            ...expectedRow?.columns,
            ...currentValues,
          },
        } as TableOverviewRow;

        return result;
      });

      cellValues.forEach((cellValue) => {
        const rowIndexToUpdate = data.findIndex((x) => x.id === cellValue.rowId);
        const fieldName: string | undefined = cellValue.column?.split('.')[1];

        const valueIsValid = !isNaN(cellValue.value) && isNumber(Number(cellValue.value));

        if (fieldName) {
          data[rowIndexToUpdate] = {
            ...data?.[rowIndexToUpdate],
            columns: {
              ...data?.[rowIndexToUpdate]?.columns,
              [fieldName]: valueIsValid ? cellValue.value : 0,
            },
          };
        }
      });

      const unsaved = {
        ...unsavedChanges,
        ...{ [genderProductLineKey]: [...data] },
      };

      setUnsavedChanges(unsaved);
      setSelectedInheritance(Inheritance.Typed);
      dispatchEvent(new Event(UnsavedChangesEvent));
    },
    [genderProductLineKey, originalData, setSelectedInheritance, setUnsavedChanges, storeId, typedData, unsavedChanges]
  );

  return callback;
};
