import styled from '@emotion/styled';
import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { StoreInformationChip } from 'src/components/molecules/StoreInformationChip/StoreInformationChip';
import { useFilterContext } from 'src/contexts/FilterContext';
import { Color } from 'src/domain';
import { StoreGroup, StoreSelectionList } from 'src/domain/models/store/StoreSelection.types';
import { StoreType } from 'src/infrastructure/rest-api/api-types';
import { buildPathFromContext } from 'src/utils/buildPath';

interface Props {
  stores: StoreSelectionList;
  storeType: StoreType;
}

const SelectionList: FC<Props> = ({ stores, storeType }) => {
  const [filterContext] = useFilterContext();

  const calculateWidth = (nbrOfGroupings: number, nbrOfStores: number) => {
    const height = Math.floor((nbrOfStores * 15) / 120);
    if (nbrOfGroupings >= 8) {
      return '100%';
    } else {
      if (height > 0) {
        return `${(height + 3) * 160}px`;
      }
      return `${nbrOfGroupings * 160}px`;
    }
  };

  return (
    <>
      {Array.from(stores.entries()).map(([chainId, chain]) => (
        <Fragment key={`chain-${chainId}`}>
          <ChainWrapper
            width={calculateWidth(
              Array.from(chain.groups.entries()).length,
              Array.from<[string, StoreGroup]>(stores.get(chainId)?.groups.entries() ?? []).reduce(
                (acc, [_, { stores }]) => acc + stores.length,
                0
              )
            )}
            chain={chain.chainName}
          >
            <ChainTitle>{chain.chainName}</ChainTitle>
            <CountryStoresList>
              {Array.from(stores.get(chainId)?.groups.entries() ?? []).map(([_, group], _index, groupArray) => (
                <CountryWrapper key={group.groupId} length={groupArray.length}>
                  <Link
                    to={{
                      pathname: '/turnover',
                      search: buildPathFromContext(
                        chainId.toString(),
                        storeType,
                        filterContext.grouping,
                        'overview',
                        group.groupKey
                      ),
                    }}
                  >
                    <CountryTitle>{group.groupName}</CountryTitle>
                  </Link>
                  {group.stores.map((store) => (
                    <Link
                      key={`store-${store.id}`}
                      to={{
                        pathname: '/turnover',
                        search: buildPathFromContext(
                          chainId.toString(),
                          storeType,
                          'store',
                          store.id.toString() ?? '',
                          group.toString()
                        ),
                      }}
                    >
                      <ItemLabel>
                        <span style={{ marginTop: 'auto', marginBottom: 'auto' }}>{store.storeName}</span>
                        <StoreInformationChip store={store} />
                      </ItemLabel>
                    </Link>
                  ))}
                </CountryWrapper>
              ))}
            </CountryStoresList>
          </ChainWrapper>
        </Fragment>
      ))}
    </>
  );
};

const CountryStoresList = styled.div`
  column-count: auto;
  column-width: 200px;
  position: relative;
  border: 1px dashed #333333;
  border-radius: 2px;
  padding: 15px 10px;
  flex: 1;
`;

const ChainWrapper = styled.div<{
  width: string;
  chain: string;
}>`
  display: flex;
  position: relative;
  ${(props) =>
    props.chain.toLowerCase() === 'object collectors item' &&
    `
  min-width: 250px;
  `}
  width: ${(props) => `min(${props.width}, 100%)`};
`;

const ChainTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  z-index: 1;
  top: -22px;
  left: 5px;
  padding: 0px 5px;
  background-color: ${Color.white};
  align-self: flex-start;
  text-transform: uppercase;
`;

const CountryWrapper = styled.div<{ length: number }>`
  margin-bottom: ${(props) => (props.length > 1 ? `10px` : `0px`)};
`;

const CountryTitle = styled.h3`
  font-size: 12px;
  margin: 0;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ItemLabel = styled.p`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin: 0;
  font-size: 12px;
  max-width: 200px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export default SelectionList;
