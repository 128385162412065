import styled from '@emotion/styled';
import { ITooltipParams } from 'ag-grid-community';
import MathJax from 'better-react-mathjax/MathJax';

export function CalculationToolTip(params: ITooltipParams<unknown, [string, string] | string>) {
  return (
    <ToolTipWrapper>
      {Array.isArray(params.value) && (
        <>
          <MathJax inline>{`${params.value?.[0]} ${params.value?.[1]}`}</MathJax>
        </>
      )}
      {!Array.isArray(params.value) && (
        <>
          <MathJax inline>{params.value}</MathJax>
        </>
      )}
    </ToolTipWrapper>
  );
}

const ToolTipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(97, 97, 97, 0.92);
  border-radius: 4px;
  padding: 4px 8px;
  color: white;
  font-size: 20px;
`;
