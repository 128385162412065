import { useMemo } from 'react';
import { useChainMenuSettingsApiQuery } from './useChainMenuSettingsApiQuery';

export const useIsUsingDeliveryProfile = () => {
  const { data } = useChainMenuSettingsApiQuery();

  return useMemo(() => {
    return !!data?.find((item) => item.menu.toString() === 'DeliveryProfile')?.visible;
  }, [data]);
};
