import { useMemo } from 'react';
import { TableOverviewRow } from 'src/domain';
import { useOverviewRowsSum } from './useOverviewRowsSum';

export const useIsSum100 = (data: TableOverviewRow[] | undefined) => {
  const sum = useOverviewRowsSum(data);

  const sumIs100 = useMemo(() => {
    if (!sum) {
      return false;
    }
    return Object.entries(sum).every(([_key, value]) => value === 100);
  }, [sum]);

  return sumIs100;
};
