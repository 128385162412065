import styled from '@emotion/styled';
import { ComponentProps, FC } from 'react';
import { StoreSelectionList } from 'src/domain/models/store/StoreSelection.types';
import { StoreType } from 'src/infrastructure/rest-api/api-types';
import SelectionList from '../SelectionList';

interface Props extends ComponentProps<'div'> {
  stores: StoreSelectionList;
  storeType: StoreType;
}

const SelectionContainer: FC<Props> = ({ stores, storeType }) => {
  if (stores.size > 0) {
    return (
      <Wrapper>
        <SelectionList stores={stores} storeType={storeType} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <NoDataParagraph>0 results found</NoDataParagraph>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;
  flex-direction: column;
`;

const NoDataParagraph = styled.p`
  text-align: center;
  width: 100%;
`;

export default SelectionContainer;
