import { Inheritance } from 'src/domain';
import { useWeekCoverInheritanceQuery } from './useWeekCoverInheritanceQuery';

export const useWeekCoverQuery = (inheritance?: Inheritance, genderId?: number, productLineId?: number) => {
  const { data, sanitizedData, styleCategories, loading, error } = useWeekCoverInheritanceQuery(
    inheritance,
    genderId,
    productLineId
  );
  return { data, sanitizedData, styleCategories, loading, error };
};
