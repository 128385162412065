import CodeIcon from '@mui/icons-material/Code';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { FC, ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Module } from 'src/domain';
import { useDevTools } from 'src/hooks/devtools/useDevTools';
import { useCurrentModule } from 'src/hooks/useCurrentModule';

export const DevTools: FC = () => {
  const {
    impersonateEmail,
    setImpersonateEmail,
    isImpersonating,
    setIsImpersonating,
    impersonateRoles,
    setImpersonateRoles,
  } = useDevTools();

  const handleImpersonateEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setImpersonateEmail(event.target.value);
    },
    [setImpersonateEmail]
  );

  // Set impersonate roles
  const handleImpersonateRolesChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const checked = event.target.checked;

      setImpersonateRoles((previousRoles: string[]) => {
        const inx = previousRoles.findIndex((role) => role === value);
        const hasRole = inx >= 0;

        if (hasRole && !checked) {
          return [...previousRoles.slice(0, inx), ...previousRoles.slice(inx + 1)];
        }

        if (!hasRole && checked) {
          return [...previousRoles, value];
        }

        return previousRoles;
      });
    },
    [setImpersonateRoles]
  );

  const handleImpersonateClick = () => {
    setIsImpersonating(!isImpersonating);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const currentModule = useCurrentModule();
  const moduleIsStoreSelection = useMemo(
    () => currentModule === Module.StoreSelection || currentModule === undefined,
    [currentModule]
  );

  return (
    <DevToolsWrapper sx={{ zIndex: isOpen ? 5 : 1 }} data-testid="devtools">
      <DevToolsToggle onClick={toggleOpen}>
        <CodeIcon fontSize={'small'}></CodeIcon>
        <Typography>DevTools</Typography>
      </DevToolsToggle>
      <Stack
        direction={'column'}
        gap={4}
        sx={{
          transition: 'width 0.3s ease-in-out',
          transitionProperty: 'width, padding',
          width: isOpen ? 300 : 0,
          padding: isOpen ? '1rem 1.5rem' : '1rem 0',
          overflow: 'hidden',
        }}
      >
        {moduleIsStoreSelection ? (
          <Typography variant="h4" component={'h6'}>
            DevTools
          </Typography>
        ) : (
          <Typography variant="h4" component={'h6'} sx={{ paddingTop: '50px' }}>
            DevTools
          </Typography>
        )}
        <Stack direction="column" gap={1}>
          <TextField
            data-testid="impersonate-input"
            size="small"
            disabled={isImpersonating}
            label="Impersonate email"
            placeholder="Enter email address of user to impersonate"
            value={impersonateEmail}
            onChange={handleImpersonateEmailChange}
          ></TextField>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={impersonateRoles.includes('superuser')}
                  value="superuser"
                  onChange={handleImpersonateRolesChange}
                />
              }
              label="SuperUser"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={impersonateRoles.includes('admin')}
                  value="admin"
                  onChange={handleImpersonateRolesChange}
                />
              }
              label="Admin"
            />
          </FormGroup>
          <Button
            data-testid="impersonate-button"
            onClick={handleImpersonateClick}
            variant="contained"
            color={isImpersonating ? 'error' : 'primary'}
            disabled={!impersonateEmail}
          >
            {isImpersonating ? 'Stop impersonating' : 'Impersonate'}
          </Button>
        </Stack>
      </Stack>
    </DevToolsWrapper>
  );
};

const DevToolsToggle = styled(Box)`
  cursor: pointer;
  position: absolute;
  top: 60px;
  left: -33px;
  padding: 0.75rem 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  writing-mode: vertical-lr;
  text-orientation: mixed;

  background-color: white;
  border-radius: 5px 0 0 5px;
  border: 1px solid #e0e0e0;
  border-right: none;
`;

const DevToolsWrapper = styled(Box)`
  height: 100%;
  position: fixed;
  right: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;

  transition: width 0.3s ease-in-out;
  transition-property: width, padding;
`;
