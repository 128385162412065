import { GetGroupRowAggParams } from 'ag-grid-community';
import { isNumber } from 'lodash';
import { CategoryDetailsBaseRow, CategoryDetailsRow } from 'src/domain';

export interface CategorySplitDetailsAggregation
  extends Omit<CategoryDetailsBaseRow, 'id' | 'category' | 'plannedIndex' | 'plannedSalesIv'> {
  plannedIndex: [number, number | undefined];
  plannedSalesIv: [number, number | undefined];
  salesIvLly: number;
  indexLy: number;
  gmLy: number;
  cogsLY: number;
  netSalesLY: number;
  grossProfitLY: number;
  sumCurrentPlannedSalesIv: number;
  sumPredictedPlannedSalesIv: number;
}

export const weightedAverageCategorySplitDetails = (params: GetGroupRowAggParams<CategoryDetailsRow>) => {
  return params.nodes.reduce<CategorySplitDetailsAggregation>(
    (acc, value) => {
      const data = value.data;

      if (!data) return acc;

      const currentPlannedSalesIv = Array.isArray(data?.plannedSalesIv)
        ? data?.plannedSalesIv[0]
        : data?.plannedSalesIv;
      const predictedPlannedSalesIv = Array.isArray(data?.plannedSalesIv) ? data?.plannedSalesIv[1] : undefined;

      const salesIvLY = data?.salesIvLy;
      const salesIvLLY = data?.salesIvLly;
      const plannedSplit = data.plannedSplit;
      const netSalesLY = data?.netSalesLY;
      const cogsLY = data?.cogsLY;

      if (isNumber(salesIvLY)) acc.salesIvLy += salesIvLY;
      if (isNumber(salesIvLLY)) acc.salesIvLly += salesIvLLY;

      acc.splitLly += data?.splitLly;
      if (data.splitLy !== -999) acc.splitLy += data?.splitLy;
      acc.plannedSplit += plannedSplit;
      acc.indexLy = (acc.salesIvLy / acc.salesIvLly) * 100;
      if (isNumber(data?.cogsLY)) acc.cogsLY += data.cogsLY;

      if (isNumber(netSalesLY) && isNumber(cogsLY)) acc.grossProfitLY += netSalesLY - cogsLY;
      if (isNumber(netSalesLY)) acc.netSalesLY += netSalesLY;

      acc.gmLy = (acc.grossProfitLY / acc.netSalesLY) * 100;

      // calculate planned sales iv
      if (isNumber(currentPlannedSalesIv)) acc.sumCurrentPlannedSalesIv += currentPlannedSalesIv;
      if (isNumber(predictedPlannedSalesIv)) {
        acc.sumPredictedPlannedSalesIv += predictedPlannedSalesIv;
      } else {
        acc.sumPredictedPlannedSalesIv += currentPlannedSalesIv;
      }

      // check if planned sales iv has unsaved changes
      if (acc.sumCurrentPlannedSalesIv !== acc.sumPredictedPlannedSalesIv) {
        acc.plannedSalesIv = [acc.sumCurrentPlannedSalesIv, acc.sumPredictedPlannedSalesIv];
        acc.plannedIndex = [
          (acc.sumCurrentPlannedSalesIv / acc.salesIvLy) * 100,
          (acc.sumPredictedPlannedSalesIv / acc.salesIvLy) * 100,
        ];
      } else {
        acc.plannedSalesIv = [acc.sumCurrentPlannedSalesIv, undefined];
        acc.plannedIndex = [(acc.sumCurrentPlannedSalesIv / acc.salesIvLy) * 100, undefined];
      }

      return acc;
    },
    {
      splitLly: 0,
      splitLy: 0,
      plannedSplit: 0,
      plannedIndex: [0, undefined],
      plannedSalesIv: [0, undefined],
      salesIvLy: 0,
      salesIvLly: 0,
      indexLy: 0,
      gmLy: 0,
      cogsLY: 0,
      netSalesLY: 0,
      grossProfitLY: 0,
      sumCurrentPlannedSalesIv: 0,
      sumPredictedPlannedSalesIv: 0,
    }
  );
};
