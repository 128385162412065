import { useMemo } from 'react';
import { StoreModel } from 'src/infrastructure/rest-api/api-types';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useCompositePartner } from '../partner/useCompositePartner';
import { useSelectedPartner } from '../useSelectedPartner';

export const useClustersByCompositeId = () => {
  const [selectedPartner] = useSelectedPartner();
  const partner = useCompositePartner(selectedPartner);

  const { data, loading, error } = useApiQuery('/api/clusters/partners/{partnerId}', 'get', {
    path: {
      partnerId: partner?.id,
    },
    enabled: !!partner?.id,
  });

  const stores = useMemo(() => {
    if (data) {
      return data.reduce((acc, cluster) => {
        if (cluster.stores) {
          return [
            ...acc,
            ...(cluster.stores.map((store) => ({
              ...store,
              cluster: cluster,
            })) as StoreModel[]),
          ];
        }

        return acc;
      }, [] as StoreModel[]);
    }
  }, [data]);

  return { clusters: data, stores, loading, error };
};
