import { GroupWork } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { DrawerItem } from 'src/components/molecules/DrawerItem/DrawerItem';

export const SettingsSidebar: FC = () => {
  const url = useLocation();
  return (
    <Stack justifyContent="center">
      <DrawerItem
        icon={<GroupWork />}
        open
        title="Cluster Management"
        to={{ pathname: '/settings/cluster-management', search: url.search }}
      />
    </Stack>
  );
};
