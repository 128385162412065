import { ScopeEnum } from 'src/domain';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useScope } from 'src/hooks/useScope';
import { useSelectedPartner } from 'src/hooks/useSelectedPartner';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useStorefrontType } from '../useStorefrontType';

export const usePartnerStyleTimelineQuery = (selectedStorefrontType?: string | null) => {
  const defaultStorefrontType = useStorefrontType();
  const storefrontType = selectedStorefrontType ?? defaultStorefrontType;

  const [partner] = useSelectedPartner();
  const partnerComposite = useCompositePartner(partner);
  const scope = useScope();

  const response = useApiQuery('/api/styletimeline/partners/{partnerCompositeId}', 'get', {
    path: {
      partnerCompositeId: partnerComposite?.id,
    },
    query: {
      storefrontType,
    },
    enabled: !!partnerComposite?.id && !!storefrontType && scope === ScopeEnum.PARTNER,
  });

  return response;
};
