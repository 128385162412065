import { useMemo } from 'react';
import { CategoryOverviewRow, Inheritance, Module } from 'src/domain';
import { useInheritance } from 'src/hooks';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { useAggregatedDataMergerForUnsavedChanges } from 'src/hooks/useAggregatedDataMergerForUnsavedChanges';
import { useGenderProductLineKey } from 'src/hooks/useGenderProductLineKey';
import { useCategorySplitOverviewRows } from '../table/useCategorySplitOverviewRows';

export const useCategorySplitOverviewSimulationRows = (
  inheritance?: Inheritance,
  genderId?: number,
  productLineId?: number,
  aggregateOnGenderProductlines = false
) => {
  const [unsavedChanges] = useUnsavedChangesModule<Record<string, CategoryOverviewRow[]>>(Module.CategorySplit);
  const { data, aggregatedData, columns, loading, error } =
    useCategorySplitOverviewRows(inheritance, genderId, productLineId, aggregateOnGenderProductlines) ?? {};
  const genderProductLineKey = useGenderProductLineKey();
  const mergeUnsavedChanges = useAggregatedDataMergerForUnsavedChanges();

  const moduleInheritance = useInheritance(Module.CategorySplit);
  const selectedInheritance = inheritance ?? moduleInheritance;

  const isTyped = selectedInheritance === Inheritance.Typed;

  const unsavedChangesGenderProductLine = unsavedChanges?.[genderProductLineKey];
  const hasUnsavedChanges = !!unsavedChangesGenderProductLine;
  const overviewRows = useMemo(() => {
    return isTyped && hasUnsavedChanges ? unsavedChangesGenderProductLine : data;
  }, [data, hasUnsavedChanges, isTyped, unsavedChangesGenderProductLine]);

  const aggregatedOverviewRows = useMemo(() => {
    if (aggregateOnGenderProductlines) {
      return mergeUnsavedChanges(aggregatedData, unsavedChanges);
    }
  }, [aggregateOnGenderProductlines, aggregatedData, mergeUnsavedChanges, unsavedChanges]);

  return { data: overviewRows, aggregatedData: aggregatedOverviewRows, columns, loading, error };
};
