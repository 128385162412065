import styled from '@emotion/styled';
import { BuildCircle, Error, Info, NewReleases } from '@mui/icons-material';
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNumber } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import Container from 'src/components/atoms/Container';
import Spacer from 'src/components/atoms/Spacer';
import { StyledDiscardButton as CancelButton } from 'src/components/styled/DiscardButton';
import { StyledLoadingButton as SaveButton } from 'src/components/styled/LoadingButton';
import { Module, Spacings } from 'src/domain';
import { useCreateNewAnnouncement } from 'src/hooks/announcements/create/useCreateAnnouncement';
import { useUpdateAnnouncement } from 'src/hooks/announcements/update/useUpdateAnnouncement';
import { AnnouncementTypeEnum } from 'src/infrastructure/rest-api/api-types';

interface Props {
  id: number | undefined;
  title: string | undefined;
  description: string | undefined;
  type: AnnouncementTypeEnum | undefined;
  module: Module | null | undefined;
  resolved: boolean;
  open: boolean;
  handleCloseDialog: () => void;
  handleRefetch: () => void;
}

export const AnnouncementDialog: FC<Props> = ({
  id,
  title: announcementTitle,
  description: announcementDescription,
  type: announcementType,
  module: announcementModule,
  resolved: announcementResolved,
  open,
  handleCloseDialog,
  handleRefetch,
}) => {
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [title, setTitle] = useState(announcementTitle ?? '');
  const [description, setDescription] = useState(announcementDescription ?? '');
  const [type, setType] = useState(announcementType ?? 'Information');
  const [module, setModule] = useState(announcementModule ?? null);
  const [resolved, setResolved] = useState<boolean>(announcementResolved ?? false);
  const [dialogOpen, setDialogOpen] = useState(open ?? false);

  const [createAnnouncement, { loading: creatingAnnouncement }] = useCreateNewAnnouncement({
    title,
    description,
    type,
    module,
  });

  const [updateAnnouncement, { loading: updatingAnnouncement }] = useUpdateAnnouncement({
    id,
    title,
    description,
    type,
    module,
    resolved,
  });

  const loading = updatingAnnouncement || creatingAnnouncement;

  const canSave = useCallback(() => {
    if (!title.length || !description.length || !type) return false;
    return true;
  }, [title, description, type]);

  const closeDialogue = useCallback(() => {
    setDialogOpen(false);
    handleCloseDialog();
  }, [handleCloseDialog]);

  const resetAnnouncement = useCallback(() => {
    setTitle('');
    setDescription('');
    setType('Information');
    setModule(null);
    setResolved(false);
    setTitleError(false);
    setDescriptionError(false);
  }, []);

  const onCancel = useCallback(() => {
    closeDialogue();
    resetAnnouncement();
  }, [closeDialogue, resetAnnouncement]);

  const handleSaveAnnouncement = useCallback(async () => {
    if (id !== undefined) {
      await updateAnnouncement();
    } else {
      await createAnnouncement();
    }

    handleRefetch();
    closeDialogue();
    resetAnnouncement();
  }, [closeDialogue, createAnnouncement, handleRefetch, id, resetAnnouncement, updateAnnouncement]);

  useEffect(() => {
    if (open) setDialogOpen(open);
    if (announcementTitle) setTitle(announcementTitle);
    if (announcementDescription) setDescription(announcementDescription);
    if (announcementType) setType(announcementType);
    if (announcementModule) setModule(announcementModule);
    if (announcementResolved) setResolved(announcementResolved);
  }, [announcementDescription, announcementModule, announcementResolved, announcementTitle, announcementType, open]);

  return (
    <Collapse in={dialogOpen}>
      <DialogWrapper>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: 'absolute',
            height: '100%',
          }}
          open={loading}
        >
          {loading && <CircularProgress color="inherit" />}
        </Backdrop>
        <Stack spacing={2}>
          <TitleWrapper>
            <TextField
              error={titleError}
              onBlur={() => {
                if (!title) setTitleError(true);
                else setTitleError(false);
              }}
              margin="dense"
              id="standard-basic"
              placeholder="New Announcement Title"
              variant="standard"
              onChange={(e) => {
                setTitle(e.target.value);
                if (e.target.value) setTitleError(false);
              }}
              value={title}
              style={{ width: '300px', margin: 0 }}
              helperText={titleError ? 'Cannot be empty' : undefined}
              inputProps={{ maxLength: 50 }}
            />
            <FormGroup>
              <ResolvedLabel
                disabled={!isNumber(id)}
                control={<Checkbox />}
                label="Mark as resolved"
                onChange={() => setResolved((state) => !state)}
                checked={resolved}
              />
            </FormGroup>
          </TitleWrapper>
          <FormControl>
            <InputLabel id="announcement-type-label">Announcement Type</InputLabel>
            <AnnouncementTypeSelect
              labelId="announcement-type-label"
              id="announcement-type-select"
              value={type}
              label="Announcement Type"
              size="small"
              onChange={(e) => {
                setType(e.target.value as AnnouncementTypeEnum);
              }}
            >
              <AnnouncementTypeItem value={'Information'}>
                <Info fontSize="small" sx={{ color: '#4472C4' }} />
                Information
              </AnnouncementTypeItem>
              <AnnouncementTypeItem value={'Maintenance'}>
                <BuildCircle fontSize="small" sx={{ color: '#4472C4' }} />
                Maintenance
              </AnnouncementTypeItem>
              <AnnouncementTypeItem value={'MinorIncident'}>
                <Error fontSize="small" sx={{ color: '#DBAB09' }} />
                Minor Incident
              </AnnouncementTypeItem>
              <AnnouncementTypeItem value={'MajorIncident'}>
                <NewReleases fontSize="small" sx={{ color: '#D9512C' }} />
                Major Incident
              </AnnouncementTypeItem>
            </AnnouncementTypeSelect>
          </FormControl>
          <TextField
            id="outlined-multiline-flexible"
            label="Description"
            multiline
            maxRows={6}
            sx={{ maxWidth: '70%' }}
            onChange={(e) => {
              setDescription(e.target.value);
              if (e.target.value) setDescriptionError(false);
            }}
            helperText={descriptionError ? 'Cannot be empty' : undefined}
            value={description}
            inputProps={{ maxLength: 300 }}
          />
          <Typography variant="subtitle1" component="h2">
            Module affected by this announcement:
          </Typography>
          <ModulesGroup
            value={module}
            onChange={(e) => {
              setModule(e.target.value as Module);
            }}
          >
            {Object.values(Module)
              .sort()
              .map((module) => (
                <FormControlLabel
                  key={module}
                  control={<Radio sx={{ padding: '6px 4px' }} />}
                  label={module.toUpperCaseLetters(1).replaceAll('-', ' ')}
                  sx={{ marginRight: '20px' }}
                  value={module}
                  onClick={(e) => {
                    if ((e.target as HTMLInputElement).value === module) {
                      setModule(null);
                    }
                  }}
                />
              ))}
          </ModulesGroup>
          <ButtonsContainer>
            <CancelButton color={'error'} size={'small'} variant={'contained'} onClick={onCancel}>
              Cancel
            </CancelButton>
            <Spacer spacing={Spacings.xSmall} />
            <SaveButton disabled={!canSave()} size={'small'} variant={'contained'} onClick={handleSaveAnnouncement}>
              {id !== undefined ? 'Update' : 'Save'}
            </SaveButton>
          </ButtonsContainer>
        </Stack>
      </DialogWrapper>
    </Collapse>
  );
};

const DialogWrapper = styled(Container)`
  width: 70%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ResolvedLabel = styled(FormControlLabel)`
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

const AnnouncementTypeSelect = styled(Select)`
  width: 180px;
  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const AnnouncementTypeItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ModulesGroup = styled(RadioGroup)`
  display: inline-flex;
  flex-flow: column wrap;
  max-height: 145px;
  max-width: 100%;
  padding-left: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  justify-content: flex-end;
  height: fit-content;
  align-self: flex-end;
`;
