import { Box, Stack, styled } from '@mui/material';
import { FC } from 'react';

interface ListCellRendererToolTipProps {
  items: string[] | null | undefined;
}

export const ListCellRendererToolTip: FC<ListCellRendererToolTipProps> = ({ items }) => {
  return <ToolTipWrapper>{items?.map((item) => <Box key={item}>{item}</Box>)}</ToolTipWrapper>;
};

const ToolTipWrapper = styled(Stack)`
  gap: 0.5rem;
`;
