import _, { sortBy } from 'lodash';
import { Store } from 'src/domain/models/store/store';
import { OptimalOptionCountRow } from 'src/domain/table/optimal-option-count.row';
import { OptimalOptionCount } from 'src/hooks/optimal-option-count/query/useOptimalOptionCountLevelQuery';
import { monthNumberToName } from 'src/utils/buildMonth';
import { formatPlacementDetailEnum, formatPlacementEnum, placements } from 'src/utils/placements';

export const OptimalOptionCountTotalLabel = 'Optimal Option Count';
export const OptimalOptionCountStylesPer100SQMLabel = 'Styles per 100 SQM';

const mapColumns = (data: OptimalOptionCount[], size?: number): Record<string, number> => {
  return data?.reduce<Record<string, number>>((acc, cur) => {
    if (!cur?.month) return acc;
    const month = monthNumberToName(cur?.month).toLowerCase();
    const value = cur?.value;
    const id = cur?.id;

    if (!value || !id) return acc;

    if (acc[month] && size) {
      acc[month] += Math.round(value / (size / 100));
    }

    acc[month] = size ? Math.round(value / (size / 100)) : value;
    acc[`${month}Id`] = id;
    return acc;
  }, {});
};

const sumStoreSizes = (stores: Store[]): number => {
  return stores?.reduce<number>((acc, cur) => {
    return acc + (cur?.salesFloorSquaremeters ?? 0);
  }, 0);
};

const sumStoreMonthsTo12Months = (stores: OptimalOptionCount[]): OptimalOptionCount[] => {
  return stores?.reduce<OptimalOptionCount[]>((acc, cur) => {
    const month = cur?.month;
    const value = cur?.value;

    if (!month || !value) return acc;

    const index = acc?.findIndex((x) => x?.month === month);

    if (index !== -1 && acc[index]) {
      acc[index] = { ...acc[index], value: (acc[index].value ?? 0) + value };
    } else {
      acc.push({ ...cur });
    }
    return acc;
  }, []);
};

const mapOptimalOptionCountTotals = (data: OptimalOptionCount[], size: number) => {
  const countColumns = mapColumns(data);
  const stylesPer100SQMColumns = mapColumns(data, size);

  return [
    {
      id: 0,
      category: OptimalOptionCountTotalLabel,
      columns: countColumns,
      isFooter: false,
    },
    {
      id: 1,
      category: OptimalOptionCountStylesPer100SQMLabel,
      columns: stylesPer100SQMColumns,
      isFooter: false,
    },
  ];
};

export const mapOptimalOptionCountPartnerClusterTotals = (
  current: OptimalOptionCount[] | undefined | null,
  stores: Store[]
): OptimalOptionCountRow[] => {
  if (!current) return [];
  const size = sumStoreSizes(stores);
  const sumData = sumStoreMonthsTo12Months(current);
  const data = current;

  const minValueColumns = data?.reduce<Record<string, number>>((acc, cur) => {
    if (!cur?.month) return acc;
    const month = monthNumberToName(cur?.month).toLowerCase();
    const value = cur?.value;
    const id = cur?.id;

    if (!value || !id) return acc;

    if (acc[month] && acc[month] < value) return acc;

    acc[month] = value;
    acc[`${month}Id`] = id;
    return acc;
  }, {});

  const maxValueColumns = data?.reduce<Record<string, number>>((acc, cur) => {
    if (!cur?.month) return acc;
    const month = monthNumberToName(cur?.month).toLowerCase();
    const value = cur?.value;
    const id = cur?.id;

    if (!value || !id) return acc;

    if (acc[month] && acc[month] > value) return acc;

    acc[month] = value;
    acc[`${month}Id`] = id;
    return acc;
  }, {});

  const stylesPer100SQMColumns = mapColumns(sumData, size);

  return [
    {
      id: 0,
      category: 'Min count',
      columns: minValueColumns,
      isFooter: false,
    },
    {
      id: 1,
      category: 'Max count',
      columns: maxValueColumns,
      isFooter: false,
    },
    {
      id: 2,
      category: OptimalOptionCountStylesPer100SQMLabel,
      columns: stylesPer100SQMColumns,
      isFooter: false,
    },
  ];
};

export const mapOptimalOptionCountStoreTotals = (
  current: OptimalOptionCount[] | undefined | null,
  stores: Store[]
): OptimalOptionCountRow[] => {
  if (!current) return [];
  const size = sumStoreSizes(stores);
  return mapOptimalOptionCountTotals(current, size);
};

export const mapOptimalOptionCountPlacement = (
  current: OptimalOptionCount[] | undefined | null,
  stores: Store[],
  storeId?: number,
  storeName?: string
): OptimalOptionCountRow[] => {
  if (!current) return [];
  const rows = current.reduce<OptimalOptionCountRow[]>((acc, cur) => {
    const month = cur?.month;

    if (!month) return acc;

    const optimalOptionCountPlacements = _.sortBy(cur?.optimalOptionCountPlacements, (value, _) => {
      const categorySort = placements?.find((x) => x?.storePlacement === value?.storePlacement)?.placementSort;
      return categorySort;
    });

    optimalOptionCountPlacements?.forEach((curPlacement, index) => {
      const storePlacement = formatPlacementEnum(curPlacement?.storePlacement);
      const storePlacementDetail = formatPlacementDetailEnum(curPlacement?.storePlacementDetail);
      const monthName = monthNumberToName(month).toLowerCase();

      if (!storePlacement) return;

      const row = acc.find(
        (row) =>
          (row.category === storePlacement && row.subCategory === storePlacementDetail) ||
          (row.category === storePlacement && !row.subCategory)
      );

      if (row) {
        row.columns[monthName] = curPlacement?.value ?? null;
        row.columns[`${monthName}Id`] = curPlacement?.id ?? null;
      } else {
        acc.push({
          id: storeId ? Number(`${storeId}${index}`) : index,
          category: storePlacement,
          subCategory: storePlacementDetail,
          storeName,
          storeId,
          columns: {
            [monthName]: curPlacement?.value ?? null,
            [`${monthName}Id`]: curPlacement?.id ?? null,
          },
          isFooter: false,
        });
      }
    });

    return acc;
  }, []);

  const size = sumStoreSizes(stores);
  const countColumns = mapColumns(current);
  const stylesPer100SQMColumns = mapColumns(current, size);

  const aggregatedColumns = rows.reduce<OptimalOptionCountRow>(
    (acc, cur) => {
      Object.keys(cur?.columns).forEach((key) => {
        if (!key || key.includes('Id')) return;
        const value = cur?.columns[key];
        const id = cur?.columns[`${key}Id`];

        acc.columns[key] = (acc.columns[key] ?? 0) + (value ?? 0);
        acc.columns[`${key}Id`] = id;
      });

      return acc;
    },
    {
      id: 0,
      category: 'Total',
      columns: {},
    }
  );

  const index = rows.length + 1;

  rows.push({
    id: storeId ? Number(`${storeId}${index}`) : index,
    category: 'Total',
    columns: aggregatedColumns.columns,
    isFooter: true,
    storeName,
    storeId,
  });

  rows.push({
    id: storeId ? Number(`${storeId}${index + 1}`) : index + 1,
    category: 'Optimal Option Count',
    columns: countColumns,
    isFooter: true,
    storeName,
    storeId,
  });

  rows.push({
    id: storeId ? Number(`${storeId}${index + 2}`) : index + 2,
    category: 'Styles per 100 SQM',
    columns: stylesPer100SQMColumns,
    isFooter: true,
    storeName,
    storeId,
  });

  return rows;
};

export const mapOptimalOptionCountStoreRows = (
  storesList: OptimalOptionCount[] | undefined | null,
  stores: Store[]
): OptimalOptionCountRow[] => {
  const storeRows = stores.reduce<OptimalOptionCountRow[]>((acc, store) => {
    const storeId = store.storeId;
    const storeName = store.storeName;

    // Filter the storesList to get all objects for the current storeId
    const currentStoreData = storesList?.filter((x) => x.entityId === storeId);

    if (currentStoreData && currentStoreData.length > 0) {
      // Pass the filtered array to mapOptimalOptionCountPlacement
      const storePlacements = mapOptimalOptionCountPlacement(currentStoreData, [store], storeId, storeName);
      acc.push(...storePlacements);
    }

    return acc;
  }, []);
  return sortBy(storeRows, 'storeName') ?? [];
};
