import { useMemo } from 'react';
import { chainIdToNameListVar } from 'src/infrastructure/local_state';
import { useGlobalVar } from '../useGlobalVar';
import { useQueryParams } from '../useQueryParams';

export const useChain = () => {
  const [chainIdToNameList] = useGlobalVar(chainIdToNameListVar);
  const { chainId } = useQueryParams();

  const chain = useMemo(() => {
    return chainIdToNameList.get(Number(chainId));
  }, [chainId, chainIdToNameList]);

  return chain;
};
