export function formatNumber(value: number | string | undefined, decimals = 2): string {
  if (value === undefined || value === null) {
    return '';
  }

  if (typeof value === 'string') {
    value = Number(value);
  }

  return value?.toFixed(decimals).replace('.', ',');
}

export function parseNumber(value: string | number | undefined | null): number {
  if (typeof value === 'number') {
    return value;
  }

  if (value === undefined || value === null || typeof value !== 'string') {
    return NaN;
  }

  return Number(value.replace(/\s*/, '').replace(',', '.'));
}
