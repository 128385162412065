export enum Color {
  white = '#FFFFFF',
  midnightGrey = '#333333',
  steelGrey = '#939393',
  lightGrey = '#F3F3F3',
  antracit = '#777777',
  editBlue = '#4472C4',
  errorRed = '#d9512c',
  option1 = '#EF972F',
  option2 = '#84B96B',
  option3 = '#F1D055',
  secondary = '#506385',
}
