import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { ComparableCell } from 'src/components/molecules/ComparableCell/ComparableCell';
import { EditablePercentageCell } from 'src/components/molecules/EditableCell/EditableCell';
import { DefaultColumn, DefaultColumnNumeric } from './default-columns-details';
import { isEditableWithFooter } from './is-editable';
import { parsePercentageValueToNumber } from './utils';

export const ColumnsDetailsSum: ColDef[] = [
  {
    ...DefaultColumn,
    field: 'category',
    width: 150,
    headerName: 'Category',
    suppressSizeToFit: true,
    cellRenderer: (params: ICellRendererParams) => {
      return params.value ?? 'Sum';
    },
  },
  {
    ...DefaultColumnNumeric,
    field: 'splitLly',
    headerName: 'Split LLY',
  },
  {
    ...DefaultColumnNumeric,
    field: 'splitLy',
    headerName: 'Split LY',
  },
  {
    ...DefaultColumnNumeric,
    field: 'plannedSplit',
    headerName: 'Planned Split',
    cellRenderer: EditablePercentageCell,
    editable: isEditableWithFooter,
    valueParser: parsePercentageValueToNumber,
  },
  {
    ...DefaultColumnNumeric,
    field: 'plannedIndex',
    headerName: 'Planned Index',
    cellRenderer: ComparableCell,
  },
  {
    ...DefaultColumnNumeric,
    field: 'plannedSalesIv',
    headerName: 'Planned Sales iV',
    cellRenderer: ComparableCell,
  },
  {
    ...DefaultColumnNumeric,
    field: 'salesIvLy',
    headerName: 'Sales iV LY',
  },
  {
    ...DefaultColumnNumeric,
    field: 'indexLy',
    headerName: 'Index LY',
  },
  {
    ...DefaultColumnNumeric,
    field: 'gmLy',
    headerName: 'GM % LY',
  },
];
