export const buildPathFromContext = (
  chainId: string,
  storeType: string,
  type: string,
  storeId: string,
  groupId: string
): string => {
  let countryId, cluster, partnerId;

  if (type === 'Country') {
    countryId = groupId;
  } else if (type === 'Cluster') {
    cluster = groupId;
  } else {
    partnerId = groupId;
  }

  return buildSearchParams(chainId, storeType, type, storeId, countryId, cluster, partnerId);
};

export const buildPathFromUrl = (
  chainId: string,
  storeType: string,
  type: string,
  storeId: string,
  countryId?: string,
  cluster?: string,
  partnerId?: string
): string => {
  return buildSearchParams(chainId, storeType, type, storeId, countryId, cluster, partnerId);
};

const buildSearchParams = (
  chainId: string,
  storeType: string,
  type: string,
  storeId: string,
  countryId: string | undefined,
  cluster: string | undefined,
  partnerId: string | undefined
) => {
  const searchParams = new URLSearchParams({
    chainId,
    storeType,
    type,
    storeId,
  });

  if (type === 'store') {
    return searchParams.toString();
  }

  if (countryId) {
    searchParams.set('countryId', countryId);
  }

  if (cluster) {
    searchParams.set('cluster', cluster);
  }

  if (partnerId) {
    searchParams.set('partnerId', partnerId);
  }

  return searchParams.toString();
};
