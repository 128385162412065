import styled from '@emotion/styled';
import { Add } from '@mui/icons-material';
import { Backdrop, CircularProgress, Skeleton, Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import Container from 'src/components/atoms/Container';
import { SmartModuleTitleCard } from 'src/components/organisms/SmartModuleTitleCard/SmartModuleTitleCard';

import { LoadingButton } from '@mui/lab';
import { sortBy } from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import { BuyingEventListHeader } from 'src/components/molecules/BuyingEventListHeader/BuyingEventListHeader';
import { BuyingEventListItem } from 'src/components/molecules/BuyingEventListItem/BuyingEventListItem';
import { DeleteDialog } from 'src/components/organisms/DeleteDialog/DeleteDialog';
import { MaybeBoolean, MaybeNumber, MaybeNumberArray, MaybeString } from 'src/domain/types/maybe-types';
import { useChainId } from 'src/hooks';
import { useBuyingEventQuery } from 'src/hooks/buying-event-managment/queries/useBuyingEventQuery';
import { useSnackbar } from 'src/hooks/snackbar/useSnackbar';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { formatMonthKeyToDate } from 'src/utils/monthKeys';
import { BuyingEventDialog } from '../../organisms/BuyingEventDialog/BuyingEventDialog';

type BuyingEvent = {
  id?: MaybeNumber;
  name?: MaybeString;
  orderDate?: MaybeNumber;
  deliveryRangeStart?: MaybeNumber;
  deliveryRangeEnd?: MaybeNumber;
  items?: MaybeNumberArray;
  isDefault?: MaybeBoolean;
};

export const BuyingEventManagement: FC = () => {
  const chainId = useChainId();
  const { loading: dataLoading, events, planningLevelList, refetch } = useBuyingEventQuery();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [event, setEvent] = useState<BuyingEvent | undefined>();
  const [itemPendingDeletion, setItemPendingDeletion] = useState<BuyingEvent | null>(null);
  const showSnackbar = useSnackbar();

  const [deleteEvent, { loading: deletingEvent }] = useApiMutation('/api/buyingevents/{id}', 'delete', {
    update(data, _variables, queryClient) {
      const path = { chaidId: chainId };
      queryClient.setQueryData(['/api/buyingevents/chains/{chainId}', path], data);
    },
  });

  const onAddEvent = () => {
    setEvent(undefined);
    setDialogOpen(true);
  };

  const showDeleteDialog = useCallback((event: BuyingEvent | null) => {
    setItemPendingDeletion(event);
  }, []);

  const handleCancelDeletion = useCallback(() => {
    setItemPendingDeletion(null);
  }, []);

  const handleDeleteEvent = useCallback(async () => {
    if (itemPendingDeletion?.id === undefined || itemPendingDeletion?.id === null) return;
    deleteEvent({ path: { id: itemPendingDeletion.id } })
      .then(() => {
        refetch();
        showSnackbar(`Buying event '${itemPendingDeletion.name}' has been deleted!`, 'success');
      })
      .catch(() => {
        showSnackbar('Failed to delete buying event. Please try again later.', 'error');
      })
      .finally(() => {
        setEvent(undefined);
        setItemPendingDeletion(null);
      });
  }, [deleteEvent, itemPendingDeletion, refetch, showSnackbar]);

  const handleEditEvent = (
    id: MaybeNumber,
    name: MaybeString,
    orderDate: MaybeNumber,
    deliveryRangeStart: MaybeNumber,
    deliveryRangeEnd: MaybeNumber,
    items: MaybeNumberArray,
    isDefault: MaybeBoolean
  ) => {
    if (!id || !name || !orderDate || !deliveryRangeStart || !deliveryRangeEnd || !items) return;
    setEvent({
      id,
      name,
      orderDate,
      deliveryRangeStart: deliveryRangeStart,
      deliveryRangeEnd: deliveryRangeEnd,
      items,
      isDefault: isDefault ?? false,
    });
    setDialogOpen(true);
  };

  const onCloseDialog = () => {
    setDialogOpen(false);
    setEvent(undefined);
  };

  return (
    <Stack spacing={2}>
      <SmartModuleTitleCard />
      <Container>
        <StyledContainer>
          <Typography variant="h5" component="h2">
            Buying Events
          </Typography>
          <LoadingButton
            loadingPosition="start"
            size={'small'}
            color="secondary"
            variant={'contained'}
            startIcon={<Add />}
            onClick={onAddEvent}
          >
            Add event
          </LoadingButton>
        </StyledContainer>
        <BuyingEventDialog
          id={event?.id}
          eventName={event?.name}
          eventOrderMonth={formatMonthKeyToDate(event?.orderDate)}
          initialDeliveryRangeStart={formatMonthKeyToDate(event?.deliveryRangeStart)}
          initialDeliveryRangeEnd={formatMonthKeyToDate(event?.deliveryRangeEnd)}
          plannedOnIds={event?.items}
          planningLevelList={planningLevelList}
          open={dialogOpen}
          handleCloseDialog={onCloseDialog}
          handleRefetch={refetch}
        />
        <BuyingEventListHeader />
        {!dataLoading && !!events?.length && (
          <>
            {sortBy(events, ['orderMonth'])?.map((event) => (
              <BuyingEventListItem
                key={event?.id}
                id={event?.id}
                name={event?.name}
                orderDate={event?.orderMonth}
                deliveryRangeStart={event?.buyingStart}
                deliveryRangeEnd={event?.buyingEnd}
                items={event?.plannedOnIds}
                isDefault={event?.isDefault}
                planningLevelList={planningLevelList}
                onEditEvent={handleEditEvent}
                onDeleteEvent={() => showDeleteDialog(event)}
              />
            ))}
          </>
        )}
        {itemPendingDeletion && (
          <DeleteDialog
            open={!!itemPendingDeletion}
            onCancel={handleCancelDeletion}
            onDelete={handleDeleteEvent}
            title="Delete buying event?"
            content="Deleting this buying event is permanent and cannot be undone. Are you sure you want to delete the buying event?"
          ></DeleteDialog>
        )}
        {dataLoading && (
          <>
            <StyledSkeleton />
            <StyledSkeleton />
            <StyledSkeleton />
          </>
        )}
        {!events?.length && !dataLoading && <NoEventsCreated>No events created</NoEventsCreated>}
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.modal + 1,
            position: 'absolute',
          }}
          open={deletingEvent}
        >
          {deletingEvent && <CircularProgress color="inherit" />}
        </Backdrop>
      </Container>
    </Stack>
  );
};

const StyledContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const StyledSkeleton = styled(Skeleton)`
  height: 80px;
  width: 100%;
  margin-top: 6px;
  transform: none;
`;

const NoEventsCreated = styled(Typography)`
  margin: 20px;
  color: #787c8d;
  text-align: center;
`;
