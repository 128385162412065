import { FC, PropsWithChildren, useMemo } from 'react';
import { Route as ReactRoute, Routes } from 'react-router-dom';
import { AnnouncementSystem } from './components/views/AnnouncementSystem/AnnouncementSystem';
import { Frontpage } from './components/views/Frontpage/Frontpage';
import { NoAccess } from './components/views/NoAccess/NoAccess';
import { useDynamicTitle } from './hooks/useDynamicTitle';
import { useRoutes } from './useRoutes';

export const Router = () => {
  const routes = useRoutes();

  // update title when a new module is visited
  useDynamicTitle();

  return (
    <Routes>
      <ReactRoute path={`/`} element={<Frontpage />} />
      <ReactRoute path={`/status`} element={<AnnouncementSystem />} />
      <ReactRoute path={`/no-access`} element={<NoAccess />} />

      {routes.map((route) => (
        <ReactRoute
          key={route.path}
          path={route.path}
          // Previous solution was reported as a codesmell in sonar cloud, this solution is not and is inspired from here: https://stackoverflow.com/questions/50888615/dynamically-nested-react-components#answer-50889391
          element={<RouteWithGuards guards={[...(route.guards ?? [])]} component={route.component} />}
        />
      ))}
    </Routes>
  );
};

interface RouteWithGuardsProps {
  guards: Array<FC<PropsWithChildren<unknown>>>;
  component: JSX.Element;
}
const RouteWithGuards: FC<RouteWithGuardsProps> = ({ guards, component }) => {
  const Guard = useMemo(() => guards[0], [guards]);

  if (guards.length > 0) {
    return (
      <Guard>
        <RouteWithGuards guards={guards.slice(1)} component={component}></RouteWithGuards>
      </Guard>
    );
  }
  return component;
};
