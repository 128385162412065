import { addMonths, isAfter, isEqual, parse } from 'date-fns';
import { isNumber } from 'lodash';
import { TurnoverRow, TurnoverRowTuple } from 'src/domain';

export const calculateExpectedTurnoverNext12Months = (rows: number[]): number => {
  let sum = 0;
  rows.forEach((row, index) => {
    if (index < 12) {
      sum += row;
    }
  });
  return sum;
};

export const updateRow = (
  field: string | undefined,
  row: TurnoverRow,
  value: number,
  previousValue?: number
): TurnoverRow => {
  if (field === 'plannedSalesIv') {
    row.previousPlannedSalesIv = previousValue;

    row.plannedSalesIv = value;
    row.plannedIndex = updateIndex(value, row.salesIvLy, row.salesIvLly);
    row.hasBeenChanged = true;
    return row;
  } else {
    row.previousPlannedSalesIv = previousValue;
    row.plannedSalesIv = updateSales(value, row.salesIvLy, row.salesIvLly);
    row.hasBeenChanged = true;
    row.plannedIndex = value;
    return row;
  }
};

const updateSales = (value: number, salesIvLy?: number, salesIvLly?: number) => {
  if (salesIvLy && salesIvLy > 0) {
    return Math.round((value / 100) * salesIvLy);
  } else if (salesIvLly && salesIvLly > 0) {
    return Math.round((value / 100) * salesIvLly);
  } else {
    return 100 / value;
  }
};

const updateIndex = (value: number, salesIvLy?: number, salesIvLly?: number) => {
  if (salesIvLy && salesIvLy > 0) {
    return Math.round((value * 100) / salesIvLy);
  } else if (salesIvLly && salesIvLly > 0) {
    return Math.round((value * 100) / salesIvLly);
  } else {
    return 100 / value;
  }
};

export const calculateAverageIndex = (data: number[]) => {
  let sum = 0;
  let length = 0;
  data.forEach((item, index) => {
    if (item !== undefined && item !== null && item !== 0 && index < 12) {
      length += 1;
      sum += item;
    }
  });

  if (length === 0) {
    return 0;
  }

  return Math.floor(sum / length);
};

export const calculateAverageSalesiV = (data: number[]) => {
  let sum = 0;
  let length = 0;
  data.forEach((item) => {
    if (item !== undefined && item !== null && item !== 0) {
      length += 1;
      sum += item;
    }
  });
  return Math.round(sum / length);
};

export function extractPlannedSales(overviewRows: TurnoverRow[] | TurnoverRowTuple[] | undefined) {
  return overviewRows?.map((row) => {
    if (Array.isArray(row.plannedSalesIv)) {
      if (row.hasBeenChanged) {
        return row.plannedSalesIv[1] ?? 0;
      }
      return row.plannedSalesIv[0];
    }
    return row.plannedSalesIv;
  });
}

export function extractPlannedIndex(overviewRows: TurnoverRow[] | TurnoverRowTuple[] | undefined) {
  const plannedIndexes = overviewRows?.map((row) => {
    if (Array.isArray(row.plannedIndex)) {
      if (row.hasBeenChanged) {
        return row.plannedIndex[1] ?? 0;
      }
      return row.plannedIndex[0];
    }
    return row.plannedIndex;
  });

  if (!plannedIndexes) {
    return [];
  }
  return plannedIndexes?.filter(isNumber);
}

export const isTwelveMonthsAheadOrMore = (currentMonthKey: string, monthKeyToCheck: string): boolean => {
  const currentMonthDate = parse(currentMonthKey, 'yyyyMM', new Date());
  const futureMonthDate = addMonths(currentMonthDate, 12);
  const checkDate = parse(monthKeyToCheck, 'yyyyMM', new Date());

  return isAfter(checkDate, futureMonthDate) || isEqual(checkDate, futureMonthDate);
};
