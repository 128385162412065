import { ColDef, EditableCallbackParams, IAggFuncParams } from 'ag-grid-community';
import { EditableValueCell } from 'src/components/molecules/EditableCell/EditableCell';
import { defaultColDefNumberWithSpaces } from 'src/components/organisms/DataTable/columns/default-options';
import { months } from 'src/domain';
import { sumAggFunc } from 'src/utils/ag-grid/agg-functions';

export const isOptimalOptionCountCellEditable = (params: EditableCallbackParams) => {
  const isFooter = params?.data?.isFooter;
  const isStoreOverview = params?.data?.storeId;
  if (isFooter || isStoreOverview) return false;
  return true;
};

export const defaultColDef: ColDef = {
  ...defaultColDefNumberWithSpaces,
  initialWidth: 100,
  suppressAutoSize: true,
  suppressHeaderMenuButton: true,
};

export const defaultColumns: ColDef[] = [
  ...months.map<ColDef>((month) => ({
    field: `columns.${month.toLowerCase()}`,
    headerName: month,
    initialWidth: 100,
    type: 'numericColumn',
    cellStyle({ data }) {
      if (!data) return;
      if (data.isFooter) return { fontWeight: 'bold' };
    },
    cellRenderer: EditableValueCell,
    cellRendererParams: {
      spacing: 5,
      isOptimalOptionCount: true,
      editable: true,
    },
    editable: isOptimalOptionCountCellEditable,
    aggFunc: sumAggFunc,
  })),
];

export const totalsColumns: ColDef[] = [
  {
    field: 'category',
    headerName: '',
    initialWidth: 160,
    suppressSizeToFit: true,
    pinned: 'left',
    lockPinned: true,
  },
  ...defaultColumns,
];

export const placementsColumns: ColDef[] = [
  {
    field: 'category',
    hide: true,
    rowGroup: true,
  },
  {
    field: 'subCategory',
    hide: true,
  },
  ...defaultColumns,
];

export const storesColumns: ColDef[] = [
  {
    field: 'storeName',
    hide: true,
    rowGroup: true,
  },
  {
    field: 'category',
    hide: true,
    rowGroup: true,
    suppressSizeToFit: true,
    initialWidth: 160,
  },
  ...defaultColumns.map<ColDef>((column) => ({
    ...column,
    cellRendererParams: {
      ...column.cellRendererParams,
      editable: false,
    },
    aggFunc: (params: IAggFuncParams) => {
      let sum = 0;
      // the length is 7 if its a storeName row. Then I want to use only the value of optimalOptionCount to show in the cell.
      const placementValues =
        params.values.length === 7
          ? [params.values[params.values.length - 2]]
          : params.values.filter((value) => value !== null);
      placementValues.forEach((value) => {
        let parsedValue = value;
        if (typeof value === 'string') {
          parsedValue = parseFloat(value.replace(',', '.'));
        }
        sum += parsedValue;
      });
      return sum;
    },
  })),
];
