import { useMemo } from 'react';
import { useInitializeIsCategorySplitHistorical } from 'src/hooks/historical';
import { components } from 'src/infrastructure/rest-api/generated';
import { useClusterCategorySplitQuery } from './useClusterCategorySplitQuery';
import { usePartnerCategorySplitQuery } from './usePartnerCategorySplitQuery';
import { useStoreCategorySplitQuery } from './useStoreCategorySplitQuery';

export type CategorySplit = components['schemas']['CategorySplitModel'][] | undefined;

export type CategorySplitLevelQuery = {
  data: {
    storeCategorySplit?: CategorySplit;
    clusterCategorySplit?: CategorySplit;
    partnerCategorySplit?: CategorySplit;
  };
  error: Error | null;
  loading: boolean;
};

export const useCategorySplitLevelQuery = (): CategorySplitLevelQuery | undefined => {
  const { data: storeCategorySplit, error: storeError, loading: storeLoading } = useStoreCategorySplitQuery();
  const { data: clusterCategorySplit, error: clusterError, loading: clusterLoading } = useClusterCategorySplitQuery();
  const { data: partnerCategorySplit, error: partnerError, loading: partnerLoading } = usePartnerCategorySplitQuery();

  const data = useMemo(() => {
    return {
      storeCategorySplit,
      clusterCategorySplit,
      partnerCategorySplit,
    };
  }, [storeCategorySplit, clusterCategorySplit, partnerCategorySplit]);

  useInitializeIsCategorySplitHistorical(data);

  return {
    data,
    error: storeError ?? clusterError ?? partnerError,
    loading: storeLoading ?? clusterLoading ?? partnerLoading,
  };
};
