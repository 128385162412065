import { useCallback } from 'react';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { UpdatePartnerStyleTimelineSettingInput } from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';

export const useStyleTimelineSettingsUpdate = () => {
  const partnerComposite = useCompositePartner();

  const [updateSettings] = useApiMutation('/api/styletimelinesettings', 'put', {
    update(data, _variables, queryClient) {
      const path = { partnerId: partnerComposite?.id };
      queryClient.setQueryData(['/api/styletimelinesettings/partners/{partnerId}', path], data);
    },
  });

  const update = useCallback(
    (input: UpdatePartnerStyleTimelineSettingInput[]) => {
      return updateSettings({
        body: input,
      });
    },
    [updateSettings]
  );

  return update;
};
