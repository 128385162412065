import { useMemo } from 'react';
import { Inheritance, Module } from 'src/domain';
import { useDefaultInheritance } from 'src/hooks/inheritance';
import { useProductLineGroupsApiQuery } from 'src/hooks/useProductLineGroupsApiQuery';
import { useProductLineSplitLevelQuery } from './useProductLineSplitLevelQuery';

export const useProductLineSplitInheritanceQuery = (inheritance?: Inheritance) => {
  const { data, error, loading } = useProductLineSplitLevelQuery() ?? { loading: false };
  const currentInheritance = useDefaultInheritance(Module.ProductLineSplit);
  const actualInheritance = inheritance ?? currentInheritance;
  const {
    data: productLineGroups,
    error: productLineGroupsError,
    loading: productLineGroupsLoading,
  } = useProductLineGroupsApiQuery();

  const sanitizedData = useMemo(() => {
    if (!data) return;

    switch (actualInheritance) {
      case Inheritance.Typed:
      case Inheritance.Historical:
        return data.storeProductLineSplit ?? data.clusterProductLineSplit ?? data.partnerProductLineSplit;

      case Inheritance.Cluster:
        return data.clusterProductLineSplit;

      case Inheritance.Partner:
        return data.partnerProductLineSplit;
    }
  }, [data, actualInheritance]);

  const productLineData = productLineGroups?.map((productLineGroup) => ({
    productLineGroupId: productLineGroup.id,
    productLineGroupName: productLineGroup.name,
  }));

  return {
    expected: sanitizedData,
    productLineGroups: productLineData,
    data,
    error: error ?? productLineGroupsError,
    loading: loading ?? productLineGroupsLoading,
  };
};
