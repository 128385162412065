import { Inheritance } from 'src/domain';
import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useMarkdownInheritanceQuery } from './useMarkdownInheritanceQuery';

export const useMarkdownQuery = (inheritance?: Inheritance) => {
  const { data, expected, error, loading } = useMarkdownInheritanceQuery(inheritance);
  const {
    data: styleCategories,
    error: styleCategoriesError,
    loading: styleCategoriesLoading,
  } = useStyleCategoriesQuery();

  return {
    expected,
    data,
    styleCategories: styleCategories,
    error: error ?? styleCategoriesError,
    loading: loading || styleCategoriesLoading,
  };
};
