import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import { isNumber } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

export interface GroupedOption {
  isHeader: boolean;
  display: string;
  value: string;
}

interface Props extends Omit<SelectProps<string>, 'renderValue' | 'onSelect' | 'variant' | 'onClose'> {
  variant?: SelectProps<string>['variant'];
  options: (string | GroupedOption)[];
  onSelect?: (item: string) => unknown;
  activeItem?: string | null;
  showLabel?: boolean;
  label?: string | null;
  size?: 'small' | 'medium';
  width?: 'auto' | 'fixed' | 'widest' | number;
  minWidth?: string;
  open?: boolean;
  useMargin?: boolean;
  isHeaderSelectable?: boolean;
  renderValue?: (value: string) => string;
  onClose?: () => void;
}

const Select: FC<Props> = ({
  variant = 'outlined',
  options,
  onSelect,
  activeItem,
  showLabel = true,
  label = 'Select',
  size = 'small',
  width = 'fixed',
  minWidth,
  open = false,
  useMargin = true,
  isHeaderSelectable = false,
  onClose,
  renderValue,
  ...selectProps
}) => {
  const [selection, setSelection] = useState('');
  const [isOpen, setIsOpen] = useState(open);

  // all viable values
  const optionValues = options.filter((x) => typeof x !== 'string').map((x) => (typeof x === 'string' ? x : x.display));

  // Avoid MUI warning when option changes, set value to empty string if current selection is not found in options
  const selectionValue = useMemo(() => {
    const result = options
      .filter((x) => (typeof x !== 'string' && (!x.isHeader || isHeaderSelectable)) || typeof x === 'string')
      .find((x) => (typeof x === 'string' ? x === selection : x.value === selection));

    if (result) return isGroupedOption(result) ? result.value : result;
    return '';
  }, [isHeaderSelectable, options, selection]);

  const defaultRenderValue = useCallback(
    (value: string) => {
      const option = options.find((x) => (typeof x !== 'string' ? x.value === value : x === value));

      if (typeof option === 'string') return option;
      return option?.display ?? '';
    },
    [options]
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const selectedItem = event.target.value;

      setSelection(selectedItem);
      onSelect?.(selectedItem);
    },
    [onSelect]
  );

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    const newSelection = activeItem ?? '';
    if (newSelection !== selection) {
      setSelection(newSelection);
    }
  }, [activeItem, selection]);

  // Sort works in place
  const maxLen = [...optionValues].sort((a, b) => b.length - a.length).first()?.length ?? 1;
  const approxWidthPerChar = 9;
  const guesstimatedWidth = maxLen * approxWidthPerChar;
  const selectWidth = isNumber(width) ? `${width}px` : width === 'fixed' ? '100%' : guesstimatedWidth;

  return (
    <FormControl sx={{ m: useMargin ? 1 : 0 }} size={size}>
      {showLabel && <InputLabel>{label}</InputLabel>}
      <MuiSelect
        {...selectProps}
        variant={variant}
        sx={{ width: selectWidth, minWidth }}
        autoWidth={width === 'auto'}
        value={selectionValue}
        label={showLabel ? label : undefined}
        onChange={handleChange}
        renderValue={renderValue ?? defaultRenderValue}
        onOpen={handleOpen}
        onClose={handleClose}
        open={isOpen}
      >
        {options.map((option, i) => {
          if (typeof option === 'string')
            return (
              <MenuItem key={option + i} value={option} selected={option === selectionValue}>
                {option}
              </MenuItem>
            );

          if (isGroupedOption(option) && !option.isHeader)
            return (
              <MenuItem
                key={option.display + i}
                value={option.value}
                selected={option.value === selectionValue}
                sx={{ paddingLeft: '32px', paddingRight: '32px' }}
              >
                {option.display}
              </MenuItem>
            );

          if (isGroupedOption(option) && option.isHeader && isHeaderSelectable)
            return (
              <MenuItem key={option.display + i} value={option.value} selected={option.value === selectionValue}>
                {option.display}
              </MenuItem>
            );

          return (
            <ListSubheader key={option.display + i} sx={{ fontStyle: 'italic' }}>
              {option.display}
            </ListSubheader>
          );
        })}
      </MuiSelect>
    </FormControl>
  );
};

function isGroupedOption(value: unknown): value is GroupedOption {
  return (
    !!value &&
    Object.prototype.hasOwnProperty.call(value, 'isHeader') &&
    Object.prototype.hasOwnProperty.call(value, 'display') &&
    Object.prototype.hasOwnProperty.call(value, 'value')
  );
}

export default Select;
