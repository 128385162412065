import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollRestoration = (scrollableRef: React.RefObject<HTMLElement>) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (scrollableRef.current && typeof scrollableRef.current.scrollTo === 'function') {
      scrollableRef.current.scrollTo(0, 0);
    }
  }, [pathname, scrollableRef]);
};

export default useScrollRestoration;
