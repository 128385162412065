import { DefaultError, QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { DeepNullable } from 'src/domain';
import { HttpStatusError } from 'src/domain/errors/HttpStatusError';
import { useAcquireToken } from 'src/hooks/auth/useAcquireToken';
import { useDevTools } from 'src/hooks/devtools/useDevTools';
import { removeMsalToken, removeToken } from 'src/utils/token/tokenUtils';
import { paths } from './generated';

export type Path = keyof paths;

type PathMethod<T extends Path> = keyof paths[T];

type RequestParams<P extends Path, M extends PathMethod<P>> = paths[P][M] extends {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parameters: any;
}
  ? paths[P][M]['parameters']
  : undefined;

type RequestBody<P extends Path, M extends PathMethod<P>> = paths[P][M] extends {
  requestBody: {
    content: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'application/json': infer R;
    };
  };
}
  ? R
  : undefined;

type OptionalRequestParams<P extends Path, M extends PathMethod<P>> =
  RequestParams<P, M> extends {
    query?: { [x: string]: unknown };
    path?: { [x: string]: unknown };
  }
    ? {
        query?: DeepNullable<RequestParams<P, M>['query']>;
        path?: DeepNullable<RequestParams<P, M>['path']>;
      }
    : RequestParams<P, M>;

export type ResponseType<P extends Path, M extends PathMethod<P>> = paths[P][M] extends {
  responses: {
    200: {
      content: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        'application/json': any;
      };
    };
  };
}
  ? paths[P][M]['responses'][200]['content']['application/json']
  : undefined;

interface ApiImpersonateProps {
  email: string;
  roles: string[];
}

type ApiCallProps = {
  url: string;
  method: 'get' | 'post' | 'put' | 'delete';
  query?: Record<string, string>;
  body?: unknown;
  acquireToken: () => Promise<unknown>;
  impersonate?: ApiImpersonateProps;
};

function apiCall<Response>({ url, method, query, body, acquireToken, impersonate }: ApiCallProps): Promise<Response> {
  const token = localStorage.getItem('token');
  return fetch(`${url}?${query ? new URLSearchParams(query) : ''}`, {
    method: method.toString(),
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...(impersonate
        ? {
            'X-Impersonate': impersonate.email,
            'X-Impersonate-Roles': impersonate.roles.join(','),
          }
        : {}),
    },
  }).then((res) => {
    if (res.status === 401) {
      // handle unauthorized

      // remove tokens
      removeToken();
      removeMsalToken();

      // acquire new token and retry
      return acquireToken().then((token) => {
        if (token) {
          return apiCall({ url, method, query, body, acquireToken });
        }
        throw new HttpStatusError(res.status, res.statusText, { error: 'Unauthorized' });
      });
    }

    if (!res.ok) {
      // handle http errors
      return res.json().then((errorData) => {
        throw new HttpStatusError(res.status, res.statusText, errorData);
      });
    }

    return res.json().catch(() => {
      return {} as Response;
    });
  });
}

export function buildApiUrl(baseUrl: string, path: Record<string, string> = {}): string {
  return Object.keys(path).reduce((acc, key) => {
    return acc.replace(`{${key}}`, path[key]);
  }, baseUrl);
}

interface UseApiQueryExtraParams {
  enabled?: boolean;
  gcTime?: number;
}

export const useApiQuery = <P extends Path, M extends keyof paths[P]>(
  baseUrl: P,
  method: M,
  params?: OptionalRequestParams<P, M> extends undefined
    ? UseApiQueryExtraParams
    : OptionalRequestParams<P, M> & UseApiQueryExtraParams
) => {
  const acquireToken = useAcquireToken();
  const url = buildApiUrl(baseUrl, params?.path);

  const { isImpersonating, impersonateEmail, impersonateRoles } = useDevTools();

  const { data, isLoading, error, dataUpdatedAt, refetch } = useQuery<ResponseType<P, M>>({
    queryKey: [url, params?.query],
    enabled: params?.enabled ?? true,
    gcTime: params?.gcTime ?? undefined,
    queryFn: () =>
      apiCall<ResponseType<P, M>>({
        url,
        method: method.toString() as ApiCallProps['method'],
        query: params?.query,
        acquireToken,
        impersonate: isImpersonating ? { email: impersonateEmail, roles: impersonateRoles } : undefined,
      }),
  });

  return { data, loading: isLoading, error, dataUpdatedAt, refetch } as const;
};

export type MutationRequestParams<
  P extends Path,
  M extends PathMethod<P>,
  R extends RequestBody<P, M> = RequestBody<P, M>,
  // eslint-disable-next-line @typescript-eslint/ban-types
> = (OptionalRequestParams<P, M> extends undefined ? {} : OptionalRequestParams<P, M>) &
  // eslint-disable-next-line @typescript-eslint/ban-types
  (R extends undefined ? {} : { body: R });

export type UpdateCacheFn<P extends Path, M extends PathMethod<P>> = (
  data: ResponseType<P, M>,
  variables: MutationRequestParams<P, M>,
  queryClient: QueryClient
) => void;

interface ApiMutationOptions<P extends Path, M extends PathMethod<P>> {
  update?: UpdateCacheFn<P, M>;
}

export const useApiMutation = <P extends Path, M extends keyof paths[P]>(
  baseUrl: P,
  method: M,
  options?: ApiMutationOptions<P, M>
) => {
  const { isImpersonating, impersonateEmail, impersonateRoles } = useDevTools();
  const queryClient = useQueryClient();
  const acquireToken = useAcquireToken();
  const { mutate, isPending, error } = useMutation<ResponseType<P, M>, DefaultError, MutationRequestParams<P, M>>({
    mutationKey: [baseUrl],
    mutationFn: (variables) => {
      const url = buildApiUrl(baseUrl, variables.path);
      return apiCall<ResponseType<P, M>>({
        url,
        method: method.toString() as ApiCallProps['method'],
        query: variables.query,
        body: variables.body,
        acquireToken,
        impersonate: isImpersonating ? { email: impersonateEmail, roles: impersonateRoles } : undefined,
      });
    },
  });

  const update = useCallback(
    (params: MutationRequestParams<P, M>): Promise<ResponseType<P, M>> => {
      return new Promise((resolve, reject) => {
        mutate(params, {
          onSuccess(data, variables) {
            const url = buildApiUrl(baseUrl, variables.path);

            if (typeof options?.update === 'function') {
              options.update(data, variables, queryClient);
            } else {
              queryClient.setQueryData([url, params.query], data);
            }

            resolve(data);
          },
          onError(error) {
            reject(error);
          },
        });
      });
    },
    [baseUrl, mutate, options, queryClient]
  );

  return [update, { loading: isPending, error }] as const;
};
