import { isNumber, sortBy } from 'lodash';
import { StyleWarningsRow } from 'src/domain/table/style-warnings-row';
import { IntersectedStyleTimelineData } from 'src/hooks/style-timeline/queries/useStyleTimelineLevelQuery';
import { StyleCategoryModel } from 'src/infrastructure/rest-api/api-types';
import { formatDateAsWeekYear } from 'src/utils/formatAsWeekYear';

type StyleWarning = {
  predictedUnsuccessfulPhaseOut: StyleWarningsRow[];
  unsuccessfulPhaseOut: StyleWarningsRow[];
};

export const mapStyleWarnings = (
  data: IntersectedStyleTimelineData[] | undefined,
  styleCategories: StyleCategoryModel[] | undefined
): StyleWarning => {
  const rows = data?.reduce<StyleWarning>(
    (acc, model) => {
      const currentDate = new Date();
      const currentWeek = formatDateAsWeekYear(currentDate);
      const exitWeek = model.exitWeek;
      const weeksUntilExitWeek = isNumber(exitWeek) ? exitWeek - currentWeek : undefined;
      const normQtyWeekCover = model.normQtyWeekCover ?? 0;
      const projectedWeeksBeforeSellout = model.projectedWeeksBeforeSellout ?? 0;

      const stockQuantity = model.stockQuantity ?? 0;
      const openOrders = model.openOrders ?? 0;
      const totalStockQuantity = stockQuantity + openOrders;

      const salesQtyPerWeek = isNumber(model.salesQuantityPast8Weeks) ? model.salesQuantityPast8Weeks / 8 : 0;
      const predictedStockQuantity = () => {
        if (isNumber(weeksUntilExitWeek) && isNumber(salesQtyPerWeek)) {
          const prediction = Math.round(totalStockQuantity - weeksUntilExitWeek * salesQtyPerWeek);
          if (prediction < 0) return 0;

          return prediction;
        }
      };

      const stockValueLcy = model.stockValueLcy;

      const predictedStockValue =
        isNumber(stockValueLcy) && isNumber(predictedStockQuantity())
          ? (stockValueLcy / stockQuantity) * (predictedStockQuantity() ?? 1)
          : undefined;

      const isInStock = totalStockQuantity > 0;
      const hasReplenishment = isNumber(model?.normQuantity) && model.normQuantity > 0;
      const isCloseToExit = isNumber(weeksUntilExitWeek) && weeksUntilExitWeek >= 0 && weeksUntilExitWeek <= 8;
      const isPastExit = isNumber(weeksUntilExitWeek) && weeksUntilExitWeek < 0;
      const isRelevent = isCloseToExit && (isInStock || hasReplenishment);

      const shouldRaiseFlag =
        (isNumber(weeksUntilExitWeek) && weeksUntilExitWeek < normQtyWeekCover) ||
        (isNumber(exitWeek) && currentWeek + projectedWeeksBeforeSellout > exitWeek);

      if (isRelevent && shouldRaiseFlag) {
        acc.predictedUnsuccessfulPhaseOut.push({
          id: model.id ?? 0, // this ok?
          styleCategory: styleCategories?.find((category) => category?.id === model?.styleCategoryId),
          styleNumber: model.styleNumber ?? 0,
          styleName: model.styleName,
          norm: model.normQuantity,
          openOrders,
          stockValue: stockValueLcy,
          stockQuantity,
          predictedStockValue: isNumber(predictedStockValue) ? Math.round(predictedStockValue) : undefined,
          predictedStockQuantity: predictedStockQuantity(),
          weeksUntilExitWeek,
        });
      }

      if (isPastExit && isInStock) {
        acc.unsuccessfulPhaseOut.push({
          id: model.id ?? 0, // this ok?
          styleCategory: styleCategories?.find((category) => category?.id === model?.styleCategoryId),
          styleNumber: model.styleNumber ?? 0,
          styleName: model.styleName,
          norm: model.normQuantity,
          openOrders,
          stockValue: stockValueLcy,
          stockQuantity,
          predictedStockValue: isNumber(predictedStockValue) ? Math.round(predictedStockValue) : undefined,
          predictedStockQuantity: predictedStockQuantity(),
          weeksUntilExitWeek,
        });
      }

      return acc;
    },
    {
      unsuccessfulPhaseOut: [],
      predictedUnsuccessfulPhaseOut: [],
    }
  );

  const predictedUnsuccessfulPhaseOut = sortBy(rows?.predictedUnsuccessfulPhaseOut, (row) => row.predictedStockValue);
  const unsuccessfulPhaseOut = sortBy(rows?.unsuccessfulPhaseOut, (row) => row.stockValue);

  return {
    predictedUnsuccessfulPhaseOut,
    unsuccessfulPhaseOut,
  };
};
