import styled from '@emotion/styled';
import { FC } from 'react';
import Spacer from 'src/components/atoms/Spacer';
import { Spacings } from 'src/domain';
import { useChain } from 'src/hooks/chain/useChain';
import { StoreModel } from 'src/infrastructure/rest-api/api-types';
import { StoreInfo } from '../StoreInfo/StoreInfo';

interface Props {
  store?: StoreModel;
  partner?: string | null;
}
export const StoreInfoContainer: FC<Props> = ({ store, partner }) => {
  const partnerName = store?.operationalResponsible?.name ?? partner;
  const chain = useChain();
  const cluster = store?.cluster?.name;
  const country = store?.country?.name;

  return (
    <Wrapper>
      <Column>
        {chain && <StoreInfo title="Chain" content={chain} compact={!store}></StoreInfo>}
        {country && <StoreInfo title="Country" content={country}></StoreInfo>}
      </Column>
      <Spacer spacing={Spacings.small} />
      <Column>
        {partnerName && partnerName !== '?' && <StoreInfo title="Partner" content={partnerName}></StoreInfo>}
        {cluster && <StoreInfo title="Cluster" content={cluster}></StoreInfo>}
      </Column>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: fit-content;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
