import { useMemo } from 'react';
import { months } from 'src/domain';
import { OptimalOptionCountTotalLabel } from 'src/mapping/optimal-option-count.mapping';
import { useOptimalOptionCountPlacementSimulationRows } from './simulation/useOptimalOptionCountPlacementSimulationRows';

export const useIsWithinTreshhold = () => {
  const { data: rows } = useOptimalOptionCountPlacementSimulationRows();

  const withinTreshhold = useMemo(() => {
    let result = true;
    const totalRow = rows?.find((row) => row.category === 'Total');
    const optimalOptionCountRow = rows?.find((row) => row.category === OptimalOptionCountTotalLabel);

    months.forEach((month) => {
      if (
        totalRow?.columns[month.toLowerCase()] !== optimalOptionCountRow?.columns[month.toLowerCase()] &&
        totalRow?.columns[month.toLowerCase()] !== undefined &&
        optimalOptionCountRow?.columns[month.toLowerCase()] !== undefined
      ) {
        result = false;
      }
      if (optimalOptionCountRow?.columns[month.toLowerCase()] === undefined) result = false;
    });
    return result;
  }, [rows]);
  return withinTreshhold;
};

export const useMonthsWithinTreshhold = () => {
  const { data: rows } = useOptimalOptionCountPlacementSimulationRows();

  const monthsWithinRange: string[] = [];

  const totalRow = rows?.find((row) => row.category === 'Total');
  const optimalOptionCountRow = rows?.find((row) => row.category === OptimalOptionCountTotalLabel);

  months.forEach((month) => {
    if (
      totalRow?.columns[month.toLowerCase()] === optimalOptionCountRow?.columns[month.toLowerCase()] ||
      (totalRow?.columns[month.toLowerCase()] === undefined &&
        optimalOptionCountRow?.columns[month.toLowerCase()] !== undefined)
    ) {
      monthsWithinRange.push(month.toLowerCase());
    }
  });
  return monthsWithinRange;
};
