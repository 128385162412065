import _, { isNumber, round } from 'lodash';
import { MarkupDetailsRow, Nullable } from 'src/domain';
import { IntersectedSplitExpectedDataNew } from 'src/domain/models/split-modules';
import { VatOverviewRow } from 'src/domain/table/vat-overview-row';
import { StyleCategoryModel } from 'src/infrastructure/rest-api/api-types';
import { calculateGrossMarginWithoutMarkdown } from 'src/utils/gross-margin/calculateGrossMargin';
import { getNext12MonthKeys, monthKeyToField } from 'src/utils/monthKeys';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';
import { getGenderProductLineSplit } from './getGenderProductLineSplit';
import { ModuleExpectedNew } from './inheritance.mapping';
import { createFindSalesFunction, createFindSplitFunction } from './split-modules.mapping';
import { ExpectedSalesData } from './turnover-mapping';

function groupByMonth(data: ModuleExpectedNew[]) {
  return data?.reduce<Map<number, ModuleExpectedNew[]>>((acc, item) => {
    if (!item?.monthKey) {
      return acc;
    }

    const items = acc.get(item.monthKey) ?? [];
    items.push(item);
    acc.set(item.monthKey, items);

    return acc;
  }, new Map<number, ModuleExpectedNew[]>());
}

export function groupByStyleCategories(data: ModuleExpectedNew[], styleCategories: StyleCategoryModel[]) {
  const sortedStyleCategories = _.sortBy(data, (value, _) => {
    const categorySort = styleCategories?.find((x) => x?.id === value?.styleCategoryId)?.order;
    return categorySort;
  });

  return sortedStyleCategories?.reduce((acc, item) => {
    const styleCategoryId = item?.styleCategoryId;

    if (!styleCategoryId) {
      return acc;
    }

    const items = acc.get(styleCategoryId) ?? [];
    items.push(item);

    acc.set(styleCategoryId, items);

    return acc;
  }, new Map<number, ModuleExpectedNew[]>());
}

export function getStyleCategoryName(data: StyleCategoryModel[], id: number) {
  return data?.find((x) => x?.id === id)?.name;
}

export const mapMarkupToDetailsTableData = (details: {
  markupData: ModuleExpectedNew[] | undefined;
  salesData: ExpectedSalesData[] | null | undefined;
  vat: VatOverviewRow;
  genderData?: {
    split: Nullable<IntersectedSplitExpectedDataNew[]>;
    isUsingGender: boolean;
    activeGender: number | undefined;
  };
  productLineData?: {
    split: Nullable<IntersectedSplitExpectedDataNew[]>;
    isUsingProductLine: boolean;
    activeProductLine: number | undefined;
  };
  categorySplit: Nullable<IntersectedSplitExpectedDataNew[]>;
  styleCategories: StyleCategoryModel[] | undefined;
}): Map<string, MarkupDetailsRow[]> | undefined => {
  const markupData = details.markupData;
  const salesData = details.salesData;
  const styleCategories = details.styleCategories;
  const genderData = details.genderData;
  const productLineData = details.productLineData;
  const categorySplit = details.categorySplit;
  const vat = details?.vat;

  if (!markupData || !styleCategories || !genderData || !productLineData) return;

  const dataGroupedByMonth = groupByMonth(markupData);

  const rows = Array.from(dataGroupedByMonth.entries()).reduce<Map<string, MarkupDetailsRow[]>>((acc, monthGroup) => {
    const [monthKey, monthValues] = monthGroup;
    const monthName = monthKeyToField(monthKey);
    const next12MonthKeys = getNext12MonthKeys();

    const dataGroupedByStyleCategories = groupByStyleCategories(monthValues, styleCategories);

    const rows = Array.from(dataGroupedByStyleCategories.entries()).reduce<MarkupDetailsRow[]>(
      (accRow, styleGroup, innerIndex) => {
        const [styleCategoryId, _] = styleGroup;
        const styleCategoryName = getStyleCategoryName(styleCategories, styleCategoryId);

        if (!styleCategoryName) return accRow;

        const findValueFunction = createFindSplitFunction(monthKey, 'styleCategories', { groupId: styleCategoryId });

        const findSaleFunction = createFindSalesFunction(monthKey);
        const findGenderSplitFunction = createFindSplitFunction(monthKey, 'genders', {
          groupId: genderData?.activeGender,
        });
        const findProductLineSplitFunction = createFindSplitFunction(monthKey, 'productLines', {
          groupId: productLineData.activeProductLine,
          genderId: genderData?.activeGender,
        });

        const currentMarkup = markupData.find(findValueFunction);

        const plannedValue = currentMarkup?.splitPct;
        const valueLY = currentMarkup?.splitPctLY;
        const valueLLY = currentMarkup?.splitPctLLY;
        const grossMarginLY = currentMarkup?.grossMarginPctLY;
        const cogsLY = currentMarkup?.cogsLY;
        const cogsLLY = currentMarkup?.cogsLLY;
        const vatValue = vat[monthName];
        const grossSalesILY = currentMarkup?.grossSalesILY;
        const grossSalesILLY = currentMarkup?.grossSalesILLY;

        const plannedSales = salesData?.find(findSaleFunction)?.salesExpectediVlcy;
        const salesLY = salesData?.find(findSaleFunction)?.salesiVlcyLY;
        const plannedGenderSplit = genderData.split?.find(findGenderSplitFunction)?.splitPct;
        const plannedProductLineSplit = productLineData.split?.find(findProductLineSplitFunction)?.splitPct;
        const plannedCategorySplit = categorySplit?.find(findValueFunction)?.splitPct ?? NaN;

        const netSalesLY = (salesLY ?? 0) / vatValue;

        const plannedGenderProductLineSplit = getGenderProductLineSplit({
          isUsingGender: genderData?.isUsingGender,
          isUsingProductLine: productLineData?.isUsingProductLine,
          genderSplit: plannedGenderSplit,
          productLineSplit: plannedProductLineSplit,
        });

        const split =
          typeof plannedCategorySplit === 'number' ? (plannedCategorySplit / 100) * plannedGenderProductLineSplit : NaN;

        const expectedSale = (plannedSales ?? 0) * split;

        if (
          !isNumber(plannedValue) ||
          !isNumber(valueLY) ||
          !isNumber(valueLLY) ||
          !isNumber(cogsLY) ||
          !isNumber(expectedSale)
        ) {
          return accRow;
        }

        const expectedGrossMargin = calculateGrossMarginWithoutMarkdown({
          vat: vatValue,
          turnover: expectedSale,
          markup: plannedValue,
        });

        const expectedCogs = typeof split === 'number' ? ((plannedSales ?? 0) * split) / plannedValue : NaN;

        const grossMarginIsNumber = !isNaN(expectedGrossMargin) && isFinite(expectedGrossMargin);
        const cogsIsNumber = !isNaN(expectedCogs) && isFinite(expectedCogs);
        const LYisNumber = !isNaN(valueLY);
        const LLYisNumber = !isNaN(valueLLY);

        const LyisNA = () => {
          if (dataGroupedByMonth.size === 18 && !next12MonthKeys.includes(monthKey)) return true;
          return false;
        };

        const expectedCogsCalculated = cogsIsNumber ? Math.round(expectedCogs * 100) / 100 : 0;
        const expectedGrossMarginCalculated = grossMarginIsNumber ? Math.round(expectedGrossMargin * 100) / 100 : 0;

        accRow.push({
          id: innerIndex,
          category: styleCategoryName,
          plannedValue: Math.round(plannedValue * 100) / 100,
          valueLY: LyisNA() ? -999 : LYisNumber ? valueLY : 0,
          valueLLY: LLYisNumber ? valueLLY : 0,
          plannedSplit: plannedCategorySplit,
          expectedCogs: expectedCogsCalculated,
          cogsLY,
          cogsLLY,
          expectedGrossMargin: expectedGrossMarginCalculated,
          grossMarginLY,
          grossSalesILY,
          grossSalesILLY,
          netSalesLY,
          vat: vatValue,
          expectedCogsCalculation: [
            `\\({{\\text{Sales iV} \\times Split} \\over Markup} \\rightarrow \\)`,
            `\\({{\\text{${numberWithSpaces(round(plannedSales ?? 0))}} \\times ${round(split, 2)}} \\over ${round(
              plannedValue,
              2
            )}} = \\text{${numberWithSpaces(round(expectedCogsCalculated, 0))}}\\)`,
          ],
          expectedGrossMarginCalculation: !isNaN(vatValue)
            ? [
                `\\({\\text{GrossProfit} \\over NetSales} \\times 100 \\rightarrow \\)`,
                `\\({{{\\text{${numberWithSpaces(
                  round(expectedSale / vatValue - expectedSale / plannedValue)
                )}}} \\over \\text{${numberWithSpaces(
                  round(expectedSale / vatValue)
                )}}} \\times 100 = \\text{${numberWithSpaces(round(expectedGrossMarginCalculated, 1))}}}\\)`,
              ]
            : `Unable to calculate VAT`,
        });
        return accRow;
      },
      []
    );

    acc.set(monthName, [...rows]);
    return acc;
  }, new Map());

  return rows;
};
