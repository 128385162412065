import { useCallback } from 'react';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';

export const useStyleTimelineSettingsDelete = () => {
  const partnerComposite = useCompositePartner();

  const [deleteStyleTimelineSetting] = useApiMutation('/api/styletimelinesettings/{id}', 'delete', {
    update(_data, _variables, queryClient) {
      const partnerId = partnerComposite?.id;
      const queryKey = [`/api/styletimelinesettings/partners/${partnerId}`];
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const deleteSetting = useCallback(
    (id: number) => {
      return deleteStyleTimelineSetting({
        path: {
          id,
        },
      });
    },
    [deleteStyleTimelineSetting]
  );

  return deleteSetting;
};
