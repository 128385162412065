import EmailIcon from '@mui/icons-material/Email';
import { Button, styled } from '@mui/material';
import { motion } from 'framer-motion';
import { FC, useState } from 'react';

export const StickyNewsLetterButton: FC = () => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <NewLetterLink
      href="https://sharepoint.us5.list-manage.com/subscribe?u=4114e362800684d19f162beca&id=622b66c504"
      target={'_blank'}
      rel="noreferrer"
    >
      <NewsletterButton
        variant="contained"
        startIcon={<EmailIcon />}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        initial={{ width: '30px' }}
        whileHover={{ width: '200px' }}
        transition={{ ease: 'easeOut', duration: 0.1 }}
      >
        {isHovering ? 'Sign up for Newsletter' : ''}
      </NewsletterButton>
    </NewLetterLink>
  );
};

const NewLetterLink = styled('a')`
  z-index: 999;
`;

const NewsletterButton = styled(motion(Button))`
  position: fixed;
  right: 0px;
  bottom: 100px;
  text-transform: none;
  border-radius: 15px 0px 0px 15px;
  height: 40px;
  max-height: 40px;
  min-width: 30px;
  padding: 6px;
  text-align: center;
  white-space: nowrap;
  background-color: lightseagreen;
  span {
    margin: 0;
  }
  &:hover {
    width: 200px;
    padding: 6px 16px;
    span {
      margin-right: 8px;
    }
    background-color: lightseagreen;
  }
`;
