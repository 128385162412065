import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  rectIntersection,
  useSensor,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { FC, useCallback, useMemo, useState } from 'react';
import Loader from 'src/components/atoms/Loader';
import { ClusterManagementChange } from 'src/domain';
import { useDeleteClusterDialog, useGlobalVar } from 'src/hooks';
import { useClustersByCompositeId } from 'src/hooks/cluster-management/useClustersByCompositeId';
import { useDeleteCluster } from 'src/hooks/cluster-management/useDeleteCluster';
import { useUnsavedChangesClusterManagement } from 'src/hooks/unsaved-changes/useUnsavedChangesClusterManagement';
import { clusterManagementIsSavingVar } from 'src/infrastructure/local_state';
import { ClusterModel, StoreModel } from 'src/infrastructure/rest-api/api-types';
import { ClusterBox, ClusterBoxProps } from './ClusterBox/ClusterBox';
import { ClusterStore } from './ClusterStore/ClusterStore';
import { useIsDragging } from './hooks/useIsDragging';

export const filterStoreWhenInUnsavedChanges = (
  cluster: ClusterModel,
  store: StoreModel,
  filterStoreId: number | undefined,
  unsavedChanges: Record<number, ClusterManagementChange> | undefined
) => {
  if (store.id === filterStoreId) return false;
  if (!unsavedChanges) return true;
  return !Object.entries(unsavedChanges ?? []).some(([changeKey, change]) => {
    return changeKey !== cluster.id.toString() && change.storeIds?.includes(store.id);
  });
};

export const ClusterManagementList: FC = () => {
  const { clusters, stores, loading } = useClustersByCompositeId();
  const [isSaving] = useGlobalVar(clusterManagementIsSavingVar);
  const sensors = [useSensor(PointerSensor)];
  const [storeDragged, setStoreDragged] = useState<StoreModel | undefined>();
  const { setIsDragging } = useIsDragging();
  const [unsavedChanges, setUnsavedChanges] = useUnsavedChangesClusterManagement();
  const [filterStoreId, setFilterStoreId] = useState<number>();
  const deleteCluster = useDeleteCluster();

  const filteredClusters = useMemo(() => {
    if (!stores || !clusters) return [];
    return (
      clusters?.map((cluster) => {
        return {
          ...cluster,
          stores: [
            ...(cluster.stores?.filter((store) => {
              if (!store) return false;
              return filterStoreWhenInUnsavedChanges(cluster, store, filterStoreId, unsavedChanges);
            }) ?? []),
            ...(unsavedChanges?.[cluster.id ?? -1]?.storeIds?.map((storeId) => {
              return stores?.find((store) => storeId === store?.id);
            }) ?? []),
          ],
        };
      }) ?? []
    );
  }, [clusters, filterStoreId, stores, unsavedChanges]);

  const handleDelete = useCallback(
    (clusterId: number) => {
      deleteCluster({ clusterId });
    },
    [deleteCluster]
  );

  const [showDeleteClusterDialog, makeDeleteClusterDialog] = useDeleteClusterDialog(
    'Are you sure you want to continue?',
    'Deleting this cluster is a permanent change and cannot be reverted.',
    {
      onOk: handleDelete,
    }
  );

  const deleteClusterDialog = useMemo(() => makeDeleteClusterDialog(), [makeDeleteClusterDialog]);

  const handleMoveToCluster = useCallback(
    (storeId: number, clusterId: number, clusterName: string, clusterDescription: string) => {
      const removeStoreFromUnsavedChanges = () => {
        if (!unsavedChanges) return;

        const [previousClusterKey] =
          Object.entries(unsavedChanges).find(([_key, value]) => value.storeIds?.includes(storeId)) ?? [];

        if (!previousClusterKey) return;

        const previousClusterId = parseInt(previousClusterKey);

        return {
          [previousClusterId]: {
            ...unsavedChanges?.[previousClusterId],
            storeIds: [
              ...(unsavedChanges?.[previousClusterId]?.storeIds?.filter((storeId) => storeId !== storeDragged?.id) ??
                []),
            ],
          },
        };
      };

      const updateCluster = () => {
        const isOriginalCluster = stores?.some((store) => store.id === storeId && store.cluster?.id === clusterId);

        if (isOriginalCluster) return;

        return {
          [clusterId]: {
            ...unsavedChanges?.[clusterId],
            storeIds: [...(unsavedChanges?.[clusterId]?.storeIds?.filter((id) => id !== storeId) ?? []), storeId],
            name: unsavedChanges?.[clusterId]?.name ?? clusterName,
            description: unsavedChanges?.[clusterId]?.description ?? clusterDescription,
          },
        };
      };

      setUnsavedChanges({
        ...unsavedChanges,
        ...updateCluster(),
        ...removeStoreFromUnsavedChanges(),
      });
    },
    [setUnsavedChanges, storeDragged?.id, stores, unsavedChanges]
  );

  const handleSaveClusterEdit = useCallback(
    (clusterId: number) => (name: string, description: string) => {
      if (clusterBoxProps.some((cluster) => cluster.clusterName === name && cluster.id !== clusterId)) return false;

      setUnsavedChanges({ ...unsavedChanges, [clusterId]: { ...unsavedChanges?.[clusterId], name, description } });
      return true;
    },
    // Dependency of clusterBoxProps results in circular dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUnsavedChanges, unsavedChanges]
  );

  const storeByClusterToClusterBoxProps = useCallback((): ClusterBoxProps[] => {
    if (!stores || !clusters) return [];

    const unsavedClustersBoxProps = Object.entries(unsavedChanges ?? [])
      .filter(([key]) => parseInt(key) < 0)
      .map(([key, value]) => {
        const localClusterId = parseInt(key);

        return {
          id: localClusterId,
          clusterName: value.name,
          description: value.description,
          stores:
            unsavedChanges?.[localClusterId ?? -1]?.storeIds?.map((storeId) => {
              return (stores ?? []).find((store) => storeId === store.id);
            }) ?? [],
          onMoveToCluster: handleMoveToCluster,
          onSaveEdit: handleSaveClusterEdit(localClusterId),
          onDeleteCluster: showDeleteClusterDialog,
          isUnsavedCluster: true,
        } as ClusterBoxProps;
      });

    const clustersBoxProps = filteredClusters.map((cluster) => {
      return {
        id: cluster.id,
        clusterName: unsavedChanges?.[cluster.id]?.name ?? cluster.name ?? '',
        description: unsavedChanges?.[cluster.id]?.description ?? cluster.description ?? '',
        stores: cluster.stores,
        onMoveToCluster: handleMoveToCluster,
        onSaveEdit: handleSaveClusterEdit(cluster.id),
        onDeleteCluster: showDeleteClusterDialog,
        isUnsavedCluster: false,
        isDefaultCluster: cluster.isDefault,
      } as ClusterBoxProps;
    });

    return [...unsavedClustersBoxProps, ...clustersBoxProps].sort((a, b) => a.clusterName.localeCompare(b.clusterName));
  }, [
    clusters,
    filteredClusters,
    handleMoveToCluster,
    handleSaveClusterEdit,
    showDeleteClusterDialog,
    stores,
    unsavedChanges,
  ]);

  const clusterBoxProps = useMemo(() => storeByClusterToClusterBoxProps(), [storeByClusterToClusterBoxProps]);

  const handleDragStart = useCallback(
    ({ active: { data } }: DragStartEvent) => {
      setIsDragging(true);
      const dragStore = data.current as StoreModel;
      setStoreDragged(dragStore);
      setFilterStoreId(dragStore.id);
    },
    [setIsDragging]
  );

  const handleDragEnd = useCallback(
    ({ over }: DragEndEvent) => {
      setIsDragging(false);

      const cluster = over?.data.current as ClusterBoxProps;

      if (cluster && storeDragged?.id) {
        handleMoveToCluster(storeDragged?.id, cluster.id, cluster.clusterName, cluster.description);
      }

      setFilterStoreId(undefined);
      setStoreDragged(undefined);
    },
    [handleMoveToCluster, setIsDragging, storeDragged]
  );

  const handleDragCancel = useCallback(() => {
    setIsDragging(false);
    setFilterStoreId(undefined);
    setStoreDragged(undefined);
  }, [setIsDragging]);

  return (
    <>
      {deleteClusterDialog}
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        modifiers={[restrictToVerticalAxis]}
      >
        {!loading &&
          clusterBoxProps.map((data) => <ClusterBox key={data.id} {...data} canEdit={!loading && !isSaving} />)}
        {loading && <Loader />}
        <DragOverlay modifiers={[restrictToWindowEdges]} style={{ opacity: 0.5 }}>
          {storeDragged && <ClusterStore store={storeDragged} />}
        </DragOverlay>
      </DndContext>
    </>
  );
};
