import { Nullable, TableOverviewRow } from 'src/domain';
import { compareMonths } from './compareMonths';
import { mergeMap } from './mergeMap';

export type CompareRowsResult = {
  id: number;
  old: number;
  new: number;
};

export const compareRows = (a: Nullable<TableOverviewRow[]>, b: Nullable<TableOverviewRow[]>): CompareRowsResult[] => {
  if (!a || !b) return [];

  return mergeMap([a, b], ([row1, row2]) => {
    if (!row1 || !row2) return [];

    const unevenMonths = compareMonths(row1.columns, row2.columns);

    return unevenMonths.reduce<CompareRowsResult[]>((resultArr, nextMonth) => {
      const oldValue = row1.columns[nextMonth];
      const newValue = row2.columns[nextMonth];

      const cellIdIndex = (nextMonth + 'Id') as `${typeof nextMonth}Id`;
      const cellId = row1.columns[cellIdIndex] ?? row2.columns[cellIdIndex]; // they should be the same ID

      const result = {
        id: cellId,
        old: oldValue,
        new: newValue,
      };

      resultArr.push(result);

      return resultArr;
    }, []);
  }).flatMap((x) => x);
};
