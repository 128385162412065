import styled from '@emotion/styled';
import { ArrowDropDown } from '@mui/icons-material';
import { Stack, Typography, useTheme } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { CustomCellEditorProps } from 'ag-grid-react';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import Select, { GroupedOption } from 'src/components/atoms/Select/Select';
import {
  Inheritance,
  InheritanceError,
  InheritanceOption,
  Module,
  ScopeEnum,
  SelectedInheritanceSettingsMap,
} from 'src/domain';
import { useInheritanceOptions } from 'src/hooks';
import { useInheritanceSettingsCellWarning } from 'src/hooks/inheritance-settings/useInheritanceSettingsCellWarning';
import { SerializeMap } from 'src/utils/SerializeMap';
import { getInheritanceValue, isInheritanceOption } from 'src/utils/inheritance-options';
import { LightTooltip } from '../LightToolTip/LightToolTip';

function formatFieldToModule(field: string | undefined) {
  switch (field) {
    case 'salesBudget':
      return Module.Turnover;
    case 'categorySplit':
      return Module.CategorySplit;
    case 'genderSplit':
      return Module.GenderSplit;
    case 'productLineSplit':
      return Module.ProductLineSplit;
    case 'noosShare':
      return Module.NoosShare;
    case 'markup':
      return Module.Markup;
    case 'markdown':
      return Module.Markdown;
    case 'deliveryProfile':
      return Module.DeliveryProfile;
    case 'weekCover':
      return Module.WeekCover;
  }
}

export const DropdownCellEditor = memo((props: CustomCellEditorProps) => {
  const { value, data, colDef, node, formatValue, onValueChange } = props;
  const [selectedInheritanceMap, setSelectedInheritanceMap] = useState<SelectedInheritanceSettingsMap>(
    new SerializeMap()
  );
  const options = useInheritanceOptions(
    {
      scope: ScopeEnum.STORE,
      module: Module.InheritanceSettings,
      clusterName: data?.clusterName,
      countryName: data?.countryName,
      storeName: data?.storeName,
    },
    colDef?.field
  );

  const id = data?.id;
  const module = formatFieldToModule(colDef?.field) as Module;
  const nonGroupedOptions: GroupedOption[] = options
    .filter((x) => typeof x.value === 'string')
    .map((x) => ({
      isHeader: false,
      value: x.value as Inheritance,
      display: x.display,
    }));

  const groupedOptions = options
    .filter((x) => typeof x.value !== 'string')
    .flatMap((x) => (typeof x.value === 'string' ? x : [x, ...x.value]))
    .map<GroupedOption>((x) => ({
      isHeader: typeof x.value === 'object',
      display: x.display,
      value: x.value as Inheritance,
    }));

  const mappedOptions = [...nonGroupedOptions, ...groupedOptions];
  const flattedOptions = options.flatMap((x) => (typeof x.value === 'string' ? x : [x, ...x.value]));

  const setInheritance = useCallback(
    (option: InheritanceOption) => {
      if (!module) throw new InheritanceError('Could not find current module');
      // we need to ensure new reference for reactive var to trigger
      selectedInheritanceMap.set({ module: module, scope: ScopeEnum.STORE, id }, option);
      setSelectedInheritanceMap(new SerializeMap(selectedInheritanceMap));

      // update the value for ag grid
      if (colDef?.field) node.setDataValue(colDef.field, option.value);
    },
    [module, selectedInheritanceMap, id, setSelectedInheritanceMap, colDef.field, node]
  );

  const handleSelect = useCallback(
    (item: string) => {
      const selected = flattedOptions.find((x) => x.value === item);

      if (!selected) throw new InheritanceError('Could not find selected inheritance');

      setInheritance(selected);

      if (isInheritanceOption(selected)) {
        onValueChange(selected.value);
      } else {
        onValueChange(selected);
      }
    },
    [flattedOptions, onValueChange, setInheritance]
  );

  const activeInheritance = selectedInheritanceMap.get({ module: module, scope: ScopeEnum.STORE, id }) ?? value;

  const activeItem = useMemo(() => getInheritanceValue(activeInheritance), [activeInheritance]);

  const renderValue = useCallback(
    (value: string) => {
      const valueToFormat = getInheritanceValue(activeInheritance);
      if (formatValue) return formatValue(valueToFormat);
      return value ?? undefined;
    },
    [activeInheritance, formatValue]
  ); // this is only needed if we want a dropdown to be shown. Or do we?

  return (
    <Wrapper>
      <Select
        showLabel={false}
        options={mappedOptions}
        activeItem={activeItem}
        onSelect={handleSelect}
        open={true}
        renderValue={renderValue}
        onClose={() => {
          setTimeout(() => props.api.stopEditing());
        }}
      />
    </Wrapper>
  );
});

interface IDropDownCellRendererParams extends ICellRendererParams {
  warningRenderer?: FC<ICellRendererParams>;
}

export const DropdownCellRenderer = (props: IDropDownCellRendererParams) => {
  const { value, valueFormatted, warningRenderer: WarningRenderer } = props;
  const warningMessage = useInheritanceSettingsCellWarning(props);
  const theme = useTheme();

  return (
    <LightTooltip
      title={
        <Stack>
          <Typography>{valueFormatted}</Typography>
          <Typography noWrap={false} color={theme.palette.warning.main}>
            {warningMessage}
          </Typography>
        </Stack>
      }
    >
      <FakeInputWrapper>
        <FakeInput className="fake-input">
          <Value>{valueFormatted ?? value}</Value>
          {WarningRenderer ? (
            <Stack pl={2} maxHeight={'24px'}>
              <WarningRenderer {...props} />
            </Stack>
          ) : (
            ''
          )}
          <DropdownIcon />
        </FakeInput>
      </FakeInputWrapper>
    </LightTooltip>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  div {
    width: 100%;
    margin: 0;
  }
`;

const Value = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const FakeInputWrapper = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  align-content: center;
  padding: 3px 0px;
  box-sizing: border-box;
`;

const FakeInput = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid rgba(114, 114, 114, 0.5);
  border-radius: 4px;
  padding: 8.5px 14px;
  padding-right: 32px;
  position: relative;
  box-sizing: content-box;
  font-size: 16px;
`;

const DropdownIcon = styled(ArrowDropDown)`
  color: #727272;
  position: absolute;
  right: 7px;
  top: calc(50% - 0.5em);
`;
