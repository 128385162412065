import { GetGroupRowAggParams } from 'ag-grid-community';
import { isNumber } from 'lodash';
import { TableOverviewRowTuple } from 'src/domain';

export type GrossMarginAggregation = Record<string, [number, number]>;
export const weightedAverageGrossMargin = (params: GetGroupRowAggParams<TableOverviewRowTuple>) => {
  const result = params.nodes.reduce<GrossMarginAggregation>((acc, value) => {
    const data = value.data;

    if (!data) return acc;

    Object.keys(data.columns).forEach((fieldName) => {
      if (fieldName.endsWith('Id') || fieldName.endsWith('PlannedSplit')) return;

      const currMonthSplit = data.columns[`${fieldName}PlannedSplit`][0];
      const plannedSplit = data.columns[`${fieldName}PlannedSplit`][1];
      const currMonthValue = data.columns[fieldName][0];
      const plannedValue = data.columns[fieldName][1];

      if (!acc[fieldName]) {
        acc[fieldName] = [0, 0];
      }

      if (isNumber(currMonthValue) && isNumber(currMonthSplit)) {
        acc[fieldName][0] += (currMonthSplit / 100) * currMonthValue;

        const effectiveSplit = isNumber(plannedSplit) ? plannedSplit : currMonthSplit;
        const effectiveValue = isNumber(plannedValue) ? plannedValue : currMonthValue;

        acc[fieldName][1] += (effectiveSplit / 100) * effectiveValue;
      }
    });

    return acc;
  }, {} as GrossMarginAggregation);

  return result;
};
