import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { useScope } from 'src/hooks';
import { useTurnoverInheritanceQuery } from './useTurnoverInheritanceQuery';

export const useTurnoverQuery = () => {
  const scope = useScope();
  const { data, expectedSalesComparable, numberOfStores, numberOfStoresComparable, error, loading, partnerRefetch } =
    useTurnoverInheritanceQuery();

  const current = useMemo(() => {
    switch (scope) {
      case ScopeEnum.STORE:
        return {
          expectedSales: data,
          expectedSalesComparable,
          error,
          loading,
          numberOfStores,
          numberOfStoresComparable,
        };

      case ScopeEnum.CLUSTER:
        return {
          expectedSales: data,
          expectedSalesComparable,
          error,
          loading,
          numberOfStores,
          numberOfStoresComparable,
        };

      case ScopeEnum.PARTNER:
        return {
          expectedSales: data,
          expectedSalesComparable,
          error,
          loading,
          numberOfStores,
          partnerRefetch,
          numberOfStoresComparable,
        };
      default:
        return;
    }
  }, [data, error, loading, numberOfStores, numberOfStoresComparable, expectedSalesComparable, partnerRefetch, scope]);

  return current;
};
