import { ArrowBack } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, CircularProgress, Stack, Tab, Typography, styled } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import Select from 'src/components/atoms/Select';
import { GroupedOption } from 'src/components/atoms/Select/Select';
import { Headings } from 'src/domain';
import { SalesCampaignOverviewRow } from 'src/domain/table/sales-campaign-overview.row';
import { useFilteredStores } from 'src/hooks';
import { useSalesCampaignSimulationRows } from 'src/hooks/sales-campaigns-planning/useSalesCampaignSimulationRows';
import { useFilteredStoresParentName } from 'src/hooks/useFilteredStoresParentName';
import { CategorySalesSummaryTab } from './tabs/CategorySalesSummaryTab';
import { StockAgeSummaryTab } from './tabs/StockAgeSummaryTab';
import { StoreSalesSummaryTab } from './tabs/StoreSalesSummaryTab';
import { TotalSummaryTab } from './tabs/TotalSummaryTab';
import { ITab } from './types';

const defaultTabs: ITab[] = [
  {
    label: 'Total summary',
    value: 'total-summary',
    TabContent: TotalSummaryTab,
  },
  {
    label: 'Category sales summary',
    value: 'category-sales-summary',
    TabContent: CategorySalesSummaryTab,
  },
  {
    label: 'Store sales summary',
    value: 'store-sales-summary',
    TabContent: StoreSalesSummaryTab,
  },
  {
    label: 'Stock age summary',
    value: 'stock-age-summary',
    TabContent: StockAgeSummaryTab,
  },
];

export const SalesCampaignReport: FC = () => {
  const { id: salesCampaignId } = useParams();

  const {
    allData: allSimulationRows,
    salesCampaign,
    loading,
  } = useSalesCampaignSimulationRows(Number(salesCampaignId));

  const salesCampaignRowsByStore = useMemo(() => {
    return allSimulationRows.reduce(
      (acc, row) => {
        row.storeStyleTimelines?.forEach((storeStyleTimeline) => {
          if (storeStyleTimeline?.storeId) {
            acc[storeStyleTimeline.storeId] = acc[storeStyleTimeline.storeId] ?? [];
            acc[storeStyleTimeline.storeId].push({
              ...row,
              stockQuantity: storeStyleTimeline.stockQuantity,
              stockValueLcy: storeStyleTimeline.stockValueLcy,
            });
          }
        });
        return acc;
      },
      {} as Record<string, SalesCampaignOverviewRow[]>
    );
  }, [allSimulationRows]);

  const [selectedReportFilterOption, setSelectedReportFilterOption] = useState<GroupedOption | null>();
  const tabs = useMemo(() => {
    const showStoreSalesSummary = selectedReportFilterOption?.isHeader === true;

    if (!showStoreSalesSummary) {
      return defaultTabs.filter((tab) => tab.value !== 'store-sales-summary');
    }

    return defaultTabs;
  }, [selectedReportFilterOption]);

  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  const onTabChange = useCallback((_: unknown, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const filteredStoresParentName = useFilteredStoresParentName();
  const [filteredStores] = useFilteredStores();
  const reportFilterOptions = useMemo(
    () =>
      [
        {
          display: filteredStoresParentName,
          isHeader: true,
          value: filteredStoresParentName,
        },
        ...filteredStores.map((store) => ({
          display: store.storeName ?? '<unknown>',
          isHeader: false,
          value: store.id.toString(),
        })),
      ] as GroupedOption[],
    [filteredStoresParentName, filteredStores]
  );

  const onReportFilterChange = useCallback(
    (value: string) => {
      const filterOption = reportFilterOptions.find((option) => option.value === value);
      setSelectedReportFilterOption(filterOption);

      // If the selected filter is a header (country/cluster) and the current tab is store-sales-summary, switch to total-summary
      if (!filterOption?.isHeader && currentTab === 'store-sales-summary') {
        setCurrentTab('total-summary');
      }
    },
    [currentTab, reportFilterOptions]
  );

  useEffect(() => {
    // If there is no selected report filter option, select the first one
    if (!selectedReportFilterOption && reportFilterOptions.length > 0) {
      setSelectedReportFilterOption(reportFilterOptions[0]);
    }
  }, [reportFilterOptions, selectedReportFilterOption]);

  const salesCampaignRows = useMemo(() => {
    if (!selectedReportFilterOption) {
      return [];
    }

    if (selectedReportFilterOption.isHeader) {
      return allSimulationRows;
    }

    return salesCampaignRowsByStore[selectedReportFilterOption.value] ?? [];
  }, [allSimulationRows, salesCampaignRowsByStore, selectedReportFilterOption]);

  return (
    <TabContext value={currentTab}>
      <Stack gap={'1rem'}>
        <Box>
          <RouterLink
            to={{
              pathname: '/sales-campaigns',
              search: location.search,
            }}
          >
            <Button variant="text" startIcon={<ArrowBack />}>
              Back to campaigns list
            </Button>
          </RouterLink>
        </Box>
        <Container>
          <Stack gap={'1rem'}>
            <Stack>
              <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} gap="1rem">
                <Header heading={Headings.h1}>Sales Campaign Report</Header>
                <Select
                  disabled={loading}
                  label={'Filter'}
                  activeItem={selectedReportFilterOption?.value}
                  onSelect={onReportFilterChange}
                  minWidth="150px"
                  options={reportFilterOptions}
                  isHeaderSelectable={true}
                />
              </Stack>
              <Stack direction="row" gap="0.5rem">
                <Typography fontWeight={'bold'}>Campaign name:</Typography>
                <Typography>{salesCampaign?.name}</Typography>
              </Stack>
            </Stack>

            {loading ? (
              <Stack alignItems={'center'}>
                <CircularProgress />
              </Stack>
            ) : (
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={onTabChange} aria-label="Summary tabs">
                  {tabs.map((tab) => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}
                </TabList>
              </Box>
            )}
          </Stack>
        </Container>
        {!loading &&
          tabs.map((tab) => (
            <StyledTabPanel key={tab.value} value={tab.value}>
              <tab.TabContent
                salesCampaignRows={salesCampaignRows}
                salesCampaignRowsByStore={salesCampaignRowsByStore}
              />
            </StyledTabPanel>
          ))}
      </Stack>
    </TabContext>
  );
};

const StyledTabPanel = styled(TabPanel)`
  padding: 0;
`;
