import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useAcquireToken } from '../auth/useAcquireToken';
import { useLogin } from '../auth/useLogin';

export const useInitializeMsal = () => {
  const { accounts, inProgress } = useMsal();
  const acquireToken = useAcquireToken();
  const login = useLogin();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).__IS_RUNNING_E2E_TESTS__) {
      return;
    }

    if (inProgress === InteractionStatus.None) {
      if (!accounts[0]) {
        login();
      } else {
        acquireToken().catch(() => login());
      }
    }
  }, [accounts, acquireToken, inProgress, login]);
};
