import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
} from '@mui/material';
import { FC, useState } from 'react';

type CreateClusterDialogProps = {
  open: boolean;
  onOk: (name: string, description?: string) => void;
  onCancel: () => void;
};

export const CreateClusterDialog: FC<CreateClusterDialogProps> = ({ onOk, onCancel, open }) => {
  const [clusterName, setClusterName] = useState<string>();
  const [clusterDescription, setClusterDescription] = useState<string>();
  const [okClicked, setOkClicked] = useState<boolean>(false);

  const reset = () => {
    setClusterName(undefined);
    setClusterDescription(undefined);
  };

  const handleOkClicked = () => {
    if (isClusterNameInvalid) {
      setOkClicked(true);
      return;
    }

    onOk(clusterName, clusterDescription ?? '');
    setOkClicked(false);
    reset();
  };

  const handleCancelClicked = () => {
    onCancel();
    reset();
  };

  const isClusterNameInvalid = !clusterName || clusterName?.isEmpty() || clusterName.match(/^ *$/) !== null;

  const isClusterNameTooLong = !!clusterName && clusterName.length > 20;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="create-cluster-dialog-title"
      aria-describedby="create-cluster-dialog-description"
    >
      <DialogTitle id="create-cluster-dialog-title">Create new cluster</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <DialogContentText id="create-cluster-dialog-description">
            Please provide a cluster name and description for your new cluster.
          </DialogContentText>

          <FormControl>
            <InputLabel htmlFor="component-outlined">Cluster Name</InputLabel>
            <OutlinedInput
              autoFocus
              error={(isClusterNameInvalid || isClusterNameTooLong) && okClicked}
              id="component-outlined"
              label="Cluster Name"
              onChange={(e) => setClusterName(e.currentTarget.value)}
            />
            {isClusterNameInvalid && okClicked && (
              <FormHelperText id="component-error-text">Please provide a valid cluster name.</FormHelperText>
            )}
            {isClusterNameTooLong && okClicked && (
              <FormHelperText id="component-error-text">Cluster name cannot be over 20 characters.</FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="component-outlined">Cluster Description</InputLabel>
            <OutlinedInput
              id="component-outlined"
              label="Cluster Description"
              onChange={(e) => setClusterDescription(e.currentTarget.value)}
            />
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClicked}>Cancel</Button>
        <Button onClick={handleOkClicked}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};
