import { useEffect } from 'react';
import {
  chainIdToNameListVar,
  clusterIdToNameListVar,
  countryIdToNameListVar,
  ownRetailChainClusterPartnersVar,
  ownRetailChainCountryPartnersVar,
  partnerIdToNameListVar,
  partnerRetailChainClusterPartnersVar,
  partnerRetailChainCountryPartnersVar,
  partnerToCompositeVar,
  semiOwnedChainClusterPartnersVar,
  semiOwnedChainCountryPartnersVar,
} from 'src/infrastructure/local_state';
import { buildLookupTables } from 'src/utils/buildLookupTables';
import { useStores } from '../store-selection/queries/useStores';
import { useGlobalVar } from '../useGlobalVar';

export const useInitializeLookupTables = () => {
  const [_partnerToComposite, setPartnerToComposite] = useGlobalVar(partnerToCompositeVar);
  const [_chainIdToNameList, setChainIdToNameList] = useGlobalVar(chainIdToNameListVar);
  const [_clusterIdToNameList, setClusterIdToNameList] = useGlobalVar(clusterIdToNameListVar);
  const [_partnerIdToNameList, setPartnerIdToNameList] = useGlobalVar(partnerIdToNameListVar);
  const [_countryIdToNameList, setCountryIdToNameList] = useGlobalVar(countryIdToNameListVar);
  const [_ownRetailChainCountryPartners, setOwnRetailChainCountryPartners] = useGlobalVar(
    ownRetailChainCountryPartnersVar
  );
  const [_ownRetailChainClusterPartners, setOwnRetailChainClusterPartners] = useGlobalVar(
    ownRetailChainClusterPartnersVar
  );
  const [_partnerRetailChainCountryPartners, setPartnerRetailChainCountryPartners] = useGlobalVar(
    partnerRetailChainCountryPartnersVar
  );
  const [_partnerRetailChainClusterPartners, setPartnerRetailChainClusterPartners] = useGlobalVar(
    partnerRetailChainClusterPartnersVar
  );
  const [_semiOwnedChainCountryPartners, setSemiOwnedChainCountryPartners] = useGlobalVar(
    semiOwnedChainCountryPartnersVar
  );
  const [_semiOwnedChainClusterPartners, setSemiOwnedChainClusterPartners] = useGlobalVar(
    semiOwnedChainClusterPartnersVar
  );

  const { data } = useStores();

  useEffect(() => {
    if (!data) return;

    const lookupTables = buildLookupTables(data.allStores);

    setChainIdToNameList(lookupTables.chainIds);
    setClusterIdToNameList(lookupTables.clusterIds);
    setPartnerIdToNameList(lookupTables.partnerIds);
    setCountryIdToNameList(lookupTables.countryIds);
    setOwnRetailChainCountryPartners(lookupTables.ownRetailChainCountryPartners);
    setOwnRetailChainClusterPartners(lookupTables.ownRetailChainClusterPartners);
    setPartnerRetailChainCountryPartners(lookupTables.partnerRetailChainCountryPartners);
    setPartnerRetailChainClusterPartners(lookupTables.partnerRetailChainClusterPartners);
    setSemiOwnedChainCountryPartners(lookupTables.semiOwnedChainCountryPartners);
    setSemiOwnedChainClusterPartners(lookupTables.semiOwnedChainClusterPartners);
    setPartnerToComposite(lookupTables.compositePartners);
  }, [
    data,
    setChainIdToNameList,
    setClusterIdToNameList,
    setCountryIdToNameList,
    setOwnRetailChainClusterPartners,
    setOwnRetailChainCountryPartners,
    setPartnerIdToNameList,
    setPartnerRetailChainClusterPartners,
    setPartnerRetailChainCountryPartners,
    setPartnerToComposite,
    setSemiOwnedChainClusterPartners,
    setSemiOwnedChainCountryPartners,
  ]);
};
