export const getButtonTooltips = (hasUnsavedChanges: boolean, sumIs100?: boolean) => {
  let saveTooltip = '';
  let discardTooltip = '';

  if (!hasUnsavedChanges) {
    saveTooltip = 'No unsaved changes.';
    discardTooltip = 'No changes to discard.';
  } else {
    if (typeof sumIs100 !== 'undefined' && !sumIs100) {
      saveTooltip = 'Sum of column should sum to 100%.';
    }
  }

  return { saveTooltip, discardTooltip };
};
