import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useCompositePartner } from '../../partner/useCompositePartner';

export const useStyleTimelineSettingsRows = () => {
  const partnerComposite = useCompositePartner();

  const { data, loading, error } = useApiQuery('/api/styletimelinesettings/partners/{partnerId}', 'get', {
    path: {
      partnerId: partnerComposite?.id,
    },
    enabled: !!partnerComposite?.id,
  });

  return { data, loading, error };
};
