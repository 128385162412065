export class HttpStatusError extends Error {
  constructor(
    public status: number,
    public statusText: string,
    public body: Record<string, unknown>
  ) {
    super(`HTTP status ${status}: ${statusText}`);
    this.name = 'HttpStatusError';
  }
}
