import { isNumber } from 'lodash';
import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { Store } from 'src/domain/models/store/store';
import { useFilteredStores, useStoreId } from 'src/hooks/store';
import { useScope } from 'src/hooks/useScope';
import {
  mapOptimalOptionCountPartnerClusterTotals,
  mapOptimalOptionCountStoreTotals,
} from 'src/mapping/optimal-option-count.mapping';
import { useOptimalOptionCountQuery } from '../query/useOptimalOptionCountQuery';

export const useOptimalOptionCountTotalsRows = () => {
  const { data, error, loading } = useOptimalOptionCountQuery();
  const scope = useScope();
  const [filteredStores] = useFilteredStores();
  const currentStoreId = useStoreId();
  const stores = useMemo(() => {
    const storeId = Number(currentStoreId);
    if (scope === ScopeEnum.STORE && isNumber(storeId)) {
      return filteredStores?.reduce<Store[]>((acc, store) => {
        if (store.id === storeId) {
          acc.push({
            storeName: store.storeName,
            storeId: store.id,
            salesFloorSquaremeters: store.salesFloorSquaremeters,
          } as Store);
        }
        return acc;
      }, []);
    }
    return filteredStores?.reduce<Store[]>((acc, store) => {
      acc.push({
        storeName: store.storeName,
        storeId: store.id,
        salesFloorSquaremeters: store.salesFloorSquaremeters,
      } as Store);
      return acc;
    }, []);
  }, [currentStoreId, filteredStores, scope]);

  const tableData = useMemo(() => {
    if (scope === ScopeEnum.STORE) {
      return mapOptimalOptionCountStoreTotals(data, stores);
    }
    return mapOptimalOptionCountPartnerClusterTotals(data, stores);
  }, [data, scope, stores]);

  return { data: tableData, error, loading };
};
