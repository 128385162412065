import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useCompositePartner } from '../partner/useCompositePartner';
import { useSelectedPartner } from '../useSelectedPartner';

export const useTurnoverForPartnerStores = () => {
  const [partner] = useSelectedPartner();
  const partnerComposite = useCompositePartner(partner);

  const { data } = useApiQuery('/api/turnover/partners/{partnerId}/stores', 'get', {
    path: {
      partnerId: partnerComposite?.id,
    },
    enabled: !!partnerComposite?.id,
  });

  return useMemo(() => {
    if (!data) {
      return [];
    }

    return data?.map((store) => ({
      storeId: store.storeId,
      turnover: sortBy(store.turnover, (item) => item?.monthKey)?.reduce<number>((acc, value, index) => {
        if (index < 12) return acc + value?.salesExpectediVlcy;
        return acc;
      }, 0),
    }));
  }, [data]);
};
