import { FC } from 'react';
import { AuditLogDrawer } from './AuditLogDrawer/AuditLogDrawer';
import { BulkEditDrawer } from './BulkEditDrawer/BulkEditDrawer';
import { DefaultDrawer } from './DefaultDrawer/DefaultDrawer';
import { SettingsDrawer } from './SettingsDrawer/SettingsDrawer';

export const Drawer: FC = () => {
  return (
    <div>
      <AuditLogDrawer />
      <SettingsDrawer />
      <DefaultDrawer />
      <BulkEditDrawer />
    </div>
  );
};
