import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { ComparableCell } from 'src/components/molecules/ComparableCell/ComparableCell';

const defaultColumnOptions: Partial<ColDef> = {
  editable: false,
  suppressHeaderMenuButton: true,
};

const defaultColumnOptionsMonths: Partial<ColDef> = {
  ...defaultColumnOptions,
  type: 'numericColumn',
  cellRenderer: ComparableCell,
};

export const Columns: ColDef[] = [
  {
    ...defaultColumnOptions,
    field: 'category',
    width: 150,
    suppressSizeToFit: true,
    cellRenderer: (params: ICellRendererParams) => {
      return params.value ?? 'Average';
    },
  },
  {
    ...defaultColumnOptionsMonths,
    field: 'january',
  },
  {
    ...defaultColumnOptionsMonths,
    field: 'february',
  },
  {
    ...defaultColumnOptionsMonths,
    field: 'march',
  },
  {
    ...defaultColumnOptionsMonths,
    field: 'april',
  },
  {
    ...defaultColumnOptionsMonths,
    field: 'may',
  },
  {
    ...defaultColumnOptionsMonths,
    field: 'june',
  },
  {
    ...defaultColumnOptionsMonths,
    field: 'july',
  },
  {
    ...defaultColumnOptionsMonths,
    field: 'august',
  },
  {
    ...defaultColumnOptionsMonths,
    field: 'september',
  },
  {
    ...defaultColumnOptionsMonths,
    field: 'october',
  },
  {
    ...defaultColumnOptionsMonths,
    field: 'november',
  },
  {
    ...defaultColumnOptionsMonths,
    field: 'december',
  },
];

export const AutoGroupColumns: ColDef = {
  cellRendererParams: {
    innerRenderer: (props: ICellRendererParams) => {
      // this is not being called
      return <div style={{ fontWeight: 'italic' }}>{props.value}</div>;
    },
  },
};
