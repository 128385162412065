import { ColDef, ICellRendererParams, ValueParserParams } from 'ag-grid-community';
import { uniq } from 'lodash';
import { sort } from 'ramda';
import { monthKeyToField, monthKeyToTitle } from 'src/utils/monthKeys';
import { defaultNumericColumnOptions } from './default-options';
import { isEditableWithFooter } from './is-editable';

export interface CreateDynamicColumnsOptions<TData> extends ColDef<TData> {
  fieldPrefix?: string;
}

/**
 * Function to create columns dynamically based on monthKeys
 * @param data The data to create columns from, must contain monthKey
 * @param options Options to configure the function - fieldPrefix can be used in case the values for the months are nested in an object
 * @returns Array of ColDef for use with AG grid
 */
export function createDynamicMonthColumns<TData>(
  data: { monthKey: number }[],
  {
    fieldPrefix = 'columns',
    editable = isEditableWithFooter,
    cellRendererParams,
    ...columnOptions
  }: CreateDynamicColumnsOptions<TData> = {}
): ColDef<TData>[] {
  if (!data) {
    return [];
  }

  return sort<number>((a, b) => a - b, uniq(data.map((value) => value.monthKey))).reduce((acc, monthKey) => {
    acc.push({
      ...defaultNumericColumnOptions,
      headerName: monthKeyToTitle(monthKey),
      headerTooltip: monthKeyToTitle(monthKey),
      field: `${fieldPrefix ? `${fieldPrefix}.` : ''}${monthKeyToField(monthKey)}`,
      editable,
      type: 'numericColumn',
      cellRendererParams: {
        isModifiedByUserGetter: (params: ICellRendererParams) => {
          if (!params.colDef?.field) {
            return;
          }
          return params.data.isModifiedByUser?.[params.colDef.field.replace('columns.', '')];
        },
        ...cellRendererParams,
      },
      suppressHeaderMenuButton: true,
      cellDataType: 'text',
      ...columnOptions,
    } as ColDef<TData>);
    return acc;
  }, [] as ColDef<TData>[]);
}

export function filterEmpty<TValue>(data: (TValue | null | undefined)[]): TValue[] {
  return data.filter((item) => !!item) as TValue[];
}

export function parsePercentageValueToNumber(params: ValueParserParams) {
  if (!params.newValue) return params.newValue;
  return params.newValue.replace('%', '');
}
