import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useCurrentModuleName = () => {
  const location = useLocation();
  return useMemo(() => {
    const url = location.pathname;

    const parts = url.split('/');
    const moduleName = /\d+/.test(parts[parts.length - 1]) ? parts[parts.length - 2] : parts[parts.length - 1];

    return moduleName;
  }, [location]);
};
