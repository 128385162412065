import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { useClusterId } from 'src/hooks/cluster';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useStoreId } from 'src/hooks/store';
import { useGlobalVar } from 'src/hooks/useGlobalVar';
import { selectedPartnerVar } from 'src/infrastructure/local_state';

import { components } from 'src/infrastructure/rest-api/generated';
import { useScope } from '../../useScope';
import { useOptimalOptionCountApiQuery } from './useOptimalOptionCountApiQuery';

export type OptimalOptionCount = components['schemas']['OptimalOptionCountModel'];

export type OptimalOptionCountLevelQuery = {
  data: OptimalOptionCount[] | undefined;
  error: Error | null;
  loading: boolean;
};

export const useOptimalOptionCountLevelQuery = (): OptimalOptionCountLevelQuery | undefined => {
  const scope = useScope();
  const storeId = useStoreId();
  const [selectedPartner] = useGlobalVar(selectedPartnerVar);
  const clusterId = useClusterId(selectedPartner);
  const compositePartner = useCompositePartner();

  const storeResponse = useOptimalOptionCountApiQuery(storeId, 'Store');
  const clusterResponse = useOptimalOptionCountApiQuery(clusterId, 'Cluster');
  const partnerResponse = useOptimalOptionCountApiQuery(compositePartner?.id, 'Partner');

  const current = useMemo<OptimalOptionCountLevelQuery | undefined>(() => {
    switch (scope) {
      case ScopeEnum.STORE:
        return {
          data: storeResponse.data,
          error: storeResponse.error,
          loading: storeResponse.loading,
        };

      case ScopeEnum.CLUSTER:
        return {
          data: clusterResponse.data,
          error: clusterResponse.error,
          loading: clusterResponse.loading,
        };

      case ScopeEnum.PARTNER:
        return {
          data: partnerResponse.data,
          error: partnerResponse.error,
          loading: partnerResponse.loading,
        };
      default:
        return;
    }
  }, [
    scope,
    clusterResponse.data,
    clusterResponse.error,
    clusterResponse.loading,
    partnerResponse.data,
    partnerResponse.error,
    partnerResponse.loading,
    storeResponse.data,
    storeResponse.error,
    storeResponse.loading,
  ]);

  return current;
};
