import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';

export type IconCellParams = ICellRendererParams & {
  showIcon: boolean;
  icon: React.ReactElement;
  tooltip: string;
};

export function IconCell({ valueFormatted, value, showIcon, tooltip, icon }: IconCellParams) {
  if (showIcon) {
    return (
      <IconWrapper>
        <Tooltip title={tooltip}>{icon}</Tooltip>
        {valueFormatted ?? value}
      </IconWrapper>
    );
  }

  return <>{valueFormatted}</>;
}

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
