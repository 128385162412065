import { useMemo } from 'react';
import { selectedPartnerVar } from 'src/infrastructure/local_state';
import { usePartners } from './partner';
import { useGlobalVar } from './useGlobalVar';
import { useType } from './useType';

export const useSelectedPartner = () => {
  const type = useType();
  const partners = usePartners(type);
  const [selectedPartner, setSelectedPartner] = useGlobalVar(selectedPartnerVar);

  return useMemo(() => {
    if (partners?.length === 1 && !selectedPartner) {
      return [partners?.[0], setSelectedPartner] as const;
    }
    return [selectedPartner, setSelectedPartner] as const;
  }, [partners, selectedPartner, setSelectedPartner]);
};
