import { format } from 'date-fns';
import { isNumber } from 'lodash';
import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { ComparableStoreTypeEnum } from 'src/domain/enums/comparableStoreTypeEnum';
import { useScope } from 'src/hooks';
import {
  StoreType,
  TurnoverOverviewModel,
  TurnoverOverviewSummarizedGroupsModel,
} from 'src/infrastructure/rest-api/api-types';
import { useStores } from '../store-selection/queries/useStores';
import { useTurnoverLevelQuery } from './queries/useTurnoverLevelQuery';
import { useComparableStore } from './useComparableStore';

function getTurnoverDetails(
  data: TurnoverOverviewSummarizedGroupsModel | undefined,
  comparable: ComparableStoreTypeEnum,
  error: Error | null | undefined,
  loading: boolean | undefined
) {
  const { storeCount: comparableStoreCount, salesFloorSquaremeters: comparableStoreSalesArea } =
    data?.comparables ?? {};
  const { storeCount: nonComparableStoreCount, salesFloorSquaremeters: nonComparableSalesArea } =
    data?.nonComparables ?? {};

  const comparableStoresCount = comparableStoreCount ?? 0;
  const nonCmparableStoresCount = nonComparableStoreCount ?? 0;
  const totalStore = comparableStoresCount + nonCmparableStoresCount;

  if (!isNumber(comparableStoreSalesArea) || !isNumber(nonComparableSalesArea)) return;
  const averageStoreSizeAll = (comparableStoreSalesArea + nonComparableSalesArea) / totalStore;
  const averageStoreSizeComparable = comparableStoreSalesArea / comparableStoresCount;
  const averageStoreSizeNonComparable = nonComparableSalesArea / nonCmparableStoresCount;

  if (comparable === ComparableStoreTypeEnum.Comparables) {
    return {
      averageStoreSize: averageStoreSizeComparable,
      numberOfStores: comparableStoresCount,
      error,
      loading,
    };
  }

  if (comparable === ComparableStoreTypeEnum.NonComparables) {
    return {
      averageStoreSize: averageStoreSizeNonComparable,
      numberOfStores: nonCmparableStoresCount,
      error,
      loading,
    };
  }

  return {
    averageStoreSize: averageStoreSizeAll,
    numberOfStores: totalStore,
    error,
    loading,
  };
}

export interface StoreDetails {
  storeSize: number;
  storeOpeningDate: string | null;
  storeOpeningDateFormatted: string | undefined;
  clusterName: string;
  countryName: string;
  areaManagerName: string;
  operationalResponsibleName: string;
  businessTradingPartnerName: string;
  storeType: StoreType;
  error: Error | null | undefined;
  loading: boolean;
}

export const useTurnoverDetails = () => {
  const scope = useScope();
  const comparable = useComparableStore();
  const { data, error, loading } = useTurnoverLevelQuery() ?? {};
  const { data: storeData } = useStores();

  const storeDetails = useMemo(() => {
    if (scope !== ScopeEnum.STORE || !data) {
      return;
    }

    const storeTurnoverOverview = data as TurnoverOverviewModel;
    const store = storeData?.allStores.find((store) => store.id === storeTurnoverOverview.storeId);

    if (!store) return;

    const details: StoreDetails = {
      storeSize: storeTurnoverOverview.storeSize,
      storeOpeningDate: storeTurnoverOverview.storeOpeningDate,
      storeOpeningDateFormatted: storeTurnoverOverview.storeOpeningDate
        ? format(new Date(storeTurnoverOverview.storeOpeningDate), 'yyyy-MM-dd')
        : undefined,
      clusterName: storeTurnoverOverview.clusterName,
      countryName: storeTurnoverOverview.countryName,
      areaManagerName: storeTurnoverOverview.areaManagerName,
      operationalResponsibleName: storeTurnoverOverview.operationalResponsibleName,
      businessTradingPartnerName: store.businessTradingPartner.name,
      storeType: store.storeType,
      error,
      loading: loading ?? false,
    };

    return details;
  }, [data, error, loading, scope, storeData?.allStores]);

  const clusterDetails = useMemo(() => {
    if (scope !== ScopeEnum.CLUSTER) {
      return;
    }

    const clusterData = data as TurnoverOverviewSummarizedGroupsModel;

    return getTurnoverDetails(clusterData, comparable, error, loading);
  }, [comparable, data, error, loading, scope]);

  const partnerDetails = useMemo(() => {
    if (scope !== ScopeEnum.PARTNER) {
      return;
    }

    const partnerData = data as TurnoverOverviewSummarizedGroupsModel;

    return getTurnoverDetails(partnerData, comparable, error, loading);
  }, [comparable, data, error, loading, scope]);

  return { storeDetails, clusterDetails, partnerDetails };
};
