import { useSnackbar } from './useSnackbar';

export const useClusterChangesSnackbar = () => {
  const showDefaultSnackbar = useSnackbar();

  const showSnackbar = (error?: Error | null) =>
    showDefaultSnackbar(
      error?.message ??
        `Changes have been saved! Stores will be recalculated and are available in Storeforecast tomorrow`,
      {
        variant: error ? 'error' : 'success',
      }
    );

  return showSnackbar;
};
