import { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';

export const generateContextMenuItems = (
  params: GetContextMenuItemsParams,
  clearCellValue: (params: GetContextMenuItemsParams) => () => void,
  copyValueToVisibleRows: (params: GetContextMenuItemsParams) => () => void,
  markAsInfinitelyAvailable: (params: GetContextMenuItemsParams) => () => void
) => {
  const { column, defaultItems } = params;

  const contextMenuItems: (string | MenuItemDef)[] = [...(defaultItems ?? [])];
  if (
    [
      'discount.discountType',
      'discount',
      'discountPercentage',
      'storePlacement',
      'storePlacementDetail',
      'exitWeek',
      'packaway',
      'lifeSpan',
      'comment',
      'eligibleForSale',
      'discount.comment',
    ].includes(column?.getColId() ?? '')
  ) {
    contextMenuItems.push(
      ...[
        'separator',
        {
          name: 'Copy value to filtered rows',
          icon: '<span class="ag-icon ag-icon-copy" unselectable="on" role="presentation"></span>',
          action: copyValueToVisibleRows(params),
        },
      ]
    );
  }

  if (['exitWeek', 'lifeSpan'].includes(column?.getColId() ?? '')) {
    contextMenuItems.push({
      name: 'Mark as indefinitely available',
      icon: '<svg class="ag-icon" unselectable="on" role="presentation" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 241.1C0 161 65 96 145.1 96c38.5 0 75.4 15.3 102.6 42.5L320 210.7l72.2-72.2C419.5 111.3 456.4 96 494.9 96C575 96 640 161 640 241.1v29.7C640 351 575 416 494.9 416c-38.5 0-75.4-15.3-102.6-42.5L320 301.3l-72.2 72.2C220.5 400.7 183.6 416 145.1 416C65 416 0 351 0 270.9V241.1zM274.7 256l-72.2-72.2c-15.2-15.2-35.9-23.8-57.4-23.8C100.3 160 64 196.3 64 241.1v29.7c0 44.8 36.3 81.1 81.1 81.1c21.5 0 42.2-8.5 57.4-23.8L274.7 256zm90.5 0l72.2 72.2c15.2 15.2 35.9 23.8 57.4 23.8c44.8 0 81.1-36.3 81.1-81.1V241.1c0-44.8-36.3-81.1-81.1-81.1c-21.5 0-42.2 8.5-57.4 23.8L365.3 256z"/></svg>',
      action: markAsInfinitelyAvailable(params),
    });
  }

  contextMenuItems.push({
    name: 'Clear',
    icon: '<svg class="ag-icon" unselectable="on" role="presentation" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"/></svg>',
    action: clearCellValue(params),
  });

  return contextMenuItems;
};
