import { useMemo } from 'react';
import { Module } from 'src/domain';
import { useStyleTimelineQuery } from 'src/hooks/style-timeline/queries/useStyleTimelineQuery';
import { useModulePlanningRange } from 'src/hooks/useModulePlanningRange';
import {
  HeatMapTypeEnum,
  mapStyleTimelineDataToProjectedOptionCountHeatMapRows,
} from 'src/mapping/projected-option-count.mapping';

export const useProjectedOptionCountHeatMapRows = (type: HeatMapTypeEnum) => {
  const { styleTimelines, styleCategories, loading, error } = useStyleTimelineQuery();
  const planningRange = useModulePlanningRange(Module.ProjectedOptionCount);

  const { rows, columns } = useMemo(() => {
    if (!styleTimelines || !styleCategories) return { rows: [], columns: [] };
    return mapStyleTimelineDataToProjectedOptionCountHeatMapRows({
      styleTimelineData: styleTimelines,
      styleCategories,
      type,
      planningRange,
    });
  }, [styleTimelines, styleCategories, type, planningRange]);

  return { data: rows, columns, loading, error };
};
