import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { FC, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { PlanningLevelItemsType } from 'src/domain/models/split-modules';
import { MaybeNumberArray } from 'src/domain/types/maybe-types';

interface Props {
  items: MaybeNumberArray;
  planningLevelList: PlanningLevelItemsType | undefined;
}

export const BuyingEventPlannedItems: FC<Props> = ({ items, planningLevelList }) => {
  const itemNamesList = useMemo(() => {
    if (items?.length === planningLevelList?.length) return ['All'];
    return planningLevelList?.reduce<string[]>((acc, list) => {
      if (items?.includes(list?.id) && list?.name) {
        acc?.push(list?.name);
      }
      return acc;
    }, []);
  }, [items, planningLevelList]);

  const [hiddenCount, setHiddenCount] = useState(0);
  const shownTags = hiddenCount === 0 ? itemNamesList : itemNamesList?.slice(0, -hiddenCount);
  const hiddenTags =
    hiddenCount === 0 ? [] : itemNamesList?.slice(itemNamesList.length - hiddenCount, itemNamesList.length);
  const ref = useRef<HTMLDivElement>(null);

  // ! FIXME: The useLayoutEffect without a dependency array seems like a hacky way to handle this, but it works, so disabling the eslint rule for now.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    if (ref.current.scrollWidth > ref.current.clientWidth) {
      setHiddenCount((count) => count + 1);
    }
  });

  return (
    <PlannedItemsContainer ref={ref}>
      {shownTags?.map((tag) => <Tag key={`tag-${tag}`}>{tag}</Tag>)}
      {hiddenCount > 0 && (
        <Tooltip title={hiddenTags?.join(', ')} placement={'top-end'}>
          <Tag>{`+${hiddenCount}`}</Tag>
        </Tooltip>
      )}
    </PlannedItemsContainer>
  );
};

const Tag = styled.span`
  border: 1px solid #787c8d;
  border-radius: 3px;
  padding: 5px 10px;
  width: fit-content;
  color: #787c8d;
  margin-left: 2px;
  white-space: nowrap;
`;

const PlannedItemsContainer = styled.div`
  display: flex;
  margin-right: 20px;
  overflow: hidden;
`;
