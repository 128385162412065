import { useState } from 'react';
import { CreateClusterDialog } from 'src/components/organisms/CreateClusterDialog/CreateClusterDialog';

export const useCreateClusterDialog = (callbacks: {
  onOk?: (name: string, description?: string) => void;
  onCancel?: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const showDialog = () => {
    setOpen(true);
  };

  const onOk = (name: string, description?: string) => {
    callbacks.onOk?.(name, description);
    setOpen(false);
  };
  const onCancel = () => {
    callbacks.onCancel?.();
    setOpen(false);
  };

  const makeDialog = () => <CreateClusterDialog open={open} onOk={onOk} onCancel={onCancel} />;

  return [showDialog, makeDialog] as const;
};
