import { useCallback, useState } from 'react';
import { useChainId } from 'src/hooks/chain';
import { useSnackbar } from 'src/hooks/snackbar/useSnackbar';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';

interface Props {
  id: number | undefined | null;
  name: string;
  initialDeliveryRangeStart: number;
  initialDeliveryRangeEnd: number;
  orderMonth: number;
  plannedOnIds: number[];
}

export const useUpdateBuyingEvent = ({
  id,
  name,
  initialDeliveryRangeStart,
  initialDeliveryRangeEnd,
  orderMonth,
  plannedOnIds,
}: Props) => {
  const chainId = useChainId();

  const [updateBuyingEvent] = useApiMutation('/api/buyingevents', 'put', {
    update(data, _variables, queryClient) {
      const path = { chaidId: chainId };
      queryClient.setQueryData(['/api/buyingevents/chains/{chainId}', path], data);
    },
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const showSnackbar = useSnackbar();

  const updatingBuyingEvent = useCallback(async () => {
    if (id === undefined || id === null) return;
    setIsUpdating(true);

    try {
      const response = await updateBuyingEvent({
        body: {
          id,
          buyingStart: initialDeliveryRangeStart,
          buyingEnd: initialDeliveryRangeEnd,
          name,
          orderMonth,
          plannedOnIds,
        },
      });
      showSnackbar(`Buying event '${name}' has been updated`, 'success');
      return response;
    } catch (err) {
      showSnackbar('Failed to update buying event', 'error');
    } finally {
      setIsUpdating(false);
    }
  }, [
    updateBuyingEvent,
    id,
    initialDeliveryRangeStart,
    initialDeliveryRangeEnd,
    name,
    orderMonth,
    plannedOnIds,
    showSnackbar,
  ]);

  return [updatingBuyingEvent, { loading: isUpdating }] as const;
};
