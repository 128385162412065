import { ColDef, ValueFormatterParams, ValueGetterParams, ValueSetterParams } from 'ag-grid-community';
import { DropdownCellEditor, DropdownCellRenderer } from 'src/components/molecules/DropdownCell/DropdownCell';
import { InheritanceSettingsWarningRenderer } from 'src/components/views/InheritanceSettings/InheritanceSettingsWarningRenderer';
import { Inheritance, InheritanceModule } from 'src/domain';
import {
  FCCInitial,
  FCCRevised,
  Historical,
  HistoricalLLY,
  NOOS_DDR,
  NOOS_HISTORICAL,
  NOOS_HISTORICAL_ORDERS,
  Typed,
} from 'src/utils/inheritance-options';
import { DefaultColumn } from './default-columns-details';

export function storeInheritanceFormatter(cell: ValueFormatterParams) {
  const clusterName = cell.data?.clusterName;
  const countryName = cell.data?.countryName;

  switch (cell.value) {
    case Inheritance.Typed:
      return `Store (${Typed})`;

    case Inheritance.Historical:
      if (cell.column?.getColId() === 'noosShare') return `${NOOS_HISTORICAL}`;
      return `Store (${Historical})`;

    case Inheritance.Cluster:
      return `Cluster ${clusterName}`;

    case Inheritance.Partner:
      return `${countryName}`;

    case Inheritance.FccInitial:
      return FCCInitial;

    case Inheritance.FccRevised:
      return FCCRevised;

    case Inheritance.HistoricalLLY:
      return `Store (${HistoricalLLY})`;

    case Inheritance.NoosDDR:
      return NOOS_DDR;

    case Inheritance.NoosHistoricalOrders:
      return NOOS_HISTORICAL_ORDERS;

    default:
      return '';
  }
}

const EditableDropdownColumn: ColDef = {
  editable: true,
  minWidth: 200,
  valueFormatter: storeInheritanceFormatter,
  cellEditor: DropdownCellEditor,
  cellRenderer: DropdownCellRenderer,
  cellRendererParams: {
    warningRenderer: InheritanceSettingsWarningRenderer,
  },
  singleClickEdit: true,
  cellStyle: {
    justifyContent: 'flex-start',
  },
  cellClass: 'inheritance-settings-cell',
};

export const InheritanceSettingsColumn: ColDef[] = [
  {
    ...DefaultColumn,
    field: 'storeName',
    minWidth: 250,
    headerName: '',
    suppressSizeToFit: true,
  },
  {
    ...EditableDropdownColumn,
    field: InheritanceModule.SalesBudget,
    headerName: 'Sales Budget',
  },
  {
    ...EditableDropdownColumn,
    field: InheritanceModule.CategorySplit,
    headerName: 'Category Split',
  },
  {
    ...EditableDropdownColumn,
    field: InheritanceModule.GenderSplit,
    headerName: 'Gender Split',
  },
  {
    ...EditableDropdownColumn,
    field: InheritanceModule.ProductLineSplit,
    headerName: 'Productline Split',
  },
  {
    ...EditableDropdownColumn,
    field: InheritanceModule.NoosShare,
    headerName: 'Noos Share',
    valueGetter(params: ValueGetterParams) {
      if (params.data.noosShare.inheritance === Inheritance.Historical) {
        return params.data.noosShare.noosActualInheritance;
      }
      return params.data.noosShare.inheritance;
    },
    valueSetter(params: ValueSetterParams) {
      switch (params.newValue) {
        case Inheritance.Typed:
        case Inheritance.Cluster:
        case Inheritance.Partner:
          // update inheritance
          params.data.noosShare = {
            inheritance: params.newValue,
            noosActualInheritance: params.data.noosShare.noosActualInheritance,
          };
          return true;
        case Inheritance.Historical:
        case Inheritance.NoosDDR:
        case Inheritance.NoosHistoricalOrders:
          // update noos actual inheritance
          params.data.noosShare = {
            inheritance: Inheritance.Historical,
            noosActualInheritance: params.newValue,
          };
          return true;
        default:
          return false;
      }
    },
  },
  {
    ...EditableDropdownColumn,
    field: InheritanceModule.Markup,
    headerName: 'Buying Markup',
  },
  {
    ...EditableDropdownColumn,
    field: InheritanceModule.WeekCover,
    headerName: 'Week Cover',
  },
  {
    ...EditableDropdownColumn,
    field: InheritanceModule.DeliveryProfile,
    headerName: 'Delivery Profile',
  },
  {
    ...EditableDropdownColumn,
    field: InheritanceModule.Markdown,
    headerName: 'Markdown',
  },
];
