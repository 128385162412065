import { useCallback } from 'react';
import { Cell } from 'src/components/organisms/DataTable/types';
import { categoriesSum } from 'src/components/views/StockManagement/helpers/categories-sum';
import { Module } from 'src/domain';
import { UnsavedChangesEvent } from 'src/domain/events/unsaved-changes.event';
import { StockManagementRow } from 'src/domain/table/stock-management.row';
import { useStoreId } from '../store';
import { useUnsavedChangesModule } from '../unsaved-changes';
import { useStockManagementOverviewSimulationRows } from './simulation/useStockManagementOverviewSimulationRows';
import { useStockManagementTableKey } from './table/useStockManagementTableKey';

export const useHandleCellValueChanges = () => {
  const { data } = useStockManagementOverviewSimulationRows();

  const [unsavedChanges, setUnsavedChanges] = useUnsavedChangesModule<Record<string, StockManagementRow[]>>(
    Module.StockManagement
  );

  const storeId = useStoreId();
  const tableKey = useStockManagementTableKey();

  const callback = useCallback(
    (cellValues: Cell[]) => {
      const stockManagement = data?.isEmpty() ? null : data;

      if (!stockManagement) throw new Error(`Current data is missing: ${stockManagement}`);
      if (!storeId) throw new Error(`Store ID is missing: ${storeId}`);

      cellValues.forEach((cellValue) => {
        const rowIndexToUpdate = stockManagement.findIndex((x) => x.id === cellValue.rowId);
        const fieldName = cellValue.column?.split('.')?.[1];

        if (fieldName) {
          stockManagement[rowIndexToUpdate] = {
            ...stockManagement?.[rowIndexToUpdate],
            columns: {
              ...stockManagement?.[rowIndexToUpdate].columns,
              [fieldName]: cellValue.value ?? null,
            },
          };
        }
      });
      let stockManagementCopy = [...stockManagement];

      if (tableKey === 'categories-table') {
        const sum = categoriesSum(stockManagement);
        stockManagementCopy = stockManagementCopy.filter((element) => element.category !== 'Total');
        stockManagementCopy.push(...sum);
      }

      const unsaved = { ...unsavedChanges, ...{ [tableKey]: [...stockManagementCopy] } };

      setUnsavedChanges(unsaved);

      dispatchEvent(new Event(UnsavedChangesEvent));
    },
    [data, setUnsavedChanges, storeId, tableKey, unsavedChanges]
  );

  return callback;
};
