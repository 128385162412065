import {
  ColDef,
  IRowNode,
  ITooltipParams,
  TooltipRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams,
} from 'ag-grid-community';
import { addWeeks, differenceInWeeks, startOfWeek } from 'date-fns';
import { isNumber, startCase, toLower } from 'lodash';
import { GroupedOption } from 'src/components/atoms/Select/Select';
import { EditableValueCell } from 'src/components/molecules/EditableCell/EditableCell';
import { ImageCellRenderer } from 'src/components/molecules/ImageCellRenderer/ImageCellRenderer';
import { LifeSpanFilter } from 'src/components/molecules/LifeSpanFilter/LifeSpanFilter';
import { ListCellRenderer } from 'src/components/molecules/ListCellRenderer/ListCellRenderer';
import { MonthFilter } from 'src/components/molecules/MonthFilter/MonthFilter';
import { RatingCell } from 'src/components/molecules/RatingCell/RatingCell';
import { RatingFilter } from 'src/components/molecules/RatingFilter/RatingFilter';
import { SelectCellEditor } from 'src/components/molecules/SelectCellEditor/SelectCellEditor';
import { StoreCountTooltip } from 'src/components/molecules/StoreCountTooltip/StoreCountTooltip';
import { WeekFilter } from 'src/components/molecules/WeekFilter/WeekFilter';
import {
  WeekPickerCellEditor,
  WeekRangePickerCellEditor,
} from 'src/components/molecules/WeekPickerCellEditor/WeekPickerCellEditor';
import { infinitySymbol } from 'src/domain/models/infinity-symbol';
import { StyleTimelineStorePlacementDetailEnum } from 'src/infrastructure/rest-api/api-types';

import { PartnerStyleTimelineModel, StyleTimelineExitWeekType } from 'src/infrastructure/rest-api/style-timeline';
import { formatMonthYearAsHumanReadable, formatWeekYearToMonthYear } from 'src/utils/formatAsMonthYear';
import { formatDateAsWeekYear, formatWeekYearAsDate, formatWeekYearAsHumanReadable } from 'src/utils/formatAsWeekYear';
import { formatNumber } from 'src/utils/formatNumber';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';

export interface ExitWeekValue {
  exitWeek?: number;
  lifeSpan?: number;
  exitWeekType: StyleTimelineExitWeekType;
}

export const defaultColDef: ColDef = {
  flex: 0,
  resizable: true,
  sortable: true,
  filter: 'agTextColumnFilter',
  menuTabs: ['filterMenuTab', 'generalMenuTab'],
  tooltipValueGetter: (params) => {
    return params.valueFormatted ?? params.value;
  },
  enablePivot: false,
  enableValue: false,
};

export const columns: ColDef[] = [
  {
    field: 'styleOptionPicture',
    headerName: 'Picture',
    cellRenderer: ImageCellRenderer,
    tooltipValueGetter: () => '',
    suppressFiltersToolPanel: true,
    suppressHeaderMenuButton: true,
    initialWidth: 80,
  },
  {
    field: 'businessModel',
    headerName: 'Business model',
    cellStyle: {
      justifyContent: 'flex-start',
    },
    hide: true,
    filter: 'agSetColumnFilter',
  },
  {
    field: 'businessModelGroup',
    headerName: 'Business model group',
    cellStyle: {
      justifyContent: 'flex-start',
    },
    hide: true,
    filter: 'agSetColumnFilter',
  },
  {
    field: 'stockAgeBucket',
    headerName: 'Stock age bucket',
    cellStyle: {
      justifyContent: 'flex-start',
    },
    hide: true,
    filter: 'agSetColumnFilter',
  },
  {
    field: 'collections',
    headerName: 'Collections',
    hide: true,
    filter: 'agSetColumnFilter',
    cellRenderer: ListCellRenderer,
    tooltipValueGetter: () => '',
    cellRendererParams: {
      onlyShowMatchesWhenFiltering: true,
    },
    valueFormatter: ({ value }: ValueFormatterParams) => {
      if (!Array.isArray(value)) {
        return value;
      }
      return value?.join(', ');
    },
  },
  {
    field: 'exitWeek',
    headerName: 'Exit week',
    editable: true,
    minWidth: 145,
    width: 145,
    valueFormatter: weekNumberFormatter,
    cellRenderer: EditableValueCell,
    cellStyle: {
      justifyContent: 'flex-start',
    },
    valueGetter: ({ data, node }) => {
      if (node?.group || !data) {
        return null;
      }

      return {
        exitWeek: data.exitWeek,
        exitWeekType: data.exitWeekType,
      };
    },
    valueSetter: ({ data, newValue }) => {
      // Prevent onCellValueChanged when using custom fill/clear operations.
      // newValue is undefined for fill drag, and null for clearing with backspace/delete.
      if (!newValue) return false;

      const newValueAsNumber = typeof newValue === 'object' ? newValue.exitWeek : Number(newValue);
      const newExitWeekType = typeof newValue === 'object' ? newValue.exitWeekType : data.exitWeekType;

      if (!newValueAsNumber || isNaN(newValueAsNumber)) {
        data.exitWeek = null;
        data.exitWeekType = ['INFINITE', 'EMPTY'].includes(newExitWeekType) ? newExitWeekType : 'EMPTY';
        data.lifeSpan = null;
        return true;
      }

      data.exitWeek = newValueAsNumber;
      data.exitWeekType = 'WEEK_KEY';
      data.lifeSpan = exitWeekToLifeSpan(data.startWeek, newValue);
      return true;
    },
    comparator: exitWeekComparator,
    filter: WeekFilter,
    filterParams: {
      supportsInfinite: true,
    },
    cellEditor: WeekPickerCellEditor,
    cellEditorParams: {
      formatValue: (value: string) => {
        return weekNumberFormatter({ value } as ValueFormatterParams);
      },
    },
  },
  {
    field: 'normQuantity',
    headerName: 'Norm',
    hide: true,
    comparator: numberComparator,
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'spsWeek',
    headerName: 'SPS pr. week',
    hide: true,
    comparator: numberComparator,
    filter: 'agSetColumnFilter',
  },
  {
    field: 'startWeek',
    headerName: 'Start week',
    valueFormatter: weekNumberFormatter,
    cellStyle: {
      justifyContent: 'flex-start',
    },
    filter: WeekFilter,
  },
  {
    field: 'startMonth',
    headerName: 'Start month',
    hide: true,
    cellStyle: {
      justifyContent: 'flex-start',
    },
    comparator: startMonthComparator,
    valueGetter: ({ data }) => {
      if (!data) return '';
      return Number(formatWeekYearToMonthYear(data.startWeek));
    },
    valueFormatter: (params) => {
      if (!params.value) return '';
      return formatMonthYearAsHumanReadable(params.value);
    },
    filter: MonthFilter,
  },
  {
    field: 'stockQuantity',
    headerName: 'Stock QTY',
    width: 130,
    hide: true,
    comparator: numberComparator,
    filter: 'agNumberColumnFilter',
    valueFormatter: (cell: ValueFormatterParams) => {
      if (!cell.value || isNaN(cell.value)) return cell.value;
      return numberWithSpaces(cell.value);
    },
  },
  {
    field: 'stockValueLcy',
    headerName: 'Stock value',
    width: 130,
    hide: true,
    comparator: numberComparator,
    filter: 'agNumberColumnFilter',
    valueFormatter: (cell: ValueFormatterParams) => {
      if (!cell.value || isNaN(cell.value)) return cell.value;
      return numberWithSpaces(cell.value);
    },
  },
  {
    field: 'stockAgeWeeks',
    headerName: 'Stock age',
    width: 130,
    hide: true,
    comparator: numberComparator,
    filter: 'agNumberColumnFilter',
    valueFormatter: (cell: ValueFormatterParams) => {
      if (!cell.value || isNaN(cell.value)) return cell.value;
      return `${numberWithSpaces(cell.value)} weeks`;
    },
  },
  {
    field: 'salesQuantityPast8Weeks',
    headerName: 'Sales QTY P8W',
    hide: true,
    cellStyle: {
      justifyContent: 'flex-end',
      paddingLeft: 45,
    },
    filter: 'agTextColumnFilter',
  },
  {
    field: 'styleCategory',
    enableRowGroup: true,
    rowGroup: true,
    rowGroupIndex: 0,
    hide: true,
    headerName: 'Style category',
    filter: 'agSetColumnFilter',
    valueGetter: ({ data }) => data?.styleCategory.name,
  },
  {
    field: 'styleSubCategoryName',
    hide: true,
    headerName: 'Style sub category',
    filter: 'agSetColumnFilter',
    cellStyle: {
      justifyContent: 'flex-start',
    },
  },
  {
    field: 'styleName',
    headerName: 'Style name',
    cellStyle: {
      justifyContent: 'flex-start',
      fontWeight: 'normal',
    },
    flex: 5,
    minWidth: 300,
    filter: 'agTextColumnFilter',
  },
  {
    field: 'styleNumber',
    headerName: 'Style number',
    cellStyle: {
      justifyContent: 'flex-end',
      paddingLeft: 45,
    },
    filter: 'agTextColumnFilter',
    sort: 'asc',
  },
  {
    field: 'openOrders',
    headerName: 'Open order QTY',
    hide: true,
    filter: 'agTextColumnFilter',
    suppressFiltersToolPanel: true,
  },
  {
    field: 'grossProfitPast8WeeksLcy',
    headerName: 'Gross Profit P8W',
    suppressFiltersToolPanel: true,
    valueFormatter: (cell: ValueFormatterParams) => {
      if (isNaN(cell.value) || !cell.value) return cell.value;
      return numberWithSpaces(Math.round(cell.value));
    },
  },
  {
    field: 'grossProfitRating',
    headerName: 'Gross Profit Rating',
    hide: true,
    comparator: ratingSorting('grossProfitRanking'),
    filter: RatingFilter,
    suppressFiltersToolPanel: true,
    cellRenderer: RatingCell,
    tooltipValueGetter: ({ data }) => {
      if (!data.grossProfitRanking || !data.maxGrossProfitRanking) return null;
      return `Rank ${data.grossProfitRanking} of ${data.maxGrossProfitRanking}`;
    },
  },
  {
    field: 'grossMarginRating',
    headerName: 'Gross Margin Rating',
    hide: true,
    comparator: ratingSorting('grossMarginRanking'),
    filter: RatingFilter,
    suppressFiltersToolPanel: true,
    cellRenderer: RatingCell,
    tooltipValueGetter: ({ data }) => {
      if (!data.grossMarginRanking || !data.maxGrossMarginRanking) return null;
      return `Rank ${data.grossMarginRanking} of ${data.maxGrossMarginRanking}`;
    },
  },
  {
    field: 'grossMarginPast8WeeksLcy',
    headerName: 'Gross Margin P8W',
    hide: true,
    filter: 'agTextColumnFilter',
    suppressFiltersToolPanel: true,
    valueFormatter: (cell: ValueFormatterParams) => {
      if (isNaN(cell.value) || !cell.value) return cell.value;
      return `${formatNumber(cell.value, 1)}%`;
    },
  },
  {
    field: 'relativeGrossProfitPast8WeeksLcy',
    headerName: 'Relative Gross Profit P8W',
    hide: true,
    filter: 'agTextColumnFilter',
    suppressFiltersToolPanel: true,
    valueFormatter: (cell: ValueFormatterParams) => {
      if (isNaN(cell.value) || !cell.value) return cell.value;
      return `${formatNumber(cell.value, 1)}%`;
    },
  },
  {
    field: 'colorName',
    headerName: 'Color',
    cellStyle: {
      justifyContent: 'flex-start',
    },
    suppressFiltersToolPanel: true,
  },
  {
    field: 'packaway',
    headerName: 'Packaway',
    suppressSizeToFit: true,
    editable: true,
    minWidth: 200,
    valueFormatter: weekRangeNumberFormatter,
    valueParser: weekRangeNumberParser,
    cellRenderer: EditableValueCell,
    suppressFiltersToolPanel: true,
    cellEditor: WeekRangePickerCellEditor,
    cellStyle: {
      justifyContent: 'flex-start',
    },
    hide: true,
    filter: 'agSetColumnFilter',
  },
  {
    field: 'lifeSpan',
    headerName: 'Life span',
    hide: true,
    editable: true,
    filter: LifeSpanFilter,
    comparator: exitWeekComparator,
    valueFormatter: lifeSpanFormatter,
    cellRenderer: EditableValueCell,
    cellStyle: {
      justifyContent: 'flex-start',
    },
    type: 'numericColumn',
    valueGetter({ data }) {
      if (!data) {
        return null;
      }

      return {
        lifeSpan: data.lifeSpan,
        exitWeekType: data.exitWeekType,
      };
    },
    valueSetter: ({ data, newValue }) => {
      // Prevent onCellValueChanged when using custom fill/clear operations.
      // newValue is undefined for fill drag, and null for clearing with backspace/delete.
      if (!newValue) return false;

      const newValueAsNumber = typeof newValue === 'object' ? newValue.lifeSpan : Number(newValue);
      const newExitWeekType = typeof newValue === 'object' ? newValue.exitWeekType : data.exitWeekType;

      if (!newValueAsNumber || isNaN(newValueAsNumber)) {
        data.exitWeek = null;
        data.exitWeekType = ['INFINITE', 'EMPTY'].includes(newExitWeekType) ? newExitWeekType : 'EMPTY';
        data.lifeSpan = null;
        return true;
      }

      data.exitWeek = lifeSpanToExitWeek(data.startWeek, newValueAsNumber);
      data.exitWeekType = 'WEEK_KEY';
      data.lifeSpan = newValueAsNumber;
      return true;
    },
  },
  {
    field: 'productLineGroup',
    headerName: 'Product Line Group',
    hide: true,
    filter: 'agSetColumnFilter',
  },
  {
    field: 'comment',
    headerName: 'Comment',
    hide: true,
    editable: true,
    filter: 'agTextColumnFilter',
    cellRenderer: EditableValueCell,
    cellStyle: {
      justifyContent: 'flex-start',
    },
  },
  {
    field: 'eligibleForSale',
    headerName: 'Eligible For Sale',
    hide: true,
    editable: true,
    filter: 'agGridCheckbox',
    cellStyle: {
      justifyContent: 'flex-end',
    },
    valueSetter: ({ data, newValue }) => {
      // ensure new value is always boolean
      data.eligibleForSale = !!newValue;
      return true;
    },
  },
  {
    headerName: 'Store placement',
    field: 'storePlacement',
    editable: true,
    filter: 'agSetColumnFilter',
    minWidth: 150,
    width: 150,
    valueFormatter: storePlacementFormatter,
    valueParser: storePlacementParser,
    cellRenderer: EditableValueCell,
    cellStyle: {
      justifyContent: 'flex-start',
    },
    valueGetter: ({ data }) => {
      if (!data?.storePlacement) return null;

      if (!data.storePlacementDetail) {
        return data.storePlacement;
      }

      return `${data.storePlacement}-${data.storePlacementDetail}`;
    },
    valueSetter: ({ data, newValue }) => {
      if (!newValue) {
        data.storePlacement = null;
        data.storePlacementDetail = null;
        return true;
      }

      const [placement, detail] = newValue.split('-');
      data.storePlacement = placement;
      data.storePlacementDetail = detail;

      return true;
    },
    suppressFiltersToolPanel: true,
    cellEditor: SelectCellEditor,
    cellEditorParams: {
      isHeaderSelectable: true,
      selectOptions: [
        ...createOptionsWithDetails([
          {
            value: 'FRONT',
            display: 'Front',
            isHeader: true,
          },
          {
            value: 'MID',
            display: 'Mid',
            isHeader: true,
          },
          {
            value: 'BACK',
            display: 'Back',
            isHeader: true,
          },
        ] as GroupedOption[]),
        {
          value: 'STOCK_ROOM',
          display: 'Stock room',
          isHeader: true,
        },
      ],
      formatValue: (value: string) => {
        return storePlacementFormatter({ value } as ValueFormatterParams);
      },
    },
  },
  {
    field: 'storeNames',
    headerName: 'Store count',
    headerTooltip: 'Number of stores with open sales orders and/or stock',
    hide: true,
    filter: 'agNumberColumnFilter',
    tooltipComponent: StoreCountTooltip,
    tooltipValueGetter: ({ data }: ITooltipParams) => {
      if (!data || !data.storeNames || data.storeNames.length === 0) {
        return '';
      }
      return data.storeNames;
    },
    valueGetter: ({ data }: ValueGetterParams) => {
      if (!data?.storeNames) return null;
      return data.storeNames?.length ?? 0;
    },
  },
  {
    field: 'variantName',
    headerName: 'Variant',
    cellStyle: {
      justifyContent: 'flex-start',
    },
    hide: true,
    suppressFiltersToolPanel: true,
  },
  {
    field: 'markDownPct',
    headerName: 'Markdown %',
    width: 130,
    hide: true,
    comparator: numberComparator,
    filter: 'agSetColumnFilter',
    suppressFiltersToolPanel: true,
    valueFormatter: (cell: ValueFormatterParams) => {
      if (isNaN(cell.value) || !cell.value) return cell.value;
      return `${formatNumber(cell.value, 1)}%`;
    },
  },
  {
    field: 'markdownRating',
    headerName: 'Markdown Rating',
    hide: true,
    comparator: ratingSorting('markDownRanking'),
    filter: RatingFilter,
    suppressFiltersToolPanel: true,
    cellRenderer: RatingCell,
    tooltipValueGetter: ({ data }) => {
      if (!data.markDownRanking || !data.maxMarkdownRanking) return null;
      return `Rank ${data.markDownRanking} of ${data.maxMarkdownRanking}`;
    },
  },
  {
    field: 'activeWeeks',
    headerName: 'Active weeks',
    hide: true,
    comparator: numberComparator,
    filter: 'agSetColumnFilter',
    suppressFiltersToolPanel: true,
  },
  {
    field: 'sps',
    headerName: 'Sold pr. store',
    hide: true,
    comparator: numberComparator,
    filter: 'agSetColumnFilter',
    suppressFiltersToolPanel: true,
  },
  {
    field: 'spsWeekRating',
    headerName: 'SPS pr. week rating',
    hide: true,
    filter: RatingFilter,
    suppressFiltersToolPanel: true,
    comparator: ratingSorting('spsWeekRanking'),
    cellRenderer: RatingCell,
    tooltipValueGetter: ({ data }) => {
      if (!data.spsWeekRanking || !data.maxSpsWeekRanking) return null;
      return `Rank ${data.spsWeekRanking} of ${data.maxSpsWeekRanking}`;
    },
  },
  {
    field: 'recommendedRetailPriceLcy',
    headerName: 'RRP',
    hide: true,
    comparator: numberComparator,
    filter: 'agSetColumnFilter',
    suppressFiltersToolPanel: true,
    width: 80,
  },
  {
    field: 'avgNormQuantity',
    headerName: 'Avg. Norm pr. Store',
    hide: true,
    comparator: numberComparator,
    filter: 'agNumberColumnFilter',
    suppressFiltersToolPanel: true,
  },
  {
    field: 'salesIvPast8WeeksLcy',
    headerName: 'Sales iV P8W',
    hide: true,
    comparator: numberComparator,
    filter: 'agNumberColumnFilter',
    suppressFiltersToolPanel: true,
    valueFormatter: (cell: ValueFormatterParams) => {
      if (isNaN(cell.value)) return cell.value;
      return numberWithSpaces(cell.value);
    },
  },
  {
    field: 'salesP8WRating',
    headerName: 'Sales iV P8W rating',
    hide: true,
    filter: RatingFilter,
    suppressFiltersToolPanel: true,
    comparator: ratingSorting('salesIvPast8WeeksRanking'),
    cellRenderer: RatingCell,
    tooltipValueGetter: ({ data }) => {
      if (!data.salesIvPast8WeeksRanking || !data.maxSalesP8WRanking) return null;
      return `Rank ${data.salesIvPast8WeeksRanking} of ${data.maxSalesP8WRanking}`;
    },
  },
  {
    field: 'salesIvPast8WeeksLcySparkline',
    headerName: 'Sales iV P8W Sparkline',
    cellRenderer: 'agSparklineCellRenderer',
    cellRendererParams: {
      sparklineOptions: {
        tooltip: {
          renderer: (params: TooltipRendererParams) => {
            const xValue = parseInt(params.xValue) - 8;
            if (xValue === -1) {
              return {
                title: isNumber(xValue) ? `Sales iV ${Math.abs(xValue)} week ago:` : `Sales iV ? week ago:`,
                content: params.yValue,
              };
            }
            return {
              title: isNumber(xValue) ? `Sales iV ${Math.abs(xValue)} weeks ago:` : `Sales iV ? weeks ago:`,
              content: params.yValue,
            };
          },
        },
      },
    },
    tooltipValueGetter: () => {
      return null;
    },
    hide: true,
    suppressFiltersToolPanel: true,
  },
  {
    field: 'styleCategorySharePctPast8Weeks',
    headerName: 'Relative Sales iV P8W',
    hide: true,
    comparator: numberComparator,
    filter: 'agNumberColumnFilter',
    suppressFiltersToolPanel: true,
    valueFormatter: (cell: ValueFormatterParams) => {
      if (isNaN(cell.value) || !cell.value) return cell.value;
      return `${formatNumber(cell.value, 1)}%`;
    },
  },
  {
    field: 'replenishmentEndOfLife',
    headerName: 'End of life',
    hide: true,
    valueFormatter: (cell: ValueFormatterParams) => {
      if (!cell.value) return '';
      return formatWeekYearAsHumanReadable(cell.value);
    },
    cellStyle: {
      justifyContent: 'flex-start',
    },
    filter: WeekFilter,
    headerTooltip: 'Date from Bestplan, when style can no longer be replenished',
  },
  {
    field: 'weekCover',
    headerName: 'Week cover',
    hide: true,
    filter: 'agNumberColumnFilter',
    valueFormatter: (cell: ValueFormatterParams) => {
      if (!cell.value) return '';
      return parseFloat(cell.value).toFixed(1).replace('.', ',');
    },
  },
  {
    field: 'stylePriority',
    headerName: 'Style priority',
    suppressSizeToFit: true,
    editable: true,
    minWidth: 200,
    cellRenderer: EditableValueCell,
    suppressFiltersToolPanel: true,
    cellStyle: {
      justifyContent: 'flex-start',
    },
    cellEditor: SelectCellEditor,
    cellEditorParams: {
      selectOptions: [
        {
          value: '',
          display: 'None',
          isHeader: false,
        },
        {
          value: 'A',
          display: 'A',
          isHeader: false,
        },
        {
          value: 'B',
          display: 'B',
          isHeader: false,
        },
        {
          value: 'C',
          display: 'C',
          isHeader: false,
        },
      ],
    },
    hide: true,
    filter: 'agSetColumnFilter',
  },
  {
    field: 'genderName',
    hide: true,
    headerName: 'Gender',
    cellStyle: {
      justifyContent: 'flex-start',
      fontWeight: 'normal',
    },
    filter: 'agSetColumnFilter',
  },
  {
    field: 'productLineName',
    hide: true,
    headerName: 'Product line',
    cellStyle: {
      justifyContent: 'flex-start',
      fontWeight: 'normal',
    },
    minWidth: 300,
    filter: 'agTextColumnFilter',
  },
];

export const globalColumns: ColDef[] = [
  { field: 'partner', headerName: 'Partner', filter: 'agTextColumnFilter', pinned: 'left' },
  ...columns.map((column) => {
    switch (column.field) {
      case 'styleCategory':
        return {
          ...column,
          enableRowGroup: true,
          rowGroup: true,
          hide: true,
        };
      case 'styleName':
        return {
          ...column,
          enableRowGroup: true,
          rowGroup: true,
          hide: true,
        };
    }
    return column;
  }),
];

export function weekNumberFormatter(cell: ValueFormatterParams) {
  const weekNumber = typeof cell.value === 'number' ? cell.value : cell.value?.exitWeek;
  if (!weekNumber) {
    return exitWeekTypeFormatter(cell);
  }
  return formatWeekYearAsHumanReadable(weekNumber);
}

export function exitWeekTypeFormatter(cell: ValueFormatterParams) {
  if (!cell.value) {
    return '';
  }

  if (!cell.value?.exitWeek) {
    if (cell.value.exitWeekType === 'EMPTY') {
      return '';
    }
    return infinitySymbol;
  }

  return cell.value?.exitWeek;
}

export function weekYearStringFormatter(week: string, year: string) {
  return `W${week.replace(/^0/, '')}, ${year}`;
}

export function weekRangeNumberFormatter(cell: ValueFormatterParams): string {
  if (!cell.value) {
    return cell.value;
  }

  const [startRange, endRange] = cell.value.toString().split('-');

  const startRangeYear = startRange.slice(0, 4);
  const startRangeWeek = startRange.slice(4, 6);

  const endRangeYear = endRange.slice(0, 4);
  const endRangeWeek = endRange.slice(4, 6);

  const formattedStartRange = weekYearStringFormatter(startRangeWeek, startRangeYear);
  const formattedEndRange = weekYearStringFormatter(endRangeWeek, endRangeYear);

  return `${formattedStartRange} - ${formattedEndRange}`;
}

export function weekRangeNumberParser(cell: ValueParserParams): string {
  if (!cell.newValue) {
    return cell.newValue;
  }

  const [startRange, endRange] = cell.newValue.split(' - ');

  const parsedStartRange = parseWeekYearString(startRange);
  const parsedEndRange = parseWeekYearString(endRange);

  return `${parsedStartRange}-${parsedEndRange}`;
}

function parseWeekYearString(weekYearString: string): string {
  const [week, year] = weekYearString.split(', ');
  return year + week.replace('W', '').padStart(2, '0');
}

export function createOptionsWithDetails(options: GroupedOption[]): GroupedOption[] {
  return options.flatMap((option) => {
    return [option].concat(
      ['LEFT', 'RIGHT'].map((detail) => ({
        value: `${option.value}-${detail}`,
        display: storePlacementDetailFormatter(detail as StyleTimelineStorePlacementDetailEnum),
        isHeader: false,
      }))
    );
  });
}

export function lifeSpanFormatter(cell: ValueFormatterParams) {
  if (!cell.value.lifeSpan) {
    return exitWeekTypeFormatter(cell);
  }
  return cell.value.lifeSpan;
}

export function storePlacementDetailFormatter(detail: StyleTimelineStorePlacementDetailEnum) {
  let formattedDetail = '';
  switch (detail) {
    case 'LEFT':
      formattedDetail = 'Left';
      break;
    case 'RIGHT':
      formattedDetail = 'Right';
      break;
  }
  return formattedDetail;
}

export function numberComparator(a: string | number, b: string | number): number {
  const aNumber = Number(a);
  const bNumber = Number(b);

  if (isNaN(aNumber)) {
    return -1;
  } else if (isNaN(bNumber)) {
    return 1;
  }
  return aNumber - bNumber;
}

export function exitWeekToLifeSpan(startWeek: number | undefined, exitWeek: number | null | undefined) {
  if (!isNumber(exitWeek) || !isNumber(startWeek)) return;

  const weeksBetween = differenceInWeeks(
    startOfWeek(formatWeekYearAsDate(exitWeek), { weekStartsOn: 1 }),
    startOfWeek(formatWeekYearAsDate(startWeek), { weekStartsOn: 1 })
  );

  return weeksBetween;
}

export function lifeSpanToExitWeek(startWeek: number | undefined, weeks: number | undefined) {
  if (!isNumber(startWeek) || !isNumber(weeks)) return;

  const startDate = formatWeekYearAsDate(startWeek);
  const endDate = addWeeks(startOfWeek(startDate, { weekStartsOn: 1 }), weeks);

  return formatDateAsWeekYear(endDate);
}

export function ratingSorting(
  field: string
): (a: string | number, b: string | number, nodeA: IRowNode, nodeB: IRowNode) => number {
  return (a, b, nodeA, nodeB) => {
    const aNumber = Number(nodeA.data?.[field]);
    const bNumber = Number(nodeB.data?.[field]);

    if (!isNumber(a)) {
      return 1;
    } else if (!isNumber(b)) {
      return -1;
    }

    return aNumber - bNumber;
  };
}

export function exitWeekComparator(
  a: ExitWeekValue,
  b: ExitWeekValue,
  nodeA: IRowNode<PartnerStyleTimelineModel>,
  nodeB: IRowNode<PartnerStyleTimelineModel>
) {
  if (nodeA.group || nodeB.group) {
    return 0;
  }

  const valueA = a.exitWeek ?? a.lifeSpan;
  const valueB = b.exitWeek ?? b.lifeSpan;

  if (!valueA && nodeA.data?.exitWeekType === 'EMPTY') {
    return -1;
  }

  if (!valueB && nodeB.data?.exitWeekType === 'EMPTY') {
    return 1;
  }

  if (nodeA.data?.exitWeekType === 'INFINITE') {
    if (nodeB.data?.exitWeekType === 'EMPTY') {
      return 1;
    }

    if (valueB) {
      return 1;
    }

    return -1;
  }

  if (nodeB.data?.exitWeekType === 'INFINITE') {
    if (nodeA.data?.exitWeekType === 'EMPTY') {
      return -1;
    }

    if (valueA) {
      return -1;
    }

    return 1;
  }

  return Number(valueA) - Number(valueB);
}

export function startMonthComparator(valueA: number, valueB: number) {
  const dateA = formatWeekYearAsDate(valueA);
  const dateB = formatWeekYearAsDate(valueB);

  if (dateA < dateB) {
    return -1;
  }
  if (dateA > dateB) {
    return 1;
  }
  return 0;
}

export function storePlacementFormatter(cell: ValueFormatterParams) {
  if (cell.value === 'STOCK_ROOM') {
    return 'Stock room';
  }

  const [placement, detail] = cell.value?.split('-') ?? [];
  const formattedPlacement = placement ? startCase(toLower(placement)) : '';
  const formattedDetail = detail ? startCase(toLower(detail)) : '';

  return `${formattedPlacement} ${formattedDetail}`.trim();
}

export function storePlacementParser(cell: ValueParserParams): string {
  if (!cell.newValue) {
    return cell.newValue;
  }

  if (cell.newValue === 'Stock room') {
    return 'STOCK_ROOM';
  }
  const [placement, detail] = cell.newValue.split(' ');

  if (!detail) {
    return placement;
  }

  return `${placement.toUpperCase()}-${detail.toUpperCase}`;
}
