import { Module } from 'src/domain';
import { InheritanceSettingsRow } from 'src/domain/table/inheritance-settings.row';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { useInheritanceSettingsRows } from '../table/useInheritanceSettingsRows';

export const useInheritanceSettingsSimulationRows = () => {
  const [unsavedChanges] = useUnsavedChangesModule<InheritanceSettingsRow[]>(Module.InheritanceSettings);
  const { data, loading, error } = useInheritanceSettingsRows() ?? {};

  return { data: unsavedChanges ?? data, loading, error };
};
