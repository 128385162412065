import styled from '@emotion/styled';
import { Divider, ListItem, ListItemText, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { DrawerItem } from 'src/components/molecules/DrawerItem/DrawerItem';
import { Route } from 'src/domain';
import { useDrawerState } from 'src/hooks';

export interface Props {
  dividerText: string;
  routes: Route[];
}

export const DrawerSection: FC<Props> = ({ dividerText, routes }) => {
  const { drawerOpen, hasSidebar } = useDrawerState();
  const url = useLocation();

  const isOpen = useMemo(() => (!hasSidebar ? true : drawerOpen), [drawerOpen, hasSidebar]);

  return (
    <>
      <StyledListItem>
        <ListItemText sx={{ width: '100%', height: '100%' }}>
          <Divider color="#B1B1B1" sx={!isOpen ? { marginTop: '11px' } : {}}>
            {isOpen && (
              <Typography variant="subtitle2" color="#7C7C7C">
                {dividerText.toUpperCase()}
              </Typography>
            )}
          </Divider>
        </ListItemText>
      </StyledListItem>
      {routes.map((x) => (
        <DrawerItem
          title={x.title}
          to={{ pathname: x.path, search: url.search }}
          icon={x.icon}
          open={isOpen}
          key={x.title}
          exactMatch={x.exactMatch ?? true}
        />
      ))}
    </>
  );
};

const StyledListItem = styled(ListItem)`
  display: 'block';
  transition: 'ease-in-out 0.5s';
  height: 48px;
  margin-top: 20px;
`;
