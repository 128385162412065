import { isNumber } from 'lodash';
import { useMemo } from 'react';
import { Inheritance, Module } from 'src/domain';
import { ModuleInheritanceLevelNoos, ModuleInheritanceLevelSalesBudget } from 'src/infrastructure/rest-api/api-types';
import {
  noosSplitLevelToInheritance,
  salesBudgetSplitLevelToInheritance,
  splitLevelToInheritance,
} from 'src/utils/splitLevels';
import { useGenderId } from '../gender';
import { useProductLineId } from '../productline';
import { useCurrentSplitLevel } from '../split-levels';
import { useCurrentModule } from '../useCurrentModule';
import { useScope } from '../useScope';

export const useDefaultInheritance = (selectedModule?: Module) => {
  const scope = useScope();
  const currentSplitLevel = useCurrentSplitLevel(selectedModule);
  const currentModule = useCurrentModule();
  const module = selectedModule ?? currentModule;

  const genderId = useGenderId();
  const productLineGroupId = useProductLineId();

  const inheritance = useMemo(() => {
    if (!currentSplitLevel || !scope || !isNumber(genderId) || !isNumber(productLineGroupId)) return;

    if (module === Module.Turnover) {
      return salesBudgetSplitLevelToInheritance(
        (currentSplitLevel as ModuleInheritanceLevelSalesBudget).salesBudgetSplit
      );
    }

    if (module === Module.NoosShare) {
      const inheritance = splitLevelToInheritance(
        currentSplitLevel as ModuleInheritanceLevelNoos,
        scope,
        genderId,
        productLineGroupId
      );
      const noosActualInheritance = noosSplitLevelToInheritance(
        (currentSplitLevel as ModuleInheritanceLevelNoos).noosSplit
      );
      return inheritance === Inheritance.Historical ? noosActualInheritance : inheritance;
    }

    return splitLevelToInheritance(
      currentSplitLevel as ModuleInheritanceLevelNoos,
      scope,
      genderId,
      productLineGroupId
    );
  }, [currentSplitLevel, genderId, module, productLineGroupId, scope]);

  return inheritance;
};
