import { useMemo } from 'react';
import { useBuyingEventsApiQuery } from 'src/hooks/buying-event-managment/queries/useBuyingEventsApiQuery';
import { useChainSettingsApiQuery } from 'src/hooks/chain-settings/useChainSettingsApiQuery';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useGendersApiQuery } from 'src/hooks/useGendersApiQuery';
import { useProductLineGroupsApiQuery } from 'src/hooks/useProductLineGroupsApiQuery';
import { useSelectedPartner } from 'src/hooks/useSelectedPartner';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { PlannedOnEntry } from 'src/mapping/open-to-buy-limit.mapping';

export const useOpenToBuyLimitQuery = () => {
  const { data: chainSettings } = useChainSettingsApiQuery();
  const { data: genders } = useGendersApiQuery();
  const { data: productLineGroups } = useProductLineGroupsApiQuery();
  const { data: styleCategories } = useStyleCategoriesQuery();
  const { data: buyingEvents } = useBuyingEventsApiQuery();
  const [partner] = useSelectedPartner();
  const partnerComposite = useCompositePartner(partner);

  const {
    data: openToBuyLimit,
    loading,
    error,
    refetch,
  } = useApiQuery('/api/opentobuylimit/partners/{partnerId}', 'get', {
    path: {
      partnerId: partnerComposite?.id,
    },
    enabled: !!partnerComposite,
  });

  const plannedOn: PlannedOnEntry[] | undefined = useMemo(() => {
    if (!chainSettings) {
      return;
    }

    if (chainSettings?.buyingEventPlanningLevel === 'StyleCategories') {
      return styleCategories;
    }

    if (chainSettings.buyingEventPlanningLevel === 'Genders') {
      return genders;
    }

    if (chainSettings.buyingEventPlanningLevel === 'ProductLineGroups') {
      return productLineGroups;
    }
  }, [chainSettings, styleCategories, genders, productLineGroups]);

  return { data: openToBuyLimit, buyingEvents: buyingEvents, plannedOn, loading, error, refetch };
};
