import { components } from 'src/infrastructure/rest-api/generated';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';

export const useOptimalOptionCountApiQuery = (
  entityId: number | string | null | undefined,
  entityType: components['schemas']['EntityTypeEnum']
) => {
  const { data, loading, error } = useApiQuery('/api/optimaloptioncount', 'get', {
    query: {
      entityId: Number(entityId),
      entityType,
    },
    enabled: !!entityId && !isNaN(Number(entityId)),
  });

  return { data, loading, error };
};
