import { useMemo } from 'react';
import { Module } from 'src/domain';
import { InheritanceLevelModel } from 'src/infrastructure/rest-api/api-types';
import { useCurrentModule } from '../useCurrentModule';
import { useCurrentSplitLevelQuery } from './queries';

export function useCurrentSplitLevel(module?: Module) {
  const { data } = useCurrentSplitLevelQuery() ?? {};
  const currentModule = useCurrentModule();
  const moduleToUse = module ?? currentModule;

  const splitLevelPropertyKey = useMemo(() => moduleToSplitLevelsKey(moduleToUse), [moduleToUse]);

  const splitLevel = useMemo(() => {
    const currentSplitLevel = splitLevelPropertyKey && data?.[splitLevelPropertyKey];

    // unknown cast until we can remove our own enum and replace with the one from backend
    return currentSplitLevel ?? null;
  }, [data, splitLevelPropertyKey]);

  return splitLevel;
}

const moduleToSplitLevelsKey = (
  module: Module | undefined
): keyof Omit<InheritanceLevelModel, 'id' | 'entityId' | 'entityType'> | undefined => {
  switch (module) {
    case Module.Markup:
      return 'markup';
    case Module.Markdown:
      return 'markdown';
    case Module.CategorySplit:
      return 'categorySplit';
    case Module.GenderSplit:
      return 'genderSplit';
    case Module.ProductLineSplit:
      return 'productLineSplit';
    case Module.NoosShare:
      return 'noosShare';
    case Module.DeliveryProfile:
      return 'deliveryProfile';
    case Module.WeekCover:
      return 'weekCover';
    case Module.Turnover:
      return 'salesBudget';
  }
};
