import { Add, ChevronRight, Delete, ExpandMore, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Divider, Stack, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import Container from 'src/components/atoms/Container';
import { AuditLogButton } from 'src/components/molecules/AuditLogButton/AuditLogButton';
import { StyledDiscardButton } from 'src/components/styled/DiscardButton';
import { StyledLoadingButton } from 'src/components/styled/LoadingButton';
import { CollapseAllEvent } from 'src/domain/events/collapse-all.event';
import { ExpandAllEvent } from 'src/domain/events/expand-all.event';
import { useCreateClusterDialog, useGlobalVar, useSelectedPartner } from 'src/hooks';
import { useIsAuditLogSupported } from 'src/hooks/audit-log/useIsAuditLogSupported';
import { useClusterManagementDiscardChanges } from 'src/hooks/cluster-management/useClusterManagementDiscardChanges';
import { useClustersByCompositeId } from 'src/hooks/cluster-management/useClustersByCompositeId';
import { useUpdateCluster } from 'src/hooks/cluster-management/useUpdateCluster';
import { useClusterChangesSnackbar } from 'src/hooks/snackbar/useClusterChangesSnackbar';
import { useUnsavedChangesClusterManagement } from 'src/hooks/unsaved-changes/useUnsavedChangesClusterManagement';
import { clusterManagementIsSavingVar } from 'src/infrastructure/local_state';

export const ClusterManagementHeader: FC = () => {
  const [partner] = useSelectedPartner();
  const discardChanges = useClusterManagementDiscardChanges();
  const [unsavedChanges, setUnsavedChanges] = useUnsavedChangesClusterManagement();
  const saveChanges = useUpdateCluster();
  const [isSaving, setIsSaving] = useGlobalVar(clusterManagementIsSavingVar);
  const showSnackbar = useClusterChangesSnackbar();
  const { loading } = useClustersByCompositeId();

  const createClusterLocal = (name: string, description?: string) => {
    // Definitely not the most ideal way of doing this. But ensures a unique value
    setUnsavedChanges({ ...unsavedChanges, [-new Date().valueOf()]: { name, description } });
  };

  const [showCreateClusterDialog, makeCreateClusterDialog] = useCreateClusterDialog({
    onOk: (name: string, description?: string) => createClusterLocal(name, description),
  });

  const handleSave = useCallback(() => {
    setIsSaving(true);
    saveChanges()
      ?.then(() => {
        showSnackbar();
        discardChanges();
      })
      .catch((err) => {
        showSnackbar(err);
      })
      .finally(() => setIsSaving(false));
  }, [discardChanges, saveChanges, setIsSaving, showSnackbar]);

  const handleDiscard = useCallback(() => {
    discardChanges();
  }, [discardChanges]);

  const handleNewCluster = useCallback(() => {
    showCreateClusterDialog();
  }, [showCreateClusterDialog]);

  const handleExpandAll = useCallback(() => dispatchEvent(new Event(ExpandAllEvent)), []);

  const handleCollapseAll = useCallback(() => dispatchEvent(new Event(CollapseAllEvent)), []);

  const isAuditLogSupported = useIsAuditLogSupported();

  const canSave = !!unsavedChanges && !loading;
  const canDiscard = !!unsavedChanges && !isSaving && !loading;

  return (
    <>
      {makeCreateClusterDialog()}
      <Stack justifyContent="space-between" direction="row">
        <Container>
          <Stack>
            <Typography variant="h4">{partner?.name}</Typography>
            <Typography sx={{ color: '#787C8D' }} variant="h5">
              Cluster Management
            </Typography>
          </Stack>
        </Container>
        <Stack justifyContent="flex-end" spacing={2} direction="row" alignSelf="flex-end">
          {isAuditLogSupported && <AuditLogButton />}
          <LoadingButton
            color="secondary"
            variant="contained"
            size="small"
            onClick={handleExpandAll}
            endIcon={<ExpandMore />}
          >
            Expand All
          </LoadingButton>
          <Divider orientation="vertical" flexItem />
          <LoadingButton
            color="secondary"
            variant="contained"
            size="small"
            onClick={handleCollapseAll}
            startIcon={<ChevronRight />}
          >
            Collapse All
          </LoadingButton>
          <Divider orientation="vertical" flexItem />
          <LoadingButton
            color="secondary"
            variant="contained"
            size="small"
            onClick={handleNewCluster}
            startIcon={<Add />}
            disabled={isSaving || loading}
          >
            New Cluster
          </LoadingButton>
          <StyledDiscardButton
            disabled={!canDiscard}
            color={'error'}
            size={'small'}
            variant={'contained'}
            startIcon={<Delete />}
            onClick={handleDiscard}
          >
            Discard changes
          </StyledDiscardButton>
          <StyledLoadingButton
            size={'small'}
            variant={'contained'}
            onClick={handleSave}
            startIcon={<Save />}
            disabled={!canSave}
            loading={isSaving}
          >
            Save Changes
          </StyledLoadingButton>
        </Stack>
      </Stack>
    </>
  );
};
