import { ArrowBack, Edit, Fullscreen, FullscreenExit } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, Stack, Tooltip, Typography, styled } from '@mui/material';
import { CellKeyDownEvent, FillOperationParams, GetContextMenuItemsParams } from 'ag-grid-community';
import { differenceInDays, parseISO } from 'date-fns';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Container from 'src/components/atoms/Container';
import { CountStatusBar } from 'src/components/molecules/CountStatusBar/CountStatusBar';
import { ActionButtons } from 'src/components/organisms/ActionButtons/ActionsButtons';
import { CachedImageProvider } from 'src/components/organisms/CachedImage/CachedImage';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { Cell } from 'src/components/organisms/DataTable/types';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { Module } from 'src/domain';
import { useBufferTime } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useSalesCampaignPlanningCellValuesChanged } from 'src/hooks/sales-campaigns-planning/useSalesCampaignPlanningCellValuesChanged';
import { useSalesCampaignPlanningSaveChanges } from 'src/hooks/sales-campaigns-planning/useSalesCampaignPlanningSaveChanges';
import { useSalesCampaignSimulationRows } from 'src/hooks/sales-campaigns-planning/useSalesCampaignSimulationRows';
import { useStorefrontTypesApiQuery } from 'src/hooks/style-timeline-settings/queries/useStorefrontTypesApiQuery';

import { CreateSalesCampaignInput, UpdateSalesCampaignInput } from 'src/infrastructure/rest-api/api-types';
import { generateContextMenuItems } from 'src/utils/ag-grid/getContextMenuItems';
import {
  fillOperationForColumn,
  getEmptyValue,
  handleClearCellValue,
  handleCopyValueToVisibleRows,
  handleMarkAsInfinitelyAvailable,
} from 'src/utils/ag-grid/styleTimelineHelper';
import { SalesCampaignDialog } from '../SalesCampaigns/CreateCampaignDialog';
import { columns, defaultColDef } from './data/columns';

export const SalesCampaignsPlanning: FC = () => {
  const [fullscreen, setFullscreen] = useState(false);

  const { id: salesCampaignId } = useParams();

  const {
    data: simulationRows,
    salesCampaign,
    loading: rowsLoading,
    error,
  } = useSalesCampaignSimulationRows(Number(salesCampaignId));

  const discardChanges = useDiscardChangesModule(Module.SalesCampaignsPlanning);
  const [saveChanges, { loading: isSaving }] = useSalesCampaignPlanningSaveChanges();
  const handleCellValuesChanged = useSalesCampaignPlanningCellValuesChanged();

  const [editableSalesCampaign, setEditableSalesCampaign] = useState<
    (UpdateSalesCampaignInput & CreateSalesCampaignInput) | null
  >();
  const { data: storefrontTypes, loading: storefrontTypesLoading } = useStorefrontTypesApiQuery();

  const loading = rowsLoading || storefrontTypesLoading;

  const [fillOperationBufferedCells, pushToFillOperationBuffer] = useBufferTime<Cell>(10);

  useEffect(() => {
    if (fillOperationBufferedCells.length > 0 && typeof handleCellValuesChanged === 'function') {
      handleCellValuesChanged(fillOperationBufferedCells);
    }
    // don't react to handleCellValuesChanged
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fillOperationBufferedCells]);

  const fillOperation = useCallback(
    (params: FillOperationParams) => fillOperationForColumn(params, pushToFillOperationBuffer),
    [pushToFillOperationBuffer]
  );

  const handleCellKeyDown = useCallback(
    (event: CellKeyDownEvent) => {
      if (!event.event) {
        return;
      }

      const keyboardEvent = event.event as unknown as KeyboardEvent;

      const colId = event.column.getColId();
      const shouldHandle = ['discountPercentage', 'discount'].includes(colId) && !event.event.defaultPrevented;

      if (shouldHandle && ['Backspace', 'Clear', 'Delete'].includes(keyboardEvent.key)) {
        event.event.preventDefault();
        const changes: Cell<unknown>[] = [
          {
            value:
              colId === 'discount'
                ? {
                    x: null,
                    y: null,
                    discountType: 'FIXED_PRICE',
                  }
                : null,
            rowId: event.node.data['rowId'],
            column: colId,
            category: undefined,
            footer: undefined,
          },
        ];
        handleCellValuesChanged(changes);
      }
    },
    [handleCellValuesChanged]
  );

  const copyValueToVisibleRows = useCallback(
    (params: GetContextMenuItemsParams) => handleCopyValueToVisibleRows(params, handleCellValuesChanged),
    [handleCellValuesChanged]
  );

  const clearCellValue = useCallback(
    (params: Partial<GetContextMenuItemsParams>) =>
      handleClearCellValue(params, getEmptyValue, handleCellValuesChanged),
    [handleCellValuesChanged]
  );

  const markAsInfinitelyAvailable = useCallback(
    (params: GetContextMenuItemsParams) => handleMarkAsInfinitelyAvailable(params, handleCellValuesChanged),
    [handleCellValuesChanged]
  );

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams) =>
      generateContextMenuItems(params, clearCellValue, copyValueToVisibleRows, markAsInfinitelyAvailable),
    [clearCellValue, copyValueToVisibleRows, markAsInfinitelyAvailable]
  );

  return (
    <>
      <Stack gap={'1rem'} height="100%">
        <Box>
          <RouterLink
            to={{
              pathname: '/sales-campaigns',
              search: location.search,
            }}
          >
            <Button variant="text" startIcon={<ArrowBack />}>
              Back to campaigns list
            </Button>
          </RouterLink>
        </Box>

        <HeaderBox>
          {!salesCampaign && <CircularProgress />}
          {salesCampaign && (
            <>
              <HeaderBoxItem sx={{ gridTemplateColumns: '1fr' }}>
                <Stack alignItems={'flex-end'}>
                  <IconButton
                    size={'small'}
                    onClick={() =>
                      setEditableSalesCampaign({
                        endDate: salesCampaign.endDate,
                        salesCampaignId: salesCampaign.id,
                        startDate: salesCampaign.startDate,
                        name: salesCampaign.name,
                        partnerCompositeId: 0,
                        storefrontTypeId: salesCampaign.storefrontType?.id ?? 0,
                      })
                    }
                  >
                    <Edit fontSize={'small'} color={'secondary'} />
                  </IconButton>
                </Stack>
              </HeaderBoxItem>
              <HeaderBoxItem>
                <Typography>Campaign:</Typography>
                <Typography>{salesCampaign?.name}</Typography>
              </HeaderBoxItem>
              <HeaderBoxItem>
                <Typography>Start:</Typography>
                <Typography>{salesCampaign?.startDate}</Typography>
              </HeaderBoxItem>
              <HeaderBoxItem>
                <Typography>End:</Typography>
                <Typography>{salesCampaign?.endDate}</Typography>
              </HeaderBoxItem>
              <HeaderBoxItem>
                <Typography>Duration:</Typography>
                <Typography>
                  {differenceInDays(parseISO(salesCampaign?.endDate), parseISO(salesCampaign?.startDate))} days
                </Typography>
              </HeaderBoxItem>
            </>
          )}
        </HeaderBox>
        <StyledContainer
          style={{
            height: '100%',
            zIndex: 3,
            position: fullscreen ? 'fixed' : 'static',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'auto',
          }}
        >
          <Stack direction="row" justifyContent={'flex-end'}>
            <ActionButtons
              onDiscard={discardChanges}
              onSave={saveChanges}
              onSetInheritance={saveChanges}
              onReset={saveChanges}
              saveLoading={isSaving}
              loading={loading || isSaving}
              module={Module.SalesCampaignsPlanning}
            >
              <IconButton color="primary" onClick={() => setFullscreen((prev) => !prev)}>
                {!fullscreen ? (
                  <Tooltip title="Open fullscreen">
                    <Fullscreen />
                  </Tooltip>
                ) : (
                  <Tooltip title="Close fullscreen">
                    <FullscreenExit />
                  </Tooltip>
                )}
              </IconButton>
            </ActionButtons>
          </Stack>

          {loading && (
            <Stack alignItems={'center'} justifyContent={'center'} height="100%">
              <CircularProgress />
            </Stack>
          )}

          {error && <Typography color="error">{error.message}</Typography>}

          <TableWrapper className="ag-theme-alpine-custom-compact small-font" flex={1}>
            <CachedImageProvider>
              <DataTable
                fillOperation={fillOperation}
                loading={loading}
                defaultColDef={defaultColDef}
                columns={columns}
                rows={simulationRows}
                isNumberTable={false}
                autoSizeToFit={false}
                suppressAutoSize={true}
                onCellValuesChanged={handleCellValuesChanged}
                domLayout="normal"
                onCellKeyDown={handleCellKeyDown}
                getContextMenuItems={getContextMenuItems}
                gridOptions={{
                  statusBar: {
                    statusPanels: [
                      {
                        statusPanel: CountStatusBar,
                        align: 'right',
                      },
                    ],
                  },
                }}
                sideBar={{
                  position: 'left',
                  toolPanels: [
                    {
                      id: 'filters',
                      labelDefault: 'Filters',
                      labelKey: 'filters',
                      iconKey: 'filter',
                      toolPanel: 'agFiltersToolPanel',
                      toolPanelParams: {
                        suppressExpandAll: false,
                      },
                    },
                    {
                      id: 'columns',
                      labelDefault: 'Columns',
                      labelKey: 'columns',
                      iconKey: 'columns',
                      toolPanel: 'agColumnsToolPanel',
                      toolPanelParams: {
                        suppressExpandAll: false,
                      },
                    },
                  ],
                }}
              />
            </CachedImageProvider>
          </TableWrapper>
        </StyledContainer>
      </Stack>

      {editableSalesCampaign && storefrontTypes && (
        <SalesCampaignDialog
          open={!!editableSalesCampaign}
          defaultValue={editableSalesCampaign}
          storefrontTypes={storefrontTypes}
          onClose={() => setEditableSalesCampaign(null)}
          isEditing
        />
      )}
    </>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const HeaderBox = styled(Stack)`
  width: fit-content;
  gap: 0.75rem;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 0.25rem;
`;

const HeaderBoxItem = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;

  & > *:first-of-type {
    font-weight: 600;
  }
`;
