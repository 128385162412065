import { CellClassParams } from 'ag-grid-community';
import {
  OptimalOptionCountStylesPer100SQMLabel,
  OptimalOptionCountTotalLabel,
} from 'src/mapping/optimal-option-count.mapping';

export const validateSumEqualsTotal = ({ column, node }: CellClassParams, months: string[]): boolean => {
  const colId = column.getColId();
  const month = colId.split('.')[1];

  if (node?.key === OptimalOptionCountTotalLabel || node?.key === OptimalOptionCountStylesPer100SQMLabel) {
    return false;
  }

  if (months.includes(month)) return false;

  return true;
};
