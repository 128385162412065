import { useContext, useEffect } from 'react';
import { Module } from 'src/domain';
import { SalesBudgetInheritanceLevelEnum } from 'src/infrastructure/rest-api/api-types';
import { IsHistoricalContext } from './IsHistoricalContext';

export const useIsTurnoverHistorical = (splitLevel: SalesBudgetInheritanceLevelEnum | null) => {
  if (splitLevel === 'Store') return false;
  if (
    splitLevel === 'FccInitial' ||
    splitLevel === 'FccRevised' ||
    splitLevel === 'Historical' ||
    splitLevel === 'HistoricalLLY'
  ) {
    return true;
  }
  return false;
};

export const useInitializeIsTurnoverHistorical = (data: SalesBudgetInheritanceLevelEnum | null) => {
  const [isHistoricalMap, setIsHistoricalMap] = useContext(IsHistoricalContext);

  const isTurnoverHistorical = useIsTurnoverHistorical(data);

  useEffect(() => {
    if (isTurnoverHistorical === undefined) return;
    if (isHistoricalMap.get(Module.Turnover) === isTurnoverHistorical) return;

    isHistoricalMap.set(Module.Turnover, isTurnoverHistorical);
    setIsHistoricalMap(new Map(isHistoricalMap));
  }, [isTurnoverHistorical, isHistoricalMap, setIsHistoricalMap]);
};
