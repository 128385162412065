import { CustomFilterProps } from 'ag-grid-react';
import { endOfMonth, subYears } from 'date-fns';
import { DateFilterBase } from 'src/components/organisms/DateFilterBase/DateFilterBase';
import { formatDateAsMonthYear, formatMonthYearAsHumanReadable } from 'src/utils/formatAsMonthYear';
import { MonthPicker } from '../MonthPicker/MonthPicker';

export const MonthFilter = (props: CustomFilterProps) => {
  const getEndExcludeDateIntervals = (startDate: Date | null) => {
    if (startDate) {
      return [
        {
          start: subYears(startDate, 200),
          end: endOfMonth(startDate),
        },
      ];
    }
    return [];
  };
  return (
    <DateFilterBase
      {...props}
      dateFormatter={(date: Date) => Number(formatDateAsMonthYear(date))}
      DatePickerComponent={MonthPicker}
      formatDate={formatMonthYearAsHumanReadable}
      getEndExcludeDateIntervals={getEndExcludeDateIntervals}
    />
  );
};
