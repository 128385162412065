import { useCallback, useEffect } from 'react';
import { UnsavedChangesStorageKey } from 'src/domain';
import { UnsavedChangesEvent } from 'src/domain/events/unsaved-changes.event';
import { getContentInSessionStorage, useSessionStorage } from 'src/hooks';

// overloads
// optional key return type
export function useUnsavedChanges<T>(): [
  Record<string, T>,
  (arg: T | ((state: Record<string, T>) => Record<string, T>)) => void,
];
export function useUnsavedChanges<T>(
  key: string
): [T | undefined | null, (arg: T | ((state: T | undefined) => T)) => void];

// implementation
export function useUnsavedChanges<T>(key?: string) {
  const [unsavedChanges, setUnsavedChanges] = useSessionStorage<Record<string, T>>(UnsavedChangesStorageKey, {});

  const setValue = useCallback(
    (arg: T | ((state: T | Record<string, T>) => T)) => {
      let value: T;

      if (arg instanceof Function) {
        value = key ? arg(unsavedChanges[key]) : arg(unsavedChanges);
      } else value = arg;
      setUnsavedChanges(key ? { ...unsavedChanges, [key]: value } : { value });
      dispatchEvent(new Event(UnsavedChangesEvent));
    },
    [key, setUnsavedChanges, unsavedChanges]
  );

  const onUnsavedChanges = useCallback(() => {
    setUnsavedChanges(getContentInSessionStorage<Record<string, T>>(UnsavedChangesStorageKey, {}));
  }, [setUnsavedChanges]);

  useEffect(() => {
    window.addEventListener(UnsavedChangesEvent, onUnsavedChanges);
    return () => {
      window.removeEventListener(UnsavedChangesEvent, onUnsavedChanges);
    };
    // Having the eventlistener tied to onUnsavedChanges caused a memory leak.
    // A leak which caused some unsavedchanges data not to propagate to components.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [key ? unsavedChanges[key] : unsavedChanges, setValue];
}
