import { useCallback, useContext } from 'react';
import { StoreCacheContext } from 'src/contexts/StoreCacheContext';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { useChainId } from '../chain';
import { useClusterId } from '../cluster/useClusterId';
import { useCompositePartner } from '../partner/useCompositePartner';
import { useUnsavedChangesClusterManagement } from '../unsaved-changes/useUnsavedChangesClusterManagement';
import { useSelectedPartner } from '../useSelectedPartner';

export const useUpdateCluster = () => {
  const [_, { refetch }] = useContext(StoreCacheContext);
  const [partner] = useSelectedPartner();
  const compositePartner = useCompositePartner(partner);
  const [unsavedChanges] = useUnsavedChangesClusterManagement();
  const clusterId = useClusterId(partner);
  const chainId = useChainId();

  const [createCluster] = useApiMutation('/api/clusters', 'post', {
    update(_data, _variables, queryClient) {
      const partnerId = compositePartner?.id;
      queryClient.invalidateQueries({ queryKey: [`/api/clusters/partners/${partnerId}`] });
      queryClient.invalidateQueries({ queryKey: [`/api/turnover/partners/${partnerId}/stores`] });
      queryClient.invalidateQueries({ queryKey: [`/api/turnover/partners/${partnerId}`] });
      queryClient.invalidateQueries({ queryKey: [`/api/turnover/clusters/${clusterId}`] });
    },
  });

  const [updateCluster] = useApiMutation('/api/clusters', 'put', {
    update(_data, _variables, queryClient) {
      const partnerId = compositePartner?.id;
      queryClient.invalidateQueries({ queryKey: [`/api/clusters/partners/${partnerId}`] });
      queryClient.invalidateQueries({ queryKey: [`/api/turnover/partners/${partnerId}/stores`] });
      queryClient.invalidateQueries({ queryKey: [`/api/turnover/partners/${partnerId}`] });
      queryClient.invalidateQueries({ queryKey: [`/api/turnover/clusters/${clusterId}`] });
    },
  });

  const updateClusterMethod = useCallback(() => {
    if (!unsavedChanges) return;

    const promises = Object.entries(unsavedChanges).map(([clusterKey, change]) => {
      const clusterId = parseInt(clusterKey);
      const isNewCluster = clusterId < 0;

      if (isNewCluster) {
        if (!chainId || !change?.name || !change?.storeIds || !compositePartner?.id) return;

        return createCluster({
          body: [
            {
              chainId: chainId,
              clusterName: change.name,
              clusterDescription: change.description ?? '',
              storeIds: change.storeIds,
              operationalResponsibleCompositeId: compositePartner.id,
              useStockManagementTotals: change.useStockManagementTotals ?? true,
            },
          ],
        }).then(() => {
          if (change.storeIds && change.storeIds.length > 0) {
            refetch();
          }
        });
      }

      if (!isNewCluster) {
        return updateCluster({
          body: [
            {
              clusterId,
              clusterName: change.name ?? '',
              description: change.description ?? '',
              newClusterStores: change.storeIds ?? [],
            },
          ],
        }).then(() => {
          if (change.storeIds && change.storeIds.length > 0) {
            refetch();
          }
        });
      }
    });

    return Promise.all(promises);
  }, [unsavedChanges, compositePartner?.id, chainId, createCluster, refetch, updateCluster]);

  return updateClusterMethod;
};
