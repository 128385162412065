import { useState } from 'react';
import { DeleteDialog } from 'src/components/organisms/DeleteDialog/DeleteDialog';

export const useDeleteClusterDialog = (
  title: string,
  content: string,
  callbacks: {
    onOk?: (clusterId: number) => void;
    onCancel?: () => void;
  }
) => {
  const [open, setOpen] = useState(false);
  const [clusterId, setClusterId] = useState<number>();

  const showDialog = (clusterId: number) => {
    setOpen(true);
    setClusterId(clusterId);
  };

  const onOk = () => {
    if (!clusterId) return;
    callbacks.onOk?.(clusterId);
    setOpen(false);
  };
  const onCancel = () => {
    callbacks.onCancel?.();
    setOpen(false);
  };

  const makeDialog = () => (
    <DeleteDialog open={open} onDelete={onOk} onCancel={onCancel} title={title} content={content} />
  );

  return [showDialog, makeDialog] as const;
};
