import styled from '@emotion/styled';
import { Button, Container, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import logo from 'src/assets/img/logo-storeforecast.svg';

export const NoAccess: FC = () => {
  return (
    <Container maxWidth={'md'}>
      <Stack gap={3}>
        <Logo src={logo} />
        <Typography variant={'h2'} noWrap={false}>
          User does not have access
        </Typography>
        <Typography noWrap={false}>
          You do not have access to any stores. If you think this is a mistake, you can request access by creating a
          ticket in the Self-Service portal.
        </Typography>
        <Button
          href="https://bestseller.service-now.com/bestsellersp?id=sc_cat_item&sys_id=8c2094411b55ac1046aff646464bcbd3&sysparm_category=0cba6c05dbf91410693e231cd39619a2&assignment_group=52724f32879fdd50fcd5cb790cbb3513"
          variant="contained"
        >
          Request access
        </Button>
      </Stack>
    </Container>
  );
};

const Logo = styled.img`
  margin: 100px auto 40px auto;
  width: 100%;
  max-width: 400px;
`;
