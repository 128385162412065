import { useCallback } from 'react';
import { CategoryOverviewRow, Module, SessionStorageGenderProductLineType } from 'src/domain';
import { DiscardChangesEvent } from 'src/domain/events/discard-changes.event';
import { useGender } from 'src/hooks/gender';
import { useProductLineName } from 'src/hooks/productline';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { combineGenderAndProductline } from 'src/utils/combineGenderAndProductline';
import { useResetInheritance } from '../inheritance/useResetInheritance';
import { useStockManagementTableKey } from '../stock-management/table/useStockManagementTableKey';

export const useDiscardChangesGenderProductline = (module?: Module) => {
  const gender = useGender();
  const productLineName = useProductLineName();
  const tableKey = useStockManagementTableKey();
  const [unsavedChanges, setUnsavedChanges] =
    useUnsavedChangesModule<SessionStorageGenderProductLineType<CategoryOverviewRow[]>>(module);
  const discardInheritanceChanges = useResetInheritance({ module });

  const discardChanges = useCallback(() => {
    const updatedStore = { ...unsavedChanges };

    const genderProductLineKey = combineGenderAndProductline(gender, productLineName);
    if (module === Module.StockManagement) {
      delete updatedStore[tableKey];
    } else if (gender && productLineName) delete updatedStore[genderProductLineKey];

    setUnsavedChanges(updatedStore);
    discardInheritanceChanges();
    dispatchEvent(new Event(DiscardChangesEvent));
  }, [discardInheritanceChanges, gender, module, productLineName, setUnsavedChanges, tableKey, unsavedChanges]);

  return discardChanges;
};
