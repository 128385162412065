import { isNumber } from 'lodash';
import { useCallback } from 'react';
import { Cell } from 'src/components/organisms/DataTable/types';
import { Inheritance, Module, TableOverviewRow } from 'src/domain';
import { UnsavedChangesEvent } from 'src/domain/events/unsaved-changes.event';
import { useGlobalVar, useSetSelectedInheritance, useStoreId, useUnsavedChangesModule } from 'src/hooks';
import { useProductLineSplitOverviewSimulationRows } from 'src/hooks/productline-split/simulation/useProductLineSplitOverviewSimulationRows';
import { activeMonthVar } from 'src/infrastructure/local_state';
import { mergeOverviewRows } from 'src/utils/mergeRows';

export const useProductLineSplitHandleCellValueChanged = () => {
  const { data: typedData } = useProductLineSplitOverviewSimulationRows(Inheritance.Typed) ?? {};
  const { data: originalData } = useProductLineSplitOverviewSimulationRows() ?? {};
  const storeId = useStoreId();
  const [activeMonth] = useGlobalVar(activeMonthVar);
  const setSelectedInheritance = useSetSelectedInheritance();
  const [_, setUnsavedChanges] = useUnsavedChangesModule<TableOverviewRow[]>(Module.ProductLineSplit);

  const callback = useCallback(
    async (cellValues: Cell[]) => {
      if (!typedData || !originalData) {
        throw new Error('Data is missing!');
      }
      if (!storeId) {
        throw new Error(`Store ID is missing`);
      }

      const mergedRows = mergeOverviewRows(typedData, originalData);

      cellValues.forEach((cellValue) => {
        const rowIndexToUpdate = mergedRows.findIndex((x) => x.id === cellValue.rowId);
        const month: string | undefined =
          !cellValue.column || cellValue.column === 'plannedSplit'
            ? activeMonth.toLowerCase()
            : cellValue.column.split('.')[1];

        const valueIsValid = !isNaN(cellValue.value) && isNumber(Number(cellValue.value));

        if (month) {
          mergedRows[rowIndexToUpdate] = {
            ...mergedRows?.[rowIndexToUpdate],
            columns: {
              ...mergedRows?.[rowIndexToUpdate].columns,
              [month]: valueIsValid ? cellValue.value : 0,
            },
          };
        }
      });

      const unsaved = [...mergedRows];

      setUnsavedChanges(unsaved);
      setSelectedInheritance(Inheritance.Typed);
      dispatchEvent(new Event(UnsavedChangesEvent));
    },
    [activeMonth, originalData, setSelectedInheritance, setUnsavedChanges, storeId, typedData]
  );

  return callback;
};
