import styled from '@emotion/styled';
import {
  CellClassParams,
  CellValueChangedEvent,
  ColDef,
  ColumnEverythingChangedEvent,
  ColumnState,
  GetRowIdParams,
  GetRowNodeIdFunc,
  GridApi,
  GridReadyEvent,
  ModelUpdatedEvent,
  ProcessCellForExportParams,
  ProcessDataFromClipboardParams,
} from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { equals } from 'ramda';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Color } from 'src/domain';
import { DataTableEditedEvent } from 'src/domain/events/data-table-edit.event';
import { useBufferTime, useOnResize } from 'src/hooks';
import { formatNumber, parseNumber } from 'src/utils/formatNumber';
import { DataTableLoading } from './DataTableLoading';
import { DefaultColumnsOverview } from './columns/default-columns-overview';
import { Cell, CellValidationOptions, Row } from './types';

function mergeColumns(column: ColDef, defaultColDef?: ColDef): ColDef {
  //* Since the column may contain an undefined value, we need to make sure we don't override the defaultColDef with undefined values.
  const mergedColumn = {
    ...defaultColDef,
  };
  Object.keys(column).forEach((key) => {
    const tKey = key as keyof typeof column;
    if (column[tKey]) {
      mergedColumn[tKey] = column[tKey];
    }
  });
  return mergedColumn;
}

export interface DataTableProps extends AgGridReactProps {
  rows: Row[] | null | undefined;
  columns?: ColDef[];
  onCellValuesChanged?: (values: Cell[]) => void;
  debounceTime?: number;
  loading?: boolean;
  columnMinWidth?: number;
  columnMaxWidth?: number;
  isNumberTable?: boolean;
  autoSizeToFit?: boolean;
  cellValidationOptions?: CellValidationOptions;
  onColumnStateChanged?: (columnState: ColumnState[]) => void;
}

export const DataTable: FC<DataTableProps> = ({
  rows,
  columns = DefaultColumnsOverview,
  onCellValuesChanged,
  debounceTime,
  loading,
  domLayout,
  columnMinWidth = 80,
  columnMaxWidth,
  onGridReady,
  isNumberTable = true,
  suppressAutoSize = true,
  autoSizeToFit = false,
  cellValidationOptions,
  onColumnStateChanged,
  ...props
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [bufferedCells, pushToBuffer] = useBufferTime<Cell>(debounceTime ?? 10);

  // resize columns when window size change
  useOnResize(() => {
    if (gridRef.current?.api) {
      sizeColumnsToFit(gridRef.current.api);
    }
  });

  const updateLoadingOverlay = useCallback(
    (api: GridApi) => {
      if (props.suppressLoadingOverlay) return;
      setTimeout(() => {
        if (loading) {
          api.showLoadingOverlay();
        } else {
          api.hideOverlay();
        }
      });
    },
    [loading, props.suppressLoadingOverlay]
  );

  const sizeColumnsToFit = useCallback(
    (api: GridApi) => {
      if (autoSizeToFit) {
        setTimeout(() => {
          api.sizeColumnsToFit({ defaultMinWidth: columnMinWidth, defaultMaxWidth: columnMaxWidth });
        });
      }
    },
    [autoSizeToFit, columnMaxWidth, columnMinWidth]
  );

  const handleGridReady = useCallback(
    (event: GridReadyEvent) => {
      // Run initialization methods when grid data is first rendered
      sizeColumnsToFit(event.api);
      updateLoadingOverlay(event.api);

      if (typeof onGridReady === 'function') {
        onGridReady(event);
      }
    },
    [onGridReady, sizeColumnsToFit, updateLoadingOverlay]
  );

  // resize columns when columnMaxWidth or columnMinWidth props changes
  useEffect(() => {
    if (gridRef.current?.api) {
      sizeColumnsToFit(gridRef.current.api);
    }
  }, [columnMaxWidth, columnMinWidth, sizeColumnsToFit, columns, rows]);

  // update loading overlay when loading prop changes
  useEffect(() => {
    if (gridRef.current?.api) {
      updateLoadingOverlay(gridRef.current.api);
    }
  }, [loading, updateLoadingOverlay]);

  const rowData = useMemo(() => {
    if (rows) {
      return structuredClone(rows);
    }
  }, [rows]);

  // whenever buffer updates, invoke callback
  useEffect(() => {
    if (bufferedCells.notEmpty()) {
      onCellValuesChanged?.(bufferedCells);

      // fire event that table has been edited
      dispatchEvent(new Event(DataTableEditedEvent));
    }
    // don't react to when onCellValuesChanged prop changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bufferedCells]);

  const getRowId = useCallback<GetRowNodeIdFunc>((params: GetRowIdParams<Row>) => {
    // Use rowId as default row id and fallback to id in case rowId is not present
    return (params?.data?.rowId ?? params?.data?.id)?.toString();
  }, []);

  const processDataFromClipboard = useCallback(
    (params: ProcessDataFromClipboardParams) =>
      params.data.map((row) =>
        // seems like AG Grid sanitizes new lines into empty string, so we remove any trailing empty strings
        // ! this could lead to cases where copying actual empty cells that are last will not be copied
        row.last()?.isEmpty() ? row.slice(0, row.length - 1) : row
      ),
    []
  );

  const processCellFromClipboard = useCallback(
    (params: ProcessCellForExportParams) => {
      if (isNumberTable) {
        if (params.value) {
          const value = params.value.replace('%', '');
          const valueAsNumber = parseNumber(value);
          // We verify that it is actually a number, but we need to return the formatted number value as pasted.
          if (!isNaN(valueAsNumber)) return value;
        }
        return 0;
      }
      try {
        return JSON.parse(params.value ?? '');
      } catch {
        return params.value;
      }
    },
    [isNumberTable]
  );

  const processCellForClipboard = useCallback(
    (params: ProcessCellForExportParams) => {
      /**
       * Value is either primitive or a tuple (used in Gross Margin)
       * @see {@link GrossMargin}
       */
      const value = params.value as unknown;

      let parsedValue: unknown;

      // assume predicted value is second, if not present default to current value
      if (Array.isArray(value)) {
        parsedValue = value[1] ?? value[0];
      } else {
        parsedValue = value;
      }

      if (isNumberTable) {
        if (typeof parsedValue === 'number') {
          parsedValue = formatNumber(parsedValue);
        }
        return typeof parsedValue === 'string' ? formatNumber(parseNumber(parsedValue)) : '0';
      }
      return JSON.stringify(parsedValue ?? '');
    },
    [isNumberTable]
  );

  const onCellChange = useCallback(
    (event: CellValueChangedEvent) => {
      // we need to remove all white spaces and set correct delimiter
      const parsedOldValue = isNumberTable ? event.oldValue?.toString().toFloat() : event.oldValue;
      const parsedNewValue = isNumberTable ? event.newValue?.toString().toFloat() : event.newValue;

      // ignore if actual value has not changed - handle both objects and primitives
      if (equals(parsedOldValue, parsedNewValue)) return;

      const rowId = event.data.rowId ?? event.data.id;
      const column = event.colDef.field;
      const category = event.data?.category;
      const footer: string | undefined = event.data?.footer ? event.data.subCategory : undefined;
      const cell: Cell<unknown> = { rowId, value: parsedNewValue, column, footer, category };

      pushToBuffer(cell);
    },
    [isNumberTable, pushToBuffer]
  );

  const columnDefs: ColDef[] = useMemo(() => {
    return columns.map((column) => {
      //* Due to an unknown issue after upgrading AG Grid, it seems the value formatter from the defaultColDef is not applied, so we just merge them manually here.
      column = mergeColumns(column, props.defaultColDef);

      if (
        !cellValidationOptions?.columns ||
        (Array.isArray(cellValidationOptions.columns) && !cellValidationOptions.columns.includes(column.field ?? ''))
      ) {
        return column;
      }

      return {
        ...column,
        autoHeaderHeight: true,
        cellClassRules: {
          'ag-cell--warning': (params: CellClassParams): boolean => {
            if (typeof cellValidationOptions.validationFunc === 'function') {
              return cellValidationOptions.validationFunc(params);
            }
            return false;
          },
          ...column.cellClassRules,
        },
      };
    });
  }, [cellValidationOptions, columns, props.defaultColDef]);

  const saveColumnState = useCallback(
    ({ api, source }: ColumnEverythingChangedEvent) => {
      if (['gridOptionsChanged'].includes(source)) return;
      if (typeof onColumnStateChanged === 'function') {
        onColumnStateChanged(structuredClone(api.getColumnState()));
      }
    },
    [onColumnStateChanged]
  );

  const onModelUpdated = useCallback(({ api }: ModelUpdatedEvent) => {
    api.refreshCells({ force: true });
  }, []);

  return (
    <StyledAGGridReact
      {...props}
      onModelUpdated={onModelUpdated}
      ref={gridRef}
      rowData={rowData}
      enableFillHandle={true}
      enableRangeSelection={true}
      enableRangeHandle={true}
      suppressMultiRangeSelection={true}
      columnDefs={columnDefs}
      getRowId={getRowId}
      onCellValueChanged={onCellChange}
      onGridReady={handleGridReady}
      onColumnEverythingChanged={saveColumnState}
      processCellForClipboard={processCellForClipboard}
      enterNavigatesVerticallyAfterEdit
      domLayout={domLayout ?? 'autoHeight'}
      processCellFromClipboard={processCellFromClipboard}
      processDataFromClipboard={processDataFromClipboard}
      tooltipShowDelay={500}
      tooltipHideDelay={10000}
      loadingOverlayComponent={DataTableLoading}
      suppressAutoSize={suppressAutoSize}
    />
  );
};

export const StyledAGGridReact = styled(AgGridReact)<{ headerHeight?: number }>`
  .ag-root-wrapper {
    color: #424242;
    border-radius: 5px;
    border: none;
  }

  .ag-root {
    width: fit-content;
  }

  .ag-header {
    border-bottom: 2px solid ${Color.white};
  }

  .ag-header-cell {
    background-color: #ececec;
    border-right: 2px solid ${Color.white};
    color: #424242;
  }

  .ag-cell-value {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /*   .ag-header,
  .ag-header-row,
  .ag-cell-label-container {
    ${({ headerHeight }) => `
    height: ${headerHeight ?? 35}px !important;
    min-height: ${headerHeight ?? 35}px !important;
    `};
  } */
  .ag-cell,
  .ag-cell-label-container,
  .ag-center-cols-container > .ag-row > .ag-cell:first-of-type,
  .ag-pinned-left-cols-container .ag-row .ag-cell:first-of-type {
    font-size: 14px;
  }

  .ag-row {
    border: none;
  }

  .ag-row .ag-cell {
    border-right: 2px solid ${Color.white};
    color: #424242;
    border-bottom: none;
    border-top: none;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .ag-row .ag-cell[aria-colindex='1'] {
    justify-content: left;
    font-weight: bold;
  }

  .ag-row .ag-cell:not([aria-colindex='1']):last-child,
  .ag-header-cell:not([aria-colindex='1']):last-child {
    border-right: 1px solid transparent;
  }

  .ag-root-wrapper .ag-row .ag-cell-range-single-cell.ag-cell-range-handle {
    border-right: 1px solid rgb(33, 150, 243) !important;
  }
  .ag-pinned-left-header {
    border: none;
  }

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    border-right: 2px solid ${Color.white};
  }

  .ag-horizontal-left-spacer {
    display: table;
  }
`;
