import { Key } from 'react';
import { GroupLevel } from '../enums/grouping';

export type FilterOption = {
  value: Key | null | undefined;
  label: string;
  key?: string;
  disabled?: boolean;
};

export type Search = {
  storeSelection: string;
  sidebar: string;
};

export type ActiveTab = {
  ownStoresTab: boolean;
  partnersTab: boolean;
  semiOwnedTab: boolean;
  wholesaleTab: boolean;
  ownEComTab: boolean;
};

export type Filter = {
  chains: FilterOption[];
  countries: FilterOption[];
  clusters: FilterOption[];
  partners: FilterOption[];
  searchQuery: Search;
  grouping: GroupLevel;
  activeTab: ActiveTab;
  storeIds: number[];
};

export const initialFilters: Filter = {
  chains: [],
  countries: [],
  clusters: [],
  partners: [],
  searchQuery: {
    storeSelection: '',
    sidebar: '',
  },
  grouping: GroupLevel.Country,
  activeTab: {
    ownStoresTab: true,
    partnersTab: false,
    semiOwnedTab: false,
    wholesaleTab: false,
    ownEComTab: false,
  },
  storeIds: [],
};
