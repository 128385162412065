import { useMemo } from 'react';
import { EditablePercentageCell } from 'src/components/molecules/EditableCell/EditableCell';
import { createDynamicMonthColumns } from 'src/components/organisms/DataTable/columns/utils';
import { Inheritance, Module, TableOverviewRow } from 'src/domain';
import { useCategorySplitOverviewRows } from 'src/hooks/category-split/table/useCategorySplitOverviewRows';
import { useInheritance } from 'src/hooks/inheritance';
import { styleCategoriesToGroupedByData } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { mapInheritanceToExpectedNew } from 'src/mapping/inheritance.mapping';
import { mapCategorySplitsToRows } from 'src/mapping/planned-splits.mapping';
import { mapSplitToOverviewTableDataNew } from 'src/mapping/split-modules.mapping';
import { groupByGenderAndProductLine } from 'src/utils/mergeRows';
import { useMarkupQuery } from '../queries/useMarkupQuery';

export const useMarkupOverviewRows = (
  inheritance?: Inheritance,
  genderId?: number,
  productLineId?: number,
  aggregateOnGenderProductlines = false
) => {
  const { expected, styleCategories, error, loading, sanitizedData } = useMarkupQuery(
    inheritance,
    genderId,
    productLineId
  );
  const { data: categoryData } = useCategorySplitOverviewRows(
    undefined,
    genderId,
    productLineId,
    aggregateOnGenderProductlines
  );

  const moduleInheritance = useInheritance(Module.Markup);
  const inheritanceToUse = inheritance ?? moduleInheritance;
  const isHistorical = inheritanceToUse === Inheritance.Historical;

  const groupedByData = useMemo(() => {
    return styleCategoriesToGroupedByData(styleCategories);
  }, [styleCategories]);

  const aggregatedTableData = useMemo(() => {
    if (!inheritanceToUse) {
      return;
    }

    if (aggregateOnGenderProductlines) {
      if (!sanitizedData) return;

      const rowsByGenderProductline: Record<string, Record<string, TableOverviewRow[]>> = {};

      const markupByProductline = groupByGenderAndProductLine(sanitizedData);
      Object.keys(markupByProductline).forEach((productlineKey) => {
        rowsByGenderProductline[productlineKey] = rowsByGenderProductline[productlineKey] ?? {};

        const markupByGender = markupByProductline[productlineKey];
        return Object.keys(markupByGender).forEach((genderKey) => {
          const markupExpected = markupByGender[genderKey];
          rowsByGenderProductline[productlineKey][genderKey] =
            mapSplitToOverviewTableDataNew(
              mapInheritanceToExpectedNew(markupExpected, inheritanceToUse),
              groupedByData,
              isHistorical ?? false
            ) ?? [];
        });
      });
      return rowsByGenderProductline;
    }
  }, [aggregateOnGenderProductlines, groupedByData, inheritanceToUse, isHistorical, sanitizedData]);

  const tableData = useMemo(() => {
    if (!inheritanceToUse) {
      return;
    }

    const mappedTable = mapSplitToOverviewTableDataNew(
      mapInheritanceToExpectedNew(expected, inheritanceToUse),
      groupedByData,
      isHistorical
    );

    if (!categoryData || !mappedTable) return;

    return mapCategorySplitsToRows(mappedTable, categoryData);
  }, [categoryData, expected, groupedByData, inheritanceToUse, isHistorical]);

  const columns = useMemo(
    () =>
      expected ? createDynamicMonthColumns(expected, { cellRenderer: EditablePercentageCell, aggFunc: 'avg' }) : [],
    [expected]
  );

  return { data: tableData, aggregatedData: aggregatedTableData, columns, error, loading };
};
