import { Module } from 'src/domain';
import { getDefaultProductlineIdFromChainId } from 'src/utils/getDefaultProductlineIdFromChainId';
import { useChainId } from '../chain';
import { useProductLine } from './useProductLine';

export const useProductLineId = (module?: Module) => {
  const chainId = useChainId();
  const defaultProductLineId = getDefaultProductlineIdFromChainId(chainId);
  const productLine = useProductLine(module);

  return productLine?.id ?? defaultProductLineId ?? undefined;
};
