import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { DiscountCellRendererEditor } from 'src/components/molecules/DiscountCellRendererEditor/DiscountCellRendererEditor';
import { EditableValueCell } from 'src/components/molecules/EditableCell/EditableCell';
import { ImageCellRenderer } from 'src/components/molecules/ImageCellRenderer/ImageCellRenderer';
import { SelectCellEditor } from 'src/components/molecules/SelectCellEditor/SelectCellEditor';
import { formatNumber, parseNumber } from 'src/utils/formatNumber';
import { columns as styleTimelineColumns } from '../../StyleTimeline/data/columns';

export const defaultColDef: ColDef = {
  resizable: true,
  sortable: true,
  filter: 'agTextColumnFilter',
  menuTabs: ['filterMenuTab', 'generalMenuTab'],
  tooltipValueGetter(params) {
    return params.valueFormatted ?? params.value;
  },
  enablePivot: false,
  enableValue: false,
  enableRowGroup: false,
};

const salesCampaignPlanningcolumns: ColDef[] = [
  {
    field: 'styleOptionPicture',
    headerName: 'Picture',
    cellRenderer: ImageCellRenderer,
    tooltipValueGetter: () => '',
    suppressFiltersToolPanel: true,
    suppressHeaderMenuButton: true,
    initialWidth: 80,
  },
  {
    field: 'styleName',
    headerName: 'Style Name',
    initialWidth: 400,
  },
  {
    field: 'stockQuantity',
    headerName: 'Stock Quantity',
    initialWidth: 160,
  },
  {
    field: 'salesQuantityPast8Weeks',
    headerName: 'Sales QTY P8W',
    initialWidth: 160,
  },
  {
    field: 'colorName',
    headerName: 'Color',
    initialWidth: 150,
  },
  {
    field: 'variantName',
    headerName: 'Variant',
    initialWidth: 180,
  },
  {
    field: 'recommendedRetailPriceLcy',
    headerName: 'Recommended Retail Price',
    headerTooltip: 'Recommended Retail Price',
    initialWidth: 240,
    valueFormatter: (params) => {
      return formatNumber(params.value) ?? '';
    },
  },
  {
    field: 'discount.discountType',
    headerName: 'Discount Type',
    initialWidth: 160,
    editable: true,
    cellRenderer: EditableValueCell,
    cellEditor: SelectCellEditor,
    cellEditorParams: {
      selectOptions: [
        {
          display: 'Fixed price',
          value: 'FIXED_PRICE',
          isHeader: false,
        },
        {
          display: 'X for fixed price',
          value: 'X_FOR_FIXED_PRICE',
          isHeader: false,
        },
        {
          display: 'X for Y',
          value: 'X_FOR_Y',
          isHeader: false,
        },
      ],
    },
    valueFormatter(params) {
      switch (params.value) {
        case 'FIXED_PRICE':
          return 'Fixed price';
        case 'X_FOR_FIXED_PRICE':
          return 'X for fixed price';
        case 'X_FOR_Y':
          return 'X for Y';
        default:
          return params.value;
      }
    },
    valueSetter(params) {
      if (params.newValue === 'FIXED_PRICE') {
        params.data.discount.valueX = 1;
      }
      params.data.discount.discountType = params.newValue;
      return true;
    },
  },
  {
    field: 'discount',
    headerName: 'Discount',
    valueGetter(params) {
      const y = parseNumber(params.data.discount?.valueY);
      return {
        x: params.data.discount?.valueX,
        y: isNaN(y) ? undefined : y,
        discountType: params.data.discount?.discountType,
      };
    },
    valueSetter(params) {
      const newValue = parseNumber(params.newValue);
      if (!isNaN(newValue)) {
        // this typically happens when the user pastes a number that wasn't copied from the discount cell
        // we should set the pasted value to the Y value
        params.data.discount.valueX = 1;
        params.data.discount.valueY = params.newValue;
        params.data.discount.discountType = 'FIXED_PRICE';
      } else {
        const { x, y, discountType } = params.newValue ?? { x: 1, discountType: 'FIXED_PRICE' };
        params.data.discount.valueX = x;
        params.data.discount.valueY = y;
        params.data.discount.discountType = discountType ?? params.data.discount.discountType;
      }
      return true;
    },
    valueFormatter(params) {
      if (typeof params.value?.x !== 'number' || typeof params.value?.y !== 'number') {
        return '';
      }

      const formattedY: string =
        params.value.discountType === 'X_FOR_Y' ? params.value.y : formatNumber(params.value.y);

      return params.value.discountType === 'FIXED_PRICE' ? formattedY : `${params.value.x} for ${formattedY}`;
    },
    initialWidth: 160,
    tooltipValueGetter(params) {
      const valueFormatter = (params.colDef as ColDef).valueFormatter;
      if (typeof valueFormatter === 'function') {
        return valueFormatter(params as ValueFormatterParams);
      }
      return params.value;
    },
    editable: false,
    cellRenderer: DiscountCellRendererEditor,
    cellStyle: {
      padding: 0,
    },
  },
  {
    field: 'discountPercentage',
    cellDataType: 'number',
    headerName: '% Discount',
    initialWidth: 160,
    editable(params) {
      return typeof params.data.recommendedRetailPriceLcy === 'number';
    },
    cellRenderer: EditableValueCell,
    valueFormatter: (params) => {
      if (typeof params.data.discount?.valueX !== 'number' || typeof params.data.discount?.valueY !== 'number') {
        return '';
      }
      const formatted = formatNumber(params.value, 1);
      return formatted ? `${formatted}%` : '';
    },
  },
  {
    field: 'newSalesPrice',
    headerName: 'New Sales Price',
    initialWidth: 170,
    valueFormatter: (params) => {
      if (typeof params.data.discount?.valueX !== 'number' || typeof params.data.discount?.valueY !== 'number') {
        return '';
      }
      return formatNumber(params.value) ?? '';
    },
  },
  {
    field: 'newGMPercent',
    headerName: 'New GM %',
    initialWidth: 160,
    valueFormatter: (params) => {
      const formatted = formatNumber(params.value);
      return formatted ? `${formatted}%` : '';
    },
  },
  {
    field: 'discount.comment',
    headerName: 'Comment',
    initialWidth: 200,
    editable: true,
    cellRenderer: EditableValueCell,
  },
];

export const columns = [
  ...salesCampaignPlanningcolumns,
  // Include all style timeline columns that are not already included in salesCampaignPlanningcolumns, except for collections - but make them hidden by default and non-editable
  ...styleTimelineColumns
    .filter(
      (column) =>
        column.field !== 'collections' &&
        !salesCampaignPlanningcolumns.find((planningCol) => planningCol.field === column.field)
    )
    .map((column) => ({
      ...column,
      editable: false,
      cellRenderer: column.cellRenderer === EditableValueCell ? undefined : column.cellRenderer,
      hide: true,
      rowGroup: false,
      enableRowGroup: false,
      suppressColumnsToolPanel: false,
    })),
];
