import { useMemo } from 'react';
import { EditablePercentageCell } from 'src/components/molecules/EditableCell/EditableCell';
import { createDynamicMonthColumns } from 'src/components/organisms/DataTable/columns/utils';
import { Inheritance, Module } from 'src/domain';
import { useInheritance } from 'src/hooks/inheritance';
import { mapSplitToOverviewTableDataNew } from 'src/mapping/split-modules.mapping';
import { useGenderSplitQuery } from '../queries/useGenderSplitQuery';

export const useGenderSplitOverviewRows = (inheritance?: Inheritance) => {
  const moduleInheritance = useInheritance(Module.GenderSplit);
  const inheritanceToUse = inheritance ?? moduleInheritance;
  const isHistorical = inheritanceToUse === Inheritance.Historical;
  const { expected, genders, error, loading } = useGenderSplitQuery(inheritanceToUse);

  const tableData = useMemo(
    () => mapSplitToOverviewTableDataNew(expected, genders, isHistorical ?? false),
    [expected, genders, isHistorical]
  );

  const columns = useMemo(
    () =>
      expected
        ? createDynamicMonthColumns(
            // making typescript happy by ensuring values are of the right type before casting
            expected
              .map((value) => value?.monthKey)
              .filter((value) => !!value)
              .map((value) => ({ monthKey: value })) as { monthKey: number }[],
            {
              cellRenderer: EditablePercentageCell,
            }
          )
        : [],
    [expected]
  );

  return { data: tableData, columns, error, loading };
};
