import { Inheritance, Module } from 'src/domain';
import { DeliveryProfileRow } from 'src/domain/table/delivery-profile.row';
import { useInheritance } from 'src/hooks/inheritance';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { useGenderProductLineKey } from 'src/hooks/useGenderProductLineKey';
import { useDeliveryProfileOverviewRows } from '../table/useDeliveryProfileOverviewRows';

export const useDeliveryProfileOverviewSimulationRows = (inheritance?: Inheritance) => {
  const { data, columns, error, loading } = useDeliveryProfileOverviewRows(inheritance);
  const [unsavedChanges] = useUnsavedChangesModule<Record<string, DeliveryProfileRow[]>>(Module.DeliveryProfile);
  const genderProductLineKey = useGenderProductLineKey();
  const realInheritance = useInheritance();
  const actualInheritance = inheritance ?? realInheritance;
  const isTyped = actualInheritance === Inheritance.Typed;

  const unsavedChangesGenderProductLine = unsavedChanges?.[genderProductLineKey];
  const hasUnsavedChanges = !!unsavedChangesGenderProductLine;
  const overviewRows = isTyped && hasUnsavedChanges ? unsavedChangesGenderProductLine : data;

  return { data: overviewRows, columns, error, loading };
};
