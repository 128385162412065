import { SvgIcon } from '@mui/material';
import { FC } from 'react';

export const StockManagementIcon: FC = () => {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 81 60">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.9405 17.9147C69.9555 17.9147 69.9604 17.8944 69.947 17.8876L35.4556 0.233214C35.1695 0.0867718 34.8305 0.0867724 34.5444 0.233214L0.0529835 17.8876C0.0395709 17.8944 0.0444536 17.9147 0.059521 17.9147V17.9147C0.0674454 17.9147 0.0738693 17.9211 0.0738693 17.929V59C0.0738693 59.5523 0.521586 60 1.07387 60H9.05276C9.60505 60 10.0528 59.5523 10.0528 59V30.2891C10.0528 29.7368 10.5005 29.2891 11.0528 29.2891H58.9472C59.4995 29.2891 59.9472 29.7368 59.9472 30.2891V32C59.9472 32.5523 60.395 33 60.9472 33H68.9261C69.4784 33 69.9261 32.5523 69.9261 32V17.929C69.9261 17.9211 69.9325 17.9147 69.9405 17.9147V17.9147ZM28 14.5C28 10.36 31.3525 7 35.4925 7C39.64 7 43 10.36 43 14.5C43 18.64 39.64 22 35.4925 22C31.3525 22 28 18.64 28 14.5ZM29.5 14.5C29.5 17.815 32.185 20.5 35.5 20.5C38.815 20.5 41.5 17.815 41.5 14.5C41.5 11.185 38.815 8.5 35.5 8.5C32.185 8.5 29.5 11.185 29.5 14.5ZM35.875 11.3125C35.875 11.0018 35.6232 10.75 35.3125 10.75V10.75C35.0018 10.75 34.75 11.0018 34.75 11.3125V14.6838C34.75 15.0351 34.9343 15.3606 35.2355 15.5413L38.2287 17.3372C38.4826 17.4896 38.8119 17.4085 38.9661 17.1557V17.1557C39.1223 16.8994 39.0391 16.5648 38.7809 16.4117L36.3647 14.9781C36.0611 14.7979 35.875 14.4711 35.875 14.1181V11.3125Z"
        />
        <path d="M77 56.5C77 58.433 75.2091 60 73 60C70.7909 60 69 58.433 69 56.5C69 54.567 70.7909 53 73 53C75.2091 53 77 54.567 77 56.5Z" />
        <path d="M77 56.5C77 58.433 75.2091 60 73 60C70.7909 60 69 58.433 69 56.5C69 54.567 70.7909 53 73 53C75.2091 53 77 54.567 77 56.5Z" />
        <path d="M77 56.5C77 58.433 75.2091 60 73 60C70.7909 60 69 58.433 69 56.5C69 54.567 70.7909 53 73 53C75.2091 53 77 54.567 77 56.5Z" />
        <path d="M46 56.5C46 58.433 44.2091 60 42 60C39.7909 60 38 58.433 38 56.5C38 54.567 39.7909 53 42 53C44.2091 53 46 54.567 46 56.5Z" />
        <path d="M46 56.5C46 58.433 44.2091 60 42 60C39.7909 60 38 58.433 38 56.5C38 54.567 39.7909 53 42 53C44.2091 53 46 54.567 46 56.5Z" />
        <path d="M46 56.5C46 58.433 44.2091 60 42 60C39.7909 60 38 58.433 38 56.5C38 54.567 39.7909 53 42 53C44.2091 53 46 54.567 46 56.5Z" />
        <path d="M49 37C49 36.4477 49.4477 36 50 36H80C80.5523 36 81 36.4477 81 37V55C81 55.5523 80.5523 56 80 56H78.8581C78.3517 56 77.9378 55.6148 77.7711 55.1366C77.1166 53.2588 75.1459 52 73 52C70.8541 52 68.8834 53.2588 68.2289 55.1366C68.0622 55.6148 67.6483 56 67.1419 56H50C49.4477 56 49 55.5523 49 55V37Z" />
        <path d="M49 37C49 36.4477 49.4477 36 50 36H80C80.5523 36 81 36.4477 81 37V55C81 55.5523 80.5523 56 80 56H78.8581C78.3517 56 77.9378 55.6148 77.7711 55.1366C77.1166 53.2588 75.1459 52 73 52C70.8541 52 68.8834 53.2588 68.2289 55.1366C68.0622 55.6148 67.6483 56 67.1419 56H50C49.4477 56 49 55.5523 49 55V37Z" />
        <path d="M49 37C49 36.4477 49.4477 36 50 36H80C80.5523 36 81 36.4477 81 37V55C81 55.5523 80.5523 56 80 56H78.8581C78.3517 56 77.9378 55.6148 77.7711 55.1366C77.1166 53.2588 75.1459 52 73 52C70.8541 52 68.8834 53.2588 68.2289 55.1366C68.0622 55.6148 67.6483 56 67.1419 56H50C49.4477 56 49 55.5523 49 55V37Z" />
        <path d="M73 53C74.5506 53 75.895 53.772 76.5589 54.9006C76.8715 55.4322 76.3994 56 75.7827 56H70.2173C69.6006 56 69.1285 55.4322 69.4411 54.9006C70.105 53.772 71.4494 53 73 53Z" />
        <path d="M73 53C74.5506 53 75.895 53.772 76.5589 54.9006C76.8715 55.4322 76.3994 56 75.7827 56H70.2173C69.6006 56 69.1285 55.4322 69.4411 54.9006C70.105 53.772 71.4494 53 73 53Z" />
        <path d="M73 53C74.5506 53 75.895 53.772 76.5589 54.9006C76.8715 55.4322 76.3994 56 75.7827 56H70.2173C69.6006 56 69.1285 55.4322 69.4411 54.9006C70.105 53.772 71.4494 53 73 53Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34 46.4142C34 46.149 34.1054 45.8946 34.2929 45.7071L38.7071 41.2929C38.8946 41.1054 39.149 41 39.4142 41H47C47.5523 41 48 41.4477 48 42V55C48 55.5523 47.5523 56 47 56V56C46.9831 56 46.9689 55.9872 46.9668 55.9705C46.6716 53.6409 44.45 52 42 52C39.8541 52 37.8834 53.2588 37.2289 55.1366C37.0623 55.6148 36.6483 56 36.1419 56H35C34.4477 56 34 55.5523 34 55V46.4142ZM39.6312 43C39.4845 43 39.3453 43.0644 39.2503 43.1762L36.7003 46.1762C36.4242 46.5009 36.655 47 37.0812 47H44.5C44.7761 47 45 46.7761 45 46.5V43.5C45 43.2239 44.7761 43 44.5 43H39.6312Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34 46.4142C34 46.149 34.1054 45.8946 34.2929 45.7071L38.7071 41.2929C38.8946 41.1054 39.149 41 39.4142 41H47C47.5523 41 48 41.4477 48 42V55C48 55.5523 47.5523 56 47 56V56C46.9831 56 46.9689 55.9872 46.9668 55.9705C46.6716 53.6409 44.45 52 42 52C39.8541 52 37.8834 53.2588 37.2289 55.1366C37.0623 55.6148 36.6483 56 36.1419 56H35C34.4477 56 34 55.5523 34 55V46.4142ZM39.6312 43C39.4845 43 39.3453 43.0644 39.2503 43.1762L36.7003 46.1762C36.4242 46.5009 36.655 47 37.0812 47H44.5C44.7761 47 45 46.7761 45 46.5V43.5C45 43.2239 44.7761 43 44.5 43H39.6312Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34 46.4142C34 46.149 34.1054 45.8946 34.2929 45.7071L38.7071 41.2929C38.8946 41.1054 39.149 41 39.4142 41H47C47.5523 41 48 41.4477 48 42V55C48 55.5523 47.5523 56 47 56V56C46.9831 56 46.9689 55.9872 46.9668 55.9705C46.6716 53.6409 44.45 52 42 52C39.8541 52 37.8834 53.2588 37.2289 55.1366C37.0623 55.6148 36.6483 56 36.1419 56H35C34.4477 56 34 55.5523 34 55V46.4142ZM39.6312 43C39.4845 43 39.3453 43.0644 39.2503 43.1762L36.7003 46.1762C36.4242 46.5009 36.655 47 37.0812 47H44.5C44.7761 47 45 46.7761 45 46.5V43.5C45 43.2239 44.7761 43 44.5 43H39.6312Z"
        />
        <path d="M42 53C43.5505 53 44.895 53.772 45.5589 54.9006C45.8715 55.4322 45.3994 56 44.7827 56H39.2173C38.6006 56 38.1285 55.4322 38.4411 54.9006C39.105 53.772 40.4495 53 42 53Z" />
        <path d="M42 53C43.5505 53 44.895 53.772 45.5589 54.9006C45.8715 55.4322 45.3994 56 44.7827 56H39.2173C38.6006 56 38.1285 55.4322 38.4411 54.9006C39.105 53.772 40.4495 53 42 53Z" />
        <path d="M42 53C43.5505 53 44.895 53.772 45.5589 54.9006C45.8715 55.4322 45.3994 56 44.7827 56H39.2173C38.6006 56 38.1285 55.4322 38.4411 54.9006C39.105 53.772 40.4495 53 42 53Z" />
      </svg>
    </SvgIcon>
  );
};
