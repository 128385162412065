import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const url = useLocation();
  return useMemo(() => {
    const params = new URLSearchParams(url.search);

    const pathname = url.pathname.replace('/', '');
    const storeId = params.get('storeId');
    const type = params.get('type');
    const storeType = params.get('storeType');
    const chain = params.get('chain');
    const chainId = params.get('chainId');
    const country = params.get('country');
    const countryId = params.get('countryId');
    const cluster = params.get('cluster');
    const clusterId = params.get('clusterId');
    const partner = params.get('partner');
    const partnerId = params.get('partnerId');

    return {
      storeId,
      type,
      storeType,
      chain,
      chainId,
      country,
      countryId,
      cluster,
      clusterId,
      pathname,
      partner,
      partnerId,
    };
  }, [url]);
};
