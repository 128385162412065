import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type Size = 'small' | 'medium' | 'large';

interface Props {
  size?: Size;
  className?: string;
}

const CenteredContainer: FC<PropsWithChildren<Props>> = ({ size, children, className }) => {
  return (
    <StyledContainer size={size ?? 'medium'} className={className}>
      {children}
    </StyledContainer>
  );
};

const StyledContainer = styled(Paper)<{ size?: Size }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => {
    switch (props.size) {
      case 'small':
        return '.25em .5em';
      case 'medium':
        return '.5em 1em';
      case 'large':
        return '1em 2em';
      default:
        return '1em 2em';
    }
  }};
`;

export default CenteredContainer;
