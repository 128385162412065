import { useCallback, useState } from 'react';
import { Inheritance, Module, ScopeEnum } from 'src/domain';
import { TableOverviewRow } from 'src/domain/table/table-overview-row';
import { useChangesSnackbar, useCurrentId, useDiscardChangesGenderProductline, useScope } from 'src/hooks';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useUpdateSplitLevel } from 'src/hooks/split-levels/useUpdateSplitLevel';
import { useEntityType } from 'src/hooks/useEntityType';
import { components } from 'src/infrastructure/rest-api/generated';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { useNoosShareOverviewSimulationRows } from '../simulation/useNoosShareOverviewSimulationRows';

type UpdateNoosShareInput = components['schemas']['UpdateNoosShareInput'];

function valueMapper(row: TableOverviewRow): UpdateNoosShareInput[] {
  return Object.keys(row.columns).reduce<UpdateNoosShareInput[]>((acc, key) => {
    if (key.endsWith('Id') || key.endsWith('PlannedSplit')) return acc;

    const id = row.columns[`${key}Id`];
    const value = row.columns[key];

    acc.push({
      id: id,
      splitPercent: value,
    });

    return acc;
  }, []);
}

export const useNoosShareSaveChanges = () => {
  const currentId = useCurrentId();
  const compositePartner = useCompositePartner();
  const entityType = useEntityType();
  const scope = useScope();

  const [mutateNoosShare] = useApiMutation('/api/noosshare', 'put', {
    update(data, _variables, queryClient) {
      const query = { entityId: Number(currentId), entityType };
      if (scope === ScopeEnum.PARTNER) {
        query['entityId'] = Number(compositePartner?.id);
      }
      queryClient.setQueryData(['/api/noosshare', query], data);
      queryClient.invalidateQueries({
        queryKey: ['/api/inheritance', query],
      });
    },
  });

  const { updateSplitLevel } = useUpdateSplitLevel();

  const showSnackbar = useChangesSnackbar();
  const discardChanges = useDiscardChangesGenderProductline(Module.NoosShare);

  const { data: valuesToUpdate } = useNoosShareOverviewSimulationRows();
  const [isUpdating, setIsUpdating] = useState(false);
  const sendUpdateQuery = useCallback(async () => {
    if (!valuesToUpdate) {
      return;
    }

    const payload = valuesToUpdate.flatMap(valueMapper);

    return mutateNoosShare({
      body: payload,
    }).then(() => {
      if (scope === ScopeEnum.STORE) {
        return updateSplitLevel(Inheritance.Typed);
      }
    });
  }, [mutateNoosShare, scope, updateSplitLevel, valuesToUpdate]);

  const updateNoosShare = useCallback(async () => {
    setIsUpdating(true);

    try {
      const response = await sendUpdateQuery();
      discardChanges();
      showSnackbar();
      return response;
    } catch {
      showSnackbar(true);
    } finally {
      setIsUpdating(false);
    }
  }, [discardChanges, sendUpdateQuery, showSnackbar]);

  return [updateNoosShare, { loading: isUpdating }] as const;
};
