import { Skeleton } from '@mui/material';
import { isNumber } from 'lodash';
import { FC } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { TurnoverRow, TurnoverRowTuple } from 'src/domain';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';

interface Props {
  data: (TurnoverRow | TurnoverRowTuple)[] | undefined;
  loading?: boolean;
  overview?: boolean;
}
export const TurnoverLineChart: FC<Props> = ({ data, loading, overview }) => {
  if (loading) return <Skeleton variant="rectangular" width={'100%'} height={400} />;

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart width={730} height={250} data={data} margin={{ top: 25, left: 20, right: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" tick={{ fontSize: 12 }} angle={-45} textAnchor="end" height={70} />
        <YAxis tick={{ fontSize: 12 }} />
        <Tooltip
          formatter={(value) => {
            if (!data) return;
            if (isNumber(value)) return numberWithSpaces(value);
            return value;
          }}
        />
        <Legend />
        <Line connectNulls type="monotone" dataKey="salesIvLy" name="Sales iV Ly" stroke="#007BA7" />
        <Line connectNulls type="monotone" dataKey="salesIvLly" name="Sales iV Lly" stroke="#FF7F50" />
        <Line
          connectNulls
          type="monotone"
          dataKey={overview ? 'plannedSalesIv.[0]' : 'plannedSalesIv'}
          name="Planned sales iV"
          stroke="#4CD966"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
