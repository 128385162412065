import styled from '@emotion/styled';
import { ArrowForwardIos } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { AgRowNodeEventListener } from 'node_modules/ag-grid-community/dist/types/core/interfaces/iRowNode';
import { useCallback, useEffect, useState } from 'react';
import {
  OptimalOptionCountStylesPer100SQMLabel,
  OptimalOptionCountTotalLabel,
} from 'src/mapping/optimal-option-count.mapping';

// custom params to be used with cellRendererParams
export type EditablePercentageCellParams = ICellRendererParams & {
  rowGroupField: string;
  rowGroupTotalField?: string;
  aggregationLabel?: string;
};

export const RowGroupCell = (componentProps: EditablePercentageCellParams) => {
  const { valueFormatted, value, node, rowGroupField, aggregationLabel = 'Sum' } = componentProps;

  const isFooter = node?.data?.isFooter;

  if (node?.aggData && !node?.field) {
    return aggregationLabel;
  }

  if (valueFormatted === undefined) {
    return <div>{value}</div>;
  }

  if ((node.field === rowGroupField || valueFormatted === 'Stock Room') && !isFooter) {
    return <ProductLineCellWrapper>{valueFormatted}</ProductLineCellWrapper>;
  }

  if (isFooter) {
    return <div>{valueFormatted ?? value}</div>;
  }

  return <GenderCellWrapper>{valueFormatted}</GenderCellWrapper>;
};

export function GroupedExpandableCell(componentProps: EditablePercentageCellParams) {
  const { node, valueFormatted, value, column } = componentProps;

  const [expanded, setExpanded] = useState(node.expanded);

  useEffect(() => {
    const expandListener: AgRowNodeEventListener<'expandedChanged', unknown> = (event) =>
      setExpanded(event.node.expanded);

    node.addEventListener('expandedChanged', expandListener);

    return () => {
      node.removeEventListener('expandedChanged', expandListener);
    };
  }, [node]);

  const onClick = useCallback(() => {
    if (['BACK', 'FRONT', 'MID', 'STOCK_ROOM'].includes(value)) return;
    node.setExpanded(!node.expanded);
  }, [node, value]);

  if ((node?.field === 'category' || valueFormatted === 'Stock Room') && column?.getColId() === 'ag-Grid-AutoColumn') {
    return <ProductLineCellWrapper style={{ paddingLeft: '15px' }}>{valueFormatted}</ProductLineCellWrapper>;
  }

  if (
    valueFormatted === OptimalOptionCountTotalLabel ||
    valueFormatted === OptimalOptionCountStylesPer100SQMLabel ||
    valueFormatted === 'Total'
  ) {
    return <div style={{ paddingLeft: '15px' }}>{valueFormatted}</div>;
  }

  return (
    <Tooltip title={value} placement="right-end">
      <Wrapper level={node.level}>
        {node.group && (
          <ArrowForwardIos
            sx={{
              fontSize: '16px',
              cursor: 'pointer',
              transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
              display: 'inline-block',
            }}
            onClick={onClick}
          />
        )}
        &nbsp;
        <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{value}</span>
      </Wrapper>
    </Tooltip>
  );
}

const Wrapper = styled.div<{ level: number }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: ${({ level }) => level * 15}px;
`;

const ProductLineCellWrapper = styled.div`
  font-size: 16px;
  height: 22px;
  font-weight: bold;
`;

const GenderCellWrapper = styled.div`
  padding-left: 10px;
`;
