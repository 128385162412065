import { useMemo } from 'react';
import { Module } from 'src/domain';
import { AuditLogEnum } from 'src/infrastructure/rest-api/api-types';
import { useCurrentModule } from '../useCurrentModule';

export const useAuditLogModuleType = (): AuditLogEnum | null => {
  const currentModule = useCurrentModule();

  // ! FIXME: Could we reuse some typings to prevent this insane mapping function?
  const moduleType = useMemo(() => {
    switch (currentModule) {
      case Module.Turnover:
        return 'Turnover';
      case Module.CategorySplit:
        return 'CategorySplit';
      case Module.BuyingEventManagement:
        return 'BuyingEvent';
      case Module.DeliveryProfile:
        return 'DeliveryProfile';
      case Module.GenderSplit:
        return 'GenderSplit';
      case Module.Markdown:
        return 'Markdown';
      case Module.Markup:
        return 'Markup';
      case Module.NoosShare:
        return 'NoosShare';
      case Module.OpenToBuyLimit:
        return 'OpenToBuyLimit';
      case Module.ProductLineSplit:
        return 'GenderProductLineSplit';
      case Module.WeekCover:
        return 'WeekCover';
      case Module.StockManagement:
        return 'StockManagement';
      case Module.OptimalOptionCount:
        return 'OptimalOptionCount';
      default:
        return null;
    }
  }, [currentModule]);

  return moduleType;
};
