import { useEffect, useState } from 'react';
import { useRoutes } from 'src/useRoutes';
import { useCurrentModule } from './useCurrentModule';

export const useDynamicTitle = () => {
  const routes = useRoutes();
  const [defaultTitle] = useState(import.meta.env.VITE_APP_NAME ?? 'StoreForecast');
  const currentModule = useCurrentModule();
  const moduleTitle = routes.find((x) => x.path.includesInsensitive(currentModule))?.title;

  useEffect(() => {
    if (moduleTitle) document.title = `${defaultTitle} - ${moduleTitle}`;

    return () => {
      document.title = defaultTitle;
    };
  }, [defaultTitle, moduleTitle]);
};
