import { SvgIcon } from '@mui/material';
import { FC } from 'react';

export const GenderIcon: FC = () => {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 7C15.43 7 17 8.57 17 10.5C17 12.43 15.43 14 13.5 14C11.57 14 10 12.43 10 10.5C10 8.57 11.57 7 13.5 7ZM13.5 5C10.46 5 8 7.46 8 10.5C8 13.54 10.46 16 13.5 16C16.54 16 19 13.54 19 10.5C19 9.34 18.64 8.27 18.03 7.38L22 3.42V6H24V0H18V2H20.58L16.61 5.97C15.73 5.36 14.66 5 13.5 5Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5915 15.6605C12.1414 15.1876 12.5963 14.6077 12.9239 13.9526C12.2373 13.8384 11.6176 13.5237 11.1273 13.0709C10.8203 13.6796 10.3408 14.1869 9.75342 14.5283C10.2802 15.0184 10.9033 15.4062 11.5915 15.6605ZM9.27329 14.7603C9.80454 15.2874 10.434 15.7157 11.1319 16.0154C10.5039 16.4496 9.7808 16.7566 9 16.9V19H11V21H9V23H7V21H5V19H7V16.9C4.44 16.43 2.5 14.2 2.5 11.5C2.5 8.46 4.96 6 8 6C8.46539 6 8.91718 6.05765 9.34863 6.16621C8.80566 6.68643 8.36043 7.30783 8.04305 8.00026C8.02872 8.00009 8.01437 8 8 8C6.07 8 4.5 9.57 4.5 11.5C4.5 13.43 6.07 15 8 15C8.44899 15 8.8785 14.915 9.27329 14.7603ZM8.5749 8.04719C8.90145 7.39207 9.35484 6.81148 9.903 6.33748C10.5934 6.59168 11.2184 6.98029 11.7466 7.47165C11.1592 7.81306 10.6797 8.32044 10.3727 8.92912C9.8821 8.47602 9.26199 8.1612 8.5749 8.04719ZM11.3323 12.5716C11.4412 12.2337 11.5 11.8736 11.5 11.5C11.5 10.6785 11.2156 9.92226 10.74 9.32453C11.0125 8.68744 11.4993 8.16295 12.1093 7.84238C12.9746 8.814 13.5 10.0951 13.5 11.5C13.5 12.1981 13.3703 12.8648 13.1337 13.4777C12.4292 13.3916 11.7993 13.0601 11.3323 12.5716Z"
        />
      </svg>
    </SvgIcon>
  );
};
