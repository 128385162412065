import { GroupLevel } from 'src/domain';
import { useQueryParams } from './useQueryParams';

export const useType = () => {
  const { type } = useQueryParams();

  switch (type) {
    case 'Country':
      return GroupLevel.Country;
    case 'Cluster':
      return GroupLevel.Cluster;
    default:
      return null;
  }
};
