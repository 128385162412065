import { ColDef } from 'ag-grid-community';
import { addMonths, endOfWeek, isAfter, isBefore } from 'date-fns';
import { sortBy } from 'lodash';
import { createDynamicMonthColumns } from 'src/components/organisms/DataTable/columns/utils';
import { getHeatMapColor } from 'src/components/views/ProjectedOptionCount/data/columns';
import { ProjectedOptionCountRow } from 'src/domain/table/projected-option-count.row';
import { IntersectedStyleTimelineData } from 'src/hooks/style-timeline/queries/useStyleTimelineLevelQuery';

import {
  PlanningRangeEnum,
  StyleCategoryModel,
  StyleTimelineStorePlacementEnum,
} from 'src/infrastructure/rest-api/api-types';
import { formatWeekYearAsDate } from 'src/utils/formatAsWeekYear';
import { formatNumber, parseNumber } from 'src/utils/formatNumber';
import { monthKeyToDateRange, monthKeyToField } from 'src/utils/monthKeys';
import { createRangeRemapper } from 'src/utils/rangeRemapper';

export enum HeatMapTypeEnum {
  Category,
  Placement,
}

export interface Range<T = Date> {
  start: Date;
  end: T;
}

export function getOverlapRangeWithinMonth(range: Range<Date | null>, monthRange: Range): Range<Date | null> | null {
  let startDate: Date | null = null;
  let endDate: Date | null = null;

  if (range.start > monthRange.start) {
    startDate = range.start;
  } else {
    startDate = monthRange.start;
  }

  if (!range.end && monthRange.end) {
    endDate = monthRange.end;
  }

  if (range.end && !monthRange.end) {
    endDate = range.end;
  }

  if (range.end && range.end < monthRange.end) {
    endDate = range.end;
  }

  if (range.end && range.end > monthRange.end) {
    endDate = monthRange.end;
  }

  if (startDate > monthRange.end) {
    // invalid - not in month
    return null;
  }

  if (endDate && endDate > monthRange.end) {
    endDate = monthRange.end;
  }

  return {
    start: startDate,
    end: endDate,
  };
}

export function isStyleTimelineModelInMonth(model: IntersectedStyleTimelineData, monthKey: number) {
  // 1. convert month to date range
  const { monthStartDate, monthEndDate } = monthKeyToDateRange(monthKey);

  if (model?.startWeek === undefined) return false;

  const startWeekDate = formatWeekYearAsDate(model?.startWeek);
  const exitWeekDate = model?.exitWeek ? formatWeekYearAsDate(model.exitWeek) : null;

  // If start week is after month end or exit week is before month start, then the model is not included in the month
  if (startWeekDate > monthEndDate || (exitWeekDate && exitWeekDate < monthStartDate)) {
    return false;
  }

  // 2. get start and end dates for overlap within month
  const overlapRange = getOverlapRangeWithinMonth(
    {
      start: startWeekDate,
      end: exitWeekDate,
    },
    {
      start: monthStartDate,
      end: monthEndDate,
    }
  );

  if (!overlapRange) {
    return false;
  }

  // 3. if packaway has been set, check if packaway overlaps with the start/end dates
  const packawayStartWeekDate = model.packawayStartWeek ? formatWeekYearAsDate(model.packawayStartWeek) : null;
  const packawayEndWeekDate = model.packawayEndWeek ? endOfWeek(formatWeekYearAsDate(model.packawayEndWeek)) : null;

  const isPackedAway =
    packawayStartWeekDate && packawayEndWeekDate
      ? !(
          isBefore(overlapRange.start, packawayStartWeekDate) ||
          overlapRange.end === null ||
          isAfter(overlapRange.end, packawayEndWeekDate)
        )
      : false;

  return !isPackedAway;
}

export function groupByPlacement(data: IntersectedStyleTimelineData[]) {
  return data.reduce(
    (acc, model) => {
      const placement = model?.storePlacement as StyleTimelineStorePlacementEnum;
      if (!placement) {
        return acc;
      }

      const items = acc.get(placement) ?? [];
      items.push(model);

      acc.set(placement, items);

      return acc;
    },
    new Map<StyleTimelineStorePlacementEnum, IntersectedStyleTimelineData[]>([
      ['FRONT', []],
      ['MID', []],
      ['BACK', []],
      ['STOCK_ROOM', []],
    ])
  );
}

export interface MapToProjectedOptionCountHeatMapRowsProps {
  styleTimelineData: IntersectedStyleTimelineData[];
  styleCategories: StyleCategoryModel[];
  type: HeatMapTypeEnum;
  planningRange?: PlanningRangeEnum;
}

export interface MapToProjectedOptionCountHeatMapRowsReturnType {
  rows: ProjectedOptionCountRow[];
  columns: ColDef<ProjectedOptionCountRow>[];
}

function getHeatMapRow(models: IntersectedStyleTimelineData[], monthKeys: number[]) {
  return monthKeys.reduce(
    (row, monthKey) => {
      const monthField = monthKeyToField(monthKey);

      if (typeof row.columns[monthField] !== 'number') {
        row.columns[monthField] = 0;
      }

      models.forEach((model) => {
        if (isStyleTimelineModelInMonth(model, monthKey)) {
          row.columns[monthField] += 1;
        }
      });

      if (row.columns[monthField] > row.maxValue) {
        row.maxValue = row.columns[monthField];
      }

      if (row.columns[monthField] < row.minValue) {
        row.minValue = row.columns[monthField];
      }

      return row;
    },
    {
      minValue: Number.MAX_SAFE_INTEGER,
      maxValue: 0,
      columns: {},
    } as ProjectedOptionCountRow
  );
}

export function createSumRow(rows: ProjectedOptionCountRow[]): ProjectedOptionCountRow {
  const sumRow = rows.reduce<ProjectedOptionCountRow>(
    (acc, row) => {
      Object.keys(row.columns).forEach((key) => {
        if (!acc.columns[key]) {
          acc.columns[key] = 0;
        }
        if (typeof row.columns[key] === 'number' && !['Left', 'Right'].includes(row.category)) {
          acc.columns[key] += row.columns[key];
        }
      });

      return acc;
    },
    {
      id: 9999,
      category: 'Total',
      minValue: Number.MAX_SAFE_INTEGER,
      maxValue: 0,
      columns: {},
    } as ProjectedOptionCountRow
  );

  Object.keys(sumRow.columns).forEach((key) => {
    if (sumRow.columns[key] > sumRow.maxValue) {
      sumRow.maxValue = sumRow.columns[key];
    }

    if (sumRow.columns[key] < sumRow.minValue) {
      sumRow.minValue = sumRow.columns[key];
    }
  });

  return sumRow;
}

export function createCategoryRows(
  monthKeys: number[],
  styleTimelineData: IntersectedStyleTimelineData[],
  styleCategories: StyleCategoryModel[] | undefined
): ProjectedOptionCountRow[] {
  // For each category, get the models and then for each month, check if the model is in the month

  const sortedStyleCategories = sortBy(styleCategories, (x) => x?.order);

  return sortedStyleCategories.reduce<ProjectedOptionCountRow[]>((acc, styleCategory) => {
    const models = styleTimelineData.filter((model) => model?.styleCategoryId === styleCategory?.id);
    const row = getHeatMapRow(models, monthKeys);

    const categoryId = styleCategory?.id;
    if (!categoryId) return acc;

    row.id = styleCategory?.id;
    row.category = styleCategory?.name ?? '';
    acc.push(row);

    return acc;
  }, []);
}

export function createPlacementRows(
  monthKeys: number[],
  styleTimelineData: IntersectedStyleTimelineData[]
): ProjectedOptionCountRow[] {
  const groupedByPlacement = groupByPlacement(styleTimelineData);

  return Array.from(groupedByPlacement.entries()).reduce<ProjectedOptionCountRow[]>((acc, [placement, models]) => {
    const row = getHeatMapRow(models, monthKeys);

    switch (placement) {
      case 'FRONT':
        row.id = 1;
        row.category = 'Front';
        break;
      case 'MID':
        row.id = 4;
        row.category = 'Mid';
        break;
      case 'BACK':
        row.id = 7;
        row.category = 'Back';
        break;
      case 'STOCK_ROOM':
        row.category = 'Stock room';
        row.id = 10;
        break;
    }
    acc.push(row);
    if (placement !== 'STOCK_ROOM') {
      const leftModels = models.filter((model) => model?.storePlacementDetail === 'LEFT');

      const leftRow = getHeatMapRow(leftModels, monthKeys);
      leftRow.id = row.id + 1;
      leftRow.category = 'Left';

      const rightModels = models.filter((model) => model?.storePlacementDetail === 'RIGHT');

      const rightRow = getHeatMapRow(rightModels, monthKeys);
      rightRow.id = row.id + 2;
      rightRow.category = 'Right';

      acc.push(leftRow);
      acc.push(rightRow);
    }

    return acc;
  }, []);
}

export function mapStyleTimelineDataToProjectedOptionCountHeatMapRows({
  styleTimelineData,
  styleCategories,
  type,
  planningRange,
}: MapToProjectedOptionCountHeatMapRowsProps): MapToProjectedOptionCountHeatMapRowsReturnType {
  // Define month keys to include in the table
  const monthKeysLength = planningRange === 'EIGHTEEN_MONTHS' ? 18 : 12;
  const monthKeys = Array.from({ length: monthKeysLength }).map((_, index) => {
    const date = new Date();
    const newDate = addMonths(date, index);

    return Number(`${newDate.getFullYear()}${(newDate.getMonth() + 1).toString().padStart(2, '0')}`);
  });

  const rows: ProjectedOptionCountRow[] =
    type === HeatMapTypeEnum.Category
      ? createCategoryRows(monthKeys, styleTimelineData, styleCategories)
      : createPlacementRows(monthKeys, styleTimelineData);

  const monthSumValues = createSumRow(rows);
  rows.push(monthSumValues);

  const columns = createDynamicMonthColumns<ProjectedOptionCountRow>(
    monthKeys.map((monthKey) => ({ monthKey })),
    {
      fieldPrefix: 'columns',
      cellRenderer: undefined,
      editable: false,
      valueFormatter({ value }) {
        return formatNumber(parseNumber(value), 0);
      },
      cellStyle({ value, data }) {
        if (!data) {
          return;
        }

        const valueAsNumber = parseNumber(value);

        const remap = createRangeRemapper([
          data.minValue === data.maxValue ? data.maxValue - 1 : data.minValue,
          data.maxValue,
        ]);

        const backgroundColor = valueAsNumber === 0 ? '#fff' : getHeatMapColor(remap(valueAsNumber));
        const fontWeight = data.category === 'Total' ? 'bold' : 'normal';

        return {
          backgroundColor,
          fontWeight,
        };
      },
    }
  );

  return { rows, columns };
}
