import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { FC } from 'react';

const NotAuthorized: FC = () => {
  return (
    <Container>
      <Typography mt={2}>Please wait, you&apos;re being redirected to the sign-in page.</Typography>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default NotAuthorized;
