import { useMemo } from 'react';
import { StyleTimelineOverviewRow } from 'src/domain/table/style-timeline-overview-row';
import { mapStyleTimelineDataToOverviewRows } from 'src/mapping/style-timeline.mapping';
import { useStores } from '../store-selection/queries/useStores';
import { useStyleCategoriesQuery } from '../style-categories/queries/useStyleCategoriesQuery';
import { usePartnerStyleTimelineQuery } from '../style-timeline/queries/usePartnerStyleTimelineQuery';
import { useStyleTimelineCollectionsQuery } from '../style-timeline/queries/useStyleTimelineCollectionsQuery';

export const useGrossList = (storefrontType: string | undefined | null) => {
  const { data: partnerStyleTimelines, loading, error } = usePartnerStyleTimelineQuery(storefrontType);
  const {
    data: collections,
    loading: collectionsLoading,
    error: collectionsError,
  } = useStyleTimelineCollectionsQuery(storefrontType);
  const {
    data: styleCategories,
    loading: styleCategoriesLoading,
    error: styleCategoriesError,
  } = useStyleCategoriesQuery();

  const { data: stores } = useStores();

  const styleTimelineData = useMemo(
    () =>
      mapStyleTimelineDataToOverviewRows(
        partnerStyleTimelines,
        collections,
        styleCategories,
        stores.allStores
      ) as StyleTimelineOverviewRow[],
    [collections, partnerStyleTimelines, stores.allStores, styleCategories]
  );

  return {
    data: styleTimelineData,
    styleCategories: styleCategories,
    loading: loading || collectionsLoading || styleCategoriesLoading,
    error: error ?? collectionsError ?? styleCategoriesError,
  };
};
