import { addMonths } from 'date-fns';
import { isNumber } from 'lodash';
import { MarkupDetailsRow, TableOverviewRow } from 'src/domain';
import { StyleCategoryModel } from 'src/infrastructure/rest-api/api-types';
import { formatDateToMonthKey, monthKeyToField } from './monthKeys';

export function calculateStockOptimalMonthTotal(
  month: Date,
  styleCategories: StyleCategoryModel[] | undefined,
  markup: Map<string, MarkupDetailsRow[]> | undefined,
  weekCover: TableOverviewRow[]
) {
  const nextMonth = addMonths(month, 1);
  const nextNextMonth = addMonths(nextMonth, 1);

  const monthField = monthKeyToField(formatDateToMonthKey(month));
  const nextMonthField = monthKeyToField(formatDateToMonthKey(nextMonth));
  const nextNextMonthField = monthKeyToField(formatDateToMonthKey(nextNextMonth));

  let stockOptimal = 0;

  styleCategories?.map((category) => {
    const cogsNextMonth =
      markup?.get(nextMonthField)?.find((markupRow) => markupRow.category === category?.name)?.expectedCogs ?? 0;
    const cogsNextNextMonth =
      markup?.get(nextNextMonthField)?.find((markupRow) => markupRow.category === category?.name)?.expectedCogs ?? 0;

    const weekCoverValue = weekCover.find((weekCoverRow) => weekCoverRow.category === category?.name)?.columns[
      monthField
    ];

    if (isNumber(weekCoverValue))
      stockOptimal += ((cogsNextMonth + cogsNextNextMonth) / 2 / (52 / 12)) * weekCoverValue;
  });
  return Math.round(stockOptimal);
}

export function calculateStockOptimalAdjusted(stockOptimal: number, stockMin: number | null, stockMax: number | null) {
  if (isNumber(stockMin) && stockOptimal < stockMin) return stockMin;
  if (isNumber(stockMax) && stockOptimal > stockMax) return stockMax;
  return stockOptimal;
}
