import { Module } from 'src/domain';
import { useDefaultGender } from './useDefaultGender';
import { useSelectedGender } from './useSelectedGender';

export const useGender = (module?: Module) => {
  const defaultGender = useDefaultGender();
  const selectedGender = useSelectedGender(module);

  return selectedGender ?? defaultGender;
};
