import { useCallback } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useInheritance } from 'src/hooks/inheritance';
import { useUpdateSplitLevel } from 'src/hooks/split-levels/useUpdateSplitLevel';
import { useScope } from 'src/hooks/useScope';

/**
 * Updates the delivery profile to the inheritance which is currently selected.
 * It does this by sending a mutation to the backend setting splitlevel..
 *
 * @returns An object containing promise of results and a loading state.
 */
export const useDeliveryProfileUpdateInheritance = () => {
  const { updateSplitLevel: updateStoreSplitLevel, loading } = useUpdateSplitLevel();
  const discardChanges = useDiscardChangesModule(Module.DeliveryProfile);

  const scope = useScope();
  const inheritance = useInheritance();

  const updateInheritance = useCallback(() => {
    // eslint-disable-next-line no-console
    if (!inheritance) return console.warn(`No inheritance set: ${inheritance}`);

    switch (scope) {
      case ScopeEnum.STORE:
        return updateStoreSplitLevel(inheritance)?.then(() => discardChanges());
      default:
        return;
    }
  }, [discardChanges, inheritance, scope, updateStoreSplitLevel]);

  return { updateInheritance, loading };
};
