import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import React, { FC } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import Paragraph from 'src/components/atoms/Paragraph';
import KPIContainer, { ResponsiveKpiStack } from 'src/components/molecules/KPIContainer/KPIContainer';
import { Color, Headings, ScopeEnum } from 'src/domain';
import { useChain } from 'src/hooks/chain/useChain';
import { StoreDetails } from 'src/hooks/turnover/useTurnoverDetails';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';

export type ComparableValue<T> = T | readonly [T, T] | readonly [T, undefined];

export interface ComparableKpiNodeProps {
  value: ComparableValue<number>;
}

export const ComparableKpiNode: FC<ComparableKpiNodeProps> = ({ value }) => {
  return (
    <>
      {Array.isArray(value) ? (
        <Stack direction="row" gap={1} alignItems={'center'} justifyContent={'center'} lineHeight={'21px'}>
          {value[1] && value[1] !== value[0] ? (
            <>
              <span style={{ fontSize: '14px', fontWeight: 'normal' }}>({numberWithSpaces(Math.round(value[0]))})</span>
              <span>{numberWithSpaces(Math.round(value[1]))}</span>
            </>
          ) : (
            <span>{numberWithSpaces(Math.round(value[0]))}</span>
          )}
        </Stack>
      ) : (
        <span>{numberWithSpaces(Math.round(Number(value)))}</span>
      )}
    </>
  );
};

export interface KpiItem {
  title: string;
  value: ComparableValue<number>;
}

export interface TurnoverKpisProps {
  storeDetails: StoreDetails | undefined;
  scope: ScopeEnum | undefined;
  expectedSales: KpiItem | undefined;
  expectedSalesPerSquareMeter: KpiItem | undefined;
  averageIndex: ComparableValue<number>;
  salesFloorSquareMeters: KpiItem | undefined;
  numberOfStores: ComparableValue<number>;
}

export const TurnoverKpis: React.FC<TurnoverKpisProps> = ({
  storeDetails,
  scope: scope,
  expectedSales,
  expectedSalesPerSquareMeter,
  averageIndex,
  salesFloorSquareMeters,
  numberOfStores,
}) => {
  const chain = useChain();
  return (
    <ResponsiveKpiStack
      defaultDirection="row"
      invertOnLargeScreen
      gap={1}
      style={{ gridArea: 'kpi', maxWidth: 'fit-content' }}
    >
      {storeDetails && (
        <StoreInfoContainer>
          {(storeDetails.storeType === 'WHOLESALE' || storeDetails.operationalResponsibleName !== '?') && (
            <StoreInfo
              title="Primary Partner"
              content={
                storeDetails.storeType === 'WHOLESALE'
                  ? storeDetails.businessTradingPartnerName
                  : storeDetails.operationalResponsibleName ?? ''
              }
            ></StoreInfo>
          )}
          {storeDetails.areaManagerName !== '?' && (
            <StoreInfo title="Area Manager" content={storeDetails.areaManagerName ?? ''}></StoreInfo>
          )}
          <StoreInfo title="Opening Date" content={storeDetails.storeOpeningDateFormatted ?? ''}></StoreInfo>
          <StoreInfo title="Country" content={storeDetails.countryName ?? ''}></StoreInfo>
          <StoreInfo title="Chain" content={chain ?? ''}></StoreInfo>
        </StoreInfoContainer>
      )}

      <ResponsiveKpiStack
        defaultDirection={scope === ScopeEnum.STORE ? 'column' : 'row'}
        invertOnLargeScreen={scope !== ScopeEnum.STORE}
        gap={1}
      >
        {expectedSales && (
          <KPIContainer title={expectedSales.title} content={<ComparableKpiNode value={expectedSales.value} />} />
        )}
        <Stack direction="row" gap={1}>
          {expectedSalesPerSquareMeter && (
            <KPIContainer
              title={expectedSalesPerSquareMeter.title}
              content={<ComparableKpiNode value={expectedSalesPerSquareMeter.value} />}
            />
          )}

          {/* Only show avg index here for a store, layout for cluster and partner is different from store */}
          {scope === ScopeEnum.STORE && (
            <KPIContainer title={'AVG INDEX NEXT 12 MONTHS'} content={<ComparableKpiNode value={averageIndex} />} />
          )}
        </Stack>
      </ResponsiveKpiStack>

      <ResponsiveKpiStack
        defaultDirection={scope !== ScopeEnum.STORE ? 'row' : 'column'}
        invertOnLargeScreen={scope === ScopeEnum.STORE}
        gap={1}
      >
        {/* Only show avg index here when not on a store, layout for cluster and partner is different from store */}
        {scope !== ScopeEnum.STORE && (
          <KPIContainer title={'AVG INDEX NEXT 12 MONTHS'} content={<ComparableKpiNode value={averageIndex} />} />
        )}
        {salesFloorSquareMeters && (
          <KPIContainer
            title={salesFloorSquareMeters.title}
            content={salesFloorSquareMeters.value.toString()}
            suffix={'m'}
            powerOf={2}
          />
        )}
        {storeDetails?.clusterName && <KPIContainer title={'CLUSTER'} content={storeDetails.clusterName} />}
      </ResponsiveKpiStack>

      <ResponsiveKpiStack
        defaultDirection={scope !== ScopeEnum.STORE ? 'row' : 'column'}
        invertOnLargeScreen={scope === ScopeEnum.STORE}
        gap={1}
      >
        {/* Only show avg index here when not on a store, layout for cluster and partner is different from store */}
        {scope !== ScopeEnum.STORE && (
          <KPIContainer title={'Number of stores'} content={<ComparableKpiNode value={numberOfStores} />} />
        )}

        {storeDetails?.clusterName && <KPIContainer title={'CLUSTER'} content={storeDetails.clusterName} />}
      </ResponsiveKpiStack>
    </ResponsiveKpiStack>
  );
};

interface StoreInfoProps {
  title: string;
  content: string;
}
const StoreInfo: React.FC<StoreInfoProps> = ({ title, content }) => {
  return (
    <InfoWrapper>
      <StyledH3 heading={Headings.h3}>{title}</StyledH3>
      <Paragraph>{content}</Paragraph>
    </InfoWrapper>
  );
};

const StoreInfoContainer = styled(Container)`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
`;

const StyledH3 = styled(Header)`
  font-size: 14px;
  font-weight: bold;
  color: ${Color.antracit};
  margin-right: 10px;
  width: 110px;
  text-align: right;
`;
