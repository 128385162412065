import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ListHeaderBox = styled(Box)`
  display: flex;
  align-items: center;
  background-color: #f9f9fb;
  border-radius: 5px 5px 0 0;
  border: 1px solid #f5f5f5;
  height: 30px;
  margin-top: 24px;
`;
