import { pick } from 'ramda';
import { useCallback } from 'react';
import { ArgumentError } from 'src/domain';
import { useChangesSnackbar, useCurrentId, useGenderId, useProductLineId } from 'src/hooks';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useEntityType } from 'src/hooks/useEntityType';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { useMarkupUpdateInheritance } from '../split-level/useMarkupUpdateInheritance';

export const useMarkupReset = () => {
  const currentId = useCurrentId();
  const partnerComposite = useCompositePartner();
  const productLineGroupId = useProductLineId();
  const genderId = useGenderId();
  const entityType = useEntityType();
  const showSnackbar = useChangesSnackbar();

  const [reset, { loading: resetLoading }] = useApiMutation('/api/markup/reset', 'put', {
    update: (data, variables, queryClient) => {
      queryClient.setQueryData(['/api/markup', pick(['entityId', 'entityType'], variables.query!)], data);
    },
  });
  const { updateInheritance, loading: inheritanceLoading } = useMarkupUpdateInheritance();

  const resetMarkup = useCallback(() => {
    if (!currentId) throw new ArgumentError(`ID cannot be null: ${currentId}`);
    if (!productLineGroupId) throw new ArgumentError(`productline ID cannot be null: ${productLineGroupId}`);
    if (!genderId) throw new ArgumentError(`gender ID cannot be null: ${genderId}`);

    const query = {
      entityId: Number(currentId),
      entityType,
      productLineGroupId,
      genderId,
    };

    if (entityType === 'Partner') {
      if (!partnerComposite?.id) throw new ArgumentError(`Composite partner id cannot be null`);
      query.entityId = partnerComposite.id;
    }

    return reset({
      query,
    })
      .then(() => updateInheritance())
      .then(() => showSnackbar());
  }, [currentId, productLineGroupId, genderId, updateInheritance, entityType, reset, partnerComposite, showSnackbar]);

  const loading = resetLoading || inheritanceLoading;

  return { resetMarkup, loading };
};
