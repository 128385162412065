import _ from 'lodash';
import { useCallback } from 'react';
import { SessionStorageClusterManagementType } from 'src/domain';
import { ClusterManagementChange } from 'src/domain/models/cluster-management-change';
import { ClusterManagementStorageKey } from 'src/domain/session-storage/cluster-management-key';
import { useSelectedPartner } from '../useSelectedPartner';
import { useUnsavedChanges } from '../useUnsavedChanges';

export const useUnsavedChangesClusterManagement = (id?: number) => {
  const [partner] = useSelectedPartner();
  const partnerIdToUse = id ?? partner?.id;
  const moduleKey = ClusterManagementStorageKey;

  const [unsavedChangesForLevel, setUnsavedChangesForLevel] =
    useUnsavedChanges<SessionStorageClusterManagementType<ClusterManagementChange>>(moduleKey);

  const setUnsavedChangesForModule = useCallback(
    (object: Record<number, ClusterManagementChange>) => {
      if (!partnerIdToUse) return;
      if (!moduleKey) return;

      const currentChanges = {
        ...unsavedChangesForLevel,
        ...{ [partnerIdToUse]: { ...object } },
      };

      if (_.isEmpty(object)) delete currentChanges[partnerIdToUse];

      setUnsavedChangesForLevel(currentChanges);
    },
    [partnerIdToUse, moduleKey, unsavedChangesForLevel, setUnsavedChangesForLevel]
  );

  if (!partnerIdToUse) return [];
  if (!moduleKey) return [];

  return [unsavedChangesForLevel?.[partnerIdToUse], setUnsavedChangesForModule] as const;
};
