import { Stack } from '@mui/material';
import { FC } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { StorefrontTypeSelect } from 'src/components/molecules/StorefrontTypeSelect/StorefrontTypeSelect';
import { SmartTitleCard } from 'src/components/organisms/SmartTitleCard/SmartTitleCard';
import { Headings, ScopeEnum } from 'src/domain';
import { useScope } from 'src/hooks';
import { HeatMapTypeEnum } from 'src/mapping/projected-option-count.mapping';
import { ProjectedOptionCountHeatMapTable } from './ProjectedOptionCountHeatMapTable';

export const ProjectedOptionCount: FC = () => {
  const scope = useScope();
  return (
    <Stack spacing={2} height="100%">
      <SmartTitleCard />
      <Container>
        <Stack gap="2rem">
          <Stack direction="row" justifyContent={'space-between'} alignItems="center">
            <Header heading={Headings.h2}>Projected option count</Header>
            {scope !== ScopeEnum.STORE && <StorefrontTypeSelect />}
          </Stack>
          <Stack gap="0.75rem">
            <Header heading={Headings.h4}>By category</Header>
            <ProjectedOptionCountHeatMapTable type={HeatMapTypeEnum.Category} />
          </Stack>
          <Stack gap="0.75rem">
            <Header heading={Headings.h4}>By placement</Header>
            <ProjectedOptionCountHeatMapTable type={HeatMapTypeEnum.Placement} />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
