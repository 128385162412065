import { useCallback, useState } from 'react';
import { Module } from 'src/domain';
import { OpenToBuyLimitOverviewRow } from 'src/domain/table/open-to-buy-limit.row';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useChangesSnackbar } from 'src/hooks/snackbar/useChangesSnackbar';

import { useChainSettingsApiQuery } from 'src/hooks/chain-settings/useChainSettingsApiQuery';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useSelectedPartner } from 'src/hooks/useSelectedPartner';

import { BuyingEventPlanningLevelEnum, UpdateOpenToBuyLimitInput } from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { useOpenToBuyLimitOverviewSimulationRows } from '../simulation/useOpenToBuyLimitOverviewSimulationRow';

function valueMapper(
  rows: OpenToBuyLimitOverviewRow[] | undefined,
  planningLevel: BuyingEventPlanningLevelEnum
): UpdateOpenToBuyLimitInput[] {
  if (!rows) return [];
  return rows.reduce<UpdateOpenToBuyLimitInput[]>((acc, row) => {
    const values = Object.keys(row.columns).reduce((acc: UpdateOpenToBuyLimitInput[], key) => {
      if (key.endsWith('Id')) return acc;

      const cellValue = row.columns[key];
      const cellId = row.columns[`${key}Id`];

      acc.push({
        id: cellId,
        splitPercent: cellValue,
        planningLevel,
      });

      return acc;
    }, []);

    acc.push(...values);

    return acc;
  }, []);
}

export const useOpenToBuyLimitSaveChanges = () => {
  const [partner] = useSelectedPartner();
  const partnerComposite = useCompositePartner(partner);
  const { data: chainSettings } = useChainSettingsApiQuery();

  const showSnackbar = useChangesSnackbar();
  const discardChanges = useDiscardChangesModule(Module.OpenToBuyLimit);

  const [mutateOpenToBuyLimit] = useApiMutation('/api/opentobuylimit', 'put', {
    update(_data, _variables, queryClient) {
      const partnerId = partnerComposite?.id;
      const queryKey = [`/api/opentobuylimit/partners/${partnerId}`];
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const { data } = useOpenToBuyLimitOverviewSimulationRows();

  const [isUpdating, setIsUpdating] = useState(false);

  const sendUpdateQuery = useCallback(async () => {
    if (!chainSettings) {
      return;
    }

    const payload = valueMapper(data, chainSettings.buyingEventPlanningLevel);
    return mutateOpenToBuyLimit({ body: payload });
  }, [chainSettings, data, mutateOpenToBuyLimit]);

  const updateOpenToBuyLimit = useCallback(async () => {
    setIsUpdating(true);

    try {
      const response = await sendUpdateQuery();
      discardChanges();
      showSnackbar();
      return response;
    } catch {
      showSnackbar(true);
    } finally {
      setIsUpdating(false);
    }
  }, [sendUpdateQuery, discardChanges, showSnackbar]);

  return [updateOpenToBuyLimit, { loading: isUpdating }] as const;
};
