export interface StyleCategory {
  categorySort?: number | null;
  styleCategoryId: number;
  styleCategoryName: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isStyleCategory(obj: any): obj is StyleCategory {
  return obj && typeof obj.styleCategoryName === 'string';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasStyleCategoryId(obj: any): obj is { styleCategoryId: number } {
  return obj && typeof obj.styleCategoryId === 'number';
}
