import { useMemo } from 'react';
import { useClusterId } from 'src/hooks/cluster';
import { useInitializeIsProductlineSplitHistorical } from 'src/hooks/historical';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useIsUsingProductline } from 'src/hooks/productline/useShowProductLineSplit';
import { useStoreId } from 'src/hooks/store';
import { useSelectedPartner } from 'src/hooks/useSelectedPartner';
import { components } from 'src/infrastructure/rest-api/generated';
import { useProductLineSplitApiQuery } from './useProductLineSplitApiQuery';

export type ProductLineSplit = components['schemas']['ProductLineSplitModel'][] | undefined;

export type ProductLineSplitLevelQuery = {
  data:
    | {
        storeProductLineSplit?: ProductLineSplit;
        clusterProductLineSplit?: ProductLineSplit;
        partnerProductLineSplit?: ProductLineSplit;
      }
    | undefined;
  error: Error | null;
  loading: boolean;
};

export const useProductLineSplitLevelQuery = (): ProductLineSplitLevelQuery | undefined => {
  const isUsingProductline = useIsUsingProductline();

  const storeId = useStoreId();
  const [partner] = useSelectedPartner();
  const clusterId = useClusterId(partner);
  const compositePartner = useCompositePartner();

  const { data: storeSplit, error: storeError, loading: storeLoading } = useProductLineSplitApiQuery(storeId, 'Store');
  const {
    data: clusterSplit,
    error: clusterError,
    loading: clusterLoading,
  } = useProductLineSplitApiQuery(clusterId, 'Cluster');
  const {
    data: partnerSplit,
    error: partnerError,
    loading: partnerLoading,
  } = useProductLineSplitApiQuery(compositePartner?.id, 'Partner');

  const data = useMemo(() => {
    if (!isUsingProductline) return;

    return {
      storeProductLineSplit: storeSplit,
      clusterProductLineSplit: clusterSplit,
      partnerProductLineSplit: partnerSplit,
    };
  }, [isUsingProductline, storeSplit, clusterSplit, partnerSplit]);

  useInitializeIsProductlineSplitHistorical(data);

  return {
    data,
    error: storeError ?? clusterError ?? partnerError,
    loading: storeLoading ?? clusterLoading ?? partnerLoading,
  };
};
