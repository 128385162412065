import { useMemo } from 'react';
import { Module, MonthMinMaxList, ScopeEnum } from 'src/domain';
import { StockManagementRow } from 'src/domain/table/stock-management.row';
import { useScope, useWeekCoverOverviewSimulationRows } from 'src/hooks';
import { useMarkupDetailsSimulationRows } from 'src/hooks/markup/simulation/useMarkupDetailsSimulationRows';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { stockManagementTotalsToTableData } from 'src/mapping/stock-management.mapping';
import { useStockManagementQuery } from '../queries/useStockMgmtQuery';
import { useStockManagementOverviewRows } from '../table/useStockManagementOverviewRows';
import { useStockManagementTableKey } from '../table/useStockManagementTableKey';

export const useStockManagementOverviewSimulationRows = () => {
  const { styleCategories } = useStockManagementQuery();
  const { data, columns, error, loading } = useStockManagementOverviewRows();
  const { aggregatedData: markupSimulationData, loading: markupLoading } = useMarkupDetailsSimulationRows(true);
  const { aggregatedData: weekCoverAggregatedData, loading: weekCoverLoading } = useWeekCoverOverviewSimulationRows(
    undefined,
    undefined,
    undefined,
    true
  );
  const [unsavedChanges] = useUnsavedChangesModule<Record<string, StockManagementRow[]>>(Module.StockManagement);
  const tableKey = useStockManagementTableKey();
  const scope = useScope();

  const isLoading = loading || markupLoading || weekCoverLoading;

  const simulatedData = useMemo(() => {
    if (loading || !data) return;

    const simulationData = unsavedChanges?.[tableKey] ?? data;

    if (tableKey === 'categories-table') return simulationData;

    if (scope !== ScopeEnum.STORE) return simulationData;

    if (!markupSimulationData || !weekCoverAggregatedData) {
      return simulationData;
    }

    const filteredSimulationData = simulationData.filter(
      (row) => !row.category.includes('Stock Optimal') && !row.category.includes('COGS')
    );

    const minRow = simulationData.find((row) => row.category === 'Minimum');
    const maxRow = simulationData.find((row) => row.category === 'Maximum');

    const simulatedMinMaxList = Object.keys(minRow?.columns ?? {}).reduce((acc, monthField) => {
      acc[monthField] = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: minRow!.columns[`${monthField}Id`]!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        min: minRow!.columns[monthField],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        max: maxRow!.columns[monthField],
      };
      return acc;
    }, {} as MonthMinMaxList);

    const stockOptimalRows = stockManagementTotalsToTableData(
      simulatedMinMaxList,
      markupSimulationData,
      weekCoverAggregatedData,
      styleCategories
    ).filter((row) => row.category.includes('Stock Optimal') || row.category.includes('COGS'));

    return filteredSimulationData.concat(stockOptimalRows);
  }, [data, loading, markupSimulationData, styleCategories, scope, tableKey, unsavedChanges, weekCoverAggregatedData]);

  return useMemo(
    () => ({
      data: simulatedData,
      columns,
      error,
      loading: isLoading,
    }),
    [columns, error, isLoading, simulatedData]
  );
};
