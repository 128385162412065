import { parseISO } from 'date-fns';
import { groupBy, isNumber, sortBy } from 'lodash';
import { pipe, sort } from 'ramda';
import { exitWeekToLifeSpan } from 'src/components/views/StyleTimeline/data/columns';
import { StyleTimelineOverviewRow } from 'src/domain/table/style-timeline-overview-row';
import { IntersectedStyleTimelineData } from 'src/hooks/style-timeline/queries/useStyleTimelineLevelQuery';
import { StoreModel, StyleCategoryModel } from 'src/infrastructure/rest-api/api-types';
import { StyleTimelineCollectionModel } from 'src/infrastructure/rest-api/style-timeline';

const sortByGrossMarginPast8WeeksLCY = sort<StyleTimelineOverviewRow>(
  (a, b) => (b.grossMarginPast8WeeksLCY ?? 0) - (a.grossMarginPast8WeeksLCY ?? 0)
);

export function collectionsToMap(
  collections: StyleTimelineCollectionModel[] | undefined
): Map<number, StyleTimelineCollectionModel> {
  return (
    collections?.reduce<Map<number, StyleTimelineCollectionModel>>((acc, collection) => {
      if (typeof collection.id === 'number') {
        acc.set(collection.id, collection);
      }
      return acc;
    }, new Map<number, StyleTimelineCollectionModel>()) ?? new Map()
  );
}

export function mapModelToRow(
  model: IntersectedStyleTimelineData,
  collectionsMap: Map<number, StyleTimelineCollectionModel>,
  stores: StoreModel[],
  styleCategories?: StyleCategoryModel[]
): StyleTimelineOverviewRow {
  const packaway = model.packawayEndWeek ? `${model.packawayStartWeek}-${model.packawayEndWeek}` : null;
  const markdownPct = isNumber(model.markDownPct) ? Math.round(model.markDownPct * 10) / 10 : null;
  const avgNormQuantity = isNumber(model.avgNormQuantity) ? Math.round(model.avgNormQuantity * 10) / 10 : undefined;
  const salesIvPast8WeeksLcy = isNumber(model.salesIvPast8WeeksLcy) ? Math.round(model.salesIvPast8WeeksLcy) : null;
  const stockValueLcy = Math.round(model.stockValueLcy);
  const salesIvPast8WeeksLcySparkline = [
    model?.salesIvMinus8WeeksLcy,
    model?.salesIvMinus7WeeksLcy,
    model?.salesIvMinus6WeeksLcy,
    model?.salesIvMinus5WeeksLcy,
    model?.salesIvMinus4WeeksLcy,
    model?.salesIvMinus3WeeksLcy,
    model?.salesIvMinus2WeeksLcy,
    model?.salesIvMinus1WeekLcy,
  ];

  const salesSparkLineRounded = salesIvPast8WeeksLcySparkline.map((value) => (isNumber(value) ? Math.round(value) : 0));
  const lifeSpan = exitWeekToLifeSpan(model.startWeek, model.exitWeek);
  const isEligibleForSale = model.eligibleForSale ?? false;

  const collections =
    (model.collectionIds?.filter((id) => typeof id === 'number') as number[]).map((id) => collectionsMap.get(id)) ?? [];

  const storeIds = (model.storeStyleTimelines ?? [])
    .filter((item) => item && typeof item.storeId === 'number')
    .map((item) => item?.storeId) as number[];

  const storeNames = stores
    ?.filter((store) => storeIds.includes(store.id))
    .map((store) => store.storeName)
    .filter((name) => typeof name === 'string') as string[];

  const sortedCollections = collections.toSorted((a, b) => {
    if (!a?.startDate) {
      return 1;
    }

    if (!b?.startDate) {
      return -1;
    }

    return parseISO(b.startDate).getTime() - parseISO(a.startDate).getTime();
  });

  return {
    ...model,
    id: model.id ?? 0, // this ok?
    collections: sortedCollections.map((collection) => collection?.name ?? ''),
    rowId: `${model.id}-${model.countryId}-${model.operationalResponsibleId}`,
    packaway,
    markDownPct: markdownPct,
    avgNormQuantity: avgNormQuantity ?? null,
    partner: `${model.country}, ${model.operationalResponsible}`,
    lifeSpan,
    styleCategory: styleCategories?.find((category) => category?.id === model?.styleCategoryId),
    salesIvPast8WeeksLcy,
    stockValueLcy,
    salesIvPast8WeeksLcySparkline: salesSparkLineRounded,
    comment: model.comment ?? '',
    eligibleForSale: isEligibleForSale,
    storeNames,
    /* storeId: model.storeId, */
  };
}

export const mapStyleTimelineDataToOverviewRows = (
  data: IntersectedStyleTimelineData[] | undefined,
  collections: StyleTimelineCollectionModel[] | undefined,
  styleCategories: StyleCategoryModel[] | undefined,
  stores: StoreModel[],
  isGlobal?: boolean
): StyleTimelineOverviewRow[] => {
  if (!data) return [];

  const collectionsMap = collectionsToMap(collections);

  const rows = data.reduce<StyleTimelineOverviewRow[]>((rows, model) => {
    rows.push(mapModelToRow(model, collectionsMap, stores, styleCategories));
    return rows;
  }, []);

  if (isGlobal) return sortBy(rows, (row) => row.styleCategory?.order);

  const ratedRows = rateStyleTimelineRows(rows ?? []);
  return sortBy(ratedRows, (row) => row.styleCategory?.order);
};

export const mergeStyleTimelineRows = (
  allRows: StyleTimelineOverviewRow[],
  filteredRows: StyleTimelineOverviewRow[]
): StyleTimelineOverviewRow[] => {
  const mergedRows: StyleTimelineOverviewRow[] = [...filteredRows];
  for (const row of allRows) {
    const rowExists = filteredRows.find((r) => r.id === row.id);
    if (!rowExists) {
      mergedRows.push(row);
    }
  }
  return mergedRows;
};

export const rateStyleTimelineRows = (data: StyleTimelineOverviewRow[]): StyleTimelineOverviewRow[] => {
  const groupedByCategory = groupBy(data, (row) => row.styleCategoryId);

  return Object.entries(groupedByCategory).reduce<StyleTimelineOverviewRow[]>((acc, [_, models]) => {
    // Note: Rating functions can be put into the pipe to apply them in order
    const modelsWithRating = pipe(
      calculateMarkdownRating,
      calculateSpsWeekRating,
      calculateSalesP8WRating,
      calculateGrossProfitRating,
      calculateGrossMarginRating
    )(models);
    return acc.concat(modelsWithRating);
  }, []);
};

export const groupRowsBy = (
  rows: StyleTimelineOverviewRow[],
  groupFn: (row: StyleTimelineOverviewRow) => string | number
) => {
  const groupedBy = new Map<string | number, StyleTimelineOverviewRow[]>();

  for (const row of rows) {
    const key = groupFn(row);
    const collection = groupedBy.get(key) ?? [];
    collection.push(row);
    groupedBy.set(key, collection);
  }

  return groupedBy;
};

export const calculateSalesP8WRating = (rows: StyleTimelineOverviewRow[]): StyleTimelineOverviewRow[] => {
  // 1. Sort by sps week value
  const sortedRows = sortByGrossMarginPast8WeeksLCY(rows.filter((model) => isNumber(model.salesIvPast8WeeksLcy)));

  // 2. Group by sps week value
  const groupedBySalesP8W = groupRowsBy(sortedRows, (model) => model.salesIvPast8WeeksLcy ?? 0);

  // 3. Calculate rating by group rank (i.e. index)
  return mergeStyleTimelineRows(
    rows,
    getModelWithRating(groupedBySalesP8W, 'salesP8WRating', 'salesIvPast8WeeksRanking', 'maxSalesP8WRanking')
  );
};

export const calculateSpsWeekRating = (rows: StyleTimelineOverviewRow[]): StyleTimelineOverviewRow[] => {
  // 1. Sort by sps week value
  const sortedRows = sortByGrossMarginPast8WeeksLCY(rows.filter((model) => isNumber(model.spsWeek)));

  // 2. Group by sps week value
  const groupedBySpsWeek = groupRowsBy(sortedRows, (model) => model.spsWeek ?? 0);

  // 3. Calculate rating by group rank (i.e. index)
  return mergeStyleTimelineRows(
    rows,
    getModelWithRating(groupedBySpsWeek, 'spsWeekRating', 'spsWeekRanking', 'maxSpsWeekRanking')
  );
};

export const calculateMarkdownRating = (rows: StyleTimelineOverviewRow[]): StyleTimelineOverviewRow[] => {
  // 1. Sort by markdown value
  const sortedRows = sort<StyleTimelineOverviewRow>((a, b) => (a.markDownPct ?? 0) - (b.markDownPct ?? 0))(
    rows.filter((model) => isNumber(model.markDownPct))
  );

  // 2. Group by markdown value
  const groupedByMarkdown = groupRowsBy(sortedRows, (row) => row.markDownPct ?? 0);

  // 3. Calculate rating by group rank (i.e. index)
  return mergeStyleTimelineRows(
    rows,
    getModelWithRating(groupedByMarkdown, 'markdownRating', 'markDownRanking', 'maxMarkdownRanking')
  );
};

function calculateGrossProfitRating(rows: StyleTimelineOverviewRow[]): StyleTimelineOverviewRow[] {
  // 1. Sort by gross profit value
  const sortedRows = sort<StyleTimelineOverviewRow>(
    (a, b) => (b.grossProfitPast8WeeksLCY ?? 0) - (a.grossProfitPast8WeeksLCY ?? 0)
  )(rows.filter((model) => isNumber(model.grossProfitPast8WeeksLCY)));

  // 2. Group by gross profit value
  const groupedByGrossProfit = groupRowsBy(sortedRows, (row) => row.grossProfitPast8WeeksLCY ?? 0);

  // 3. Calculate rating by group rank (i.e. index)
  return mergeStyleTimelineRows(
    rows,
    getModelWithRating(groupedByGrossProfit, 'grossProfitRating', 'grossProfitRanking', 'maxGrossProfitRanking')
  );
}

function calculateGrossMarginRating(rows: StyleTimelineOverviewRow[]): StyleTimelineOverviewRow[] {
  // 1. Sort by gross margin value
  const sortedRows = sortByGrossMarginPast8WeeksLCY(rows);

  // 2. Group by gross margin value
  const groupedByGrossMargin = groupRowsBy(sortedRows, (row) => row.grossMarginPast8WeeksLCY ?? 0);

  // 3. Calculate rating by group rank (i.e. index)
  return mergeStyleTimelineRows(
    rows,
    getModelWithRating(groupedByGrossMargin, 'grossMarginRating', 'grossMarginRanking', 'maxGrossMarginRanking')
  );
}

export const getModelWithRating = (
  group: Map<number | string, StyleTimelineOverviewRow[]>,
  ratingProperty: keyof StyleTimelineOverviewRow,
  rankingProperty: keyof StyleTimelineOverviewRow,
  maxRankingProperty: keyof StyleTimelineOverviewRow
): StyleTimelineOverviewRow[] => {
  const maxRank = Array.from(group.keys()).length;
  const values = Array.from(group.values()).map((value, inx) =>
    value.map((model) => ({ ...model, [rankingProperty]: inx + 1, [maxRankingProperty]: maxRank }))
  );
  values.reverse();

  const oneStarRating = {
    start: 0,
    end: Math.floor(maxRank * 0.1),
  };

  const twoStarRating = {
    start: oneStarRating.end,
    end: Math.floor(maxRank * 0.3),
  };

  const threeStarRating = {
    start: twoStarRating.end,
    end: Math.floor(maxRank * 0.7),
  };

  const fourStarRating = {
    start: threeStarRating.end,
    end: Math.floor(maxRank * 0.9),
  };

  const fiveStarRating = {
    start: fourStarRating.end,
    end: maxRank,
  };

  const oneStarRatingModels = values.slice(oneStarRating.start, oneStarRating.end);
  const twoStarRatingModels = values.slice(twoStarRating.start, twoStarRating.end);
  const threeStarRatingModels = values.slice(threeStarRating.start, threeStarRating.end);
  const fourStarRatingModels = values.slice(fourStarRating.start, fourStarRating.end);
  const fiveStarRatingModels = values.slice(fiveStarRating.start, fiveStarRating.end);

  return [
    oneStarRatingModels.flatMap(withRating(1, ratingProperty)),
    twoStarRatingModels.flatMap(withRating(2, ratingProperty)),
    threeStarRatingModels.flatMap(withRating(3, ratingProperty)),
    fourStarRatingModels.flatMap(withRating(4, ratingProperty)),
    fiveStarRatingModels.flatMap(withRating(5, ratingProperty)),
  ].flat();
};

export const withRating =
  (rating: number, property: keyof StyleTimelineOverviewRow) => (models: StyleTimelineOverviewRow[]) => {
    return models.map((model) => ({ ...model, [property]: rating }));
  };
