import styled from '@emotion/styled';

export const ContentWrapper = styled.div`
  display: grid;
  width: fit-content;
  gap: 16px;
  width: 100%;

  grid-template-columns: minmax(auto, 1200px) auto;
  grid-template-areas: 'title .' 'table kpi';

  @media screen and (max-width: 1820px) {
    grid-template-columns: minmax(100%, 1200px);
    grid-template-areas: 'title' 'kpi' 'table';
  }
`;
