import { Inheritance } from 'src/domain';
import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useMarkupInheritanceQuery } from './useMarkupInheritanceQuery';

export const useMarkupQuery = (inheritance?: Inheritance, genderId?: number, productLineId?: number) => {
  const {
    data: styleCategories,
    error: styleCategoriesError,
    loading: styleCategoriesLoading,
  } = useStyleCategoriesQuery();

  const { data, expected, error, loading, sanitizedData } = useMarkupInheritanceQuery(
    inheritance,
    genderId,
    productLineId
  );

  return {
    expected,
    data,
    sanitizedData,
    styleCategories: styleCategories,
    error: error ?? styleCategoriesError,
    loading: loading || styleCategoriesLoading,
  };
};
