import { FC, memo, useMemo } from 'react';
import TitleCard from 'src/components/molecules/TitleCard';
import { useCurrentModuleName, useFilteredStores } from 'src/hooks';
import { useChain } from 'src/hooks/chain/useChain';

export const SmartModuleTitleCard: FC = memo(() => {
  const chain = useChain();
  const module = useCurrentModuleName();
  const moduleName = module?.replaceAll('-', ' ')?.capitalize();

  const [filteredStores] = useFilteredStores();
  const brandNumber = filteredStores?.[0]?.chain.brandNumber;

  const isLoading = useMemo(() => !chain && !module, [chain, module]);

  return <TitleCard productLineNumber={brandNumber} chain={chain} moduleName={moduleName} loading={isLoading} />;
});
