import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { Inheritance, InheritanceError, Module, SessionStorageType } from 'src/domain';
import { UnsavedInheritanceChangesEvent } from 'src/domain/events/unsaved-inheritance-changes.event';
import { UnsavedInheritanceKey } from 'src/domain/session-storage/unsaved-inheritance-key';
import { getSplitLevelKey } from 'src/utils/getSplitLevelKey';
import { useCurrentId } from '../useCurrentId';
import { useCurrentModule } from '../useCurrentModule';
import { useScope } from '../useScope';
import { getContentInSessionStorage, useSessionStorage } from '../useSessionStorage';

type InheritanceSessionStorage = SessionStorageType<Record<Module, { inheritance: Inheritance }>>;
export const useSessionStorageInheritance = (module?: Module) => {
  const scope = useScope();
  const id = useCurrentId();
  const currentModule = useCurrentModule();
  const selectedModule = module ?? currentModule;

  const storageKey = UnsavedInheritanceKey;
  const scopeKey = useMemo(() => getSplitLevelKey(scope), [scope]);

  const [storedValue, setStoredValue] = useSessionStorage<InheritanceSessionStorage>(storageKey, {});

  const inheritance = useMemo(() => {
    if (!id || !selectedModule) return null;
    const storedInheritance = storedValue[scopeKey]?.[id]?.[selectedModule]?.inheritance;
    return storedInheritance ?? null;
  }, [storedValue, scopeKey, id, selectedModule]);

  const setSessionStorageInheritance = useCallback(
    (inheritance: Inheritance | object) => {
      if (!scope) throw new InheritanceError('Could not find current split level');
      if (!currentModule) throw new InheritanceError('Could not find current module');
      if (typeof id !== 'number') throw new InheritanceError('Could not find current id');

      const updatedValue = {
        ...storedValue,
        [scopeKey]: {
          ...storedValue[scopeKey],
          [id]: {
            ...storedValue[scopeKey]?.[id],
            [currentModule]: {
              inheritance: inheritance,
            },
          },
        },
      } as InheritanceSessionStorage;

      if (isEmpty(inheritance)) delete updatedValue[scopeKey]?.[id]?.[currentModule];
      setStoredValue(updatedValue);
      dispatchEvent(new Event(UnsavedInheritanceChangesEvent));
    },
    [scope, currentModule, id, storedValue, setStoredValue, scopeKey]
  );

  const onUnsavedChanges = useCallback(() => {
    setStoredValue(getContentInSessionStorage<InheritanceSessionStorage>(storageKey, {}));
  }, [setStoredValue, storageKey]);

  useEffect(() => {
    window.addEventListener(UnsavedInheritanceChangesEvent, onUnsavedChanges);
    return () => {
      window.removeEventListener(UnsavedInheritanceChangesEvent, onUnsavedChanges);
    };
    // Having the eventlistener tied to onUnsavedChanges caused a memory leak.
    // A leak which caused some unsavedchanges data not to propagate to components.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { inheritance, setSessionStorageInheritance };
};
