import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useState } from 'react';

interface isDraggingContext {
  isDragging: boolean;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
}

const IsDraggingContext = createContext<isDraggingContext>({
  isDragging: false,
  setIsDragging: () => {
    // Do nothing
  },
});

export const IsDraggingProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [isDragging, setIsDragging] = useState(false);

  return <IsDraggingContext.Provider value={{ isDragging, setIsDragging }}>{children}</IsDraggingContext.Provider>;
};

export const useIsDragging = () => useContext(IsDraggingContext);
