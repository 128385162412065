import { Menu } from '@mui/material';
import { FC } from 'react';
import { CustomMenuItem, MenuItemProps } from 'src/components/atoms/MenuItem/CustomMenuItem';

interface CustomMenuProps {
  items: MenuItemProps[];
  anchorEl: null | HTMLElement;
  onClose: () => void;
}
/**
 * Menu that holds nested menu items. Max depth is 3
 */
export const CustomMenu: FC<CustomMenuProps> = ({ items, anchorEl, onClose }) => {
  const open = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      {items.map((item) => (
        <CustomMenuItem key={item.id} {...item} />
      ))}
    </Menu>
  );
};
