import { Stack } from '@mui/material';
import { isEqual } from 'lodash';
import { FC, useMemo } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { weightedAverageCategorySplitDetails } from 'src/components/organisms/DataTable/aggregators/average-category-split.agg';
import { ColumnsDetailsSum } from 'src/components/organisms/DataTable/columns/columns-details-sum';
import { defaultCategoryColumn } from 'src/components/organisms/DataTable/columns/columns-overview-sum';
import {
  DefaultColumnOptionsDetails,
  DefaultColumnsOptionsOverviewPercentage,
} from 'src/components/organisms/DataTable/columns/default-options';
import { SumBold } from 'src/components/organisms/DataTable/options/sum-bold';
import { validateSumIs100Percent } from 'src/components/organisms/DataTable/validators/validateSumIs100Percent';
import DetailsTable from 'src/components/organisms/DetailsTable';
import InheritanceDropdown from 'src/components/organisms/InheritanceDropdown';
import { InheritanceTable } from 'src/components/organisms/InheritanceTable/InheritanceTable';
import Topbar from 'src/components/organisms/Topbar';
import { StyledHeaderWrapper } from 'src/components/styled/StyledHeaderWrapper';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { CategoryDetailsRow, CategoryDetailsRowTuple, Headings, Module } from 'src/domain';
import { useCategorySplitReset } from 'src/hooks/category-split/reset/useCategorySplitReset';
import { useCategorySplitSaveChanges } from 'src/hooks/category-split/save-changes/useCategorySplitSaveChanges';
import { useCategorySplitDetailsSimulationRows } from 'src/hooks/category-split/simulation/useCategorySplitDetailsSimulationRows';
import { useCategorySplitOverviewSimulationRows } from 'src/hooks/category-split/simulation/useCategorySplitOverviewSimulationRows';
import { useCategorySplitUpdateInheritance } from 'src/hooks/category-split/split-level/useCategorySplitUpdateInheritance';
import { useCategorySplitDetailsRows } from 'src/hooks/category-split/table/useCategorySplitDetailsRows';
import { useCategorySplitOverviewRows } from 'src/hooks/category-split/table/useCategorySplitOverviewRows';
import { useCategorySplitHandleCellChanges } from 'src/hooks/category-split/useCategorySplitHandleCellChanges';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useIsSum100 } from 'src/hooks/useIsSum100';
import { useModulePlanningRange } from 'src/hooks/useModulePlanningRange';
import { mergeTableDetailsRowValuesToTuples } from 'src/mapping/table.mapping';
import { getButtonTooltips } from 'src/utils/getTooltipText';

export const CategorySplit: FC = () => {
  const {
    data: overviewData,
    columns: monthColumns,
    loading: overviewLoading,
  } = useCategorySplitOverviewSimulationRows() ?? {};
  const { data: originalOverviewData } = useCategorySplitOverviewRows();
  const { data: detailsData, loading: detailsLoading } = useCategorySplitDetailsRows() ?? {};
  const { data: detailsSimulationData, loading: detailsSimulationLoading } =
    useCategorySplitDetailsSimulationRows() ?? {};

  const planningRange = useModulePlanningRange(Module.CategorySplit);

  const columns = useMemo(() => {
    return [defaultCategoryColumn, ...monthColumns];
  }, [monthColumns]);

  const handleCellValueChanges = useCategorySplitHandleCellChanges();
  const { updateInheritance, loading: updateInheritanceLoading } = useCategorySplitUpdateInheritance(true);
  const { resetCategorySplit, loading: resetLoading } = useCategorySplitReset();

  const [saveChanges, { loading: savingChanges }] = useCategorySplitSaveChanges();
  const onDiscardChanges = useDiscardChangesModule(Module.CategorySplit);

  const loading =
    overviewLoading ||
    detailsLoading ||
    updateInheritanceLoading ||
    resetLoading ||
    detailsSimulationLoading ||
    savingChanges;

  const overviewRows = !loading ? overviewData : undefined;

  const sumIs100 = useIsSum100(overviewRows);
  const hasUnsavedChanges = !isEqual(overviewData, originalOverviewData);
  const canSave = hasUnsavedChanges && sumIs100;

  const { saveTooltip } = useMemo(() => getButtonTooltips(hasUnsavedChanges, sumIs100), [hasUnsavedChanges, sumIs100]);

  const detailsRows = useMemo(() => {
    if (loading) {
      return;
    }

    return mergeTableDetailsRowValuesToTuples<CategoryDetailsRow, CategoryDetailsRowTuple>(
      detailsData,
      detailsSimulationData,
      ['plannedSalesIv', 'plannedIndex']
    );
  }, [detailsData, detailsSimulationData, loading]);

  return (
    <Stack spacing={2}>
      <Topbar
        onDiscard={onDiscardChanges}
        onSave={saveChanges}
        saveDisabled={!canSave}
        saveTooltip={saveTooltip}
        onReset={resetCategorySplit}
        onSetInheritance={updateInheritance}
        loading={loading}
        saveLoading={savingChanges}
      />

      <Container>
        <StyledHeaderWrapper>
          <Header heading={Headings.h2}>Monthly Category Split</Header>
          <InheritanceDropdown />
        </StyledHeaderWrapper>
        <TableWrapper className="ag-theme-alpine-custom-compact ag-sum" data-testid="category-split-overview-table">
          <DataTable
            {...SumBold}
            autoSizeToFit={planningRange === 'TWELVE_MONTHS'}
            suppressAutoSize={true}
            headerHeight={60}
            columns={columns}
            suppressAggFuncInHeader={true}
            grandTotalRow="bottom"
            rows={overviewRows}
            defaultColDef={DefaultColumnsOptionsOverviewPercentage}
            onCellValuesChanged={handleCellValueChanges}
            loading={loading}
            cellValidationOptions={{
              columns: true,
              validationFunc: validateSumIs100Percent,
            }}
          />
        </TableWrapper>
      </Container>
      <DetailsTable
        {...SumBold}
        key={'details-table'}
        title={'Category Split Details'}
        monthColumns={monthColumns}
        loading={loading}
        rows={detailsRows}
        columns={ColumnsDetailsSum}
        defaultColDef={DefaultColumnOptionsDetails}
        onCellValuesChanged={handleCellValueChanges}
        suppressAggFuncInHeader
        grandTotalRow="bottom"
        getGroupRowAgg={weightedAverageCategorySplitDetails}
        cellValidationOptions={{
          columns: ['plannedSplit'],
          validationFunc: validateSumIs100Percent,
        }}
      />
      <InheritanceTable moduleString="categorySplit" />
    </Stack>
  );
};
