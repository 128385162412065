import { ScopeEnum } from 'src/domain';
import { useScope } from 'src/hooks';
import { selectedPartnerVar } from 'src/infrastructure/local_state';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useClusterId } from '../../cluster/useClusterId';
import { useGlobalVar } from '../../useGlobalVar';

export const useClusterCategorySplitQuery = () => {
  const [selectedPartner] = useGlobalVar(selectedPartnerVar);
  const clusterId = useClusterId(selectedPartner);
  const scope = useScope();

  const { data, error, loading } = useApiQuery('/api/categorysplit', 'get', {
    query: {
      entityId: Number(clusterId),
      entityType: 'Cluster',
    },
    enabled: !!clusterId && (scope === ScopeEnum.CLUSTER || scope === ScopeEnum.STORE),
  });

  return { data, error, loading } as const;
};
