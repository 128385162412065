import { ScopeEnum } from 'src/domain';
import { useScope } from 'src/hooks';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useStoreId } from '../../store/useStoreId';

export const useStoreCategorySplitQuery = () => {
  const storeId = useStoreId();
  const scope = useScope();

  const { data, error, loading } = useApiQuery('/api/categorysplit', 'get', {
    query: {
      entityId: Number(storeId),
      entityType: 'Store',
    },
    enabled: !!storeId && scope === ScopeEnum.STORE,
  });

  return { data, error, loading } as const;
};
