import { useCallback } from 'react';
import { CategoryOverviewRow, Module, SessionStorageGenderProductLineType } from 'src/domain';
import { DiscardChangesEvent } from 'src/domain/events/discard-changes.event';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { useResetInheritance } from '../inheritance';

export const useDiscardChangesModule = (module?: Module) => {
  const [_, setUnsavedChanges] =
    useUnsavedChangesModule<SessionStorageGenderProductLineType<CategoryOverviewRow[]>>(module);
  const discardInheritanceChanges = useResetInheritance({ module });

  const discardChanges = useCallback(() => {
    setUnsavedChanges({});
    discardInheritanceChanges();
    dispatchEvent(new Event(DiscardChangesEvent));
  }, [discardInheritanceChanges, setUnsavedChanges]);

  return discardChanges;
};
