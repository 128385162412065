import { EditNote } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FC } from 'react';
import { StyledSkeleton } from 'src/components/styled/StyledSkeleton';
import { useDrawerState } from 'src/hooks';

interface Props {
  loading?: boolean;
  disabled?: boolean;
  onSave?: () => unknown;
}

const BulkEditButton: FC<Props> = ({ loading, disabled }) => {
  const { bulkEditDrawerOpen, setBulkEditDrawerOpen } = useDrawerState();
  return (
    <>
      {!loading && (
        <LoadingButton
          disabled={disabled}
          size={'small'}
          variant={'contained'}
          color="secondary"
          startIcon={<EditNote />}
          onClick={() => setBulkEditDrawerOpen(!bulkEditDrawerOpen)}
          loading={loading}
          loadingPosition="start"
        >
          Bulk Edit
        </LoadingButton>
      )}
      {loading && <StyledSkeleton variant="rectangular" width={100} height={30} />}
    </>
  );
};

export default BulkEditButton;
