import { useMemo } from 'react';
import { MarkupDetailsRow, Module } from 'src/domain';
import { useCategorySplitQuery } from 'src/hooks/category-split';
import { useGenderId, useIsUsingGender } from 'src/hooks/gender';
import { useGenderSplitQuery } from 'src/hooks/gender-split/queries/useGenderSplitQuery';
import { useInheritance } from 'src/hooks/inheritance';
import { useIsUsingProductline, useProductLineId } from 'src/hooks/productline';
import { useProductLineSplitQuery } from 'src/hooks/productline-split/queries/useProductLineSplitQuery';
import { useTurnoverQuery } from 'src/hooks/turnover/queries/useTurnoverQuery';
import { useTurnoverVat } from 'src/hooks/turnover/table/useTurnoverVat';
import { mapInheritanceToExpectedNew } from 'src/mapping/inheritance.mapping';
import { mapMarkupToDetailsTableData } from 'src/mapping/markup.mapping';
import { groupByGenderAndProductLine } from 'src/utils/mergeRows';
import { useMarkupQuery } from '../queries/useMarkupQuery';

export const useMarkupDetailsRows = (aggregateOnGenderProductlines = false) => {
  const gender = useGenderId(Module.Markup);
  const productLine = useProductLineId(Module.Markup);
  const isUsingGender = useIsUsingGender();
  const isUsingProductLine = useIsUsingProductline();

  const {
    expected,
    styleCategories,
    loading,
    error,
    sanitizedData: markupSanitizedData,
  } = useMarkupQuery(undefined, gender, productLine);
  const { expectedSales } = useTurnoverQuery() ?? {};
  const { data: vat } = useTurnoverVat();
  const { expected: genderExpected } = useGenderSplitQuery();
  const { expectedSplit: productLineExpected } = useProductLineSplitQuery();
  const { expectedSplit: categorySplitExpected, sanitizedData: categorySplitSanitizedData } = useCategorySplitQuery(
    undefined,
    gender,
    productLine
  );

  const inheritance = useInheritance(Module.Markup);

  const aggregatedTableData = useMemo(() => {
    if (!inheritance) {
      return;
    }

    if (aggregateOnGenderProductlines) {
      if (!markupSanitizedData || !categorySplitSanitizedData) {
        return;
      }

      const markupByProductline = groupByGenderAndProductLine(markupSanitizedData);
      const categorySplitByProductline = groupByGenderAndProductLine(categorySplitSanitizedData);

      const rowsByGenderProductline: Record<string, Record<string, Map<string, MarkupDetailsRow[]> | undefined>> = {};
      Object.keys(markupByProductline).forEach((productlineKey) => {
        rowsByGenderProductline[productlineKey] = rowsByGenderProductline[productlineKey] ?? {};

        const markupByGender = markupByProductline[productlineKey];
        const categorySplitByGender = categorySplitByProductline[productlineKey];

        Object.keys(markupByGender).forEach((genderKey) => {
          const markupExpected = markupByGender[genderKey];
          const categorySplitExpected = categorySplitByGender[genderKey];

          const params = {
            markupData: mapInheritanceToExpectedNew(markupExpected, inheritance),
            salesData: expectedSales,
            vat,
            styleCategories,
            categorySplit: categorySplitExpected,
            genderData: {
              split: genderExpected,
              isUsingGender,
              activeGender: Number(genderKey),
            },
            productLineData: {
              split: productLineExpected,
              isUsingProductLine,
              activeProductLine: Number(productlineKey),
            },
          };
          rowsByGenderProductline[productlineKey][genderKey] = mapMarkupToDetailsTableData(params);
        });
      });

      return rowsByGenderProductline;
    }
  }, [
    aggregateOnGenderProductlines,
    categorySplitSanitizedData,
    expectedSales,
    genderExpected,
    inheritance,
    isUsingGender,
    isUsingProductLine,
    markupSanitizedData,
    productLineExpected,
    styleCategories,
    vat,
  ]);

  const tableData = useMemo(() => {
    if (!inheritance) {
      return;
    }

    const params = {
      markupData: mapInheritanceToExpectedNew(expected, inheritance),
      salesData: expectedSales,
      vat,
      styleCategories,
      categorySplit: categorySplitExpected,
      genderData: {
        split: genderExpected,
        isUsingGender,
        activeGender: gender,
      },
      productLineData: {
        split: productLineExpected,
        isUsingProductLine,
        activeProductLine: productLine,
      },
    };
    return mapMarkupToDetailsTableData(params);
  }, [
    categorySplitExpected,
    expected,
    expectedSales,
    gender,
    genderExpected,
    inheritance,
    isUsingGender,
    isUsingProductLine,
    productLine,
    productLineExpected,
    styleCategories,
    vat,
  ]);

  return { data: tableData, aggregatedData: aggregatedTableData, loading, error };
};
