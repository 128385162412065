import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { isColDef } from 'src/utils/isColDef';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';

export const defaultColDef: ColDef = {
  wrapHeaderText: true,
  flex: 0,
  resizable: true,
  sortable: true,
  tooltipValueGetter: ({ colDef, data }) => {
    if (isColDef(colDef) && colDef.field && data) {
      const value = data[colDef.field];
      const formattedValue =
        typeof colDef.valueFormatter === 'function' ? colDef.valueFormatter({ value } as ValueFormatterParams) : value;

      return formattedValue;
    }
  },
  cellStyle: {
    fontSize: 10,
    fontWeight: 'normal',
  },
  enablePivot: false,
  enableRowGroup: false,
  enableValue: false,
};

const phaseOutDefaultCols: ColDef[] = [
  {
    field: 'styleCategory',
    headerName: 'Style category',
    valueGetter: ({ data }) => data.styleCategory.styleCategoryName,
    flex: 2,
  },
  {
    field: 'styleNumber',
    headerName: 'Style number',
    sort: 'asc',
    flex: 2,
  },
  {
    field: 'styleName',
    headerName: 'Style name',
    cellStyle: {
      justifyContent: 'flex-start',
      fontWeight: 'normal',
    },
    flex: 5,
    minWidth: 300,
  },
  {
    field: 'norm',
    headerName: 'Norm',
    flex: 1.2,
  },
  {
    field: 'openOrders',
    headerName: 'Open Orders',
    flex: 1.3,
  },
  {
    field: 'stockQuantity',
    headerName: 'Stock QTY',
    flex: 1.2,
    valueFormatter: (cell: ValueFormatterParams) => {
      if (isNaN(cell.value)) return cell.value;
      return numberWithSpaces(cell.value);
    },
  },
  {
    field: 'stockValue',
    headerName: 'Stock Value',
    flex: 1.2,
    valueFormatter: (cell: ValueFormatterParams) => {
      if (isNaN(cell.value)) return cell.value;
      return numberWithSpaces(cell.value);
    },
  },
  {
    field: 'weeksUntilExitWeek',
    headerName: 'Weeks until exit week',
    valueFormatter: (cell: ValueFormatterParams) => {
      if (isNaN(cell.value)) return cell.value;
      return numberWithSpaces(cell.value);
    },
    flex: 2,
  },
];

export const unsuccessfulPhaseOutCols: ColDef[] = [...phaseOutDefaultCols];

export const predictedUnsuccessfulPhaseOutCols: ColDef[] = [
  ...phaseOutDefaultCols,
  {
    field: 'predictedStockValue',
    headerName: 'Predicted Stock Value',
    valueFormatter: (cell: ValueFormatterParams) => {
      if (isNaN(cell.value)) return cell.value;
      return numberWithSpaces(cell.value);
    },
    flex: 2,
  },
  {
    field: 'predictedStockQuantity',
    headerName: 'Predicted Stock Quantity',
    valueFormatter: (cell: ValueFormatterParams) => {
      if (isNaN(cell.value)) return cell.value;
      return numberWithSpaces(cell.value);
    },
    flex: 2,
  },
];
