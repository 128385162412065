import { useMemo } from 'react';
import { EditablePercentageCell } from 'src/components/molecules/EditableCell/EditableCell';
import {
  createDynamicMonthColumns,
  parsePercentageValueToNumber,
} from 'src/components/organisms/DataTable/columns/utils';
import { Inheritance, Module } from 'src/domain';
import { useInheritance } from 'src/hooks/inheritance';
import { mapSplitToOverviewTableDataNew } from 'src/mapping/split-modules.mapping';
import { sumAggFunc } from 'src/utils/ag-grid/agg-functions';
import { useProductLineSplitQuery } from '../queries/useProductLineSplitQuery';

export const useProductLineSplitOverviewRows = (inheritance?: Inheritance) => {
  const moduleInheritance = useInheritance(Module.ProductLineSplit);
  const inheritanceToUse = inheritance ?? moduleInheritance;
  const isHistorical = inheritanceToUse === Inheritance.Historical;
  const { expectedSplit, productLines, error, loading } = useProductLineSplitQuery(inheritanceToUse);

  const tableData = useMemo(
    () => mapSplitToOverviewTableDataNew(expectedSplit, productLines, isHistorical ?? false),
    [expectedSplit, isHistorical, productLines]
  );
  const columns = useMemo(
    () =>
      expectedSplit
        ? createDynamicMonthColumns(
            expectedSplit
              .map((value) => value?.monthKey)
              .filter((value) => !!value)
              .map((value) => ({ monthKey: value })) as { monthKey: number }[],
            {
              cellRenderer: EditablePercentageCell,
              aggFunc: sumAggFunc,
              valueParser: parsePercentageValueToNumber,
            }
          )
        : [],
    [expectedSplit]
  );

  return { data: tableData, columns, error, loading };
};
