import { useChainId } from 'src/hooks/chain';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';

export const useBuyingEventsApiQuery = () => {
  const chainId = useChainId();
  const response = useApiQuery('/api/buyingevents/chains/{chainId}', 'get', {
    path: {
      chainId: chainId,
    },
    enabled: !!chainId,
  });
  return response;
};
