import { useCallback, useEffect, useSyncExternalStore } from 'react';
import { GlobalVar } from 'src/infrastructure/local_state';

type SetFn<T> = (prev: T) => T;

export const useGlobalVar = <T>(globalVar: GlobalVar<T>, defaultValue?: T) => {
  const val = useSyncExternalStore(globalVar.subscribe, globalVar.get);

  const setGlobalVar = useCallback(
    (newVal: T | SetFn<T>) => {
      if (typeof newVal === 'function') {
        const prev = globalVar.get();
        globalVar.set((newVal as SetFn<T>)(prev));
      } else {
        globalVar.set(newVal);
      }
    },
    [globalVar]
  );

  useEffect(() => {
    if (defaultValue) setGlobalVar(defaultValue);

    // Only run on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [val, setGlobalVar] as const;
};
