import styled from '@emotion/styled';
import Badge, { BadgeProps } from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { FC, useEffect, useState } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { Headings } from 'src/domain';

export interface ToggleButtonInput {
  callbackFunction: (productId: number) => void;
  name: string;
  id: number;
  hasChanges?: boolean;
}

interface Props {
  buttonInputs: ToggleButtonInput[];
  header: string;
  defaultValue?: string | null;
}

export const ExclusiveToggleButtonGroup: FC<Props> = ({ buttonInputs, header, defaultValue }) => {
  const value = defaultValue ?? buttonInputs[0].name;
  const [active, setActive] = useState<string>();

  useEffect(() => setActive(value), [value]);

  const handleSelect = (_: unknown, v: string) => !!v && setActive(v);

  return (
    <TitleCardContainer>
      <Stack spacing={1}>
        <Header heading={Headings.h2}>{header}</Header>
        <ToggleButtonGroup value={active} exclusive onChange={handleSelect} aria-label="text alignment">
          {buttonInputs.map((toggleButton, index) => {
            return (
              <ToggleButton
                value={toggleButton.name}
                key={index}
                aria-label={toggleButton.name + ' selector'}
                onClick={() => toggleButton.callbackFunction(toggleButton.id)}
              >
                <ButtonContainer>
                  <StyledBadge
                    variant="dot"
                    invisible={!toggleButton.hasChanges}
                    color="info"
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  >
                    {toggleButton.name}
                  </StyledBadge>
                </ButtonContainer>
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Stack>
    </TitleCardContainer>
  );
};

const TitleCardContainer = styled(Container)`
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
`;

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    left: -8,
    top: 12,
    padding: '0 4px',
  },
}));
