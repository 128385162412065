import _ from 'lodash';

/**
 * Convert unsaved changes object to array with all modules with changes in them
  
   For instance convert:

   {
        stores: {
            1234: {},
            5678: {
                "category-split": [...],
            },
        },
        clusters: {
            1234: {
                "turnover": [...]
                "category-split": [...],
            },
        },
    }

    To:

    ["category-split", "turnover"]
 * 
 * @param unsavedChanges An object with type of unsaved change as key and another object with entity ID as key
 * @returns Array with all the modules with changes in it
 */
export const convertUnsavedChangesToArrayofModules = (
  unsavedChanges: Record<string, Record<string, Record<string, unknown[]>>> | undefined
): string[] => {
  if (!unsavedChanges) return [];

  return _.uniq(
    Object.values(unsavedChanges)
      .map((value) =>
        Object.values(value).reduce((tempResult, value) => {
          tempResult.push(...Object.keys(value));
          return tempResult.flat();
        }, [] as string[])
      )
      .flat()
  );
};
