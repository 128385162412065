import { useMemo } from 'react';
import { useStores } from '../store-selection/queries/useStores';
import { useStoreId } from './useStoreId';

export const useCurrentStore = (id?: number) => {
  const storeId = useStoreId();
  const stores = useStores();

  const currentStoreId = id ?? storeId;

  return useMemo(() => {
    if (!currentStoreId || !stores.data) return;
    return stores.data.allStoresLookup.get(Number(currentStoreId));
  }, [currentStoreId, stores.data]);
};
