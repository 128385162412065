import { useDroppable } from '@dnd-kit/core';
import styled from '@emotion/styled';
import { Check, Delete, Edit, NavigateNext } from '@mui/icons-material';
import { Collapse, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useCallback, useEffect, useState } from 'react';
import Header from 'src/components/atoms/Header';
import { Headings } from 'src/domain';
import { CollapseAllEvent } from 'src/domain/events/collapse-all.event';
import { ExpandAllEvent } from 'src/domain/events/expand-all.event';
import { StoreModel } from 'src/infrastructure/rest-api/api-types';
import { ClusterStore } from '../ClusterStore/ClusterStore';
import { ClusterStoreHeader } from '../ClusterStore/ClusterStoreHeader';
import { useIsDragging } from '../hooks/useIsDragging';

export interface ClusterBoxProps {
  id: number;
  clusterName: string;
  description: string;
  stores?: StoreModel[];
  onMoveToCluster: (storeId: number, clusterId: number, name: string, description: string) => void;
  onSaveEdit: (name: string, description: string) => boolean;
  onDeleteCluster: (clusterId: number) => unknown;
  isUnsavedCluster?: boolean;
  isDefaultCluster?: boolean;
  canEdit?: boolean;
}

export const ClusterBox: FC<ClusterBoxProps> = ({
  id,
  clusterName,
  description,
  stores = [],
  onMoveToCluster,
  onSaveEdit,
  isUnsavedCluster = false,
  isDefaultCluster = false,
  onDeleteCluster,
  canEdit = true,
}) => {
  const [open, setOpen] = useState(isUnsavedCluster);
  const [isEditing, setIsEditing] = useState(false);
  const { setNodeRef, isOver } = useDroppable({
    id,
    data: { id, clusterName, description, stores } as Partial<ClusterBoxProps>,
  });
  const { isDragging } = useIsDragging();
  const [nameEdit, setNameEdit] = useState<string>(clusterName);
  const [descriptionEdit, setDescriptionEdit] = useState<string>(description);

  const onExpandAll = useCallback(() => setOpen(true), []);
  const onCollapseAll = useCallback(() => setOpen(false), []);

  useEffect(() => {
    setNameEdit(clusterName);
  }, [clusterName]);

  useEffect(() => {
    setDescriptionEdit(description);
  }, [description]);

  useEffect(() => {
    window.addEventListener(ExpandAllEvent, onExpandAll);
    window.addEventListener(CollapseAllEvent, onCollapseAll);
    return () => {
      window.removeEventListener(ExpandAllEvent, onExpandAll);
      window.removeEventListener(CollapseAllEvent, onCollapseAll);
    };
  }, [onCollapseAll, onExpandAll]);

  const handleClick = useCallback(() => setOpen((prev) => !prev), [setOpen]);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleSaveEdit = useCallback(() => {
    if (!nameEdit) return;

    if (onSaveEdit(nameEdit, descriptionEdit)) setIsEditing(false);
  }, [descriptionEdit, nameEdit, onSaveEdit]);

  const notOverStyle = {
    opacity: '0.5',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 0px 0px',
    backgroundColor: 'rgba(68, 142, 196, 0.15)',
  };
  const isOverStyle = {
    opacity: '1',
    boxShadow: 'rgba(127, 183, 126, 0.2) 0px 16px 48px',
    backgroundColor: 'rgba(144,238,144, 0.15)',
  };

  const nameInputProps = {
    maxLength: 40,
    style: {
      fontSize: '1.5em',
      fontWeight: '400',
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    },
  };

  const descriptionInputProps = {
    maxLength: 100,
    style: {
      color: 'rgba(0, 0, 0, 0.5)',
      fontWeight: '400',
      fontFamily:
        '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif',
    },
  };

  return (
    <StyledBox>
      <Box
        width={'100%'}
        display="grid"
        gridTemplateColumns="0.1fr 2fr 0.2fr"
        gridTemplateRows="0.6fr 0.1fr"
        alignItems="center"
      >
        <StyledIconButton onClick={handleClick} open={open ? 1 : 0}>
          <StyledIcon />
        </StyledIconButton>
        {!isEditing && (
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Header onDoubleClick={canEdit ? handleEdit : undefined} heading={Headings.h2}>
              {clusterName}
            </Header>
          </Stack>
        )}
        {isEditing && (
          <TextField
            inputProps={nameInputProps}
            variant="standard"
            margin="none"
            autoFocus
            defaultValue={clusterName}
            placeholder="Cluster Name"
            onChange={(event) => setNameEdit(event.currentTarget.value)}
          />
        )}

        <div>
          <IconButton
            color={isEditing ? 'success' : 'primary'}
            onClick={isEditing ? handleSaveEdit : handleEdit}
            disabled={!canEdit}
          >
            {!isEditing && <Edit />}
            {isEditing && <Check />}
          </IconButton>
          <IconButton color="error" disabled={isDefaultCluster} onClick={() => onDeleteCluster(id)}>
            <Delete />
          </IconButton>
        </div>

        <div />
        <StyledDescriptionContainer>
          {!isEditing && (
            <Typography onDoubleClick={canEdit ? handleEdit : undefined} variant="body1" gutterBottom>
              {description}
            </Typography>
          )}
          {isEditing && (
            <TextField
              fullWidth
              placeholder="Cluster Description"
              inputProps={descriptionInputProps}
              variant="standard"
              margin="none"
              defaultValue={description}
              onChange={(event) => setDescriptionEdit(event.currentTarget.value)}
            />
          )}
        </StyledDescriptionContainer>
      </Box>
      <StyledStack direction="column">
        <Collapse in={open}>
          {!stores?.isEmpty() && (
            <>
              <ClusterStoreHeader />
              {stores?.map((store) => (
                <ClusterStore key={store.id} store={store} onMoveToCluster={onMoveToCluster} clusterId={id} />
              ))}
            </>
          )}
          {stores?.isEmpty() && (
            <Typography variant="subtitle1" textAlign={'center'}>
              This cluster has no stores.
            </Typography>
          )}
        </Collapse>
        {isDragging && (
          <DropBox ref={setNodeRef} style={isOver ? isOverStyle : notOverStyle}>
            <Typography>Drop here</Typography>
          </DropBox>
        )}
      </StyledStack>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  border-radius: 5px 5px 5px 5px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));
  background-color: white;
`;

const StyledIconButton = styled(IconButton)<{ open: 1 | 0 }>`
  height: 50px;
  width: 50px;
  transform: rotate(${({ open }) => (open ? '90deg' : '0deg')});
  transition: transform 0.4s linear;
  cursor: pointer;
`;

const StyledIcon = styled(NavigateNext)`
  height: 50px;
  width: 50px;
`;

const StyledStack = styled(Stack)`
  width: 100%;
`;

const DropBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  margin-top: 12px;
  transition:
    box-shadow 500ms,
    opacity 200ms,
    background-color 400ms;
  border: 1px dashed #4472c4;
`;

const StyledDescriptionContainer = styled.div`
  color: rgba(0, 0, 0, 0.5);
  align-self: flex-start;
`;
