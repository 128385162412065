import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { FC, useMemo } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { EditableValueCell } from 'src/components/molecules/EditableCell/EditableCell';
import Topbar from 'src/components/organisms/Topbar';
import { ContentWrapper } from 'src/components/styled/ContentWrapper';
import { StyledHeaderWrapper } from 'src/components/styled/StyledHeaderWrapper';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { Headings, Module, ScopeEnum } from 'src/domain';
import { useHasUnsavedChanges, useScope, useStoreId } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useTurnoverDetails } from 'src/hooks/turnover/useTurnoverDetails';

import BulkEditButton from 'src/components/molecules/BulkEditButton/BulkEditButton';
import { ComparableStoreSelect } from 'src/components/molecules/ComparableStoreSelect/ComparableStoreSelect';
import { ActionButtons } from 'src/components/organisms/ActionButtons/ActionsButtons';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { defaultColDefNumberWithSpaces } from 'src/components/organisms/DataTable/columns/default-options';
import InheritanceDropdown from 'src/components/organisms/InheritanceDropdown';
import { useIsAdmin } from 'src/hooks/auth/useIsAdmin';
import { useIsSuperUser } from 'src/hooks/auth/useIsSuperUser';
import { useTurnoverSaveChanges } from 'src/hooks/turnover/save-changes/useTurnoverSaveChanges';
import { useTurnoverSimulationRows } from 'src/hooks/turnover/simulation/useTurnoverSimulationRows';
import { useTurnoverRows } from 'src/hooks/turnover/table/useTurnoverRows';
import { useTurnoverHandleCellValueChanged } from 'src/hooks/turnover/useTurnoverHandleCellValueChanged';
import { mergeTurnoverTableDataToTuples } from 'src/mapping/table.mapping';
import { TurnoverKpis } from './TurnoverKpis';
import { TurnoverLineChart } from './TurnoverLineChart';
import {
  calculateAverageIndex,
  calculateAverageSalesiV,
  calculateExpectedTurnoverNext12Months,
  extractPlannedIndex,
  extractPlannedSales,
  isTwelveMonthsAheadOrMore,
} from './data/turnover';

export const Turnover: FC = () => {
  const storeId = useStoreId();
  const scope = useScope();
  const { storeDetails, clusterDetails, partnerDetails } = useTurnoverDetails();
  const { data: turnoverData, columns, loading: turnoverLoading } = useTurnoverRows();
  const { data: turnoverSimulationData, loading: turnoverSimulationLoading } = useTurnoverSimulationRows();
  const isSuperUser = useIsSuperUser();
  const isAdmin = useIsAdmin();

  const detailsSize = useMemo(
    () => storeDetails?.storeSize ?? clusterDetails?.averageStoreSize ?? partnerDetails?.averageStoreSize,
    [clusterDetails?.averageStoreSize, partnerDetails?.averageStoreSize, storeDetails?.storeSize]
  );

  const columnsDef = useMemo(() => {
    if (storeId === 'overview') {
      return columns;
    }
    return columns.map((column) => {
      if (['plannedSalesIv', 'plannedIndex'].includes(column.field ?? '')) {
        return {
          ...column,
          cellRenderer: EditableValueCell,
          editable: true,
        };
      }
      return column;
    });
  }, [columns, storeId]);

  const handleCellValuesChanges = useTurnoverHandleCellValueChanged();

  const [saveChanges, { loading: isSaving }] = useTurnoverSaveChanges();
  const discardChanges = useDiscardChangesModule(Module.Turnover);

  const canSave = useHasUnsavedChanges();
  const loading = turnoverLoading || turnoverSimulationLoading;

  const overviewRows = useMemo(() => {
    if (loading) {
      return;
    }

    if (storeId === 'overview') {
      return mergeTurnoverTableDataToTuples(turnoverData, turnoverSimulationData);
    }

    return turnoverSimulationData;
  }, [loading, storeId, turnoverData, turnoverSimulationData]);

  const chartData = useMemo(() => {
    if (!overviewRows) return;

    return overviewRows.map((row) => {
      if (isTwelveMonthsAheadOrMore(overviewRows[0].monthKey.toString(), row.monthKey.toString())) {
        return { ...row, salesIvLy: undefined };
      }

      return row;
    });
  }, [overviewRows]);

  const plannedSalesIvs = useMemo(() => extractPlannedSales(turnoverData), [turnoverData]);
  const plannedSalesIvsSimulated = useMemo(() => extractPlannedSales(turnoverSimulationData), [turnoverSimulationData]);

  const plannedIndexes = useMemo(() => extractPlannedIndex(turnoverData), [turnoverData]);
  const plannedIndexesSimulated = useMemo(() => extractPlannedIndex(turnoverSimulationData), [turnoverSimulationData]);

  const averageIndexes = useMemo(() => {
    return [calculateAverageIndex(plannedIndexes), calculateAverageIndex(plannedIndexesSimulated)] as const;
  }, [plannedIndexes, plannedIndexesSimulated]);

  const expectedSales = useMemo(() => {
    if (!plannedSalesIvs) {
      return;
    }

    const turnoverNext12Months = calculateExpectedTurnoverNext12Months(plannedSalesIvs);
    const turnoverNext12MonthsSimulated = plannedSalesIvsSimulated
      ? calculateExpectedTurnoverNext12Months(plannedSalesIvsSimulated)
      : undefined;

    const value = [turnoverNext12Months, turnoverNext12MonthsSimulated] as const;
    if (scope === ScopeEnum.STORE) {
      return {
        title: 'EXPECTED SALES iV NEXT 12 MONTHS',
        value,
      };
    }
    return {
      title: 'TOTAL EXPECTED SALES iV NEXT 12 MONTHS',
      value,
    };
  }, [plannedSalesIvs, plannedSalesIvsSimulated, scope]);

  const expectedSalesPerSquareMeter = useMemo(() => {
    if (typeof detailsSize !== 'number' || !plannedSalesIvs || !expectedSales) {
      return;
    }

    if (scope === ScopeEnum.STORE) {
      const salesIv = expectedSales.value[0];
      const salesIvSimulated = expectedSales.value[1];
      const salesIvPerSqm = salesIv / detailsSize;
      const salesIvPerSqmSimulated = salesIvSimulated ? salesIvSimulated / detailsSize : undefined;

      return {
        title: 'SALES iV/SQM',
        value: [salesIvPerSqm, salesIvPerSqmSimulated] as const,
      };
    }

    const avgSalesIv = calculateAverageSalesiV(plannedSalesIvs) / detailsSize;
    const avgSalesIvSimulated = plannedSalesIvsSimulated
      ? calculateAverageSalesiV(plannedSalesIvsSimulated) / detailsSize
      : undefined;
    return {
      title: 'AVG SALES iV/SQM',
      value: [avgSalesIv, avgSalesIvSimulated] as const,
    };
  }, [detailsSize, plannedSalesIvs, expectedSales, scope, plannedSalesIvsSimulated]);

  const salesFloorSquareMeters = useMemo(() => {
    if (typeof detailsSize !== 'number') {
      return;
    }

    const value = Math.round(detailsSize);
    if (scope === ScopeEnum.STORE) {
      return {
        title: 'SIZE',
        value,
      };
    }
    return {
      title: 'AVG SIZE',
      value,
    };
  }, [detailsSize, scope]);

  return (
    <Box>
      <ContentWrapper>
        <Box style={{ gridArea: 'title' }}>
          <Topbar hideToggleButtons hideButtons={true} />
        </Box>

        {!loading && (
          <TurnoverKpis
            scope={scope}
            averageIndex={averageIndexes}
            expectedSales={expectedSales}
            expectedSalesPerSquareMeter={expectedSalesPerSquareMeter}
            salesFloorSquareMeters={salesFloorSquareMeters}
            storeDetails={storeDetails}
            numberOfStores={partnerDetails?.numberOfStores ?? 0}
          />
        )}

        <div style={{ gridArea: 'table' }}>
          <Container>
            <StyledHeaderWrapper>
              <Header heading={Headings.h2}>Expected Sales iV</Header>
              {scope === ScopeEnum.STORE && (
                <ActionWrapper>
                  <StyledInheritanceDropdown />
                  <ActionButtons
                    onDiscard={discardChanges}
                    onSave={saveChanges}
                    onSetInheritance={saveChanges}
                    onReset={saveChanges}
                    saveDisabled={!canSave}
                    saveLoading={isSaving}
                    loading={loading || isSaving}
                  />
                </ActionWrapper>
              )}

              <ActionWrapper>
                {scope !== ScopeEnum.STORE && <ComparableStoreSelect />}
                {scope === ScopeEnum.PARTNER && (isSuperUser || isAdmin) && <BulkEditButton />}
              </ActionWrapper>
            </StyledHeaderWrapper>
            <TableWrapper className="ag-theme-alpine-custom">
              <StyledDataTable
                key={scope}
                autoSizeToFit={true}
                columns={columnsDef}
                defaultColDef={defaultColDefNumberWithSpaces}
                rows={overviewRows}
                onCellValuesChanged={handleCellValuesChanges}
                loading={loading}
              />
            </TableWrapper>
          </Container>
        </div>
      </ContentWrapper>
      <Box sx={{ width: '100%', maxWidth: '1200px', marginY: '16px' }}>
        <Container>
          <TurnoverLineChart data={chartData} loading={loading} overview={storeId === 'overview'} />
        </Container>
      </Box>
    </Box>
  );
};

const StyledInheritanceDropdown = styled(InheritanceDropdown)`
  margin: 0;
`;

const ActionWrapper = styled.div`
  display: inherit;
  align-items: center;
  gap: 16px;
  &:empty {
    display: none;
  }
  flex-wrap: wrap;
`;

const StyledDataTable = styled(DataTable)`
  div {
    [row-index='12'],
    [row-index='14'],
    [row-index='16'],
    [row-index='18'] {
      background-color: rgba(25, 118, 210, 0.07) !important;
      &:hover {
        background-color: rgba(25, 118, 210, 0.12) !important;
      }
    }
  }
  div {
    [row-index='13'],
    [row-index='15'],
    [row-index='17'] {
      background-color: rgba(25, 118, 210, 0.1) !important;
      &:hover {
        background-color: rgba(25, 118, 210, 0.12) !important;
      }
    }
  }
`;
