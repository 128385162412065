import { isObject } from 'lodash';

// If given object as key, make sure order is maintained
export class SerializeMap<T, U> {
  private map = new Map<string, U>();

  constructor(existingMap?: SerializeMap<T, U>);
  constructor(key?: T, value?: U);
  constructor(existingMap?: SerializeMap<T, U>, key?: T, value?: U) {
    this.map = existingMap?.map ?? new Map<string, U>();

    if (key && value) this.map.set(objectToMapKey(key), value);
  }

  entries() {
    return this.map.entries();
  }

  values() {
    return this.map.values();
  }

  keys() {
    return this.map.keys();
  }

  private getSerializedKey(key: T) {
    return typeof key === 'string' ? key : objectToMapKey(key as Record<string, unknown>);
  }

  get(key: T): U | undefined | null {
    return this.map.get(this.getSerializedKey(key));
  }

  set(key: T, value: U) {
    this.map.set(this.getSerializedKey(key), value);

    return this;
  }

  has(key: T) {
    return this.map.has(this.getSerializedKey(key));
  }

  delete(key: T) {
    return this.map.delete(this.getSerializedKey(key));
  }
}

const objectToMapKey = (obj: Record<string, unknown>): string => {
  return Object.keys(obj)
    .sort((a, b) => a.localeCompare(b))
    .map((key) => {
      if (isObject(obj[key])) return `${key}:${objectToMapKey(obj[key] as Record<string, unknown>)}`;

      return `${key}:${obj[key]}`;
    })
    .join('|');
};
