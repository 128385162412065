import { StyleCategory } from 'src/domain/models/style-category';
import { StyleCategoryModel } from 'src/infrastructure/rest-api/api-types';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';

export const useStyleCategoriesQuery = () => {
  return useApiQuery('/api/stylecategories', 'get');
};

export const styleCategoriesToGroupedByData = (
  styleCategories: StyleCategoryModel[] | undefined
): StyleCategory[] | undefined => {
  return styleCategories?.map((category) => ({
    styleCategoryId: category.id,
    styleCategoryName: category.name ?? '',
    categorySort: category.order,
  }));
};
