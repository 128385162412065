import { Button } from '@mui/material';
import { FC, useCallback } from 'react';
import { useDrawerState } from 'src/hooks';

interface AuditLogButtonProps {
  loading?: boolean;
}

export const AuditLogButton: FC<AuditLogButtonProps> = ({ loading = false }) => {
  const { auditLogDrawerOpen, setAuditLogDrawerOpen } = useDrawerState();

  const showAuditLog = useCallback(() => {
    setAuditLogDrawerOpen(!auditLogDrawerOpen);
  }, [auditLogDrawerOpen, setAuditLogDrawerOpen]);

  return (
    <Button
      onClick={showAuditLog}
      variant="contained"
      color="secondary"
      size={'small'}
      disabled={loading}
      sx={{ textTransform: 'none' }}
    >
      Audit Log
    </Button>
  );
};
