import { useContext, useEffect, useMemo } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { GenderSplitLevelQuery } from '../gender-split/queries/useGenderSplitSplitLevelQuery';
import { useScope } from '../useScope';
import { IsHistoricalContext } from './IsHistoricalContext';

export const useIsGenderSplitHistorical = (genderSplitData: GenderSplitLevelQuery['data'] | undefined) => {
  const scope = useScope();

  const isTyped = useMemo(() => {
    switch (scope) {
      case ScopeEnum.STORE:
        return genderSplitData?.storeGenderSplit?.some((x) => !!x?.modifiedByUser);

      case ScopeEnum.CLUSTER:
        return genderSplitData?.clusterGenderSplit?.some((x) => !!x?.modifiedByUser);

      case ScopeEnum.PARTNER:
        return genderSplitData?.partnerGenderSplit?.some((x) => !!x?.modifiedByUser);
    }
  }, [
    genderSplitData?.clusterGenderSplit,
    genderSplitData?.partnerGenderSplit,
    genderSplitData?.storeGenderSplit,
    scope,
  ]);

  // invert isTyped but keep undefined
  if (isTyped === true) return false;
  if (isTyped === false) return true;
  return undefined;
};

export const useInitializeIsGenderSplitHistorical = (data: GenderSplitLevelQuery['data'] | undefined) => {
  const [isHistoricalMap, setIsHistoricalMap] = useContext(IsHistoricalContext);

  const isGenderSplitHistorical = useIsGenderSplitHistorical(data);

  useEffect(() => {
    if (isGenderSplitHistorical === undefined) return;
    if (isHistoricalMap.get(Module.GenderSplit) === isGenderSplitHistorical) return;

    isHistoricalMap.set(Module.GenderSplit, isGenderSplitHistorical);
    setIsHistoricalMap(new Map(isHistoricalMap));
  }, [isGenderSplitHistorical, isHistoricalMap, setIsHistoricalMap]);
};
