import { FC } from 'react';
import { CircularProgressIcon } from 'src/components/styled/CircularProgressIcon';
import ProgressBar from '../ProgressBar';

interface Props {
  style?: 'bar' | 'spinner';
  size?: string;
  completed?: boolean;
}

const Loader: FC<Props> = ({ size = '40px', style = 'spinner', completed = false }) => {
  if (style === 'spinner') return <CircularProgressIcon size={size} />;
  if (style === 'bar') return <ProgressBar completed={completed} />;

  return <></>;
};

export default Loader;
