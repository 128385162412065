import styled from '@emotion/styled';
import { Add, CheckCircle, Error, NewReleases, UTurnLeft } from '@mui/icons-material';
import { Alert, AppBar, Button, Toolbar, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import { FC, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from 'src/assets/img/logo-storeforecast.svg';
import Container from 'src/components/atoms/Container/Container';
import Spacer from 'src/components/atoms/Spacer/Spacer';
import { AnnouncementDialog } from 'src/components/organisms/AnnouncementDialog/AnnouncementDialog';
import { AnnouncementTimeline } from 'src/components/organisms/AnnouncementTimeline/AnnouncementTimeline';
import { Color, Spacings } from 'src/domain';
import { AnnouncementRow } from 'src/domain/table/announcement.row';
import { useAnnouncementsApiQuery } from 'src/hooks/announcements/queries/useAnnouncementApiQuery';
import { useIsAdmin } from 'src/hooks/auth/useIsAdmin';
import { mapAnnouncements } from 'src/mapping/announcements.mapping';

export const AnnouncementSystem: FC = () => {
  const { data, refetch } = useAnnouncementsApiQuery();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentAnnouncement, setCurrentAnnouncement] = useState<AnnouncementRow | undefined>();

  const announcements = mapAnnouncements(data);

  const activeAnnouncements = orderBy(
    announcements.filter((announcement) => !announcement.resolved),
    ['created'],
    ['desc']
  );
  const resolvedAnnouncements = orderBy(
    announcements.filter((announcement) => announcement.resolved),
    ['resolved'],
    ['desc']
  );

  const ref = useRef<HTMLDivElement>(null);

  const systemStatusType = useMemo(() => {
    if (announcements.some((announcement) => announcement.type === 'MajorIncident' && !announcement.resolved)) {
      return 'error';
    } else if (announcements.some((announcement) => announcement.type === 'MinorIncident' && !announcement.resolved)) {
      return 'warning';
    }
  }, [announcements]);

  const systemStatusDate = useMemo(() => {
    if (systemStatusType === 'error') {
      const announcement = announcements.find(
        (announcement) => announcement.type === 'MajorIncident' && !announcement.resolved
      );

      return announcement?.created?.toLocaleTimeString([], {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    } else if (systemStatusType === 'warning') {
      const announcement = announcements.find(
        (announcement) => announcement.type === 'MinorIncident' && !announcement.resolved
      );

      return announcement?.created?.toLocaleTimeString([], {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    }
  }, [announcements, systemStatusType]);

  const onAddAnnouncement = () => {
    setCurrentAnnouncement(undefined);
    setDialogOpen(true);
  };

  const handleEditAnnouncement = (announcement: AnnouncementRow) => {
    setCurrentAnnouncement({
      id: announcement.id,
      title: announcement.title,
      description: announcement.description,
      type: announcement.type,
      module: announcement.module,
      created: announcement.created,
      modified: announcement.modified,
      resolved: announcement.resolved,
    });
    setDialogOpen(true);
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onCloseDialog = () => {
    setDialogOpen(false);
    setCurrentAnnouncement(undefined);
  };

  return (
    <>
      <StyledAppBar position="fixed">
        <StyledToolbar variant="dense">
          <Button
            color="inherit"
            endIcon={<BackIcon />}
            onClick={() => {
              navigate('/');
            }}
          >
            Back to Storeforecast
          </Button>
          <StyledImage
            src={logo}
            onClick={() => {
              navigate('/');
            }}
          />
        </StyledToolbar>
      </StyledAppBar>

      {
        <StatusBanner
          severity={systemStatusType}
          ref={ref}
          iconMapping={{
            success: <CheckCircle fontSize="medium" sx={{ color: `${Color.white}` }} />,
            warning: <Error fontSize="medium" sx={{ color: `${Color.white}` }} />,
            error: <NewReleases fontSize="medium" sx={{ color: `${Color.white}` }} />,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '20px' }}>
            {systemStatusType === 'error' && `Major incident(s) on ${systemStatusDate}`}
            {systemStatusType === 'warning' && `Incident on ${systemStatusDate}`}
            {!systemStatusType && 'All systems operational'}
          </Typography>
        </StatusBanner>
      }

      <AnnouncementDialog
        id={currentAnnouncement?.id}
        title={currentAnnouncement?.title}
        description={currentAnnouncement?.description}
        type={currentAnnouncement?.type}
        module={currentAnnouncement?.module}
        resolved={!!currentAnnouncement?.resolved}
        open={dialogOpen}
        handleCloseDialog={onCloseDialog}
        handleRefetch={refetch}
      />

      <StyledAnnouncementContainer>
        <TitleWrapper>
          <Typography variant="h4">System Status</Typography>
          {isAdmin && (
            <Button size="small" variant="contained" startIcon={<Add />} onClick={onAddAnnouncement}>
              Add announcement
            </Button>
          )}
        </TitleWrapper>

        <AnnouncementsWrapper>
          <Spacer spacing={Spacings.large} vertical />
          <Typography variant="h5" sx={{ marginLeft: '20px' }}>
            Active announcements
          </Typography>
          {activeAnnouncements.length ? (
            <AnnouncementTimeline announcements={activeAnnouncements} handleEditAnnouncement={handleEditAnnouncement} />
          ) : (
            <>
              <Typography sx={{ marginLeft: '40px', marginTop: '10px' }}>No announcements found</Typography>
              <Spacer spacing={Spacings.medium} vertical />
            </>
          )}
          <Typography variant="h5" sx={{ marginLeft: '20px' }}>
            Resolved announcements
          </Typography>
          {resolvedAnnouncements.length ? (
            <AnnouncementTimeline
              announcements={resolvedAnnouncements}
              handleEditAnnouncement={handleEditAnnouncement}
            />
          ) : (
            <>
              <Typography sx={{ marginLeft: '40px', marginTop: '10px' }}>No announcements found</Typography>
              <Spacer spacing={Spacings.medium} vertical />
            </>
          )}
        </AnnouncementsWrapper>
      </StyledAnnouncementContainer>
    </>
  );
};

const StyledAppBar = styled(AppBar)`
  background-color: ${Color.white};
  color: ${Color.midnightGrey};
  width: 100%;
`;

const StyledToolbar = styled(Toolbar)`
  width: 70%;
  max-width: 1440px;
  align-self: center;
  display: flex;
  justify-content: space-between;
`;

const BackIcon = styled(UTurnLeft)`
  transform: rotate(90deg);
`;

const StyledImage = styled.img`
  height: 25px;
  width: auto;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledAnnouncementContainer = styled(Container)`
  width: 70%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
`;

const AnnouncementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StatusBanner = styled(Alert)`
  width: 70%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  margin-top: 70px;
  color: ${Color.white};
  display: flex;
  align-items: center;
  background-color: ${(props) => {
    if (props.severity === 'error') {
      return '#D9512C';
    }
    if (props.severity === 'warning') {
      return '#DBAB09';
    }
    return '#84B96B';
  }};
`;
