import { useCallback } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useInheritance } from 'src/hooks/inheritance';
import { useChangesSnackbar } from 'src/hooks/snackbar/useChangesSnackbar';
import { useUpdateSplitLevel } from 'src/hooks/split-levels/useUpdateSplitLevel';
import { useScope } from 'src/hooks/useScope';

export const useNoosShareUpdateInheritance = () => {
  const { updateSplitLevel: updateStoreSplitLevel, loading } = useUpdateSplitLevel();
  const discardChanges = useDiscardChangesModule(Module.NoosShare);

  const scope = useScope();
  const inheritance = useInheritance();
  const showSnackbar = useChangesSnackbar();

  const updateInheritance = useCallback(() => {
    if (!inheritance) {
      // eslint-disable-next-line no-console
      console.warn(`No inheritance set: ${inheritance}`);
    } else {
      switch (scope) {
        case ScopeEnum.STORE:
          return updateStoreSplitLevel(inheritance).then(() => {
            discardChanges();
            showSnackbar();
          });
      }
    }
    return Promise.resolve();
  }, [discardChanges, inheritance, showSnackbar, scope, updateStoreSplitLevel]);

  return { updateInheritance, loading };
};
