import { sum } from 'ramda';
import { useMemo } from 'react';
import { EditablePercentageCell } from 'src/components/molecules/EditableCell/EditableCell';
import {
  createDynamicMonthColumns,
  filterEmpty,
  parsePercentageValueToNumber,
} from 'src/components/organisms/DataTable/columns/utils';
import { Inheritance, Module, TableOverviewRow } from 'src/domain';
import { useInheritance } from 'src/hooks/inheritance';
import { styleCategoriesToGroupedByData } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { mapSplitToOverviewTableDataNew } from 'src/mapping/split-modules.mapping';
import { parseNumber } from 'src/utils/formatNumber';
import { groupByGenderAndProductLine, mergeDataByMonthAndCategory } from 'src/utils/mergeRows';
import { useCategorySplitQuery } from '../queries/useCategorySplitQuery';

export const useCategorySplitOverviewRows = (
  inheritance?: Inheritance,
  genderId?: number,
  productLineId?: number,
  aggregateOnGenderProductlines = false
) => {
  const moduleInheritance = useInheritance(Module.CategorySplit);
  const inheritanceToUse = inheritance ?? moduleInheritance;
  const isHistorical = inheritanceToUse === Inheritance.Historical;

  const { expectedSplit, styleCategories, error, loading, sanitizedData } = useCategorySplitQuery(
    inheritanceToUse,
    genderId,
    productLineId
  );

  const groupedByData = useMemo(() => {
    return styleCategoriesToGroupedByData(styleCategories);
  }, [styleCategories]);

  const aggregatedTableData = useMemo(() => {
    if (aggregateOnGenderProductlines) {
      if (!sanitizedData) return;

      const rowsByGenderProductline: Record<string, Record<string, TableOverviewRow[]>> = {};

      const categorySplitByProductline = groupByGenderAndProductLine(sanitizedData);
      Object.keys(categorySplitByProductline)
        .flat()
        .forEach((productlineKey) => {
          rowsByGenderProductline[productlineKey] = rowsByGenderProductline[productlineKey] ?? {};

          const categorySplitByGender = categorySplitByProductline[productlineKey];
          return Object.keys(categorySplitByGender).forEach((genderKey) => {
            const categorySplitExpected = categorySplitByGender[genderKey];
            rowsByGenderProductline[productlineKey][genderKey] =
              mapSplitToOverviewTableDataNew(
                mergeDataByMonthAndCategory(categorySplitExpected),
                groupedByData,
                isHistorical ?? false
              ) ?? [];
          });
        });
      return rowsByGenderProductline;
    }
  }, [aggregateOnGenderProductlines, groupedByData, isHistorical, sanitizedData]);

  const tableData = useMemo(() => {
    return mapSplitToOverviewTableDataNew(expectedSplit, groupedByData, isHistorical ?? false);
  }, [expectedSplit, groupedByData, isHistorical]);

  const columns = useMemo(
    () =>
      expectedSplit
        ? createDynamicMonthColumns(filterEmpty<{ monthKey: number }>(expectedSplit), {
            aggFunc: (params) => sum(params.values.map(parseNumber)),
            cellRenderer: EditablePercentageCell,
            valueParser: parsePercentageValueToNumber,
          })
        : [],
    [expectedSplit]
  );

  return { data: tableData, aggregatedData: aggregatedTableData, columns, error, loading };
};
