import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { useScope } from 'src/hooks/useScope';
import { components } from 'src/infrastructure/rest-api/generated';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';

export const useMarkdownApiQuery = (
  entityId: number | string | null | undefined,
  entityType: components['schemas']['EntityTypeEnum']
) => {
  const scope = useScope();

  const scopeMatches = useMemo(() => {
    switch (entityType) {
      case 'Store':
        return scope === ScopeEnum.STORE;
      case 'Cluster':
        return scope === ScopeEnum.CLUSTER || scope === ScopeEnum.STORE;
      case 'Partner':
        return scope === ScopeEnum.PARTNER || scope === ScopeEnum.STORE;
      default:
        return false;
    }
  }, [entityType, scope]);

  const { data, loading, error } = useApiQuery('/api/markdown', 'get', {
    query: {
      entityId: Number(entityId),
      entityType,
    },
    enabled: !!entityId && !isNaN(Number(entityId)) && scopeMatches,
  });

  return { data, loading, error };
};
