import { useMemo } from 'react';
import { GenderDetailsRow, ScopeEnum } from 'src/domain';
import { useTurnoverQuery } from 'src/hooks/turnover/queries/useTurnoverQuery';
import { mapSplitToDetailsTableDataNew } from 'src/mapping/split-modules.mapping';
import { useScope } from '../../useScope';
import { useGenderSplitQuery } from '../queries/useGenderSplitQuery';

export const useGenderSplitDetailsRows = () => {
  const { expected, genders, error, loading } = useGenderSplitQuery();
  const { expectedSales } = useTurnoverQuery() ?? {};
  const scope = useScope();

  const tableData = useMemo(() => {
    switch (scope) {
      case ScopeEnum.STORE:
        return mapSplitToDetailsTableDataNew<GenderDetailsRow>({
          expectedSplit: expected,
          groupedByData: genders,
          expectedSalesIv: expectedSales,
        });
      case ScopeEnum.CLUSTER:
        return mapSplitToDetailsTableDataNew<GenderDetailsRow>({
          expectedSplit: expected,
          groupedByData: genders,
          expectedSalesIv: expectedSales,
        });
      case ScopeEnum.PARTNER:
        return mapSplitToDetailsTableDataNew<GenderDetailsRow>({
          expectedSplit: expected,
          groupedByData: genders,
          expectedSalesIv: expectedSales,
        });

      default:
        return;
    }
  }, [expected, expectedSales, genders, scope]);

  return { data: tableData, error, loading };
};
