import { EditableCallbackParams } from 'ag-grid-community';

export const isStockOptimalOrCOGS = (category: string | undefined) => {
  return /Stock Optimal|COGS/i.test(category ?? '');
};

export const isStockManagementCellEditable = (params: EditableCallbackParams) => {
  const value = params?.data?.category;
  if (isStockOptimalOrCOGS(value)) return false;

  return true;
};
