import { useMemo } from 'react';
import { GroupLevel, Partner } from 'src/domain';
import { useCurrentStore } from '../store';
import { useStores } from '../store-selection/queries/useStores';
import { useQueryParams } from '../useQueryParams';
import { useStoreType } from '../useStoreType';

export const usePartners = (type: GroupLevel | null) => {
  const { chainId, countryId, cluster } = useQueryParams();

  const { data } = useStores();

  const store = useCurrentStore();

  const storeType = useStoreType();

  const partners = useMemo(() => {
    if (!type) {
      // store level
      return [
        {
          id: store?.operationalResponsible?.id,
          name:
            store?.storeType === 'WHOLESALE'
              ? store.businessTradingPartner.name
              : store?.operationalResponsible?.name ?? undefined,
          compositeId: store?.operationalResponsibleComposite?.id ?? undefined,
          cluster: store?.cluster?.name ?? undefined,
          country: store?.country?.name ?? undefined,
        },
      ];
    }

    return data?.allStores.reduce((acc: Partner[], store) => {
      if (type === GroupLevel.Country && chainId && countryId) {
        if (
          store.chain?.id === Number(chainId) &&
          store.country?.id === Number(countryId) &&
          store.storeType === storeType
        ) {
          if (!acc.some((p) => p.compositeId === store.operationalResponsibleComposite?.id)) {
            acc.push({
              id: store.operationalResponsible?.id,
              name:
                store.storeType === 'WHOLESALE'
                  ? store.businessTradingPartner.name
                  : store.operationalResponsible?.name ?? undefined,
              compositeId: store.operationalResponsibleComposite?.id ?? undefined,
              cluster: store.cluster?.name ?? undefined,
              country: store.country?.name ?? undefined,
            });
          }
        }
      } else if (type === GroupLevel.Cluster && chainId && cluster && store.storeType === storeType) {
        if (store.chain?.id === Number(chainId) && store.cluster?.name === cluster) {
          if (!acc.some((p) => p.compositeId === store.operationalResponsibleComposite?.id)) {
            acc.push({
              id: store.operationalResponsible?.id,
              name:
                store.storeType === 'WHOLESALE'
                  ? store.businessTradingPartner.name
                  : store.operationalResponsible?.name ?? undefined,
              compositeId: store.operationalResponsibleComposite?.id ?? undefined,
              cluster: store.cluster?.name,
              country: store.country?.name ?? undefined,
            });
          }
        }
      }

      return acc;
    }, []);
  }, [chainId, cluster, countryId, data?.allStores, store, storeType, type]);

  return partners;
};
