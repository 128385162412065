import styled from '@emotion/styled';
import { SentimentSatisfiedAlt } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { FC } from 'react';
import CenteredContainer from 'src/components/atoms/CenteredContainer';
import { Color } from 'src/domain';

type Props = {
  noData: boolean;
};
export const DataTableNoRows: FC<Props> = ({ noData }) => {
  return (
    <StyledContainer positive={noData}>
      {noData ? (
        <>
          <Text>Phew! Looks like there are no styles in need of your attention</Text>
          <HappyFace fontSize="small" />
        </>
      ) : (
        <>
          <Loader size="16px" />
          <Text>Loading...</Text>
        </>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(CenteredContainer)<{ positive: boolean }>`
  ${({ positive }) => positive && 'background-color: #daead3;'}
  color: #333;
  ${({ positive }) => positive && 'font-weight: bold;'}
  border: ${({ positive }) => (positive ? '#38970f solid 1px' : 'lightgrey solid 1px;')};
  display: flex;
  gap: 10px;
`;

const Loader = styled(CircularProgress)`
  color: ${Color.midnightGrey};
`;

const Text = styled.p`
  margin-left: 12px;
`;

const HappyFace = styled(SentimentSatisfiedAlt)`
  color: #38970f;
`;
