import { useCallback, useMemo, useState } from 'react';
import { Inheritance, Module, ScopeEnum } from 'src/domain';
import { useDiscardChangesGenderProductline } from 'src/hooks/discard-changes';
import { useChangesSnackbar } from 'src/hooks/snackbar/useChangesSnackbar';

import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useUpdateSplitLevel } from 'src/hooks/split-levels/useUpdateSplitLevel';
import { useCurrentId } from 'src/hooks/useCurrentId';
import { useEntityType } from 'src/hooks/useEntityType';
import { useScope } from 'src/hooks/useScope';
import { components } from 'src/infrastructure/rest-api/generated';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { CompareRowsResult, compareRows } from 'src/utils/compareRows';
import { useDeliveryProfileOverviewSimulationRows } from '../simulation/useDeliveryProfileOverviewSimulationRows';
import { useDeliveryProfileOverviewRows } from '../table/useDeliveryProfileOverviewRows';

type UpdateDeliveryProfile = components['schemas']['UpdateDeliveryProfileInput'];

function valueMapper(value: CompareRowsResult): UpdateDeliveryProfile {
  return {
    id: value.id,
    value: value.new,
  };
}

export const useDeliveryProfileSaveChanges = () => {
  const currentId = useCurrentId();
  const compositePartner = useCompositePartner();
  const entityType = useEntityType();
  const scope = useScope();

  const [mutateDeliveryProfile] = useApiMutation('/api/deliveryprofile', 'put', {
    update(data, _variables, queryClient) {
      const query = { entityId: Number(currentId), entityType };
      if (scope === ScopeEnum.PARTNER) {
        query['entityId'] = Number(compositePartner?.id);
      }
      queryClient.setQueryData(['/api/deliveryprofile', query], data);
      queryClient.invalidateQueries({
        queryKey: ['/api/inheritance', query],
      });
    },
  });

  const { updateSplitLevel: updateStoreSplitLevel } = useUpdateSplitLevel();

  const showSnackbar = useChangesSnackbar();
  const discardChanges = useDiscardChangesGenderProductline(Module.DeliveryProfile);

  const { data: currentData } = useDeliveryProfileOverviewRows();
  const { data: simulatedData } = useDeliveryProfileOverviewSimulationRows();

  const valuesToUpdate = useMemo(() => compareRows(currentData, simulatedData), [currentData, simulatedData]);
  const [isUpdating, setIsUpdating] = useState(false);

  const sendUpdateQuery = useCallback(async () => {
    if (!valuesToUpdate) {
      return;
    }

    const payload = valuesToUpdate.flatMap(valueMapper);

    if (scope === ScopeEnum.STORE) {
      await updateStoreSplitLevel(Inheritance.Typed);
    }

    return mutateDeliveryProfile({
      body: payload,
    });
  }, [scope, mutateDeliveryProfile, updateStoreSplitLevel, valuesToUpdate]);

  const updateDeliveryProfile = useCallback(async () => {
    setIsUpdating(true);

    try {
      const response = await sendUpdateQuery();
      discardChanges();
      showSnackbar();
      return response;
    } catch {
      showSnackbar(true);
    } finally {
      setIsUpdating(false);
    }
  }, [discardChanges, sendUpdateQuery, showSnackbar]);

  return [updateDeliveryProfile, { loading: isUpdating }] as const;
};
