import styled from '@emotion/styled';
import { FC } from 'react';
import Autocomplete from 'src/components/atoms/Autocomplete';
import Spacer from 'src/components/atoms/Spacer';
import { FilterOption, Spacings } from 'src/domain';

interface Props {
  label: string;
  options: FilterOption[];
  value: FilterOption[];
  onSelect?: (selected: FilterOption[]) => unknown;
  disabled?: boolean;
  className?: string;
}

const SearchFilter: FC<Props> = ({ label, options, value, onSelect, className, disabled = false }) => {
  return (
    <div>
      <StyledHeader>{label}</StyledHeader>
      <Spacer spacing={Spacings.xSmall} vertical />
      <Autocomplete
        options={options}
        value={value}
        onSelect={onSelect}
        placeholder={label}
        disabled={disabled}
        className={className}
      />
    </div>
  );
};

const StyledHeader = styled.h3`
  margin: 0;
  font-weight: 400;
  width: fit-content;
  padding: 2px;
`;

export default SearchFilter;
