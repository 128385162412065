import { groupBy } from 'ramda';
import { useMemo } from 'react';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';

export const useSalesCampaignDiscounts = (salesCampaignPhaseId: number | undefined | null) => {
  const { data, loading, error } = useApiQuery('/api/salescampaigns/phase/{phaseId}/discounts', 'get', {
    path: {
      phaseId: salesCampaignPhaseId,
    },
    enabled: !!salesCampaignPhaseId,
  });

  const groupedData = useMemo(() => {
    if (data) {
      return groupBy((discount) => discount.styleOptionId.toString(), data);
    }
    return null;
  }, [data]);

  return { data, groupedData, loading, error };
};
