import styled from '@emotion/styled';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import Container from 'src/components/atoms/Container/Container';
import Header from 'src/components/atoms/Header';
import Select, { GroupedOption } from 'src/components/atoms/Select/Select';
import Spacer from 'src/components/atoms/Spacer/Spacer';
import Searchbar from 'src/components/molecules/Searchbar/Searchbar';
import { ListHeaderBox } from 'src/components/styled/ListHeaderBox';
import { Headings, Inheritance, ScopeEnum, Spacings } from 'src/domain';
import { InheritanceSettingsRow } from 'src/domain/table/inheritance-settings.row';
import { useCluster, useScope } from 'src/hooks';
import { useCountry } from 'src/hooks/country/useCountry';
import { useInheritanceSettingsSimulationRows } from 'src/hooks/inheritance-settings/simulation/useInheritanceSettingsSimulationRows';
import { storeInheritanceFormatter } from 'src/utils/splitLevels';

enum Stores {
  All = 'All',
  Affected = 'Affected',
  NotAffected = 'Not affected',
}

interface Props {
  moduleString: string;
}

export const InheritanceTable: FC<Props> = ({ moduleString }) => {
  const scope = useScope();
  const cluster = useCluster();
  const country = useCountry();

  const { data } = useInheritanceSettingsSimulationRows();

  const [selected, setSelected] = useState<Stores>(Stores.Affected);
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const options = useMemo(
    (): GroupedOption[] => [
      {
        isHeader: false,
        value: Stores.Affected,
        display: 'Stores affected by changes',
      },
      {
        isHeader: false,
        value: Stores.NotAffected,
        display: 'Stores not affected by changes',
      },
      {
        isHeader: false,
        value: Stores.All,
        display: 'All',
      },
    ],
    []
  );

  const sortStores = useCallback(
    (stores: InheritanceSettingsRow[]) => {
      return stores.sort((a, b) => {
        if (sortAscending) {
          return a.storeName?.localeCompare(b.storeName || '') || 0;
        } else {
          return b.storeName?.localeCompare(a.storeName || '') || 0;
        }
      });
    },

    [sortAscending]
  );

  const filterStoresByUserInput = useCallback(
    (stores: InheritanceSettingsRow[]) => {
      return stores.filter((store) => {
        const storeName = store.storeName?.toLowerCase();

        return storeName?.includes(searchQuery.toLowerCase());
      });
    },
    [searchQuery]
  );

  const filteredStores = useMemo(() => {
    switch (selected) {
      case Stores.Affected:
        return sortStores(
          filterStoresByUserInput(
            data.filter((store) => {
              if (scope === ScopeEnum.PARTNER) {
                return store[moduleString] === Inheritance.Partner;
              }
              if (scope === ScopeEnum.CLUSTER) {
                return store[moduleString] === Inheritance.Cluster;
              }
            })
          )
        );
      case Stores.NotAffected:
        return sortStores(
          filterStoresByUserInput(
            data.filter((store) => {
              if (scope === ScopeEnum.PARTNER) {
                return store[moduleString] !== Inheritance.Partner;
              }
              if (scope === ScopeEnum.CLUSTER) {
                return store[moduleString] !== Inheritance.Cluster;
              }
            })
          )
        );
      case Stores.All:
        return sortStores(filterStoresByUserInput(data));
    }
  }, [data, filterStoresByUserInput, moduleString, selected, sortStores, scope]);

  const title = useMemo(() => {
    switch (scope) {
      case ScopeEnum.PARTNER:
        return country;
      case ScopeEnum.CLUSTER:
        return cluster;
    }
  }, [cluster, country, scope]);

  const handleSelect = useCallback((value: string) => {
    if (value) setSelected(value as Stores);
  }, []);

  const handleUserInput = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  const handleResetInputField = useCallback(() => {
    setSearchQuery('');
  }, []);

  const handleSorting = useCallback(() => {
    setSortAscending((prev) => !prev);
  }, []);

  if (scope !== ScopeEnum.PARTNER) return null;

  return (
    <StyledContainer>
      <Header heading={Headings.h2}>Inheritance</Header>
      <Spacer spacing={Spacings.small} vertical />
      <Select
        activeItem={selected}
        options={options}
        onSelect={handleSelect}
        showLabel={false}
        width="widest"
        useMargin={false}
      />
      <Spacer spacing={Spacings.xSmall} vertical />
      <TopWrapper>
        <Title>{title}</Title>
        <StyledSearchbar
          placeholder="Search amongst stores"
          updateUserInput={handleUserInput}
          resetInputField={handleResetInputField}
          value={searchQuery}
          width={'220px'}
        />
      </TopWrapper>
      <ListHeaderBox>
        <InfoRow>
          <div />
          <HeaderColumnWrapper onClick={handleSorting}>
            <HeaderTypography>STORE NAME</HeaderTypography>
            {!sortAscending ? <SortIconUp /> : <SortIconDown />}
          </HeaderColumnWrapper>
          <HeaderTypography>INHERITING FROM</HeaderTypography>
        </InfoRow>
      </ListHeaderBox>
      <StoresContainer>
        {filteredStores.length === 0 && (
          <StyledTypography sx={{ width: '100%', textAlign: 'center' }}>No stores found</StyledTypography>
        )}
        {filteredStores.map((store) => {
          const storeInheritance = store[moduleString] as Inheritance;
          const formattedInheritance = storeInheritanceFormatter(
            storeInheritance,
            store.clusterName,
            store.countryName,
            moduleString === 'noosShare'
          );

          return (
            <ItemContainer key={store.storeId}>
              <div />
              <StyledTypography>{store.storeName}</StyledTypography>
              <StyledTypography>{formattedInheritance}</StyledTypography>
            </ItemContainer>
          );
        })}
      </StoresContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  max-width: 600px;
  height: 700px;
  display: flex;
  flex-direction: column;
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  height: 32px;
`;

const StoresContainer = styled.div`
  margin: 10px 0px;
  overflow-y: auto;
  flex-grow: 1;
`;

const HeaderColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const SortIconUp = styled(KeyboardArrowUp)`
  color: #787c8d;
  font-size: 15px;
`;

const SortIconDown = styled(KeyboardArrowDown)`
  color: #787c8d;
  font-size: 15px;
`;

const Title = styled.h2`
  margin-right: 20px;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

const StyledSearchbar = styled(Searchbar)`
  border: 1px solid #787c8d;
  border-radius: 4px;
  flex-grow: 1;
  max-width: 70%;
  div {
    box-shadow: none;
  }
  input {
    font-size: 13px;
  }
`;

const StyledTypography = styled(Typography)`
  color: #787c8d;
`;

const HeaderTypography = styled(StyledTypography)`
  font-weight: 500;
`;

const InfoRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.1fr 0.5fr 0.4fr;
  align-items: center;
`;

const ItemContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.1fr 0.5fr 0.4fr;
  align-items: center;
`;
