import { isNumber } from 'lodash';
import { useCallback } from 'react';
import { Cell } from 'src/components/organisms/DataTable/types';
import { ArgumentError, CategoryOverviewRow, Inheritance, Module } from 'src/domain';
import { UnsavedChangesEvent } from 'src/domain/events/unsaved-changes.event';
import { mergeOverviewRows } from 'src/utils/mergeRows';
import { useSetSelectedInheritance } from '../inheritance';
import { useUnsavedChangesModule } from '../unsaved-changes';
import { useActiveMonth } from '../useActiveMonth';
import { useGenderSplitOverviewSimulationRows } from './simulation/useGenderSplitOverviewSimulationRows';

export const useGenderSplitHandleCellChanges = () => {
  const [unsavedChanges, setUnsavedChanges] = useUnsavedChangesModule<CategoryOverviewRow[]>(Module.GenderSplit);

  const { data: typedData } = useGenderSplitOverviewSimulationRows(Inheritance.Typed);
  const { data: originalData } = useGenderSplitOverviewSimulationRows();

  const setSelectedInheritance = useSetSelectedInheritance();

  const [activeMonth] = useActiveMonth();

  const handleNewCellValues = useCallback(
    (cells: Cell[]) => {
      if (!originalData || originalData.isEmpty())
        throw new ArgumentError(`Current GenderSplit data is missing: ${originalData}`);
      if (!typedData || typedData.isEmpty())
        throw new ArgumentError(`Expected GenderSplit data is missing: ${typedData}`);

      const mergedRows = mergeOverviewRows(typedData, originalData);

      cells.forEach((cellValue) => {
        const rowIndexToUpdate = mergedRows.findIndex((x) => x.id === cellValue.rowId);
        const fieldName =
          !cellValue.column || cellValue.column === 'plannedSplit' ? activeMonth : cellValue.column?.split('.')[1];

        const valueIsValid = !isNaN(cellValue.value) && isNumber(Number(cellValue.value));

        if (fieldName) {
          mergedRows[rowIndexToUpdate] = {
            ...mergedRows?.[rowIndexToUpdate],
            columns: {
              ...mergedRows?.[rowIndexToUpdate].columns,
              [fieldName]: valueIsValid ? cellValue.value : 0,
            },
          };
        }
      });

      const unsaved = [...(mergedRows ?? unsavedChanges ?? [])];

      setUnsavedChanges(unsaved);
      setSelectedInheritance(Inheritance.Typed);
      dispatchEvent(new Event(UnsavedChangesEvent));
    },
    [originalData, typedData, unsavedChanges, setUnsavedChanges, setSelectedInheritance, activeMonth]
  );

  return handleNewCellValues;
};
