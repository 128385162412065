import { useMemo } from 'react';
import { StockMgmtTable } from 'src/domain/enums/stock-management-table';
import { useStockManagementQuery } from '../queries/useStockMgmtQuery';

export const useDefaultTable = () => {
  const { data } = useStockManagementQuery();

  const useTotalsTable = useMemo(() => {
    return data?.stockManagementSplit === 'Totals';
  }, [data?.stockManagementSplit]);

  return useMemo(() => {
    if (useTotalsTable !== undefined) {
      return useTotalsTable ? StockMgmtTable.Total : StockMgmtTable.Categories;
    }
    return undefined;
  }, [useTotalsTable]);
};
