import { ReportProblem } from '@mui/icons-material';
import { Stack, Typography, useTheme } from '@mui/material';
import { FC, PropsWithChildren, useEffect } from 'react';
import Container from 'src/components/atoms/Container';
import { SelectPartner } from 'src/components/views/SelectPartner/SelectPartner';
import { GroupLevel, ScopeEnum } from 'src/domain';
import { useDefaultPartner, useFilteredStores, useGlobalVar, usePartners, useScope, useType } from 'src/hooks';
import { selectedPartnerVar } from 'src/infrastructure/local_state';

export const SelectPartnerGuard: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const type = useType();
  const partners = usePartners(type);

  const [selectedPartner, setSelectedPartner] = useGlobalVar(selectedPartnerVar);
  const clusterDefaultPartner = useDefaultPartner(GroupLevel.Cluster);
  const countryDefaultPartner = useDefaultPartner(GroupLevel.Country);

  const theme = useTheme();

  // set partner when default updates
  useEffect(() => {
    if (!selectedPartner) {
      setSelectedPartner(countryDefaultPartner ?? clusterDefaultPartner ?? undefined);
    }
  }, [clusterDefaultPartner, countryDefaultPartner, selectedPartner, setSelectedPartner]);

  const scope = useScope();
  const [filteredStores] = useFilteredStores();

  const hasPartners = (partners && partners?.length === 1) || !!selectedPartner;
  const isLoading = !partners || partners.isEmpty();

  if (!hasPartners && type)
    return <SelectPartner isLoading={isLoading} partners={partners} onPartnerSelected={setSelectedPartner} />;

  const hasAccess =
    scope === ScopeEnum.STORE || filteredStores.every((store) => store.operationalResponsibleComposite?.userHasAccess);

  if (!hasAccess) {
    return (
      <Container>
        <Stack direction="row" gap={'0.5rem'} color={theme.palette.text.primary}>
          <ReportProblem />
          <Typography>You do not have permission to view aggregated data for the selected partner.</Typography>
        </Stack>
      </Container>
    );
  }

  return <>{children}</>;
};
