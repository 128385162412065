import { ColDef } from 'ag-grid-community';
import { FC, useMemo } from 'react';
import Container from 'src/components/atoms/Container';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { SalesCampaignOverviewRow } from 'src/domain/table/sales-campaign-overview.row';
import { withDefaultHeaderToolTip } from 'src/utils/ag-grid/withDefaultHeaderToolTip';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';
import { SummaryData, calculateSummary } from '../calculateSummary';
import { SummaryReportProps } from '../types';

interface SummaryRow extends SummaryData {
  stockAgeWeeks: number;
}

const columns: ColDef<SummaryRow>[] = withDefaultHeaderToolTip([
  {
    field: 'stockAgeWeeks',
    headerName: 'Stock age',
    pinned: 'left',
    lockPinned: true,
    valueFormatter: (params) => {
      return params.value ?? 'Unknown';
    },
  },
  {
    field: 'totalStockValue',
    headerName: 'Total stock value',
  },
  {
    field: 'totalStockValueOnSale',
    headerName: 'Stock value on sale',
  },
  {
    field: 'percentageStockValueOnSale',
    headerName: '% stock value on sale',
    valueFormatter: (params) => {
      return numberWithSpaces(params.value, { postfix: '%' });
    },
  },

  {
    field: 'totalStockQuantityOnSale',
    headerName: 'Stock quantity on sale',
  },

  {
    field: 'percentageStockQuantityOnSale',
    headerName: '% stock quantity on sale',
    valueFormatter: (params) => {
      return numberWithSpaces(params.value, { postfix: '%' });
    },
  },
  {
    field: 'avgMarkDown',
    headerName: 'Avg. markdown %',
    valueFormatter: (params) => {
      return numberWithSpaces(params.value, { postfix: '%', default: 'N/A' });
    },
  },
  {
    field: 'stylesOnSale',
    headerName: 'No. of styles on sale',
  },
  {
    field: 'styleOptionsOnSale',
    headerName: 'No. of style options on sale',
  },
]);

const defaultColDef: ColDef<SummaryRow> = {
  editable: false,
  sortable: true,
  valueFormatter: (params) => {
    return numberWithSpaces(params.value);
  },
};

export const StockAgeSummaryTab: FC<SummaryReportProps> = ({ salesCampaignRows }) => {
  const groupedByWeeks = useMemo(() => {
    const acc: Record<string, SalesCampaignOverviewRow[]> = {
      '0-4 weeks': [],
      '5-8 weeks': [],
      '9-12 weeks': [],
      '13-16 weeks': [],
      '>16 weeks': [],
    };

    // Reduce the salesCampaignRows
    return salesCampaignRows.reduce((acc, currentItem) => {
      const { stockAgeWeeks } = currentItem;
      let groupKey;

      if (!stockAgeWeeks) return acc;
      if (stockAgeWeeks >= 0 && stockAgeWeeks <= 4) {
        groupKey = '0-4 weeks';
      } else if (stockAgeWeeks >= 5 && stockAgeWeeks <= 8) {
        groupKey = '5-8 weeks';
      } else if (stockAgeWeeks >= 9 && stockAgeWeeks <= 12) {
        groupKey = '9-12 weeks';
      } else if (stockAgeWeeks >= 13 && stockAgeWeeks <= 16) {
        groupKey = '13-16 weeks';
      } else {
        groupKey = '>16 weeks';
      }

      // Push the current item into its corresponding group
      acc[groupKey].push(currentItem);

      return acc;
    }, acc);
  }, [salesCampaignRows]);
  const summaryRows = useMemo(() => {
    const rows = Object.entries(groupedByWeeks).map(([groupKey, groupItems]) => {
      return { id: groupKey, stockAgeWeeks: groupKey, ...calculateSummary(groupItems) };
    });

    return [...rows, { id: 'total', stockAgeWeeks: 'Total', ...calculateSummary(salesCampaignRows) }];
  }, [groupedByWeeks, salesCampaignRows]);

  return (
    <Container>
      <TableWrapper className="ag-theme-alpine-custom-compact small-font" flex={1}>
        <DataTable autoSizeToFit rows={summaryRows} columns={columns} defaultColDef={defaultColDef} />
      </TableWrapper>
    </Container>
  );
};
