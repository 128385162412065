import { useMemo } from 'react';
import { Module, ProductLineDetailsRow, TableOverviewRow } from 'src/domain';
import { useTurnoverSimulationRows } from 'src/hooks/turnover/simulation/useTurnoverSimulationRows';
import { monthKeyToField } from 'src/utils/monthKeys';
import { calculatePlannedIndex } from 'src/utils/turnover/calculatePlannedIndex';
import { useUnsavedChangesModule } from '../../unsaved-changes';
import { useProductLineSplitDetailsRows } from '../table/useProductLineSplitDetailsRows';
import { useProductLineSplitOverviewRows } from '../table/useProductLineSplitOverviewRows';

export const useProductLineSplitDetailsSimulationRows = () => {
  const { data, loading, error } = useProductLineSplitDetailsRows() ?? {};
  const { data: overviewData } = useProductLineSplitOverviewRows() ?? {};
  const [unsavedChangesProductLineSplit] = useUnsavedChangesModule<TableOverviewRow[]>(Module.ProductLineSplit);
  const { data: turnoverData } = useTurnoverSimulationRows();

  const simulatedData = useMemo(() => {
    if (!data) return;

    const map = new Map<string, ProductLineDetailsRow[]>();

    data.forEach((detailsRows, month) =>
      map.set(
        month,
        detailsRows.map((row) => {
          const plannedSplit =
            unsavedChangesProductLineSplit?.find((unsavedRow) => unsavedRow.id === row.id)?.columns[month] ??
            overviewData?.find((unsavedRow) => unsavedRow.id === row.id)?.columns[month] ??
            0;
          const plannedSalesIv =
            (turnoverData?.find((change) => {
              return monthKeyToField(change.monthKey).equalsInsensitive(month);
            })?.plannedSalesIv ?? 0) *
            (plannedSplit / 100);

          return {
            ...row,
            plannedSplit,
            plannedSalesIv: Math.round(plannedSalesIv * 100) / 100,
            plannedIndex: calculatePlannedIndex(1, plannedSalesIv, row.salesIvLy, row.salesIvLly),
          };
        })
      )
    );

    return map;
  }, [data, overviewData, turnoverData, unsavedChangesProductLineSplit]);

  return { data: simulatedData, loading, error };
};
