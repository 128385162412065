import { ICellRendererParams } from 'ag-grid-community';
import { kebabCase } from 'lodash';
import { useMemo } from 'react';
import { Inheritance, InheritanceModule, Module } from 'src/domain';
import { getInheritanceWarning } from 'src/utils/inheritance-help';
import { useInheritanceSettingsRows } from './table/useInheritanceSettingsRows';

function inheritanceModuleToModule(inheritanceModule: InheritanceModule | undefined): Module | undefined {
  if (!inheritanceModule) return;
  switch (inheritanceModule) {
    case InheritanceModule.SalesBudget:
      return Module.Turnover;
    case InheritanceModule.ProductLineSplit:
      return Module.ProductLineSplit;
    default:
      return kebabCase(inheritanceModule) as Module;
  }
}

export const useInheritanceSettingsCellWarning = (params: ICellRendererParams) => {
  const { data: settingRows } = useInheritanceSettingsRows();
  return useMemo(() => {
    const module = inheritanceModuleToModule(params.colDef?.field as InheritanceModule | undefined);
    const field = params.colDef?.field;
    if (!settingRows || !module || !field) return '';

    const defaultInheritance = settingRows.find((row) => row.id.toString() === params.node.id)?.[field];

    if (!defaultInheritance) {
      return '';
    }

    return getInheritanceWarning(module, defaultInheritance as Inheritance, params.value);
  }, [params.colDef?.field, params.node.id, params.value, settingRows]);
};
