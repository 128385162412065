import { pick } from 'ramda';
import { useCallback, useMemo } from 'react';
import { ArgumentError } from 'src/domain';
import { useChangesSnackbar, useCurrentId, useGenderId, useInheritance, useProductLineId } from 'src/hooks';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useEntityType } from 'src/hooks/useEntityType';
import { NoosHistoricalInheritanceLevelEnum } from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { noosInheritanceToSplitLevel } from 'src/utils/splitLevels';
import { useNoosShareUpdateInheritance } from '../split-level/useNoosShareUpdateInheritance';

export const useNoosShareReset = () => {
  const currentId = useCurrentId();
  const partnerComposite = useCompositePartner();
  const productLineGroupId = useProductLineId();
  const genderId = useGenderId();
  const inheritance = useInheritance();
  const entityType = useEntityType();
  const showSnackbar = useChangesSnackbar();

  const [reset, { loading: resetLoading }] = useApiMutation('/api/noosshare/reset', 'put', {
    update: (data, variables, queryClient) => {
      queryClient.setQueryData(['/api/noosshare', pick(['entityId', 'entityType'], variables.query!)], data);
    },
  });

  const { updateInheritance, loading: inheritanceLoading } = useNoosShareUpdateInheritance();

  const noosHistoricalSplitLevel: NoosHistoricalInheritanceLevelEnum | null = useMemo(() => {
    if (inheritance) {
      return noosInheritanceToSplitLevel(inheritance);
    }
    return null;
  }, [inheritance]);

  const resetNoosShare = useCallback(() => {
    if (!currentId) throw new ArgumentError(`ID cannot be null: ${currentId}`);
    if (!productLineGroupId) throw new ArgumentError(`productline ID cannot be null: ${productLineGroupId}`);
    if (!genderId) throw new ArgumentError(`gender ID cannot be null: ${genderId}`);
    if (!noosHistoricalSplitLevel) throw new ArgumentError(`Inheritance cannot be null: ${noosHistoricalSplitLevel}`);

    const query = {
      entityId: Number(currentId),
      entityType,
      productLineGroupId,
      genderId,
      noosHistoricalSplitLevel,
    };

    if (entityType === 'Partner') {
      if (!partnerComposite?.id) throw new ArgumentError(`Composite partner id cannot be null`);
      query.entityId = partnerComposite.id;
    }

    return reset({
      query,
    })
      .then(() => updateInheritance())
      .then(() => {
        return showSnackbar();
      });
  }, [
    currentId,
    productLineGroupId,
    genderId,
    noosHistoricalSplitLevel,
    updateInheritance,
    reset,
    partnerComposite,
    showSnackbar,
    entityType,
  ]);

  const loading = resetLoading || inheritanceLoading;

  return { resetNoosShare, loading };
};
