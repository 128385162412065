import { Paper, Popper, styled } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { FC, useCallback, useState } from 'react';
import { CachedImage } from 'src/components/organisms/CachedImage/CachedImage';

type Props = ICellRendererParams;

export const ImageCellRenderer: FC<Props> = ({ value }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [showPopover, setShowPopover] = useState(false);

  const handlePopoverState = useCallback((state: boolean) => {
    setShowPopover(state);
  }, []);

  return (
    <>
      <CachedImage
        ref={setReferenceElement}
        height="100%"
        src={value}
        alt={''}
        onMouseOver={handlePopoverState.bind(null, true)}
        onMouseOut={handlePopoverState.bind(null, false)}
      />
      {showPopover && (
        <Popper open={true} anchorEl={referenceElement} className="ag-custom-component-popup" sx={{ zIndex: 9 }}>
          <PopoverImageWrapper elevation={6}>
            <CachedImage src={value} alt={''} />
          </PopoverImageWrapper>
        </Popper>
      )}
    </>
  );
};

const PopoverImageWrapper = styled(Paper)`
  border-radius: 4px;
  overflow: hidden;
  background-color: white;
  width: 350px;
  height: 350px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;
