import { Inheritance, Module, TableOverviewRow } from 'src/domain';
import { useInheritance } from 'src/hooks';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { useProductLineSplitOverviewRows } from '../table/useProductLineSplitOverviewRows';

export const useProductLineSplitOverviewSimulationRows = (inheritance?: Inheritance) => {
  const [unsavedChanges] = useUnsavedChangesModule<TableOverviewRow[]>(Module.ProductLineSplit);
  const { data, columns, loading, error } = useProductLineSplitOverviewRows(inheritance);

  const currentInheritance = useInheritance(Module.ProductLineSplit);
  const actualInheritance = inheritance ?? currentInheritance;

  const isTyped = actualInheritance === Inheritance.Typed;

  const hasUnsavedChanges = !!unsavedChanges;
  const overviewRows = isTyped && hasUnsavedChanges ? unsavedChanges : data;

  return { data: overviewRows, columns, loading, error };
};
