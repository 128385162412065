import { GetGroupRowAggParams } from 'ag-grid-community';
import { isNumber } from 'lodash';
import { MarkupDetailsBaseRow, MarkupDetailsRow } from 'src/domain';

export interface MarkupDetailsAggregation
  extends Omit<MarkupDetailsBaseRow, 'id' | 'category' | 'expectedGrossMargin' | 'expectedCogs'> {
  expectedGrossMargin: [number, number | undefined];
  expectedCogs: [number, number | undefined];
  grossProfitLY: number;
  grossSalesILY: number;
  grossSalesILLY: number;
  cogsLLY: number;
  sumCurrentExpectedCogs: number;
  sumPredictedExpectedCogs: number;
  sumCurrentExpectedGrossMargin: number;
  sumPredictedExpectedGrossMargin: number;
}

export const weightedAverageMarkupDetails = (params: GetGroupRowAggParams<MarkupDetailsRow>) => {
  return params.nodes.reduce<MarkupDetailsAggregation>(
    (acc, value) => {
      const data = value.data;

      if (!data) return acc;

      const currentExpectedCogs = Array.isArray(data?.expectedCogs) ? data?.expectedCogs[0] : data?.expectedCogs;
      const predictedExpectedCogs = Array.isArray(data?.expectedCogs) ? data?.expectedCogs[1] : undefined;

      const currentExpectedGrossMargin = Array.isArray(data?.expectedGrossMargin)
        ? data?.expectedGrossMargin[0]
        : data?.expectedGrossMargin;
      const predictedExpectedGrossMargin = Array.isArray(data?.expectedGrossMargin)
        ? data?.expectedGrossMargin[1]
        : undefined;

      const plannedValue = data.plannedValue;
      const cogsLLY = data?.cogsLLY;
      const grossSalesILY = data?.grossSalesILY;
      const grossSalesILLY = data?.grossSalesILLY;
      const vat = data.vat;

      acc.cogsLY += data.cogsLY;
      acc.plannedSplit = data.plannedSplit;

      if (isNumber(cogsLLY)) {
        acc.cogsLLY += cogsLLY;
      }
      if (isNumber(grossSalesILY) && isNumber(vat)) {
        acc.grossProfitLY += grossSalesILY / vat - data.cogsLY;
        acc.grossSalesILY += grossSalesILY;
      }
      if (isNumber(grossSalesILLY)) acc.grossSalesILLY += grossSalesILLY;

      if (isNumber(currentExpectedCogs)) acc.sumCurrentExpectedCogs += currentExpectedCogs;
      if (isNumber(predictedExpectedCogs)) {
        acc.sumPredictedExpectedCogs += predictedExpectedCogs;
      } else {
        acc.sumPredictedExpectedCogs += currentExpectedCogs;
      }

      if (isNumber(plannedValue)) acc.plannedValue += (acc.plannedSplit / 100) * plannedValue;

      if (isNumber(currentExpectedGrossMargin))
        acc.sumCurrentExpectedGrossMargin += (data.plannedSplit / 100) * currentExpectedGrossMargin;
      if (isNumber(predictedExpectedGrossMargin)) {
        acc.sumPredictedExpectedGrossMargin += (data.plannedSplit / 100) * predictedExpectedGrossMargin;
      } else {
        acc.sumPredictedExpectedGrossMargin += (data.plannedSplit / 100) * currentExpectedGrossMargin;
      }

      if (acc.sumCurrentExpectedGrossMargin !== acc.sumPredictedExpectedGrossMargin) {
        acc.expectedGrossMargin = [acc.sumCurrentExpectedGrossMargin, acc.sumPredictedExpectedGrossMargin];
      } else {
        acc.expectedGrossMargin = [acc.sumCurrentExpectedGrossMargin, undefined];
      }

      if (acc.sumCurrentExpectedCogs !== acc.sumPredictedExpectedCogs) {
        acc.expectedCogs = [acc.sumCurrentExpectedCogs, acc.sumPredictedExpectedCogs];
      } else {
        acc.expectedCogs = [acc.sumCurrentExpectedCogs, undefined];
      }

      if (data.valueLY !== -999 && acc.grossSalesILY !== 0 && acc.cogsLY !== 0)
        acc.valueLY = acc.grossSalesILY / acc.cogsLY;
      if (acc.grossSalesILLY !== 0 && acc.cogsLLY !== 0) acc.valueLLY = acc.grossSalesILLY / acc.cogsLLY;
      if (isNumber(vat)) acc.grossMarginLY = (acc.grossProfitLY / (acc.grossSalesILY / vat)) * 100;

      return acc;
    },
    {
      valueLY: 0,
      valueLLY: 0,
      plannedValue: 0,
      expectedGrossMargin: [0, undefined],
      grossMarginLY: 0,
      grossProfitLY: 0,
      netSalesLY: 0,
      expectedCogs: [0, undefined],
      cogsLY: 0,
      cogsLLY: 0,
      grossSalesILY: 0,
      grossSalesILLY: 0,
      plannedSplit: 0,
      sumCurrentExpectedCogs: 0,
      sumPredictedExpectedCogs: 0,
      sumCurrentExpectedGrossMargin: 0,
      sumPredictedExpectedGrossMargin: 0,
    }
  );
};
