import styled from '@emotion/styled';
import { ComponentProps, FC } from 'react';
import { Color } from 'src/domain';

interface Props extends ComponentProps<'div'> {
  background?: Color;
}

const Container: FC<Props> = ({ children, background = Color.white, ...props }) => {
  return (
    <StyledContainer background={background} {...props}>
      {children}
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ background: Color }>`
  padding: 20px;
  border-radius: 4px;
  background-color: ${(props) => props.background};
  box-shadow:
    0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
  -moz-box-shadow:
    0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
  -webkit-box-shadow:
    0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
  box-sizing: border-box;
`;

export default Container;
