import React, { Dispatch, FC, PropsWithChildren, SetStateAction, useEffect, useState } from 'react';
import { useIsDevEnv } from '../useIsDevEnv';

interface IDevToolsContext {
  impersonateEmail: string;
  setImpersonateEmail: Dispatch<SetStateAction<string>>;
  isImpersonating: boolean;
  setIsImpersonating: Dispatch<SetStateAction<boolean>>;
  impersonateRoles: string[];
  setImpersonateRoles: Dispatch<SetStateAction<string[]>>;
}

export const DevToolsContext = React.createContext<IDevToolsContext>({
  impersonateEmail: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setImpersonateEmail: () => {},
  isImpersonating: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsImpersonating: () => {},
  impersonateRoles: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setImpersonateRoles: () => {},
});

export const DevToolsProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [impersonateEmail, setImpersonateEmail] = useState<string>('');
  const [isImpersonating, setIsImpersonating] = useState<boolean>(false);
  const [impersonateRoles, setImpersonateRoles] = useState<string[]>(['default']);
  const isDevEnv = useIsDevEnv();

  useEffect(() => {
    // Make testing easier by allowing direct access to the devtools api from code,
    // but only in dev env. This helps to make the E2E tests more reliable and faster.
    if (isDevEnv) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any)['__DevToolsApi__'] = {
        setImpersonateEmail,
        setIsImpersonating,
      };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any)['__DevToolsApi__'] = undefined;
    }
  }, [isDevEnv]);

  return (
    <DevToolsContext.Provider
      value={{
        impersonateEmail,
        setImpersonateEmail,
        isImpersonating,
        setIsImpersonating,
        setImpersonateRoles,
        impersonateRoles,
      }}
    >
      {children}
    </DevToolsContext.Provider>
  );
};
