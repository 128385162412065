import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { differenceInDays, format, isSameDay, parseISO } from 'date-fns';
import { FC, useMemo } from 'react';
import { StoreModel } from 'src/infrastructure/rest-api/api-types';

interface Props {
  store: StoreModel;
}

export const StoreInformationChip: FC<Props> = ({ store }) => {
  const openingDate = useMemo(
    () => store.storeOpeningDate && parseISO(store.storeOpeningDate),
    [store.storeOpeningDate]
  );
  const closingDate = useMemo(
    () => store.chainClosingDate && parseISO(store.chainClosingDate),
    [store.chainClosingDate]
  );
  const locationClosingDate = useMemo(
    () => store.locationClosingDate && parseISO(store.locationClosingDate),
    [store.locationClosingDate]
  );
  const storeReopeningDate = useMemo(
    () => store.chainReOpeningDate && parseISO(store.chainReOpeningDate),
    [store.chainReOpeningDate]
  );
  const formattedOpeningDate = useMemo(() => openingDate && format(openingDate, 'd MMM yyyy'), [openingDate]);
  const formattedClosingDate = useMemo(() => closingDate && format(closingDate, 'd MMM yyyy'), [closingDate]);
  const formattedLocationClosingDate = useMemo(
    () => locationClosingDate && format(locationClosingDate, 'd MMM yyyy'),
    [locationClosingDate]
  );
  const formattedChainReopeningDate = useMemo(
    () => storeReopeningDate && format(storeReopeningDate, 'd MMM yyyy'),
    [storeReopeningDate]
  );

  const isOpeningSoon = useMemo(() => openingDate && differenceInDays(new Date(), openingDate) < 0, [openingDate]);

  const isNewStore = useMemo(() => {
    if (!openingDate) {
      return false;
    }
    const daysBetween = differenceInDays(new Date(), openingDate);
    return daysBetween >= 0 && daysBetween < 28;
  }, [openingDate]);

  // Based on ChainClosingDate
  // There can be multiple store on a location
  const storeIsClosing = useMemo(() => {
    if (!closingDate) {
      return false;
    }
    if (!isSameDay(closingDate, new Date('1900-01-01'))) {
      return differenceInDays(new Date(), closingDate) < 0;
    }
    return false;
  }, [closingDate]);

  const storeIsClosed = useMemo(() => {
    if (!closingDate) {
      return false;
    }
    if (!isSameDay(closingDate, new Date('1900-01-01'))) {
      return differenceInDays(new Date(), closingDate) >= 0;
    }
    return false;
  }, [closingDate]);

  // Based on LocationClosingDate
  // If the location is closing - all related stores are closing
  const locationIsClosing = useMemo(() => {
    if (!locationClosingDate) {
      return false;
    }
    if (!isSameDay(locationClosingDate, new Date('1900-01-01'))) {
      return differenceInDays(new Date(), locationClosingDate) <= 0;
    }
    return false;
  }, [locationClosingDate]);

  const storeIsReopening = useMemo(() => {
    if (!storeReopeningDate) {
      return false;
    }
    if (!isSameDay(storeReopeningDate, new Date('1900-01-01'))) {
      return differenceInDays(new Date(), storeReopeningDate) <= 0;
    }
    return false;
  }, [storeReopeningDate]);

  const getClosingDate = useMemo(() => {
    if (locationIsClosing) {
      return formattedLocationClosingDate;
    } else {
      return formattedClosingDate;
    }
  }, [locationIsClosing, formattedLocationClosingDate, formattedClosingDate]);

  return (
    <>
      {isOpeningSoon && (
        <Tooltip title={`Opening: ${formattedOpeningDate}`}>
          <OpeningSoonLabel>New</OpeningSoonLabel>
        </Tooltip>
      )}
      {isNewStore && (
        <Tooltip title={`Open since: ${formattedOpeningDate}`}>
          <NewLabel>New</NewLabel>
        </Tooltip>
      )}
      {((locationIsClosing && !storeIsReopening) || (storeIsClosing && !storeIsReopening)) && (
        <Tooltip title={`Closing: ${getClosingDate}`}>
          <ClosingLabel>Closing</ClosingLabel>
        </Tooltip>
      )}
      {((locationIsClosing && storeIsReopening) || (storeIsClosing && storeIsReopening)) && (
        <Tooltip title={`Closing: ${getClosingDate} - ReOpening: ${formattedChainReopeningDate}`}>
          <ClosingForRenovationLabel>Closing</ClosingForRenovationLabel>
        </Tooltip>
      )}
      {storeIsClosed && storeIsReopening && (
        <Tooltip title={`Closed since: ${getClosingDate} - ReOpening: ${formattedChainReopeningDate}`}>
          <UndergoingRenovationLabel>Renovation</UndergoingRenovationLabel>
        </Tooltip>
      )}
    </>
  );
};

const NewLabel = styled.span`
  background-color: #4472c4;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 10px;
  max-width: 21px;
  margin: 2px 0px;
  color: white;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const OpeningSoonLabel = styled.span`
  background-color: lightseagreen;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 10px;
  max-width: 21px;
  margin: 2px 0px;
  color: white;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ClosingLabel = styled.span`
  background-color: #d9512c;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 10px;
  margin: 2px 0px;
  color: white;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ClosingForRenovationLabel = styled.span`
  background-color: #c47a44;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 10px;
  margin: 2px 0px;
  color: white;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const UndergoingRenovationLabel = styled.span`
  background-color: #ff781f;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 10px;
  margin: 2px 0px;
  color: white;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
