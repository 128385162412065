export interface GetGenderProductLineSplitProps {
  isUsingGender: boolean | undefined;
  isUsingProductLine: boolean | undefined;
  genderSplit: number | undefined;
  productLineSplit: number | undefined;
  shouldZero?: boolean;
}

export const getGenderProductLineSplit = ({
  isUsingGender,
  isUsingProductLine,
  genderSplit,
  productLineSplit,
  shouldZero = false,
}: GetGenderProductLineSplitProps) => {
  if (!isUsingProductLine && isUsingGender && typeof genderSplit === 'number') return genderSplit / 100;
  if (isUsingProductLine && typeof productLineSplit === 'number') return productLineSplit / 100;
  if (shouldZero) {
    return 0;
  }
  return 1;
};
