import { useDraggable } from '@dnd-kit/core';
import styled from '@emotion/styled';
import { DragIndicator, MoreVert } from '@mui/icons-material';
import { Box, IconButton, Skeleton, Typography } from '@mui/material';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { CustomMenu } from 'src/components/molecules/Menu/CustomMenu';
import { ClusterInfoRow } from 'src/components/styled/ClusterInfoRow';
import { useClustersByCompositeId } from 'src/hooks/cluster-management/useClustersByCompositeId';
import { useTurnoverForPartnerStores } from 'src/hooks/cluster-management/useTurnoverForPartnerStores';
import { useUnsavedChangesClusterManagement } from 'src/hooks/unsaved-changes/useUnsavedChangesClusterManagement';
import { StoreModel } from 'src/infrastructure/rest-api/api-types';

import { numberWithSpaces } from 'src/utils/numberWithSpaces';

interface ClusterStoreProps {
  store: StoreModel;
  clusterId?: number;
  disabled?: boolean;
  onMoveToCluster?: (storeId: number, clusterId: number, name: string, description: string) => void;
}

export const ClusterStore: FC<ClusterStoreProps> = memo(
  ({ store, clusterId, disabled, onMoveToCluster }: ClusterStoreProps) => {
    const { clusters } = useClustersByCompositeId();
    const [unsavedChanges] = useUnsavedChangesClusterManagement();
    const isChange = clusterId && clusterId !== store.cluster?.id;
    const storesTurnover = useTurnoverForPartnerStores();

    const { setNodeRef, listeners, attributes, setActivatorNodeRef, transform } = useDraggable({
      id: store.id,
      data: store,
    });

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpenMenu = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      },
      [setAnchorEl]
    );

    const handleCloseMenu = useCallback(() => setAnchorEl(null), [setAnchorEl]);

    const style = transform
      ? {
          transform: `translate3d(${transform.x})px, ${transform.y}px, 0`,
          backgroundColor: isChange ? 'rgba(0, 0, 255, 0.03)' : 'white',
        }
      : undefined;

    const storeTurnoverNext12Months = useMemo(
      () => storesTurnover.find((storeTurnover) => storeTurnover.storeId === store.id)?.turnover,
      [store.id, storesTurnover]
    );

    const salesiV = useMemo(() => {
      if (storeTurnoverNext12Months !== undefined) return numberWithSpaces(Math.round(storeTurnoverNext12Months));
    }, [storeTurnoverNext12Months]);

    const salesiVBySquareMeter =
      typeof storeTurnoverNext12Months === 'number' &&
      typeof store.salesFloorSquaremeters === 'number' &&
      store.salesFloorSquaremeters > 0
        ? numberWithSpaces(Math.round((storeTurnoverNext12Months ?? 0) / store.salesFloorSquaremeters))
        : 0;

    const openingDate = new Date(store.storeOpeningDate ?? '');

    const existingClusterMenuItems = useMemo(
      () =>
        clusters
          ?.filter((cluster) => cluster?.id !== clusterId)
          .map((cluster) => ({
            id: cluster.id ?? -1,
            label: `Cluster ${unsavedChanges?.[cluster.id ?? -1]?.name ?? cluster.name}`,
            onClick: () => onMoveToCluster?.(store.id, cluster?.id, cluster.name ?? '', cluster.description ?? ''),
          })) ?? [],
      [clusterId, onMoveToCluster, clusters, store.id, unsavedChanges]
    );

    const localClusterMenuItems = useMemo(
      () =>
        Object.entries(unsavedChanges ?? [])
          .filter(([key]) => parseInt(key) !== clusterId && parseInt(key) < 0)
          .map(([key, change]) => ({
            id: parseInt(key),
            label: `Cluster ${change.name}`,
            onClick: () => onMoveToCluster?.(store.id, parseInt(key), change.name ?? '', change.description ?? ''),
          })) ?? [],
      [clusterId, onMoveToCluster, store.id, unsavedChanges]
    );

    const menuItems = [...localClusterMenuItems, ...existingClusterMenuItems];

    return (
      <StyledBox ref={setNodeRef} style={style}>
        <ClusterInfoRow>
          <IconButton
            disabled={disabled}
            disableRipple
            ref={setActivatorNodeRef}
            {...listeners}
            {...attributes}
            style={{ width: 40, cursor: 'grab' }}
          >
            <DragIndicator />
          </IconButton>
          <Typography>{store.storeName}</Typography>
          <Typography>{store.country?.name?.toUpperCaseLetters()}</Typography>
          <Typography>{store.chain?.name}</Typography>
          <Typography>{openingDate.toLocaleDateString()}</Typography>
          <Typography>{salesiV?.isNumeric() ? salesiV : <Skeleton width={'30%'} variant="text" />}</Typography>
          <Typography>
            {salesiV?.isNumeric() ? salesiVBySquareMeter : <Skeleton width={'30%'} variant="text" />}
          </Typography>
          <Typography>{store.salesFloorSquaremeters}</Typography>
          <IconButton
            disabled={disabled}
            onClick={handleOpenMenu}
            id="menu-button"
            aria-controls={open ? 'menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVert />
          </IconButton>
        </ClusterInfoRow>
        <CustomMenu anchorEl={anchorEl} onClose={handleCloseMenu} items={menuItems} />
      </StyledBox>
    );
  }
);

const StyledBox = styled(Box)`
  display: flex;
  padding: 8px 0;
  align-items: center;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
`;
