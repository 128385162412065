import { useChainId } from 'src/hooks/chain';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useStoreType } from '../useStoreType';

export const useChainMenuSettingsApiQuery = () => {
  const chainId = useChainId();
  const storeType = useStoreType();

  const { data, loading, error, refetch } = useApiQuery('/api/chainmenusettings/chains/{chainId}', 'get', {
    query: { storeType },
    path: {
      chainId,
    },
    enabled: !!chainId && !!storeType,
  });

  return {
    data,
    loading,
    error,
    refetch,
  };
};
