import { useCallback } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { useChangesSnackbar, useScope } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useInheritance } from 'src/hooks/inheritance';
import { useUpdateSplitLevel } from 'src/hooks/split-levels/useUpdateSplitLevel';

export const useMarkupUpdateInheritance = () => {
  const { updateSplitLevel: updateStoreSplitLevel, loading } = useUpdateSplitLevel();
  const discardChanges = useDiscardChangesModule(Module.Markup);

  const scope = useScope();
  const inheritance = useInheritance();
  const showSnackbar = useChangesSnackbar();

  const updateInheritance = useCallback(() => {
    // eslint-disable-next-line no-console
    if (!inheritance) return console.warn(`No inheritance set: ${inheritance}`);

    if (scope === ScopeEnum.STORE) {
      return updateStoreSplitLevel(inheritance)?.then(() => {
        discardChanges();
        showSnackbar();
      });
    }
  }, [discardChanges, inheritance, showSnackbar, scope, updateStoreSplitLevel]);

  return { updateInheritance, loading };
};
