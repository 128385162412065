import { useMemo } from 'react';
import {
  OptimalOptionCountStylesPer100SQMLabel,
  OptimalOptionCountTotalLabel,
} from 'src/mapping/optimal-option-count.mapping';
import { useOptimalOptionCountTotalsRows } from '../table/useOptimalOptionCountTotalsRows';
import { useOptimalOptionCountPlacementSimulationRows } from './useOptimalOptionCountPlacementSimulationRows';

export const useOptimalOptionCountTotalsSimulationRows = () => {
  const { data: placementSimulationData } = useOptimalOptionCountPlacementSimulationRows();
  const { data, loading, error } = useOptimalOptionCountTotalsRows() ?? {};

  const simulatedData = useMemo(() => {
    if (!data) return;

    if (!placementSimulationData) return data;
    const optimalOptionCountTotalRow = placementSimulationData?.find(
      (row) => row.category === OptimalOptionCountTotalLabel
    );
    const stylesPerSqmRow = placementSimulationData?.find(
      (row) => row.category === OptimalOptionCountStylesPer100SQMLabel
    );
    return data.map((row) => {
      const updatedRow = { ...row };
      if (optimalOptionCountTotalRow && row.category === OptimalOptionCountTotalLabel) {
        updatedRow.columns = optimalOptionCountTotalRow.columns;
      }
      if (stylesPerSqmRow && row.category === OptimalOptionCountStylesPer100SQMLabel) {
        updatedRow.columns = stylesPerSqmRow.columns;
      }
      return updatedRow;
    });
  }, [data, placementSimulationData]);

  return { data: simulatedData, loading, error };
};
