import { ColDef } from 'ag-grid-community';
import { defaultNumericColumnOptions } from './default-options';

export const DefaultColumn: ColDef = {
  suppressAutoSize: true,
  suppressHeaderMenuButton: true,
};

export const DefaultColumnNumeric: ColDef = {
  ...DefaultColumn,
  ...defaultNumericColumnOptions,
  type: 'numericColumn',
};
