import { Stack } from '@mui/material';
import { useMemo } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { defaultCategoryColumn } from 'src/components/organisms/DataTable/columns/columns-overview-sum';
import { DefaultColumnOptionsNumbers } from 'src/components/organisms/DataTable/columns/default-options';
import InheritanceDropdown from 'src/components/organisms/InheritanceDropdown';
import { InheritanceTable } from 'src/components/organisms/InheritanceTable/InheritanceTable';
import Topbar from 'src/components/organisms/Topbar';
import { StyledHeaderWrapper } from 'src/components/styled/StyledHeaderWrapper';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { Headings, Module } from 'src/domain';
import { useDeliveryProfileSaveChanges } from 'src/hooks/delivery-profile/save-changes/useDeliveryProfileSaveChanges';
import { useDeliveryProfileOverviewSimulationRows } from 'src/hooks/delivery-profile/simulation/useDeliveryProfileOverviewSimulationRows';
import { useDeliveryProfileUpdateInheritance } from 'src/hooks/delivery-profile/split-level/useDeliveryProfileUpdateInheritance';
import { useDeliveryProfileHandleCellValueChanged } from 'src/hooks/delivery-profile/useDeliveryProfileHandleCellValueChanged';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useHasUnsavedChangesGenderProductline } from 'src/hooks/unsaved-changes';
import { useModulePlanningRange } from 'src/hooks/useModulePlanningRange';

export const DeliveryProfile: React.FC = () => {
  const planningRange = useModulePlanningRange(Module.DeliveryProfile);
  const hasUnsavedChanges = useHasUnsavedChangesGenderProductline();
  const canSave = hasUnsavedChanges;

  const onDiscardChanges = useDiscardChangesModule(Module.DeliveryProfile);

  const [saveChanges, { loading: isSaving }] = useDeliveryProfileSaveChanges();
  const { updateInheritance, loading: updateInheritanceLoading } = useDeliveryProfileUpdateInheritance();

  const { data, columns: monthColumns, loading: overviewRowsLoading } = useDeliveryProfileOverviewSimulationRows();
  const handleCellValueChanges = useDeliveryProfileHandleCellValueChanged();

  const loading = overviewRowsLoading || updateInheritanceLoading || isSaving;

  const columns = useMemo(() => {
    return [defaultCategoryColumn, ...monthColumns];
  }, [monthColumns]);

  if (!data) {
    return <></>;
  }

  return (
    <Stack spacing={2}>
      <Topbar
        onDiscard={onDiscardChanges}
        onSave={saveChanges}
        saveDisabled={!canSave}
        onSetInheritance={updateInheritance}
        saveLoading={isSaving}
        loading={loading}
      />
      <Container>
        <StyledHeaderWrapper>
          <Header heading={Headings.h2}>Delivery Profile</Header>
          <InheritanceDropdown />
        </StyledHeaderWrapper>
        <TableWrapper className="ag-theme-alpine-custom-compact">
          <DataTable
            autoSizeToFit={planningRange === 'TWELVE_MONTHS'}
            headerHeight={60}
            columns={columns}
            rows={data}
            defaultColDef={DefaultColumnOptionsNumbers}
            onCellValuesChanged={handleCellValueChanges}
            loading={loading}
          />
        </TableWrapper>
      </Container>
      <InheritanceTable moduleString="deliveryProfile" />
    </Stack>
  );
};
