import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { InheritanceSettingsRow } from 'src/domain/table/inheritance-settings.row';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useGenderId } from 'src/hooks/gender';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useProductLineId } from 'src/hooks/productline';
import { useChangesSnackbar } from 'src/hooks/snackbar/useChangesSnackbar';
import { UpdateInheritanceLevelInput } from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import {
  inheritanceToSplitLevel,
  noosInheritanceToSplitLevel,
  salesBudgetInheritanceToSplitLevel,
} from 'src/utils/splitLevels';
import { useInheritanceSettingsSimulationRows } from '../simulation/useInheritanceSettingsSimulationRows';
import { useInheritanceSettingsRows } from '../table/useInheritanceSettingsRows';

function valueMapper(row: InheritanceSettingsRow): UpdateInheritanceLevelInput {
  return {
    entityId: row.storeId,
    entityType: 'Store',
    categorySplit: {
      splitLevel: inheritanceToSplitLevel(row.categorySplit, ScopeEnum.STORE),
    },
    genderSplit: {
      splitLevel: inheritanceToSplitLevel(row.genderSplit, ScopeEnum.STORE),
    },
    productLineSplit: {
      splitLevel: inheritanceToSplitLevel(row.productLineSplit, ScopeEnum.STORE),
    },
    deliveryProfile: {
      splitLevel: inheritanceToSplitLevel(row.deliveryProfile, ScopeEnum.STORE),
    },
    weekCover: {
      splitLevel: inheritanceToSplitLevel(row.weekCover, ScopeEnum.STORE),
    },
    markdown: {
      splitLevel: inheritanceToSplitLevel(row.markdown, ScopeEnum.STORE),
    },
    markup: {
      splitLevel: inheritanceToSplitLevel(row.markup, ScopeEnum.STORE),
    },
    noosShare: {
      splitLevel: inheritanceToSplitLevel(row.noosShare.inheritance, ScopeEnum.STORE),
      noosSplit: noosInheritanceToSplitLevel(row.noosShare.noosActualInheritance)!,
    },
    salesBudget: {
      salesBudgetSplit: salesBudgetInheritanceToSplitLevel(row.salesBudget),
    },
  };
}

export const useInheritanceSettingsSaveChanges = () => {
  const partnerComposite = useCompositePartner();
  const genderId = useGenderId();
  const productLineId = useProductLineId();

  const [updateInheritanceSettings] = useApiMutation('/api/inheritance', 'put', {
    update: (_data, _variables, queryClient) => {
      queryClient.invalidateQueries({
        queryKey: ['/api/inheritances', { partnerId: partnerComposite?.id }],
      });
    },
  });

  const showSnackbar = useChangesSnackbar();
  const discardChanges = useDiscardChangesModule(Module.InheritanceSettings);

  const { data: typedData } = useInheritanceSettingsSimulationRows();
  const { data: originalData } = useInheritanceSettingsRows();

  const valuesToUpdate = typedData?.reduce<InheritanceSettingsRow[]>((acc, unsavedRow) => {
    const row = originalData?.find((x) => x.id === unsavedRow.id);

    if (!row) return acc;

    if (isEqual(row, unsavedRow)) return acc;

    const changedRow = {
      ...row,
      ...unsavedRow,
    };

    return [...acc, changedRow];
  }, []);

  const [isUpdating, setIsUpdating] = useState(false);

  const sendUpdateQuery = useCallback(async () => {
    if (!valuesToUpdate.length || !genderId || !productLineId) {
      return;
    }

    return updateInheritanceSettings({
      body: valuesToUpdate.flatMap(valueMapper),
    });
  }, [genderId, productLineId, updateInheritanceSettings, valuesToUpdate]);

  const update = useCallback(async () => {
    setIsUpdating(true);

    try {
      const response = await sendUpdateQuery();

      discardChanges();
      showSnackbar();
      return response;
    } catch {
      showSnackbar(true);
    } finally {
      setIsUpdating(false);
    }
  }, [sendUpdateQuery, discardChanges, showSnackbar]);

  return [update, { loading: isUpdating }] as const;
};
