import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { ComparableCell } from 'src/components/molecules/ComparableCell/ComparableCell';
import { EditableValueCell } from 'src/components/molecules/EditableCell/EditableCell';
import { CalculationToolTip } from 'src/components/molecules/ToolTip/CalculationToolTip';
import { avgAggFunc, sumAggFunc } from 'src/utils/ag-grid/agg-functions';
import { comparableCellAvg, comparableCellSum } from 'src/utils/comparableCellAgg';
import { defaultCategoryColumn } from './columns-overview-sum';
import { DefaultColumnNumeric } from './default-columns-details';
import { DefaultColumnPercentWithSingleDecimal, defaultColDefNumberWithSpaces } from './default-options';
import { isEditableWithFooter } from './is-editable';

export const ColumnsDetailsMarkup: ColDef[] = [
  {
    ...defaultCategoryColumn,
    field: 'category',
    width: 150,
    headerName: 'Category',
    suppressSizeToFit: true,
    cellRenderer: (params: ICellRendererParams) => {
      return params.value ?? 'Sum/Average';
    },
    pinned: 'left',
  },
  {
    ...DefaultColumnNumeric,
    field: 'valueLLY',
    headerName: 'Markup LLY',
    aggFunc: comparableCellAvg,
  },
  {
    ...DefaultColumnNumeric,
    field: 'valueLY',
    headerName: 'Markup LY',
    aggFunc: avgAggFunc,
  },
  {
    ...DefaultColumnNumeric,
    field: 'plannedValue',
    headerName: 'Planned Markup',
    cellRenderer: EditableValueCell,
    editable: isEditableWithFooter,
    aggFunc: avgAggFunc,
  },
  {
    ...DefaultColumnNumeric,
    ...defaultColDefNumberWithSpaces,
    field: 'expectedCogs',
    headerName: 'Expected COGS',
    cellRenderer: ComparableCell,
    cellRendererParams: {
      hideTooltip: true,
    },
    aggFunc: comparableCellSum,
    tooltipComponent: CalculationToolTip,
    tooltipField: 'expectedCogsCalculation',
  },
  {
    ...DefaultColumnNumeric,
    ...defaultColDefNumberWithSpaces,
    field: 'cogsLY',
    headerName: 'COGS LY',
    aggFunc: sumAggFunc,
  },
  {
    ...DefaultColumnNumeric,
    ...DefaultColumnPercentWithSingleDecimal,
    field: 'expectedGrossMargin',
    headerName: 'Expected GM %',
    cellRenderer: ComparableCell,
    cellRendererParams: {
      hideTooltip: true,
    },
    tooltipComponent: CalculationToolTip,
    tooltipField: 'expectedGrossMarginCalculation',
  },
  {
    ...DefaultColumnNumeric,
    ...DefaultColumnPercentWithSingleDecimal,
    field: 'grossMarginLY',
    headerName: 'GM % LY',
  },
];
