import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/material/styles';

export const tabsWrapperStyle = (theme: Theme): CSSObject => ({
  zIndex: 2,
  marginTop: theme.spacing(0.5),
  margin: 0,
  height: '40px',
  minHeight: '40px',
  textTransform: 'initial',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .Mui-selected': {
    color: '#333',
    '& + .MuiTabs-root': {
      zIndex: 1,
    },
    '& + .MuiTabs-root:before': {
      opacity: 0,
    },
  },
});

export const tabItemStyles = (theme: Theme): CSSObject => {
  return {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '40px',
    height: '40px',
    gap: '10px',
    alignItems: 'center',
    opacity: 1,
    overflow: 'initial',
    padding: '5px 20px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    color: '#333',
    backgroundColor: '#fff',
    textTransform: 'none',
    transition: '0.2s',
    [theme.breakpoints.up('md')]: {
      minWidth: 120,
    },
    '& .MuiTab-iconWrapper': {
      margin: 0,
    },
    '&:before': {
      transition: '0.2s',
    },
    '& + .Mui-selected:before': {
      opacity: 0,
    },
    '&:not(.Mui-selected)': {
      backgroundColor: '#F1F1F1',
    },
    '&:hover': {
      '&:not(.Mui-selected)': {
        backgroundColor: '#F8F8F8',
      },
      '&::before': {
        opacity: 0,
      },
      '& + .MuiTabs-root:before': {
        opacity: 0,
      },
    },
  };
};
