/* eslint-disable prettier/prettier */
import { Stack } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { FC, memo, useMemo } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { calculateAveragesWithPlannedSplits } from 'src/components/organisms/DataTable/aggregators/average-total.agg';
import { defaultCategoryColumn } from 'src/components/organisms/DataTable/columns/columns-overview-sum';
import { DefaultColumnOptionsNumbers } from 'src/components/organisms/DataTable/columns/default-options';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { averageCellRenderer } from 'src/components/organisms/DataTable/helpers/average-cell-renderer';
import InheritanceDropdown from 'src/components/organisms/InheritanceDropdown';
import Topbar from 'src/components/organisms/Topbar';
import { HeaderWrapper } from 'src/components/styled/HeaderWrapper';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { Headings } from 'src/domain';
import { useDiscardChangesGenderProductline, useHandleCellValueChangedWeekCover } from 'src/hooks';
import { useHasUnsavedChangesGenderProductline } from 'src/hooks/unsaved-changes/useHasUnsavedChangesGenderProductline';
import { useSaveChangesWeekCover } from 'src/hooks/week-cover/save-changes/useSaveChangesWeekCover';
import { useWeekCoverUpdateInheritance } from 'src/hooks/week-cover/split-level/useWeekCoverUpdateInheritance';
import { useWeekCoverOverviewSimulationRows } from 'src/hooks/week-cover/useWeekCoverOverviewSimulationRows';

export const WeekCover: FC = memo(() => {
  const {
    data: weekCoverData,
    columns: monthColumns,
    loading: weekCoverDataLoading,
  } = useWeekCoverOverviewSimulationRows();
  const [saveChanges, { loading: isSaving }] = useSaveChangesWeekCover();
  const onDiscardChanges = useDiscardChangesGenderProductline();
  const handleCellValueChanges = useHandleCellValueChangedWeekCover();
  const hasUnsavedChanges = useHasUnsavedChangesGenderProductline();

  const { updateInheritance, loading: updateInheritanceLoading } = useWeekCoverUpdateInheritance();

  const isLoading = weekCoverDataLoading || updateInheritanceLoading || isSaving;
  const canSave = hasUnsavedChanges && !isSaving;

  const columns: ColDef[] = useMemo(() => {
    return [
      {
        ...defaultCategoryColumn,
        cellRenderer: (params: ICellRendererParams) => {
          return params.value ?? 'Average';
        },
      },
      ...monthColumns.map((column) => {
        return {
          ...column,
          cellRenderer: (params: ICellRendererParams) => averageCellRenderer(column, params),
        };
      }),
    ];
  }, [monthColumns]);

  return (
    <Stack spacing={2}>
      <Topbar
        saveDisabled={!canSave}
        onDiscard={onDiscardChanges}
        onSave={saveChanges}
        loading={isLoading}
        saveLoading={isSaving}
        onSetInheritance={updateInheritance}
      />
      <Container>
        <HeaderWrapper>
          <Header heading={Headings.h2}>Week Cover</Header>
          <InheritanceDropdown />
        </HeaderWrapper>
        <TableWrapper className="ag-theme-alpine-custom-compact">
          <DataTable
            autoSizeToFit
            headerHeight={60}
            columns={columns}
            rows={weekCoverData}
            defaultColDef={DefaultColumnOptionsNumbers}
            onCellValuesChanged={handleCellValueChanges}
            getGroupRowAgg={calculateAveragesWithPlannedSplits}
            grandTotalRow="bottom"
            loading={isLoading}
          />
        </TableWrapper>
      </Container>
    </Stack>
  );
});
