import { ScopeEnum } from 'src/domain';
import { useScope } from 'src/hooks';
import { useChainId } from 'src/hooks/chain';
import { useStoreType } from 'src/hooks/useStoreType';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useStorefrontType } from '../useStorefrontType';

export const useGlobalStyleTimelineQuery = () => {
  const storefrontType = useStorefrontType();

  const chainId = useChainId();
  const storeType = useStoreType();
  const scope = useScope();

  const response = useApiQuery('/api/styletimeline/chains/{chainId}', 'get', {
    path: {
      chainId: chainId,
    },
    query: {
      storefrontType,
      storeType,
    },
    enabled: !!chainId && !!storefrontType && scope === ScopeEnum.GLOBAL,
  });

  return response;
};
