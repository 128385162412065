import { useClusterId } from 'src/hooks/cluster';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useStoreId } from 'src/hooks/store';
import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useSelectedPartner } from 'src/hooks/useSelectedPartner';
import { StockManagementOverviewModel, StyleCategoryModel } from 'src/infrastructure/rest-api/api-types';
import { useStockManagementApiQuery } from './useStockManagementApiQuery';

export type CurrentStockManagementQuery = {
  data: StockManagementOverviewModel | undefined;
  styleCategories: StyleCategoryModel[] | undefined;
  error: Error | null;
  loading: boolean;
};

export const useStockManagementQuery = (): CurrentStockManagementQuery => {
  const storeId = useStoreId();
  const [selectedPartner] = useSelectedPartner();
  const clusterId = useClusterId(selectedPartner);
  const compositePartner = useCompositePartner();
  const { data: storeData, error: storeError, loading: storeLoading } = useStockManagementApiQuery(storeId, 'Store');
  const {
    data: clusterData,
    error: clusterError,
    loading: clusterLoading,
  } = useStockManagementApiQuery(clusterId, 'Cluster');
  const {
    data: partnerData,
    error: partnerError,
    loading: partnerLoading,
  } = useStockManagementApiQuery(compositePartner?.id, 'Partner');
  const {
    data: styleCategories,
    error: styleCategoriesError,
    loading: styleCategoriesLoading,
  } = useStyleCategoriesQuery();

  return {
    data: storeData ?? clusterData ?? partnerData,
    styleCategories: styleCategories,
    error: storeError ?? clusterError ?? partnerError ?? styleCategoriesError ?? null,
    loading: (storeLoading || clusterLoading || partnerLoading || styleCategoriesLoading) ?? false,
  };
};
