import { ChainClusterPartnersMap, ChainCountryPartnersMap, IdToNameListMap, PartnerToCompositeMap } from 'src/domain';
import { StoreModel } from 'src/infrastructure/rest-api/api-types';
import { SerializeMap } from 'src/utils/SerializeMap';

type ChainLookupTable = {
  chainIds: IdToNameListMap;
  clusterIds: IdToNameListMap;
  partnerIds: IdToNameListMap;
  countryIds: IdToNameListMap;
  compositePartners: PartnerToCompositeMap;
  ownRetailChainCountryPartners: ChainCountryPartnersMap;
  ownRetailChainClusterPartners: ChainClusterPartnersMap;
  partnerRetailChainCountryPartners: ChainCountryPartnersMap;
  partnerRetailChainClusterPartners: ChainClusterPartnersMap;
  semiOwnedChainCountryPartners: ChainCountryPartnersMap;
  semiOwnedChainClusterPartners: ChainClusterPartnersMap;
};

const idToName = (name: string | undefined | null, id: number, list: IdToNameListMap) => {
  if (name !== undefined && name !== null && list.get(id) === undefined) {
    list.set(id, name);
  }
  return list;
};

export const buildLookupTables = (stores: StoreModel[]): ChainLookupTable => {
  const result: ChainLookupTable = {
    chainIds: new Map(),
    clusterIds: new Map(),
    partnerIds: new Map(),
    countryIds: new Map(),
    compositePartners: new SerializeMap(),
    ownRetailChainCountryPartners: new SerializeMap(),
    ownRetailChainClusterPartners: new SerializeMap(),
    partnerRetailChainCountryPartners: new SerializeMap(),
    partnerRetailChainClusterPartners: new SerializeMap(),
    semiOwnedChainCountryPartners: new SerializeMap(),
    semiOwnedChainClusterPartners: new SerializeMap(),
  };

  stores.forEach((store) => {
    const chainName = store.chain?.name ?? '';
    const chainId = store.chain?.id ?? -1;
    result.chainIds = idToName(chainName, chainId, result.chainIds);

    const partnerName = store.operationalResponsible?.name ?? '';
    const partnerId = store.operationalResponsible?.id ?? -1;
    result.partnerIds = idToName(partnerName, partnerId, result.partnerIds);

    const countryName = store.country?.name ?? '';
    const countryId = store.country?.id ?? -1;
    result.countryIds = idToName(countryName, countryId, result.countryIds);

    const clusterName = store.cluster?.name;
    const clusterId = store.cluster?.id ?? -1;
    result.clusterIds = idToName(clusterName, clusterId, result.clusterIds);

    if (store.operationalResponsibleComposite) {
      result.compositePartners.set({ partnerId, countryId }, store.operationalResponsibleComposite);
      result.compositePartners.set({ partnerId, clusterId }, store.operationalResponsibleComposite);
    }

    switch (store.storeType) {
      case 'OWN_STORE':
        {
          const partners = result.ownRetailChainCountryPartners.get({
            chainId: chainId.toString(),
            countryId: countryId.toString(),
          });

          if (partnerId && !partners?.includes(partnerId)) {
            result.ownRetailChainCountryPartners.set(
              {
                chainId: chainId.toString(),
                countryId: countryId.toString(),
              },
              [...(partners ?? []), partnerId]
            );
          }
        }
        {
          const partners = result.ownRetailChainClusterPartners.get({
            chainId: chainId.toString(),
            clusterId: clusterId.toString(),
          });

          if (partnerId && !partners?.includes(partnerId)) {
            result.ownRetailChainClusterPartners.set(
              {
                chainId: chainId.toString(),
                clusterId: clusterId.toString(),
              },
              [...(partners ?? []), partnerId]
            );
          }
        }
        break;
      case 'PARTNER_STORE':
        {
          const partners = result.partnerRetailChainCountryPartners.get({
            chainId: chainId.toString(),
            countryId: countryId.toString(),
          });

          if (partnerId && !partners?.includes(partnerId)) {
            result.partnerRetailChainCountryPartners.set(
              {
                chainId: chainId.toString(),
                countryId: countryId.toString(),
              },
              [...(partners ?? []), partnerId]
            );
          }
        }
        {
          const partners = result.partnerRetailChainClusterPartners.get({
            chainId: chainId.toString(),
            clusterId: clusterId.toString(),
          });

          if (partnerId && !partners?.includes(partnerId)) {
            result.partnerRetailChainClusterPartners.set(
              {
                chainId: chainId.toString(),
                clusterId: clusterId.toString(),
              },
              [...(partners ?? []), partnerId]
            );
          }
        }
        break;
      case 'SEMI_OWNED_STORE':
        {
          const partners = result.semiOwnedChainCountryPartners.get({
            chainId: chainId.toString(),
            countryId: countryId.toString(),
          });

          if (partnerId && !partners?.includes(partnerId)) {
            result.semiOwnedChainCountryPartners.set(
              {
                chainId: chainId.toString(),
                countryId: countryId.toString(),
              },
              [...(partners ?? []), partnerId]
            );
          }
        }
        {
          const partners = result.semiOwnedChainClusterPartners.get({
            chainId: chainId.toString(),
            clusterId: clusterId.toString(),
          });

          if (partnerId && !partners?.includes(partnerId)) {
            result.semiOwnedChainClusterPartners.set(
              {
                chainId: chainId.toString(),
                clusterId: countryId.toString(),
              },
              [...(partners ?? []), partnerId]
            );
          }
        }
        break;
    }
  });

  return result;
};
