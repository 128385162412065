import { GetGroupRowAggParams } from 'ag-grid-community';
import { isNumber } from 'lodash';
import { TableOverviewRow } from 'src/domain';

export type Aggregation = TableOverviewRow['columns'];
export const calculateAveragesWithPlannedSplits = (params: GetGroupRowAggParams<TableOverviewRow>): Aggregation => {
  const result = params.nodes.reduce<Aggregation>((acc, value) => {
    const data = value.data;

    if (!data) return acc;

    Object.keys(data.columns).forEach((fieldName) => {
      if (fieldName.endsWith('Id') || fieldName.endsWith('PlannedSplit')) return;
      const monthSplit = data.columns[`${fieldName}PlannedSplit`];
      const monthValue = data.columns[fieldName];

      if (!acc[fieldName]) {
        acc[fieldName] = 0;
      }

      if (isNumber(monthValue) && isNumber(monthSplit)) {
        acc[fieldName] += (monthSplit / 100) * monthValue;
      }
    });

    return acc;
  }, {} as Aggregation);
  return result;
};
