import { Gender, SessionStorageGenderProductLineType } from 'src/domain';
import { useCurrentId, useCurrentModule, useUnsavedChangesModule } from 'src/hooks';
import { GenderProductLineDelimiter } from 'src/utils/combineGenderAndProductline';

export const useGenderProductlinesWithChanges = () => {
  const module = useCurrentModule();
  const id = useCurrentId();
  const [unsavedChanges] = useUnsavedChangesModule<SessionStorageGenderProductLineType>(module);

  if (!id || !module) return null;

  if (!unsavedChanges) return null;

  const map = new Map<string, Gender[]>();

  Object.keys(unsavedChanges).forEach((x) => {
    const [productLine, gender] = x.split(GenderProductLineDelimiter);

    map.set(productLine, [...(map.get(productLine) ?? []), gender as Gender]);
  });

  return map;
};
