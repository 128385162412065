import { IStatusPanelParams } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';

export function CountStatusBar(props: IStatusPanelParams) {
  const [count, setCount] = useState(0);
  const [filteredCount, setFilteredCount] = useState(0);

  useEffect(() => {
    props.api.addEventListener('modelUpdated', () => {
      let filteredNodeCount = 0;
      let nodeCount = 0;
      props.api.forEachNode((node) => {
        if (!node.group) nodeCount++;
      });
      props.api.forEachNodeAfterFilterAndSort((node) => {
        if (!node.group) filteredNodeCount++;
      });
      setCount(nodeCount);
      setFilteredCount(filteredNodeCount);
    });
  }, [props.api]);

  if (filteredCount !== count) {
    return (
      <div className="ag-status-name-value">
        <span className="component">Showing </span>
        <span className="ag-status-name-value-value">{numberWithSpaces(filteredCount)}</span>
        <span className="component"> out of </span>
        <span className="ag-status-name-value-value">{numberWithSpaces(count)}</span>
        <span className="component"> style options</span>
      </div>
    );
  }

  return (
    <div className="ag-status-name-value">
      <span className="component">Showing </span>
      <span className="ag-status-name-value-value">{numberWithSpaces(count)}</span>
      <span className="component"> style options</span>
    </div>
  );
}
