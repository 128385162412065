import { ColDef } from 'ag-grid-community';
import { FC, useMemo } from 'react';
import Container from 'src/components/atoms/Container';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { SalesCampaignOverviewRow } from 'src/domain/table/sales-campaign-overview.row';
import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { withDefaultHeaderToolTip } from 'src/utils/ag-grid/withDefaultHeaderToolTip';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';
import { SummaryData, calculateSummary } from '../calculateSummary';
import { SummaryReportProps } from '../types';

interface SummaryRow extends SummaryData {
  id: number;
  categoryName: string;
}

const columns: ColDef<SummaryRow>[] = withDefaultHeaderToolTip([
  {
    field: 'categoryName',
    headerName: 'Style category',
    valueFormatter: (params) => {
      return params.value ?? 'Unknown';
    },
    pinned: 'left',
    lockPinned: true,
  },
  {
    field: 'totalStockValue',
    headerName: 'Total stock value',
  },
  {
    field: 'totalStockValueOnSale',
    headerName: 'Stock value on sale',
  },
  {
    field: 'totalStockValueNotOnSale',
    headerName: 'Stock value not on sale',
  },
  {
    field: 'percentageStockValueOnSale',
    headerName: '% stock value on sale',
    valueFormatter: (params) => {
      return numberWithSpaces(params.value, { postfix: '%' });
    },
  },
  {
    field: 'avgMarkDown',
    headerName: 'Avg. markdown %',
    valueFormatter: (params) => {
      return numberWithSpaces(params.value, { postfix: '%', default: 'N/A' });
    },
  },
  {
    field: 'stylesOnSale',
    headerName: 'No. of styles on sale',
  },
  {
    field: 'styleOptionsOnSale',
    headerName: 'No. of style options on sale',
  },
]);

const defaultColDef: ColDef<SummaryRow> = {
  editable: false,
  sortable: true,
  valueFormatter: (params) => {
    return numberWithSpaces(params.value);
  },
};

export const CategorySalesSummaryTab: FC<SummaryReportProps> = ({ salesCampaignRows }) => {
  const { data: styleCategories } = useStyleCategoriesQuery();

  const byCategory = useMemo(() => {
    return salesCampaignRows.reduce((acc, row) => {
      if (!row.styleCategoryId) {
        return acc;
      }

      const currentRows = acc.get(row.styleCategoryId) ?? [];
      currentRows.push(row);
      acc.set(row.styleCategoryId, currentRows);

      return acc;
    }, new Map<number, SalesCampaignOverviewRow[]>());
  }, [salesCampaignRows]);

  const summaryRows = useMemo(() => {
    if (!styleCategories) {
      return null;
    }

    return styleCategories
      .toSorted((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity))
      .map((category) => {
        const rows = byCategory.get(category.id) ?? [];

        return {
          id: category.id,
          categoryName: category.name,
          ...calculateSummary(rows),
        };
      });
  }, [byCategory, styleCategories]);

  return (
    <Container>
      <TableWrapper className="ag-theme-alpine-custom-compact small-font" flex={1}>
        <DataTable autoSizeToFit rows={summaryRows} columns={columns} defaultColDef={defaultColDef} />
      </TableWrapper>
    </Container>
  );
};
