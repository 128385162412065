import styled from '@emotion/styled';
import { FC } from 'react';
import { ClusterManagementList } from './ClusterManagamentList';
import { ClusterManagementHeader } from './ClusterManagementHeader';
import { IsDraggingProvider } from './hooks/useIsDragging';

export const ClusterManagementView: FC = () => {
  return (
    <IsDraggingProvider>
      <Container>
        <ClusterManagementHeader />
        <ClusterManagementList />
      </Container>
    </IsDraggingProvider>
  );
};

const Container = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
