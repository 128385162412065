import { SimpleTable, SimpleTableCell, SimpleTableRow } from '@bestseller-bit/retail-planning.ui.atoms.simple-table';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Add, ArrowDownward, ArrowUpward, Delete, DragIndicator, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, IconButton, Stack, Typography, styled } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { DeleteDialog } from 'src/components/organisms/DeleteDialog/DeleteDialog';
import { Headings } from 'src/domain';
import { infinitySymbol } from 'src/domain/models/infinity-symbol';
import { StyleTimelineSettingsRow } from 'src/domain/table/style-timeline-settings.row';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useSnackbar } from 'src/hooks/snackbar/useSnackbar';
import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useBusinessModelGroupsApiQuery } from 'src/hooks/style-timeline-settings/queries/useBusinessModelGroupsApiQuery';
import { useBusinessModelsApiQuery } from 'src/hooks/style-timeline-settings/queries/useBusinessModelsApiQuery';
import { useStorefrontTypesApiQuery } from 'src/hooks/style-timeline-settings/queries/useStorefrontTypesApiQuery';
import { useSubStyleCategoriesApiQuery } from 'src/hooks/style-timeline-settings/queries/useSubStyleCategoriesApiQuery';
import { useStyleTimelineSettingsDelete } from 'src/hooks/style-timeline-settings/save-changes/useStyleTimelineSettingsDelete';
import { useStyleTimelineSettingsSaveChanges } from 'src/hooks/style-timeline-settings/save-changes/useStyleTimelineSettingsSaveChanges';
import { useStyleTimelineSettingsUpdate } from 'src/hooks/style-timeline-settings/save-changes/useStyleTimelineSettingsUpdateSettings';
import { useStyleTimelineSettingsRows } from 'src/hooks/style-timeline-settings/table/useStyleTimelineSettingsRows';
import { useProductLineGroupsApiQuery } from 'src/hooks/useProductLineGroupsApiQuery';
import {
  CreatePartnerStyleTimelineSettingInput,
  UpdatePartnerStyleTimelineSettingInput,
} from 'src/infrastructure/rest-api/api-types';
import { DefaultLifeSpanEditDialog, EditableStyleTimelineSettingRow } from './DefaultLifeSpanEditDialog';

const mapToSettingInput = (
  data: EditableStyleTimelineSettingRow,
  priority: number,
  partnerCompositeId: number
): CreatePartnerStyleTimelineSettingInput => {
  return {
    lifespanWeeks: data.lifeSpanWeeks ?? 1,
    storefrontTypeId: data.storefrontTypeId ?? null,
    styleCategoryId: data.styleCategoryId ?? null,
    styleSubCategoryId: data.styleSubCategoryId ?? null,
    productLineGroupId: data.productLineGroupId ?? null,
    businessModelId: data.businessModelId ?? null,
    businessModelGroupId: data.businessModelGroupId ?? null,
    priority,
    partnerCompositeId,
  };
};

const mapToUpdateSettingInput = (data: StyleTimelineSettingsRow): UpdatePartnerStyleTimelineSettingInput => {
  return {
    id: data.id,
    priority: data.priority,
    lifespanWeeks: data.lifeSpanWeeks,
    storefrontTypeId: data.storefrontTypeId ?? null,
    styleCategoryId: data.styleCategoryId ?? null,
    styleSubCategoryId: data.styleSubCategoryId ?? null,
    productLineGroupId: data.productLineGroupId ?? null,
    businessModelId: data.businessModelId ?? null,
    businessModelGroupId: data.businessModelGroupId ?? null,
  };
};

export const byPriority = (a: StyleTimelineSettingsRow, b: StyleTimelineSettingsRow) => {
  return a.priority - b.priority;
};

export const StyleTimelineSettings: FC = () => {
  const { data: settingsData, loading: rowsLoading } = useStyleTimelineSettingsRows();
  const [rows, setRows] = useState<StyleTimelineSettingsRow[] | undefined>(undefined);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const showSnackbar = useSnackbar();

  const compositePartner = useCompositePartner();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedRow, setSelectedRow] = useState<EditableStyleTimelineSettingRow | null>(null);
  const saveChanges = useStyleTimelineSettingsSaveChanges();
  const updateSettings = useStyleTimelineSettingsUpdate();
  const deleteSetting = useStyleTimelineSettingsDelete();
  const [rowPendingDeletion, setRowPendingDeletion] = useState<StyleTimelineSettingsRow | null>(null);

  const handleUpdate = useCallback(
    (updatedRows: StyleTimelineSettingsRow[]) => {
      if (!compositePartner || !updatedRows) {
        // eslint-disable-next-line no-console
        console.warn('No composite partner found!');
        return;
      }

      setRows(updatedRows);
      setIsSaving(true);
      updateSettings(updatedRows.map(mapToUpdateSettingInput))
        .then(() => {
          setIsSaving(false);
          setIsDialogOpen(false);
          showSnackbar('Your changes have been saved.', 'success');
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          setIsSaving(false);
          showSnackbar('We could not save your changes. Please try again.', 'error');
        });
    },
    [compositePartner, showSnackbar, updateSettings]
  );

  const handleClose = useCallback(
    (data: EditableStyleTimelineSettingRow | null) => {
      if (!data) {
        // close dialog - cancel was clicked
        setIsDialogOpen(false);
        return;
      }

      if (!compositePartner) {
        // eslint-disable-next-line no-console
        console.warn('No composite partner found!');
        return;
      }

      if (data && !data.id) {
        setIsSaving(true);
        saveChanges(mapToSettingInput(data, (rows ?? []).length, compositePartner.id))
          .then(() => {
            setIsSaving(false);
            setIsDialogOpen(false);
            showSnackbar('Your changes have been saved.', 'success');
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            setIsSaving(false);
            showSnackbar('We could not save your changes. Please try again.', 'error');
          });
      } else if (data?.id) {
        const newRows = (rows ?? []).map((row) => {
          if (row.id === data.id) {
            return {
              ...row,
              styleCategoryId: data.styleCategoryId,
              storefrontTypeId: data.storefrontTypeId,
              styleSubCategoryId: data.styleSubCategoryId,
              productLineGroupId: data.productLineGroupId,
              businessModelId: data.businessModelId,
              businessModelGroupId: data.businessModelGroupId,
              lifeSpanWeeks: data.lifeSpanWeeks ?? 1,
            };
          }
          return row;
        });
        handleUpdate(newRows);
      }
    },
    [compositePartner, rows, saveChanges, showSnackbar, handleUpdate]
  );

  const handleEditRow = useCallback((data: StyleTimelineSettingsRow) => {
    setSelectedRow(data);
    setIsDialogOpen(true);
  }, []);

  const handleDeleteRow = useCallback(() => {
    if (rowPendingDeletion) {
      deleteSetting(rowPendingDeletion.id);
      setRowPendingDeletion(null);
    }
  }, [deleteSetting, rowPendingDeletion]);

  const handleCancelDeletion = useCallback(() => {
    setRowPendingDeletion(null);
  }, []);

  const showDeleteDialog = useCallback((data: StyleTimelineSettingsRow) => {
    setRowPendingDeletion(data);
  }, []);

  const handleChangePrioritization = useCallback(
    (priority: number, newPriority: number) => {
      if (newPriority < 0) {
        return;
      }

      const lowestPriority = Math.min(priority, newPriority);
      const highestPriority = Math.max(priority, newPriority);

      const rowsToUpdate =
        rows
          ?.filter((row) => row.priority >= lowestPriority && row.priority <= highestPriority)
          .map((row) => {
            return {
              ...row,
              priority: row.priority === priority ? newPriority : row.priority - 1 * Math.sign(newPriority - priority),
            };
          }) ?? [];

      const newRows = rows
        ?.filter((row) => row.priority < lowestPriority || row.priority > highestPriority)
        .concat(rowsToUpdate)
        .toSorted(byPriority);

      handleUpdate(newRows ?? []);
    },
    [handleUpdate, rows]
  );

  const openDialog = useCallback(() => {
    setIsDialogOpen(true);
    setSelectedRow(null);
  }, []);

  const loading = rowsLoading;

  useEffect(() => {
    setRows(loading ? undefined : settingsData?.toSorted(byPriority));
  }, [loading, settingsData]);

  const maxPriority = useMemo(() => {
    return rows?.reduce((acc, row) => Math.max(acc, row.priority), 0);
  }, [rows]);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      const activeData = rows?.find((x) => x.id === active.id);
      const overData = rows?.find((x) => x.id === over?.id);

      if (activeData && overData) {
        handleChangePrioritization(activeData.priority, overData.priority);
      }
    },
    [handleChangePrioritization, rows]
  );

  return (
    <Container>
      <Stack direction="row" alignItems={'center'}>
        <Header heading={Headings.h2}>Default life span rules</Header>
        <Box ml="auto">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={openDialog}
            startIcon={<Add />}
            loading={loading}
            loadingPosition="start"
          >
            Add new rule
          </LoadingButton>
        </Box>
      </Stack>
      <Stack mt={'2rem'} gap={'1rem'}>
        {loading && (
          <Stack alignItems={'center'}>
            <CircularProgress />
          </Stack>
        )}
        {!loading && rows && rows.length === 0 && (
          <Typography fontStyle={'italic'} textAlign={'center'}>
            No rules yet. Add a new rule to get started.
          </Typography>
        )}
        {!loading && rows && rows.length > 0 && (
          <Stack mb={2} gap={'0.5rem'}>
            <Typography>
              The rules below are used to automatically apply the default life span value to new style options added to
              the system.
            </Typography>
            <Typography>
              The rules are applied from top to bottom and when a rule is matched, no further rules will be processed.
            </Typography>
          </Stack>
        )}
        <SimpleTable columnSizing={['24px', '80px', 'repeat(7, minmax(90px, 1fr))', 'minmax(160px, 1fr)']}>
          <SimpleTableRow isHeader>
            <SimpleTableCell />
            <SimpleTableCell>
              <Typography>Priority</Typography>
            </SimpleTableCell>
            <SimpleTableCell>
              <Typography>Storefront type</Typography>
            </SimpleTableCell>
            <SimpleTableCell>
              <Typography>Style category</Typography>
            </SimpleTableCell>
            <SimpleTableCell>
              <Typography>Style sub category</Typography>
            </SimpleTableCell>
            <SimpleTableCell>
              <Typography>Product line group</Typography>
            </SimpleTableCell>
            <SimpleTableCell>
              <Typography>Default life span</Typography>
            </SimpleTableCell>
            <SimpleTableCell>
              <Typography>Business model</Typography>
            </SimpleTableCell>
            <SimpleTableCell>
              <Typography>Business model group</Typography>
            </SimpleTableCell>
          </SimpleTableRow>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={rows ?? []} strategy={verticalListSortingStrategy}>
              {rows?.map((row) => (
                <DefaultLifeSpanBox
                  key={row.id}
                  data={row}
                  maxPriority={maxPriority ?? 0}
                  onDelete={showDeleteDialog}
                  onEdit={handleEditRow}
                  onPriorityChange={handleChangePrioritization}
                />
              ))}
            </SortableContext>
          </DndContext>
        </SimpleTable>
      </Stack>
      {isDialogOpen && (
        <DefaultLifeSpanEditDialog isSaving={isSaving} data={selectedRow} open={isDialogOpen} onClose={handleClose} />
      )}
      {rowPendingDeletion && (
        <DeleteDialog
          open={!!rowPendingDeletion}
          onCancel={handleCancelDeletion}
          onDelete={handleDeleteRow}
          title="Delete rule?"
          content="Deleting this rule is permanent and cannot be undone. Are you sure you want to delete this rule?"
        ></DeleteDialog>
      )}
    </Container>
  );
};

export interface DefaultLifeSpanBoxProps {
  data: StyleTimelineSettingsRow;
  isHeader?: boolean;
  onPriorityChange: (oldPriority: number, newPriority: number) => void;
  onEdit: (data: StyleTimelineSettingsRow) => void;
  onDelete: (data: StyleTimelineSettingsRow) => void;
  maxPriority: number;
}

export const DefaultLifeSpanBox: FC<DefaultLifeSpanBoxProps> = ({
  data,
  maxPriority,
  onPriorityChange,
  onEdit,
  onDelete,
}) => {
  const { data: styleCategories } = useStyleCategoriesQuery();
  const { data: styleSubCategoryData } = useSubStyleCategoriesApiQuery();
  const { data: productLineGroupsData } = useProductLineGroupsApiQuery();
  const { data: businessModelsData } = useBusinessModelsApiQuery();
  const { data: businessModelGroupsData } = useBusinessModelGroupsApiQuery();
  const { data: storefrontTypesData } = useStorefrontTypesApiQuery();

  const storefrontTypeName = useMemo(() => {
    return storefrontTypesData?.find((x) => x.id === data.storefrontTypeId)?.name ?? 'All';
  }, [storefrontTypesData, data.storefrontTypeId]);

  const businessModelName = useMemo(() => {
    return businessModelsData?.find((x) => x.id === data.businessModelId)?.name ?? 'All';
  }, [businessModelsData, data.businessModelId]);

  const businessModelGroupName = useMemo(() => {
    return businessModelGroupsData?.find((x) => x.id === data.businessModelGroupId)?.name ?? 'All';
  }, [businessModelGroupsData, data.businessModelGroupId]);

  const styleCategoryName = useMemo(() => {
    return styleCategories?.find((x) => x.id === data.styleCategoryId)?.name ?? 'All';
  }, [styleCategories, data.styleCategoryId]);

  const styleSubCategoryName = useMemo(() => {
    return styleSubCategoryData?.find((x) => x.id === data.styleSubCategoryId)?.name ?? 'All';
  }, [styleSubCategoryData, data.styleSubCategoryId]);

  const productLineGroupName = useMemo(() => {
    return productLineGroupsData?.find((x) => x.id === data.productLineGroupId)?.name ?? 'All';
  }, [productLineGroupsData, data.productLineGroupId]);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: data.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <SimpleTableRow ref={setNodeRef} style={style}>
      <SimpleTableCell>
        <Stack alignItems={'center'} justifyContent={'center'}>
          <StyledDragIndicator {...attributes} {...listeners} />
        </Stack>
      </SimpleTableCell>
      <SimpleTableCell>
        <Stack>
          <Typography>{data.priority}</Typography>
        </Stack>
      </SimpleTableCell>
      <SimpleTableCell>
        <Stack>
          <Typography>{storefrontTypeName}</Typography>
        </Stack>
      </SimpleTableCell>
      <SimpleTableCell>
        <Stack>
          <Typography>{styleCategoryName}</Typography>
        </Stack>
      </SimpleTableCell>
      <SimpleTableCell>
        <Stack>
          <Typography>{styleSubCategoryName}</Typography>
        </Stack>
      </SimpleTableCell>
      <SimpleTableCell>
        <Stack>
          <Typography>{productLineGroupName}</Typography>
        </Stack>
      </SimpleTableCell>
      <SimpleTableCell>
        <Stack>
          <Typography>{data.lifeSpanWeeks < 0 ? infinitySymbol : `${data.lifeSpanWeeks} weeks`}</Typography>
        </Stack>
      </SimpleTableCell>
      <SimpleTableCell>
        <Stack>
          <Typography>{businessModelName}</Typography>
        </Stack>
      </SimpleTableCell>
      <SimpleTableCell>
        <Stack>
          <Typography>{businessModelGroupName}</Typography>
        </Stack>
      </SimpleTableCell>
      <SimpleTableCell>
        <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
          <IconButton disabled={data.priority === 0} onClick={() => onPriorityChange(data.priority, data.priority - 1)}>
            <ArrowUpward />
          </IconButton>
          <IconButton
            disabled={data.priority === maxPriority}
            onClick={() => onPriorityChange(data.priority, data.priority + 1)}
          >
            <ArrowDownward />
          </IconButton>
          <IconButton onClick={() => onEdit(data)}>
            <Edit color="primary" />
          </IconButton>
          <IconButton onClick={() => onDelete(data)}>
            <Delete color="error" />
          </IconButton>
        </Stack>
      </SimpleTableCell>
    </SimpleTableRow>
  );
};

export const StyledDragIndicator = styled(DragIndicator)`
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;
