import styled from '@emotion/styled';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Button, Card, CardContent, Collapse } from '@mui/material';
import { uniqBy } from 'lodash';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import Spacer from 'src/components/atoms/Spacer';
import { useFilterContext } from 'src/contexts/FilterContext';
import { FilterOption, GroupLevel, Spacings } from 'src/domain';
import Searchbar from '../Searchbar';
import SearchFilter from './SearchFilter';

interface Props {
  groupLevel: GroupLevel | null;
  chains: FilterOption[];
  countries: FilterOption[];
  clusters: FilterOption[];
  partners: FilterOption[];
  width: string;
}

const SearchAndFiltering: FC<Props> = ({ groupLevel, chains, countries, clusters, partners, width }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [filterContext, setFilterContext] = useFilterContext();

  const chainOptions = useMemo(() => uniqBy(chains, (chain) => chain.label), [chains]);
  const countryOptions = useMemo(() => uniqBy(countries, (country) => country.label), [countries]);
  const clusterOptions = useMemo(() => uniqBy(clusters, (cluster) => cluster.label), [clusters]);
  const partnerOptions = useMemo(() => uniqBy(partners, (partner) => partner.label), [partners]);
  const loading =
    chainOptions.isEmpty() && countryOptions.isEmpty() && clusterOptions.isEmpty() && partnerOptions.isEmpty();

  const sortOptions = (option: FilterOption[]) => {
    return option.sort((a, b) => a.label.localeCompare(b.label));
  };

  const handleUserInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterContext({
      ...filterContext,
      searchQuery: {
        ...filterContext.searchQuery,
        storeSelection: event.target.value,
      },
    });
  };

  const handleResetInputField = () => {
    setFilterContext({
      ...filterContext,
      searchQuery: {
        ...filterContext.searchQuery,
        storeSelection: '',
      },
    });
  };

  return (
    <Wrapper>
      <StyledSearchArea>
        <Searchbar
          placeholder={'Search for stores'}
          width={'100%'}
          updateUserInput={(e) => handleUserInput(e)}
          resetInputField={() => handleResetInputField()}
          value={filterContext.searchQuery.storeSelection}
        />
        <Spacer spacing={Spacings.xSmall} />
        <FiltersButton
          variant="contained"
          endIcon={showFilters ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          onClick={() => !loading && setShowFilters((prevVal) => !prevVal)}
        >
          Filters
        </FiltersButton>
      </StyledSearchArea>
      <Spacer spacing={Spacings.xSmall} vertical />
      <Collapse in={showFilters} sx={{ width }}>
        <Card sx={{ width }}>
          <StyledCardContent>
            <SearchFilter
              label="Chain"
              options={sortOptions(chainOptions)}
              value={filterContext.chains}
              onSelect={(filter: FilterOption[]) => {
                setFilterContext({
                  ...filterContext,
                  chains: filter,
                });
              }}
            />

            <SearchFilter
              label="Country"
              options={sortOptions(countryOptions)}
              value={filterContext.countries}
              onSelect={(filter: FilterOption[]) => {
                setFilterContext({
                  ...filterContext,
                  countries: filter,
                });
              }}
            />

            <SearchFilter
              label="Cluster"
              options={sortOptions(clusterOptions)}
              value={filterContext.clusters}
              disabled={groupLevel !== GroupLevel.Cluster}
              onSelect={(filter: FilterOption[]) => {
                setFilterContext({
                  ...filterContext,
                  clusters: filter,
                });
              }}
            />

            <SearchFilter
              label="Partner"
              options={sortOptions(partnerOptions)}
              value={filterContext.partners}
              onSelect={(filter: FilterOption[]) => {
                setFilterContext({
                  ...filterContext,
                  partners: filter,
                });
              }}
              disabled={!(filterContext.activeTab.partnersTab || filterContext.activeTab.semiOwnedTab)}
              className={
                filterContext.activeTab.partnersTab || filterContext.activeTab.semiOwnedTab ? '' : 'dropdown-disabled'
              }
            />
          </StyledCardContent>
        </Card>
      </Collapse>
    </Wrapper>
  );
};

const StyledCardContent = styled(CardContent)`
  padding: 16px 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const FiltersButton = styled(Button)`
  background-color: #979797;
  &:hover {
    background-color: #979797;
  }
`;

const StyledSearchArea = styled.div`
  display: flex;
  position: relative;
  height: 55px;
  width: 100%;
`;

export default SearchAndFiltering;
