import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { useChainId } from 'src/hooks/chain';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useScope } from 'src/hooks/useScope';
import { useStoreType } from 'src/hooks/useStoreType';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useStorefrontType } from '../useStorefrontType';

export const useStyleTimelineCollectionsQuery = (selectedStorefrontType?: string | null) => {
  const chainId = useChainId();
  const scope = useScope();
  const compositePartner = useCompositePartner();
  const defaultStorefrontType = useStorefrontType();
  const storefrontType = selectedStorefrontType ?? defaultStorefrontType;
  const storeType = useStoreType();

  const {
    data: partnerCollections,
    loading: partnerCollectionsLoading,
    error: partnerCollectionsError,
  } = useApiQuery('/api/styletimeline/partners/{partnerCompositeId}/collections', 'get', {
    path: {
      partnerCompositeId: compositePartner?.id,
    },
    query: {
      storefrontType,
    },
    enabled: scope === ScopeEnum.PARTNER && !!compositePartner?.id,
  });

  const {
    data: chainCollections,
    loading: chainCollectionsLoading,
    error: chainCollectionsError,
  } = useApiQuery('/api/styletimeline/chains/{chainId}/collections', 'get', {
    path: {
      chainId,
    },
    query: {
      storefrontType,
      storeType,
    },
    enabled: scope === ScopeEnum.GLOBAL && !!chainId,
  });

  return useMemo(
    () => ({
      data: partnerCollections ?? chainCollections,
      loading: partnerCollectionsLoading || chainCollectionsLoading,
      error: partnerCollectionsError ?? chainCollectionsError,
    }),
    [
      chainCollections,
      chainCollectionsError,
      chainCollectionsLoading,
      partnerCollections,
      partnerCollectionsError,
      partnerCollectionsLoading,
    ]
  );
};
