import { Inheritance } from 'src/domain';
import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useCategorySplitInheritanceQuery } from './useCategorySplitInheritanceQuery';

export const useCategorySplitQuery = (inheritance?: Inheritance, genderId?: number, productLineId?: number) => {
  const {
    data: styleCategories,
    error: styleCategoriesError,
    loading: styleCategoriesLoading,
  } = useStyleCategoriesQuery();

  const { data, expected, sanitizedData, error, loading } = useCategorySplitInheritanceQuery(
    inheritance,
    genderId,
    productLineId
  );

  return {
    expectedSplit: expected,
    sanitizedData,
    data,
    styleCategories: styleCategories,
    error: error ?? styleCategoriesError,
    loading: loading || styleCategoriesLoading,
  };
};
