import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';
import { msal } from 'src/auth/azure-authentication-config';

export const useLogin = () => {
  const { instance } = useMsal();

  const login = useCallback(
    () =>
      instance.loginRedirect(msal.loginRequest).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      }),
    [instance]
  );

  return login;
};
