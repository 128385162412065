import styled from '@emotion/styled';
import { BuildCircleOutlined, Edit, ErrorOutline, InfoOutlined, NewReleasesOutlined } from '@mui/icons-material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab';
import { Chip, Typography } from '@mui/material';
import { FC } from 'react';
import { StyledLoadingButton } from 'src/components/styled/LoadingButton';
import { AnnouncementRow } from 'src/domain/table/announcement.row';
import { useIsAdmin } from 'src/hooks/auth/useIsAdmin';
import { AnnouncementTypeEnum } from 'src/infrastructure/rest-api/api-types';

interface Props {
  announcements: AnnouncementRow[];
  handleEditAnnouncement: (announcement: AnnouncementRow) => void;
}

export const AnnouncementTimeline: FC<Props> = ({ announcements, handleEditAnnouncement }) => {
  const isAdmin = useIsAdmin();

  const announcementIsResolved = (announcement: AnnouncementRow) => {
    return (announcement.type === 'MajorIncident' || announcement.type === 'MinorIncident') && announcement.resolved;
  };

  const announcementColor = (announcementType: AnnouncementTypeEnum) => {
    switch (announcementType) {
      case 'Information':
      case 'Maintenance':
        return '#4472C4';

      case 'MinorIncident':
        return '#DBAB09';
      case 'MajorIncident':
        return '#D9512C';
    }
  };

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        marginLeft: '50px',
      }}
    >
      {announcements.map((announcement, index) => (
        <TimelineItem key={announcement.created?.toString()}>
          <TimelineSeparator>
            <TimelineItemDot
              sx={{ color: 'white', backgroundColor: announcementColor(announcement.type), boxShadow: 'none' }}
            >
              {announcement.type === 'Information' && <InfoOutlined sx={{ fontSize: '30px' }} />}
              {announcement.type === 'Maintenance' && <BuildCircleOutlined sx={{ fontSize: '30px' }} />}
              {announcement.type === 'MajorIncident' && <NewReleasesOutlined sx={{ fontSize: '30px' }} />}
              {announcement.type === 'MinorIncident' && <ErrorOutline sx={{ fontSize: '30px' }} />}
            </TimelineItemDot>
            {announcements.length - 1 !== index && <TimelineConnector sx={{ backgroundColor: '#D5D6DC' }} />}
          </TimelineSeparator>
          <TimelineContent sx={{ paddingBottom: '20px', paddingTop: '0px', px: 2 }}>
            <TitleWrapper>
              <AnnouncementTitle variant="h6" type={announcement.type}>
                {announcement.title}
              </AnnouncementTitle>
              {announcementIsResolved(announcement) && <ActiveChip label="Resolved" />}
              {isAdmin && (
                <StyledLoadingButton
                  size="small"
                  variant="contained"
                  startIcon={<Edit />}
                  onClick={() => {
                    handleEditAnnouncement({
                      id: announcement.id,
                      title: announcement.title,
                      description: announcement.description,
                      type: announcement.type,
                      module: announcement.module,
                      created: announcement.created,
                      modified: announcement.modified,
                      resolved: announcement.resolved,
                    });
                  }}
                  sx={{ height: '28px' }}
                >
                  Update
                </StyledLoadingButton>
              )}
            </TitleWrapper>
            {announcement.modified && (
              <Typography sx={{ color: '#787C8D', fontSize: '14px' }}>
                {announcement.modified &&
                  !announcement.resolved &&
                  `Updated ${announcement.modified.toLocaleTimeString([], {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}`}
              </Typography>
            )}
            <Typography sx={{ color: '#787C8D', fontSize: '14px' }}>
              {announcement.modified && !announcement.resolved && 'Created '}
              {announcement.created.toLocaleTimeString([], {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
              {announcement.resolved &&
                ` - ${announcement.resolved.toLocaleTimeString([], {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}`}
            </Typography>
            <Typography sx={{ whiteSpace: 'pre-wrap' }}>{announcement.description}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

const TimelineItemDot = styled(TimelineDot)`
  margin: 0;
  padding: 0;
`;

const AnnouncementTitle = styled(Typography)<{ type: AnnouncementTypeEnum }>`
  color: '#333';
`;

const ActiveChip = styled(Chip)`
  border-radius: 3px;
  height: 28px;
  background-color: ${(props) => (props.label === 'Active' ? '#B26253' : '#7FA179')};
  color: #fff;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
