import { Rating } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { isNumber } from 'lodash';
import { StyleTimelineOverviewRow } from 'src/domain/table/style-timeline-overview-row';

export const RatingCell = (componentProps: ICellRendererParams<StyleTimelineOverviewRow, number>) => {
  const { value } = componentProps;
  if (!isNumber(value)) return undefined;

  return <Rating name="read-only" value={value} readOnly />;
};
