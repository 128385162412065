import { Stack } from '@mui/material';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Container from 'src/components/atoms/Container';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { InheritanceSettingsColumn } from 'src/components/organisms/DataTable/columns/inheritance-settings-columns';
import Topbar from 'src/components/organisms/Topbar';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { InheritanceModule, Module } from 'src/domain';
import { useHasUnsavedChanges, useIsUsingGender, useIsUsingProductline } from 'src/hooks';
import { useIsUsingDeliveryProfile } from 'src/hooks/chain-settings/useIsUsingDeliveryProfile';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useInheritanceSettingsSaveChanges } from 'src/hooks/inheritance-settings/save-changes/useInheritanceSettingsSaveChanges';
import { useInheritanceSettingsSimulationRows } from 'src/hooks/inheritance-settings/simulation/useInheritanceSettingsSimulationRows';
import { useInheritanceSettingsHandleCellChanges } from 'src/hooks/inheritance-settings/useInheritanceSettingsHandleCellChanges';

export const InheritanceSettings: React.FC = () => {
  const { data: rows, loading: rowsLoading } = useInheritanceSettingsSimulationRows() ?? {};
  const handleCellValueChanges = useInheritanceSettingsHandleCellChanges();

  const [saveChanges, { loading: savingChanges }] = useInheritanceSettingsSaveChanges();
  const onDiscardChanges = useDiscardChangesModule(Module.InheritanceSettings);

  const hasUnsavedChanges = useHasUnsavedChanges();
  const deliveryProfileActive = useIsUsingDeliveryProfile();
  const genderSplitActive = useIsUsingGender();
  const productLineSplitActive = useIsUsingProductline();

  const columns = useMemo(() => {
    return InheritanceSettingsColumn.map((column) => {
      if (column.field === InheritanceModule.GenderSplit) {
        return {
          ...column,
          hide: !genderSplitActive,
        };
      }
      if (column.field === InheritanceModule.ProductLineSplit) {
        return {
          ...column,
          hide: !productLineSplitActive,
        };
      }
      if (column.field === InheritanceModule.DeliveryProfile) {
        return {
          ...column,
          hide: !deliveryProfileActive,
        };
      }
      return { ...column };
    });
  }, [deliveryProfileActive, genderSplitActive, productLineSplitActive]);

  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const handleGridReady = useCallback((event: GridReadyEvent) => {
    setGridApi(event.api);
  }, []);

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption('columnDefs', columns);
    }
  }, [columns, gridApi]);

  const loading = rowsLoading || savingChanges;

  return (
    <Stack spacing={2}>
      <Topbar
        onDiscard={onDiscardChanges}
        onSave={saveChanges}
        saveDisabled={!hasUnsavedChanges}
        loading={loading}
        discardDisabled={!hasUnsavedChanges}
        saveLoading={savingChanges}
        useModuleCard={true}
      />
      <Container>
        <TableWrapper className="ag-theme-alpine-custom-inheritance-settings">
          <DataTable
            autoSizeToFit
            loading={rowsLoading}
            rows={rows}
            columns={columns}
            onGridReady={handleGridReady}
            onCellValuesChanged={handleCellValueChanges}
            isNumberTable={false}
          />
        </TableWrapper>
      </Container>
    </Stack>
  );
};
