import { useCallback, useEffect, useState } from 'react';
import { StyleTimelineOverviewRow } from 'src/domain/table/style-timeline-overview-row';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { useStyleTimelineRows } from '../table/useStyleTimelineRows';

export function mergeRowsWithCache(cache: StyleTimelineOverviewRow[], rows: StyleTimelineOverviewRow[]) {
  return rows.reduce<StyleTimelineOverviewRow[]>((acc, row) => {
    const cachedRow = cache.find((x) => x.rowId === row.rowId);
    if (cachedRow) {
      acc.push(cachedRow);
    } else {
      acc.push(row);
    }
    return acc;
  }, []);
}

export const useStyleTimelineSimulationRows = () => {
  const { data, collections, loading, error } = useStyleTimelineRows();
  const [unsavedChanges] = useUnsavedChangesModule<StyleTimelineOverviewRow[]>();
  const [simulationData, setSimulationData] = useState<StyleTimelineOverviewRow[]>([]);
  const [cache, setCache] = useState<StyleTimelineOverviewRow[]>([]);

  const updateCache = useCallback((rows: StyleTimelineOverviewRow[]) => {
    setCache((prev) => {
      // Update cache with new rows, overwriting rows that matches new rows
      const newCache = [...prev];
      rows.forEach((row) => {
        const index = newCache.findIndex((x) => x.rowId === row.rowId);
        if (index !== -1) {
          newCache[index] = row;
        } else {
          newCache.push(row);
        }
      });
      return newCache;
    });
  }, []);

  useEffect(() => {
    if (unsavedChanges) {
      const newSimulationData = mergeRowsWithCache(cache, data).map((row) => {
        const simulatedRow = unsavedChanges.find((change) => change.rowId === row.rowId);
        if (simulatedRow) {
          return simulatedRow;
        }
        return row;
      });
      setSimulationData(newSimulationData);
    } else {
      setSimulationData(mergeRowsWithCache(cache, data));
    }
  }, [cache, data, unsavedChanges]);

  return { data: simulationData, collections, loading, error, updateCache };
};
