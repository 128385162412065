import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { FC } from 'react';

import devLogo from 'src/assets/img/logo-storeforecast-dev.svg';
import logo from 'src/assets/img/logo-storeforecast.svg';
import { VersionLog } from 'src/components/organisms/VersionLog/VersionLog';
import { useIsDevEnv } from 'src/hooks/useIsDevEnv';
import { StoreSelection } from '../StoreSelection/StoreSelection';

export const Frontpage: FC = () => {
  const isDevEnv = useIsDevEnv();

  return (
    <FrontPageWrapper>
      <StyledVersionLog />
      <Logo src={isDevEnv ? devLogo : logo} />
      <Box style={{ width: '100%' }}>
        <StoreSelection />
      </Box>
    </FrontPageWrapper>
  );
};

const FrontPageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
  max-width: 1800px;
  margin: 0 auto;
`;

const StyledVersionLog = styled(VersionLog)`
  position: absolute;
  left: 50px;
  top: 25px;
`;

const Logo = styled.img`
  margin-top: 100px;
  margin-bottom: 40px;
  margin-right: 100px;
  width: 400px;
`;
