import { CategoryOverviewRow, Module } from 'src/domain';
import { useCurrentGenderProductlineKey } from './useCurrentGenderProductlineKey';
import { useUnsavedChangesModule } from './useUnsavedChangesModule';

// TODO deleteand replace when Markdown has been reworked
export const useUnsavedChangesMarkdown = () => {
  const [unsavedChanges] = useUnsavedChangesModule<Record<string, CategoryOverviewRow[]>>(Module.Markdown);
  const genderProductlineKey = useCurrentGenderProductlineKey();

  return unsavedChanges?.[genderProductlineKey];
};
