import { Stack } from '@mui/material';
import { FC, useMemo } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { RowGroupCell } from 'src/components/molecules/RowGroupCell';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { DetailsColumnProductLineRowGroup } from 'src/components/organisms/DataTable/columns/column-details-productline-row-group';
import { DefaultColumn } from 'src/components/organisms/DataTable/columns/default-columns-details';
import {
  DefaultColumnOptionsDetails,
  DefaultColumnsOptionsOverviewPercentage,
} from 'src/components/organisms/DataTable/columns/default-options';
import { defaultRowGroupOptions } from 'src/components/organisms/DataTable/options/row-group-options';
import { SumBold } from 'src/components/organisms/DataTable/options/sum-bold';
import { validateSumIs100PercentProductLineSplit } from 'src/components/organisms/DataTable/validators/validateSumIs100Percent';
import DetailsTable from 'src/components/organisms/DetailsTable';
import InheritanceDropdown from 'src/components/organisms/InheritanceDropdown';
import { InheritanceTable } from 'src/components/organisms/InheritanceTable/InheritanceTable';
import Topbar from 'src/components/organisms/Topbar';
import { StyledHeaderWrapper } from 'src/components/styled/StyledHeaderWrapper';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { Headings, Module, ProductLineDetailsRow, ProductLineDetailsRowTuple } from 'src/domain';
import { useHasUnsavedChanges } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useProductLineSplitReset } from 'src/hooks/productline-split/reset/useProductLineSplitReset';
import { useProductLineSplitSaveChanges } from 'src/hooks/productline-split/save-changes/useProductLineSplitSaveChanges';
import { useProductLineSplitDetailsSimulationRows } from 'src/hooks/productline-split/simulation/useProductLineSplitDetailsSimulationRows';
import { useProductLineSplitOverviewSimulationRows } from 'src/hooks/productline-split/simulation/useProductLineSplitOverviewSimulationRows';
import { useProductLineSplitUpdateInheritance } from 'src/hooks/productline-split/split-level/useProductLineSplitUpdateInheritance';
import { useProductLineSplitDetailsRows } from 'src/hooks/productline-split/table/useProductLineSplitDetailsRows';
import { useProductLineSplitHandleCellValueChanged } from 'src/hooks/productline-split/useProductLineSplitHandleCellValueChanged';
import { useIsSum100 } from 'src/hooks/useIsSum100';
import { useModulePlanningRange } from 'src/hooks/useModulePlanningRange';
import { mergeTableDetailsRowValuesToTuples } from 'src/mapping/table.mapping';
import { getButtonTooltips } from 'src/utils/getTooltipText';

export const ProductLineSplit: FC = () => {
  const {
    data: overviewData,
    columns: monthColumns,
    loading: overviewLoading,
  } = useProductLineSplitOverviewSimulationRows() ?? {};
  const { data: detailsData, loading: detailsLoading } = useProductLineSplitDetailsRows() ?? {};
  const { data: detailsSimulationData, loading: detailsSimulationLoading } =
    useProductLineSplitDetailsSimulationRows() ?? {};
  const planningRange = useModulePlanningRange(Module.ProductLineSplit);

  const handleCellValueChanged = useProductLineSplitHandleCellValueChanged();
  const { updateInheritance, loading: updateInheritanceLoading } = useProductLineSplitUpdateInheritance();
  const { resetProductLineSplit, loading: resetLoading } = useProductLineSplitReset();

  const [saveChanges, { loading: savingChanges }] = useProductLineSplitSaveChanges();
  const onDiscardChanges = useDiscardChangesModule(Module.ProductLineSplit);

  const loading =
    overviewLoading ||
    detailsLoading ||
    updateInheritanceLoading ||
    resetLoading ||
    detailsSimulationLoading ||
    savingChanges;

  const overviewRows = !loading ? overviewData : undefined;

  const hasUnsavedChanges = useHasUnsavedChanges();
  const sumIs100 = useIsSum100(overviewRows);
  const canSave = hasUnsavedChanges && sumIs100;
  const { saveTooltip } = useMemo(() => getButtonTooltips(hasUnsavedChanges, sumIs100), [hasUnsavedChanges, sumIs100]);

  const detailsRows = useMemo(() => {
    if (loading) {
      return;
    }

    return mergeTableDetailsRowValuesToTuples<ProductLineDetailsRow, ProductLineDetailsRowTuple>(
      detailsData,
      detailsSimulationData,
      ['plannedSalesIv', 'plannedIndex']
    );
  }, [detailsData, detailsSimulationData, loading]);

  const columns = useMemo(() => {
    return [
      {
        ...DefaultColumn,
        field: 'category',
        hide: true,
        rowGroup: true,
        headerName: 'Productline',
      },
      {
        ...DefaultColumn,
        field: 'subCategory',
        hide: true,
        headerName: 'Category',
        suppressSizeToFit: true,
      },
      ...monthColumns,
    ];
  }, [monthColumns]);

  return (
    <Stack spacing={2}>
      <Topbar
        onDiscard={onDiscardChanges}
        onSave={saveChanges}
        saveDisabled={!canSave}
        saveTooltip={saveTooltip}
        onReset={resetProductLineSplit}
        onSetInheritance={updateInheritance}
        hideToggleButtons={true}
        saveLoading={savingChanges}
        loading={loading}
      />

      <Container>
        <StyledHeaderWrapper>
          <Header heading={Headings.h2}>Monthly Productline Split</Header>
          <InheritanceDropdown />
        </StyledHeaderWrapper>
        <TableWrapper className="ag-theme-alpine-custom-compact ag-sum ag-productline">
          <DataTable
            {...defaultRowGroupOptions}
            {...SumBold}
            autoSizeToFit={planningRange === 'TWELVE_MONTHS'}
            headerHeight={60}
            autoGroupColumnDef={{
              headerName: 'Category',
              field: 'subCategory',
              cellRenderer: RowGroupCell,
              cellRendererParams: {
                rowGroupField: 'category',
              },
              suppressSizeToFit: true,
            }}
            rows={overviewRows}
            columns={columns}
            loading={loading}
            defaultColDef={DefaultColumnsOptionsOverviewPercentage}
            onCellValuesChanged={handleCellValueChanged}
            grandTotalRow="bottom"
            cellValidationOptions={{
              columns: true,
              validationFunc: validateSumIs100PercentProductLineSplit,
            }}
          />
        </TableWrapper>
      </Container>
      <DetailsTable
        {...defaultRowGroupOptions}
        {...SumBold}
        monthColumns={monthColumns}
        autoGroupColumnDef={{
          headerName: 'Category',
          field: 'subCategory',
          cellRenderer: RowGroupCell,
          cellRendererParams: {
            rowGroupField: 'category',
          },
        }}
        key={'details-table'}
        title={'Productline Split Details'}
        wrapperClass={'ag-productline'}
        rows={detailsRows}
        columns={DetailsColumnProductLineRowGroup}
        loading={loading}
        defaultColDef={DefaultColumnOptionsDetails}
        onCellValuesChanged={handleCellValueChanged}
        grandTotalRow="bottom"
        cellValidationOptions={{
          columns: ['plannedSplit'],
          validationFunc: validateSumIs100PercentProductLineSplit,
        }}
      />
      <InheritanceTable moduleString="productLineSplit" />
    </Stack>
  );
};
