import { useMemo } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { useGenderId, useIsUsingGender } from 'src/hooks/gender';
import { useGenderSplitQuery } from 'src/hooks/gender-split/queries/useGenderSplitQuery';
import { useIsUsingProductline, useProductLineId } from 'src/hooks/productline';
import { useProductLineSplitQuery } from 'src/hooks/productline-split/queries/useProductLineSplitQuery';
import { styleCategoriesToGroupedByData } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useTurnoverQuery } from 'src/hooks/turnover/queries/useTurnoverQuery';
import { useScope } from 'src/hooks/useScope';
import { mapSplitToDetailsTableDataNew } from 'src/mapping/split-modules.mapping';
import { useCategorySplitQuery } from '../queries/useCategorySplitQuery';

export const useCategorySplitDetailsRows = () => {
  const { expectedSplit, styleCategories, loading: loadingCategorySplit, error } = useCategorySplitQuery();
  const { expectedSales, loading: loadingTurnover } = useTurnoverQuery() ?? {};
  const { expected: genderExpected, loading: loadingGender } = useGenderSplitQuery();
  const { expectedSplit: productLineExpected, loading: loadingProductLine } = useProductLineSplitQuery();
  const scope = useScope();
  const gender = useGenderId(Module.CategorySplit);
  const productLine = useProductLineId(Module.CategorySplit);
  const isUsingGender = useIsUsingGender();
  const isUsingProductLine = useIsUsingProductline();

  const loading = loadingCategorySplit || loadingTurnover || loadingGender || loadingProductLine;

  const tableData = useMemo(() => {
    if (loading) {
      return;
    }

    const groupedByData = styleCategoriesToGroupedByData(styleCategories);

    switch (scope) {
      case ScopeEnum.STORE:
        return mapSplitToDetailsTableDataNew({
          expectedSplit,
          groupedByData,
          expectedSalesIv: expectedSales,
          genderData: {
            expectedSplit: genderExpected,
            isUsingGender,
            activeGender: gender,
          },
          productLineData: {
            expectedSplit: productLineExpected,
            isUsingProductLine,
            activeProductLine: productLine,
          },
        });
      case ScopeEnum.CLUSTER:
        return mapSplitToDetailsTableDataNew({
          expectedSplit,
          groupedByData,
          expectedSalesIv: expectedSales,
          genderData: {
            expectedSplit: genderExpected,
            isUsingGender,
            activeGender: gender,
          },
          productLineData: {
            expectedSplit: productLineExpected,
            isUsingProductLine,
            activeProductLine: productLine,
          },
        });
      case ScopeEnum.PARTNER:
        return mapSplitToDetailsTableDataNew({
          expectedSplit,
          groupedByData,
          expectedSalesIv: expectedSales,
          genderData: {
            expectedSplit: genderExpected,
            isUsingGender,
            activeGender: gender,
          },
          productLineData: {
            expectedSplit: productLineExpected,
            isUsingProductLine,
            activeProductLine: productLine,
          },
        });

      default:
        return;
    }
  }, [
    expectedSales,
    expectedSplit,
    gender,
    genderExpected,
    isUsingGender,
    isUsingProductLine,
    loading,
    productLine,
    productLineExpected,
    scope,
    styleCategories,
  ]);

  return { data: tableData, loading, error };
};
