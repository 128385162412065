import { useCallback, useMemo, useState } from 'react';
import { Inheritance, Module, ScopeEnum } from 'src/domain';
import {
  useChangesSnackbar,
  useCurrentId,
  useDiscardChangesGenderProductline,
  useScope,
  useWeekCoverOverviewRows,
  useWeekCoverOverviewSimulationRows,
} from 'src/hooks';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useUpdateSplitLevel } from 'src/hooks/split-levels/useUpdateSplitLevel';
import { useEntityType } from 'src/hooks/useEntityType';
import { components } from 'src/infrastructure/rest-api/generated';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { CompareRowsResult, compareRows } from 'src/utils/compareRows';

function valueMapper(value: CompareRowsResult): components['schemas']['UpdateWeekCoverInput'] {
  return {
    id: value.id,
    weekCover: value.new,
  };
}

export const useSaveChangesWeekCover = () => {
  const scope = useScope();
  const currentId = useCurrentId();
  const compositePartner = useCompositePartner();
  const entityType = useEntityType();

  const showSnackbar = useChangesSnackbar();

  const discardChanges = useDiscardChangesGenderProductline(Module.WeekCover);

  const { updateSplitLevel: updateStoreSplitLevel } = useUpdateSplitLevel();

  const [mutateWeekCover] = useApiMutation('/api/weekcover', 'put', {
    update(data, _variables, queryClient) {
      const query = { entityId: Number(currentId), entityType };
      if (scope === ScopeEnum.PARTNER) {
        query['entityId'] = Number(compositePartner?.id);
      }
      queryClient.setQueryData(['/api/weekcover', query], data);
      queryClient.invalidateQueries({
        queryKey: ['/api/inheritance', query],
      });
    },
  });

  const { data: currentData } = useWeekCoverOverviewRows();
  const { data: simulatedData } = useWeekCoverOverviewSimulationRows();

  const valuesToUpdate = useMemo(() => compareRows(currentData, simulatedData), [currentData, simulatedData]);
  const [isUpdating, setIsUpdating] = useState(false);

  const sendUpdateQuery = useCallback(async () => {
    if (!valuesToUpdate) {
      return;
    }

    const payload = valuesToUpdate.map(valueMapper);
    if (scope === ScopeEnum.STORE) {
      await updateStoreSplitLevel(Inheritance.Typed);
    }

    return mutateWeekCover({
      body: payload,
    });
  }, [mutateWeekCover, scope, updateStoreSplitLevel, valuesToUpdate]);

  const updateWeekCover = useCallback(async () => {
    setIsUpdating(true);

    try {
      const response = await sendUpdateQuery();
      discardChanges();
      showSnackbar();
      return response;
    } catch {
      showSnackbar(true);
    } finally {
      setIsUpdating(false);
    }
  }, [discardChanges, sendUpdateQuery, showSnackbar]);

  return [updateWeekCover, { loading: isUpdating }] as const;
};
