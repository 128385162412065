import { Box } from '@mui/material';
import { FC, useMemo } from 'react';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { useProjectedOptionCountHeatMapRows } from 'src/hooks/projected-option-count/table/useProjectedOptionCountHeatMapRows';
import { HeatMapTypeEnum } from 'src/mapping/projected-option-count.mapping';
import { categoryColumn, defaultColDef } from './data/columns';

export interface HeatMapTableProps {
  type: HeatMapTypeEnum;
}

export const ProjectedOptionCountHeatMapTable: FC<HeatMapTableProps> = ({ type }) => {
  const { data: rows, columns: monthColumns, loading } = useProjectedOptionCountHeatMapRows(type);

  const columns = useMemo(() => {
    return [
      {
        ...categoryColumn,
        headerName: type === HeatMapTypeEnum.Category ? 'Category' : 'Placement',
      },
      ...monthColumns,
    ];
  }, [monthColumns, type]);

  return (
    <Box className="ag-theme-alpine-custom-compact ag-sum">
      <DataTable
        autoSizeToFit
        headerHeight={60}
        rows={rows}
        columns={columns}
        defaultColDef={defaultColDef}
        loading={loading}
        domLayout="autoHeight"
      />
    </Box>
  );
};
