import { ColDef } from 'ag-grid-community';
import { ComparableCell } from 'src/components/molecules/ComparableCell/ComparableCell';
import { EditablePercentageCell } from 'src/components/molecules/EditableCell/EditableCell';
import { NoosShareDetailsColumns } from 'src/hooks/noos-share/useNoosShareDetailsTableColumnNames';
import { DefaultColumn, DefaultColumnNumeric } from './default-columns-details';
import { isEditableWithFooter } from './is-editable';
import { parsePercentageValueToNumber } from './utils';

export const ColumnsDetailsNoos = (columnNames: NoosShareDetailsColumns): ColDef[] => [
  {
    ...DefaultColumn,
    field: 'category',
    width: 150,
    headerName: 'Category',
    suppressSizeToFit: true,
  },
  {
    ...DefaultColumnNumeric,
    field: 'sharePercentLLY',
    headerName: columnNames.sharePercentLLY,
  },
  {
    ...DefaultColumnNumeric,
    field: 'sharePercentLY',
    headerName: columnNames.sharePercentLY,
  },
  {
    ...DefaultColumnNumeric,
    field: 'plannedPercent',
    headerName: columnNames.plannedPercent,
    cellRenderer: EditablePercentageCell,
    editable: isEditableWithFooter,
    valueParser: parsePercentageValueToNumber,
  },
  {
    ...DefaultColumnNumeric,
    field: 'plannedValue',
    headerName: columnNames.plannedValue,
    cellRenderer: ComparableCell,
    valueParser: parsePercentageValueToNumber,
  },
  {
    ...DefaultColumnNumeric,
    field: 'shareValueLY',
    headerName: columnNames.shareValueLY,
  },
  {
    ...DefaultColumnNumeric,
    field: 'shareValueLLY',
    headerName: columnNames.shareValueLLY,
  },
  {
    ...DefaultColumnNumeric,
    field: 'gmLY',
    headerName: 'GM % LY',
  },
];
