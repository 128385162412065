import { CellClassParams } from 'ag-grid-community';

export const validateSumIs100Percent = ({ column, node, value }: CellClassParams): boolean => {
  const colId = column.getColId();
  const parentData = Number(node.parent?.aggData?.[colId]);

  if (node.group) {
    return Math.round(value * 100) / 100 !== 100;
  }

  if (!isNaN(parentData)) {
    return Math.round(parentData * 100) / 100 !== 100;
  }

  return false;
};

export const validateSumIs100PercentProductLineSplit = ({ column, node, value }: CellClassParams): boolean => {
  const colId = column.getColId();
  const parentData = node.parent?.aggData?.[colId];

  // Recurse all the way to the top group for productline split - this handles both the row grouping and the sum row
  if (node.parent && typeof parentData === 'number') {
    return validateSumIs100PercentProductLineSplit({ column, node: node.parent, value: parentData } as CellClassParams);
  }

  // Validate the value for the group
  if (node.group) {
    return Math.round(value * 100) / 100 !== 100;
  }

  return false;
};
