import { getDefaultProductlineIdFromChainId } from 'src/utils/getDefaultProductlineIdFromChainId';
import { useChainId } from '../chain/useChainId';
import { useProductLineGroupsApiQuery } from '../useProductLineGroupsApiQuery';

export const useDefaultProductLine = () => {
  const chainId = useChainId();
  const { data: productLines, loading, error } = useProductLineGroupsApiQuery();

  const defaultProductLineId = getDefaultProductlineIdFromChainId(chainId);
  const defaultProductLine = productLines?.find((x) => x.id === defaultProductLineId);

  return [defaultProductLine, { loading, error }] as const;
};
