import defaultErrorMessage from './defaultErrorMessage';
import { useSnackbar } from './useSnackbar';

function getErrorMessage(error?: Error | null | string | true) {
  if (typeof error === 'string') {
    return error;
  }

  if (error === true) {
    // Default error message to use
    return defaultErrorMessage;
  }

  return error?.message;
}

export const useChangesSnackbar = () => {
  const showDefaultSnackbar = useSnackbar();

  const showSnackbar = (error?: Error | null | string | true) => {
    const timeToEffect = 30 - (new Date().getMinutes() % 30) + 30; // closest 30 min + an additional 30 mins to account for the transfer job
    const errorMessage = getErrorMessage(error);
    showDefaultSnackbar(
      errorMessage ?? `Changes have been saved and will be available in Storeforecast in ${timeToEffect} minutes`,
      {
        key: 'changes',
        autoHideDuration: 10000,
        variant: error ? 'error' : 'success',
        preventDuplicate: true,
      }
    );
  };

  return showSnackbar;
};
